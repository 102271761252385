import React, { useState } from "react";
import "./ticketList.scss";
import { _payInfo } from "recoil/state";
import { useRecoilState } from "recoil";
import { immers, comma } from "utils/util";

const TicketList = ({ ticketList }) => {
  const [choice, setChoice] = useState(0);
  const [payInfo, setPayInfo] = useRecoilState(_payInfo);

  // 이용권 선택
  const choiceTicket = async (idx, ticket) => {
    setChoice(idx);
    const val = ticket.dc_amount ? ticket.dc_amount : ticket.amount;
    setPayInfo(immers(payInfo, ["name", "amount"], [ticket.name, val]));
  };
  return (
    <div className="ticket_wrap">
      {ticketList.map((ticket, idx) => {
        return (
          <div
            key={idx}
            onClick={() => choiceTicket(idx, ticket)}
            className={`ticket_node ${choice === idx && "active"}`}
          >
            <div className="ticket_info">
              <h4 className="ticket_name">{ticket.name}</h4>
              <div className="price">
                {ticket.discount ? (
                  <del>₩{comma(ticket.amount)}</del>
                ) : (
                  <span>₩{comma(ticket.amount)}</span>
                )}

                {ticket.dc_amount && ticket.discount ? (
                  <span>₩{comma(ticket.dc_amount)}</span>
                ) : null}
              </div>
            </div>
            <button className="buy_button">구매</button>
            <img
              className="buy_check"
              src="https://indj.s3.ap-northeast-2.amazonaws.com/image/web_player/ticket/check.png"
              alt="체크이미지"
            />
          </div>
        );
      })}
    </div>
  );
};

export default TicketList;
