import React, { useEffect, useState } from 'react';
import './Check.scss';
import { useRecoilState } from 'recoil';
import { _footer } from 'recoil/state';
import CheckCalendar from './calendar/calendar';
import { postTokenCheckApi2 } from 'apis/apis';
import { useParams } from 'react-router';

const Check = () => {
  const [_, setFooter] = useRecoilState(_footer);
  const [ready, setReady] = useState(false);
  const { token } = useParams();
  console.log(token);
  const checkToken = async () => {
    const { data } = await postTokenCheckApi2(token);

    if (data.success) {
      window.localStorage.setItem('currentUser', token);
      setReady(true);
    }
  };

  useEffect(() => {
    setFooter(false);
    checkToken();
  }, []);

  return (
    <div id="checkWrap">
      <div className="event">
        <div className="event__circle1">
          <img src="/../images/check/main-circle_1.png" alt="circle1" className="circle__c1"></img>
          <div className="event__text1">
            <span className="text1__t1">매일매일 출석 도장 찍고</span>
            <div>
              <span className="text1__t2">음악 감상</span>
              <span className="text1__t3"> 및 </span>
              <span className="text1__t4">METABX</span>
            </div>
            <span className="text1__t5">혜택받자!</span>
          </div>
          <img src="/../images/check/check-stamp.png" className="check-stamp" />
        </div>
        <div className="event__circle2">
          <img src="/../images/check/circle2.png" className="circle__c2"></img>
        </div>
        <div className="event__date">
          {/*  점수때문에 texts 로 div하나더  */}
          <div className="date__texts">
            <span className="date__text">이벤트기간</span>
          </div>
          <ul className="date__days">
            <li className="date__day"> 2022.09.05 ~ 2022.10.31</li>
          </ul>
        </div>
        <div className="event__notice">
          {/*  점수때문에 texts 로 div하나더  */}
          <div className="notice__texts">
            <span className="notice__text">참여안내</span>
          </div>
          <ul className="notice__list">
            <li className="notice_1">
              매일매일 출석시 100% METABX 증정
              <br />
            </li>
            <li className="notice_1"> 보상 조건 : 1회 / 1일 한정, 5 METABX</li>
            <li className="notice_1"> 음악 감상은 광고 시청 후 가능</li>
            <li className="notice_1"> 한달 개근 시 100 METABX 추가 증정</li>
          </ul>
        </div>
        <img className="event__line" src="/../images/check/eventline.png" alt="eventLine"></img>
      </div>
      {ready && <CheckCalendar />}
    </div>
  );
};

export default Check;
