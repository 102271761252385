import React, { useState, useEffect, useRef } from "react";
import { addUserApi } from "apis/mbti";

const WebtoonImg = ({ fuc }) => {
  const gif = useRef(null);
  const [dot, setDot] = useState("");

  useEffect(() => {}, []);

  // 사원증 위치 때려맞추기
  const size = () => {
    const img = gif.current;
    const imgWidth = img.clientWidth;
    const imgHeight = img.clientHeight;
    const one = imgWidth * 0.6;
    const two = imgHeight * 0.7;
    const three = imgWidth * 0.9;
    const four = imgHeight * 0.95;
    const coords = `${one},${two},${three},${four}`;
    setDot(coords);
  };

  // 사원증부분 클릭
  const onEaster = async () => {
    fuc(true);
    await addUserApi(2);
  };

  return (
    <>
      <img
        ref={gif}
        onLoad={() => size()}
        className="webtoon_img"
        src="/../images/weatherTest/ex.gif"
        useMap="#easterEgg"
        alt="홍보이미지"
      />
      <map name="easterEgg">
        <area
          shape="rect"
          alt="이스터에그"
          coords={dot}
          onClick={() => onEaster()}
        />
      </map>
    </>
  );
};

export default WebtoonImg;
