import React, { useState, useEffect } from "react";
import { result } from "./data";
import LazyLoad from "react-lazyload";
import KakaoShare from "./KakaoShare";
import { shareTwitter, shareFacebook, copyURL } from "./Share";
import RecoSong from "./RecoSong";
import ReactHelmet from "../Helmet/Helmet";
import { addUserApi } from "apis/mbti";

const Result = ({ match, history, location }) => {
  const [device, setdevice] = useState(0);
  const yourType = result[match.params.type];
  const yourGood = result[result[match.params.type].good];
  const yourBad = result[result[match.params.type].bad];
  const rannum = Math.floor(Math.random() * 3);
  const recoChannel = result[match.params.type].channel[rannum];
  useEffect(() => {
    // 사용자 카운트
    addUserApi(0);
    checkDevice();
    // 푸터 삭제
    document.getElementById("footer").style.display = "none";
    // 스크롤 위로 이동
    window.scrollTo({ top: 0, behavior: "auto" });
  }, []);

  // 기기체크
  const checkDevice = () => {
    const varUA = navigator.appVersion.toLowerCase(); //userAgent 값 얻기

    if (varUA.indexOf("android") > -1 || varUA.indexOf("window") > -1) {
      //안드로이드
      setdevice(1);
    } else if (
      varUA.indexOf("iphone") > -1 ||
      varUA.indexOf("ipad") > -1 ||
      varUA.indexOf("ipod") > -1 ||
      varUA.indexOf("mac") > -1
    ) {
      //IOS
      setdevice(2);
    }
  };

  // 처음으로 가기
  const goBack = () => {
    history.push("/beforetest");
  };
  // 스토어 가기
  const goToStore = () => {
    if (device === 2) {
      window.location.assign(
        "https://apps.apple.com/kr/app/indj-%EC%9E%90%EC%9C%A0%EB%A1%AD%EA%B2%8C-%EB%93%A3%EB%8A%94-%EC%83%81%ED%99%A9-%EA%B0%90%EC%84%B1-%EC%9D%B8%EA%B3%B5%EC%A7%80%EB%8A%A5-%EC%9D%8C%EC%95%85/id1513542512"
      );
    } else {
      window.location.assign(
        "https://play.google.com/store/apps/details?id=com.indj.music"
      );
    }
  };

  return (
    <div className="before_result">
      <ReactHelmet
        keywords="inDJ,음악취향,음악취향테스트,조선시대,계급,mbti,음악추천,무료음악,음악어플,멜론,지니,벅스,유튜브뮤직,음악,music"
        description={yourType.title}
        title="음악취향으로 알아보는 나의 조선시대 계급"
        favicon={yourType.thum}
      />
      <div className="result-wrap">
        <LazyLoad offset={100}>
          <img className="result-img" src={yourType.img} alt="결과이미지" />
        </LazyLoad>
        <div className="result-desc">
          <div className="result-top">
            <p className="modifier">{yourType.mod}</p>
            <p className="title">{yourType.title}</p>
            {yourType.desc.map((el, idx) => {
              return (
                <p key={idx} className="desc">
                  {el}
                  <br />
                  <br />
                </p>
              );
            })}
          </div>
          <div className="chemi">
            <div className="chemis">
              <p className="chemi-text">궁합이 좋소</p>
              <LazyLoad offset={100}>
                <img
                  className="chemi-img"
                  src={yourGood.img}
                  alt="케미캐릭터 이미지"
                />
              </LazyLoad>
              <p className="chemi-title">{yourGood.title}</p>
            </div>
            <div className="chemis">
              <p className="chemi-text">궁합이 좋지않소</p>
              <LazyLoad offset={100}>
                <img
                  className="chemi-img"
                  src={yourBad.img}
                  alt="케미캐릭터 이미지"
                />
              </LazyLoad>
              <p className="chemi-title">{yourBad.title}</p>
            </div>
          </div>

          <div className="result-channel">
            <p className="result-channel-text">인디제이 추천 마당!</p>
            <LazyLoad offset={100}>
              <img
                className="result-channel-img"
                src={recoChannel.ch_img}
                alt="결과이미지"
                onClick={() => goToStore()}
              />
            </LazyLoad>
            <p className="result-channel-name">{recoChannel.ch_name}</p>

            <div className="result-channel-songs">
              {recoChannel
                ? recoChannel.ch_song.map((el, idx) => {
                    return (
                      <RecoSong
                        key={`${el.title}-${idx}`}
                        img={el.thumbnail}
                        title={el.title}
                        singer={el.singer}
                      />
                    );
                  })
                : null}
              <span>{`외 ${recoChannel.other} 가락`}</span>
            </div>
          </div>
          <div className="go-to-wrap">
            <button
              onClick={() => goBack()}
              className="go-to-store"
              type="button"
            >
              시험 다시보기
            </button>
            <button
              onClick={() => goToStore()}
              className="go-to-store"
              type="button"
            >
              스토어 바로가기
            </button>
            <div className="real-store-btn-wrap">
              <button className="real-store-btn" type="button">
                <LazyLoad offset={100}>
                  <img
                    className="real-store-img"
                    src="/../images/mbti/mbti_app_store.png"
                    alt="공유이미지"
                    onClick={() => goToStore()}
                  />
                </LazyLoad>
              </button>
              <button className="real-store-btn" type="button">
                <LazyLoad offset={100}>
                  <img
                    className="real-store-img"
                    src="/../images/mbti/mbti_play_store.png"
                    alt="공유이미지"
                    onClick={() => goToStore()}
                  />
                </LazyLoad>
              </button>
            </div>
          </div>

          <div className="share">
            <p className="share-text">벗들에게 공유하기</p>
            <div className="share-btn-wrap">
              <KakaoShare desc={yourType.title} img={yourType.thum} />
              <button className="share-node">
                <div className="share-img-wrap">
                  <LazyLoad offset={100}>
                    <img
                      className="share-img"
                      src="/../images/mbti/test_facebook.png"
                      alt="공유이미지"
                      onClick={() => shareFacebook(window.location.href)}
                    />
                  </LazyLoad>
                </div>
                <p className="platform-text">페북으로 공유</p>
              </button>
              <button className="share-node">
                <div className="share-img-wrap">
                  <LazyLoad offset={100}>
                    <img
                      className="share-img"
                      src="/../images/mbti/test_twitter.png"
                      alt="공유이미지"
                      onClick={() => shareTwitter(window.location.href)}
                    />
                  </LazyLoad>
                </div>
                <p className="platform-text">트위터로 공유</p>
              </button>
              <button onClick={() => copyURL()} className="share-node">
                <div className="share-img-wrap">
                  <LazyLoad offset={100}>
                    <img
                      className="share-img"
                      src="/../images/mbti/test_link.png"
                      alt="공유이미지"
                    />
                  </LazyLoad>
                </div>
                <p className="platform-text">링크 복사</p>
              </button>
            </div>
          </div>
          <LazyLoad offset={100}>
            <img
              className="gentle_logo"
              src="/../images/gentle_logo.png"
              alt="인디제이 로고"
            />
          </LazyLoad>
          <p className="rnd">인공지능 가락 연구소</p>
        </div>
      </div>
    </div>
  );
};
export default Result;
