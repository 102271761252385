import React from "react";

const Loading = () => {
  return (
    <div
      style={{ backgroundColor: "#181818", height: "100vh" }}
      className="app dk"
      id="app"
    >
      <div className="all loading">
        <img src="/images/loading.gif" alt=""></img>
      </div>
    </div>
  );
};
export default Loading;
