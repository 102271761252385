import React, { useEffect } from 'react';
import './header.scss';
import { useRecoilState } from 'recoil';
import { _footer } from 'recoil/state';
import { Link } from 'react-router-dom';

const ModaHeader = () => {
  const [_, setFooter] = useRecoilState(_footer);
  useEffect(() => {
    setFooter(false);
  }, []);
  return (
    <header id="moda_header">
      <div className="header">
        <h1>
          <Link to="/moda">MODA</Link>
        </h1>
        <ul className="header_ul">
          <li className="header_li">
            <button>서비스 소개</button>
          </li>
          <li className="header_li">
            <button>캠페인</button>
          </li>
          <li className="header_li">
            <button>활동 관리</button>
          </li>
        </ul>
        <div className="login">
          <div>
            <button>로그인 / 회원가입</button>
          </div>
          <div>
            <button className="q">문의하기</button>
          </div>
        </div>
      </div>
    </header>
  );
};
export default ModaHeader;
