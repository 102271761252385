import Main from './pages';
import Platform from './pages/Platform';
import Changepw from './pages/Changepw';
import TermsPay from './pages/Terms_pay';
import TermsUse from './pages/Terms_use';
import TermsError from './pages/Terms_error';
import TermsLoc from './pages/Terms_loc';
import TermsInfo from './pages/Terms_info';
import TermsTeenager from './pages/Terms_teenager';
import Enrollment from './pages/Enrollment';
import ErrMoment from './pages/Err_moment';
import ErrRedown from './pages/Err_redown';
import ErrServer from './pages/Err_server';
import BeforeTest from './pages/Before_test/Before_test';
import ResultPage from './pages/Before_test/Result_page';
import WeatherTest from './pages/Weather_test/Weather_test';
import WeatherResult from './pages/Weather_test/Result_page';
import Webtoon from './pages/Webtoon/Webtoon';
import WebtoonContents from './pages/Webtoon/contents';
import TicketTab from './pages/Ticket/ticketTab';
import Promotion from './pages/promotion/promotion';
import Airdrop from './pages/Event/Airdrop/airdrop';
import Check from './pages/Event/Check/Check';
import Newuser from './pages/Event/Newuser/Newuser';
import Station from './pages/Event/Station/Station';
import Redirect from './pages/Event/Redirect/redirect';
import Advert from './pages/Event/Advert/Advert';
import StationVote from './pages/Event/StationVote/StationVote';
import Prizewinner from './pages/Event/Prizewinner/Prizewinner';
import Moda from './pages/designZoos/moda';
import ModaDetail from './pages/designZoos/detail/modaDetail';
import MailOrder from './pages/Mailorder';

const routes = [
  { path: '/', exact: true, component: Main },
  { path: '/platform', component: Platform },
  { path: '/changepw', component: Changepw },
  { path: '/terms_pay', component: TermsPay },
  { path: '/terms_use', component: TermsUse },
  { path: '/terms_error', component: TermsError },
  { path: '/terms_loc', component: TermsLoc },
  { path: '/terms_private', component: TermsInfo },
  { path: '/terms_teenager', component: TermsTeenager },
  { path: '/enrollment/:idx', component: Enrollment },
  { path: '/errmoment', component: ErrMoment },
  { path: '/errredown', component: ErrRedown },
  { path: '/errserver', component: ErrServer },
  { path: '/beforetest', component: BeforeTest },
  { path: '/result/:type', component: ResultPage },
  { path: '/weathertest', component: WeatherTest },
  { path: '/weatherresult/:type', component: WeatherResult },
  { path: '/webtoon', component: Webtoon },
  { path: '/webtooncon/:num', component: WebtoonContents },
  { path: '/ticket/:token', component: TicketTab },
  { path: '/promotion', component: Promotion },
  { path: '/airdrop/:code', component: Airdrop },
  { path: '/check/:token', component: Check },
  { path: '/newuser', component: Newuser },
  { path: '/station', component: Station },
  { path: '/redirect', component: Redirect },
  { path: '/advert', component: Advert },
  { path: '/stationvote', component: StationVote },
  { path: '/prizewinner', component: Prizewinner },
  { path: '/moda', component: Moda },
  { path: '/moda_detail', component: ModaDetail },
  { path: '/mailorder', component: MailOrder },
];

export default routes;
