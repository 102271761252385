import React, { useState, useEffect, useRef } from "react";
import { webtoonLogo, webtoonText, webtoonInsta, webtoonBlog } from "../svg";
import WebtoonList from "./Webtoon_list";
import ReactHelmet from "../Helmet/Helmet";
import list from "./list.json";
import DropDown from "../parts/dropDown";

const Webtoon = ({ history }) => {
  const [imgHeight, setImgHeight] = useState(0); // 설명이미지 세로사이즈
  const [wrapwidth, setwrapwidth] = useState(0); // 전체 가로사이즈
  const [miriList, setMiriList] = useState([]);

  const wrapWidth = useRef(null);
  const header = useRef(null);
  const descImg = useRef(null);

  useEffect(() => {
    setMiriList(list.list);
    size();
  }, []);

  // 크기 측정
  const size = () => {
    const height = descImg.current.clientWidth * 0.391; // 설명 이미지 세사이즈
    setImgHeight(height);
    const width = wrapWidth.current.clientWidth; // wrap 가로사이즈
    setwrapwidth(width * 0.20531 - 50);
  };

  return (
    <div id="webtoon">
      <ReactHelmet
        keywords="inDJ,음악취향,음악취향테스트,웹툰,음악추천,무료음악,음악어플,멜론,지니,벅스,유튜브뮤직,음악,music"
        description="차원이동을 하게 된 인기 프로듀서 제이든이 오합지졸 동료들과 공주를 구하러 가는 판타지 개그물"
        title="inDJ Toon"
        favicon="https://d3coxo32tidngo.cloudfront.net/image/webtoon/w_thum_list.png"
      />
      <div ref={wrapWidth} className="wrap">
        <div style={{ marginBottom: wrapwidth }} ref={header} id="header">
          <img
            className="mo-logo"
            src="/../images/text_logo.png"
            alt="인디제이 로고"
          />
          <div className="header-text">
            <DropDown />
            <button className="head-btn">
              <a href="https://policy.indj.club/webtoon">웹툰</a>
            </button>
          </div>
        </div>
        <div className="header">
          <div className="icons">
            <div className="logo">
              {webtoonLogo()}
              {webtoonText()}
            </div>
            <div className="sns">
              <a href="https://www.instagram.com/indj_toon/">
                {webtoonInsta()}
              </a>
              <a href="https://blog.naver.com/indj_official">{webtoonBlog()}</a>
            </div>
          </div>
        </div>
        <div className="desc">
          <div ref={descImg} className="desc_img">
            <div style={{ height: imgHeight }} className="desc_text">
              <h2 className="desc_text_title">제발 우리 어플좀 써주세요</h2>
              <p className="desc_text_sub">차원이동을 하게 된 인기 프로듀서</p>
              <p className="desc_text_sub">제이든이 오합지졸 동료들과 공주를</p>
              <p className="desc_text_sub">구하러 가는 판타지 개그물</p>
            </div>
          </div>
        </div>
        <ul className="webtoon_list">
          <WebtoonList history={history} webtoon_list={miriList} />
        </ul>
      </div>
    </div>
  );
};
export default Webtoon;
