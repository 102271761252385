import React from 'react';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { _footer } from 'recoil/state';
const Redirect = () => {
  const [_, setFooter] = useRecoilState(_footer);
  useEffect(() => {
    setFooter(false);
  }, []);

  useEffect(() => {
    document.location = 'http://apps.apple.com/app/id1513542512';
  }, []);

  return <div></div>;
};

export default Redirect;
