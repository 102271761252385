import React from 'react';
import TermsUp from './Terms_up';
import { NavLink } from 'react-router-dom';
import '../css/terms.css';
import { Upload } from 'antd';

const toTop = () => {
  window.scroll({
    behavior: 'smooth',
    left: 0,
    top: 0,
  });
};

const Terms_loc = ({ match }) => {
  return (
    <div className="app dk" id="app">
      {/* <Sideheader></Sideheader> */}
      <div id="content" className="app-content mopt white bg box-shadow-z2" role="main">
        {/* <Play></Play> */}
        <div id="subContentsWrap" className="noDepth">
          {/* 사이드바 생성시 클래스에 wpl추가 */}
          <TermsUp></TermsUp>
          <h2 className="contentTitle nobar">inDJ 위치정보 이용약관</h2>

          <div className="innerWrapin subContents">
            <div className="ruleBlock">
              <div className="ruleBlockin">
                <div className="ruleCont">
                  <h4 className="tit">제1조 (목적)</h4>
                  <ol className="number-list">
                    <li>
                      본 약관은 주식회사 인디제이(이하 "회사")가 제공하는 위치기반서비스에 대해 회사와 위치기반서비스를
                      이용하는 개인위치정보주체(이하 "이용자")간의 권리·의무 및 책임사항, 기타 필요한 사항 규정을
                      목적으로 합니다.
                    </li>
                  </ol>
                </div>

                <div className="ruleCont">
                  <h4 className="tit">제2조 (이용약관의 효력 및 변경)</h4>
                  <ol className="number-list">
                    <li>
                      ①본 약관은 서비스를 신청한 고객 또는 개인위치정보주체가 본 약관에 동의하고 회사가 정한 소정의
                      절차에 따라 서비스의 이용자로 등록함으로써 효력이 발생합니다.
                    </li>
                    <li>
                      ② 회사는 법률이나 위치기반서비스의 변경사항을 반영하기 위한 목적 등으로 약관을 수정할 수 있습니다.
                    </li>
                    <li>
                      ③약관이 변경되는 경우 회사는 변경사항을 최소 7일 전에 회사의 홈페이지 등 기타 공지사항 페이지를
                      통해 게시합니다.
                    </li>
                    <li>
                      ④단, 개정되는 내용이 이용자 권리의 중대한 변경이 발생하는 경우에는 30일 전에 개정 사항을
                      게시합니다.
                    </li>
                  </ol>
                </div>

                <div className="ruleCont">
                  <h4 className="tit">제3조 (약관 외 준칙)</h4>
                  <ol className="num-circle-list">
                    <li>
                      본 약관은 신의성실의 원칙에 따라 공정하게 적용하며, 본 약관에 명시되지 아니한 사항에 대하여는
                      관계법령 또는 상관례에 따릅니다.
                    </li>
                  </ol>
                </div>

                <div className="ruleCont">
                  <h4 className="tit">제4조 (서비스의 내용)</h4>
                  <ol className="num-circle-list">
                    <li>
                      회사는 직접 수집하거나 위치정보사업자로부터 수집한 이용자의 현재 위치 또는 현재 위치가 포함된
                      지역을 이용하여 아래와 같은 위치기반서비스를 제공합니다.
                    </li>
                  </ol>
                </div>

                <div className="ruleCont">
                  <h4 className="tit">제5조 (서비스 이용요금)</h4>
                  <ol className="num-circle-list">
                    <li>
                      회사가 제공하는 위치기반서비스는 무료입니다. 단, 무선 서비스 이용 시 발생하는 데이터 통신료는
                      별도이며, 이용자가 가입한 각 이동통신사의 정책에 따릅니다.
                    </li>
                  </ol>
                </div>

                <div className="ruleCont">
                  <h4 className="tit">제6조 (서비스 이용의 제한·중지)</h4>
                  <ol className="num-circle-list">
                    <li>
                      ①회사는 위치기반서비스사업자의 정책변경 등과 같이 회사의 제반사정 또는 법률상의 이유로
                      위치기반서비스를 유지할 수 없는 경우 위치기반서비스의 전부 또는 일부를 제한·변경·중지할 수
                      있습니다.
                    </li>
                    <li>
                      ②단, 위 항에 의한 위치기반서비스 중단의 경우 회사는 사전에 회사 홈페이지 등 기타 공지사항 페이지를
                      통해 공지하거나 이용자에게 통지합니다.
                    </li>
                  </ol>
                </div>

                <div className="ruleCont">
                  <h4 className="tit">제7조 (개인위치정보주체의 권리)</h4>
                  <ol className="num-circle-list">
                    <li>
                      ①이용자는 언제든지 개인위치정보의 수집·이용·제공에 대한 동의 전부 또는 일부를 유보할 수 있습니다.
                    </li>
                    <li>
                      ②개인위치정보주체는 개인위치정보의 수집ㆍ이용ㆍ제공에 대한 동의의 전부 또는 일부를 철회할 수
                      있습니다.
                    </li>
                    <li>
                      ③개인위치정보주체는 언제든지 개인위치정보의 수집ㆍ이용ㆍ제공의 일시적인 중지를 요구할 수 있습니다.
                      이 경우 회사는 요구를 거절하지 아니하며, 이를 위한 기술적 수단을 갖추고 있습니다.
                    </li>
                    <li>
                      ④개인위치정보주체는 회사에 대하여 아래 자료의 열람 또는 고지를 요구할 수 있고, 당해 자료에 오류가
                      있는 경우에는 그 정정을 요구할 수 있습니다. 이 경우 회사는 정당한 이유 없이 요구를 거절하지
                      아니합니다.
                      <ol className="num-circle-list">
                        <li>1.개인위치정보주체에 대한 위치정보 수집ㆍ이용ㆍ제공사실 확인자료</li>
                        <li>
                          2.개인위치정보주체의 개인위치정보가 위치정보의 보호 및 이용 등에 관한 법률 또는 다른 법령의
                          규정에 의하여 제3자에게 제공된 이유 및 내용
                        </li>
                      </ol>
                    </li>
                    <li>
                      ⑤ 개인위치정보주체는 제1항 내지 제4항의 권리행사를 위하여 이 약관 제14조의 연락처를 이용하여
                      회사에 요구할 수 있습니다.
                    </li>
                  </ol>
                </div>

                <div className="ruleCont">
                  <h4 className="tit">제8조 (개인위치정보의 이용 또는 제공)</h4>
                  <ol className="num-circle-list">
                    <li>
                      ①회사는 개인위치정보를 이용하여 위치기반서비스를 제공하는 경우 본 약관에 고지하고 동의를 받습니다.
                    </li>
                    <li>
                      ②회사는 이용자의 동의 없이 개인위치정보를 제3자에게 제공하지 않으며, 제3자에게 제공하는 경우에는
                      제공받는 자 및 제공목적을 사전에 이용자에게 고지하고 동의를 받습니다.
                    </li>
                    <li>
                      ③회사는 개인위치정보를 이용자가 지정하는 제3자에게 제공하는 경우 개인위치정보를 수집한
                      통신단말장치로 매회 이용자에게 제공받는 자, 제공일시 및 제공목적을 즉시 통지합니다.
                    </li>
                    <li>
                      ④단, 아래의 경우 이용자가 미리 특정하여 지정한 통신단말장치 또는 전자우편주소, 온라인게시 등으로
                      통지합니다.
                      <ol className="num-circle-list">
                        <li>
                          1.개인위치정보를 수집한 당해 통신단말장치가 문자, 음성 또는 영상의 수신기능을 갖추지 아니한
                          경우
                        </li>
                        <li>
                          2.이용자의 개인위치정보를 수집한 통신단말장치 외의 통신단말장치 또는 전자우편주소, 온라인게시
                          등으로 통보할 것을 미리 요청한 경우
                        </li>
                      </ol>
                    </li>
                    <li>
                      ⑤회사는 위치정보의 보호 및 이용 등에 관한 법률 제16조 제2항에 근거하여 개인위치정보
                      수집·이용·제공사실 확인자료를 자동으로 기록·보존하며, 해당 자료는 6개월간 보관합니다. 위치정보
                      제공 현황 자세히 보기
                    </li>
                  </ol>
                </div>

                <div className="ruleCont">
                  <h4 className="tit">제9조 (법정대리인의 권리)</h4>
                  <ol className="num-circle-list">
                    <li>
                      회사는 14세 미만의 이용자에 대해서는 개인위치정보를 이용한 위치기반서비스 제공 및 개인위치정보의
                      제3자 제공에 대한 동의를 이용자 및 이용자의 법정대리인으로부터 동의를 받아야 합니다. 이 경우
                      법정대리인은 본 약관 제7조에 의한 이용자의 권리를 모두 가집니다.
                    </li>
                  </ol>
                </div>

                <div className="ruleCont">
                  <h4 className="tit">제10조 (8세 이하의 아동 동의 보호의무자의 권리)</h4>
                  <ol className="num-circle-list">
                    <li>
                      ①회사는 아래의 경우에 해당하는 자(이하 “8세 이하의 아동 등”)의 위치정보의 보호 및 이용 등에 관한
                      법률 제26조2항에 해당하는 자(이하 “보호의무자”)가 8세 이하의 아동 등의 생명 또는 신체보호를 위하여
                      개인위치정보의 이용 또는 제공에 동의하는 경우에는 본인의 동의가 있는 것으로 봅니다.
                      <ol className="num-circle-list">
                        <li>1. 8세 이하의 아동</li>
                        <li>2. 금치산자</li>
                        <li>
                          3. 장애인복지법제2조제2항제2호의 규정에 의한 정신적 장애를 가진 자로서
                          장애인고용촉진및직업재활법 제2조제2호의 규정에 의한 중증장애인에 해당하는 자(장애인복지법
                          제29조의 규정에 의하여 장애인등록을 한 자에 한한다)
                        </li>
                      </ol>
                    </li>
                    <li>
                      ②8세 이하의 아동 등의 생명 또는 신체의 보호를 위하여 개인위치정보의 이용 또는 제공에 동의를 하고자
                      하는 보호의무자는 서면동의서에 보호의무자임을 증명하는 서면을 첨부하여 회사에 제출하여야 합니다.
                    </li>
                    <li>
                      ③보호의무자는 8세 이하의 아동 등의 개인위치정보 이용 또는 제공에 동의하는 경우 개인위치정보주체
                      권리의 전부를 행사할 수 있습니다.
                    </li>
                  </ol>
                </div>

                <div className="ruleCont">
                  <h4 className="tit">제11조 (손해배상)</h4>
                  <ol className="num-circle-list">
                    <li>
                      회사의 위치정보의 보호 및 이용 등에 관한 법률 제15조 및 26조의 규정을 위반한 행위로 인해 손해를
                      입은 경우 이용자는 회사에 손해배상을 청구할 수 있습니다. 회사는 고의, 과실이 없음을 입증하지
                      못하는 경우 책임을 면할 수 없습니다.
                    </li>
                  </ol>
                </div>

                <div className="ruleCont">
                  <h4 className="tit">제12조 (면책)</h4>
                  <ol className="num-circle-list">
                    <li>
                      ①회사는 다음 각 호의 경우로 위치기반서비스를 제공할 수 없는 경우 이로 인하여 이용자에게 발생한
                      손해에 대해서는 책임을 부담하지 않습니다.
                      <ol className="num-circle-list">
                        <li>1. 천재지변 또는 이에 준하는 불가항력의 상태가 있는 경우</li>
                        <li>
                          2. 위치기반서비스 제공을 위하여 회사와 서비스 제휴계약을 체결한 제3자의 고의적인 서비스 방해가
                          있는 경우
                        </li>
                        <li>3. 이용자의 귀책사유로 위치기반서비스 이용에 장애가 있는 경우</li>
                        <li>4. 제1호 내지 제3호를 제외한 기타 회사의 고의·과실이 없는 사유로 인한 경우</li>
                      </ol>
                    </li>
                    <li>
                      ②회사는 위치기반서비스 및 위치기반서비스에 게재된 정보, 자료, 사실의 신뢰도, 정확성 등에 대해서는
                      보증을 하지 않으며 이로 인해 발생한 이용자의 손해에 대하여는 책임을 부담하지 아니합니다.
                    </li>
                  </ol>
                </div>

                <div className="ruleCont">
                  <h4 className="tit">제13조 (분쟁의 조정 및 기타)</h4>
                  <ol className="num-circle-list">
                    <li>①회사는 위치정보와 관련된 분쟁의 해결을 위해 이용자와 성실히 협의합니다.</li>
                    <li>
                      ②전항의 협의에서 분쟁이 해결되지 않은 경우, 회사와 이용자는 위치정보의 보호 및 이용 등에 관한 법률
                      제28조의 규정에 의해 방송통신위원회에 재정을 신청하거나, 개인정보보호법 제43조의 규정에 의해
                      개인정보 분쟁조정위원회에 조정을 신청할 수 있습니다.
                    </li>
                  </ol>
                </div>

                <div className="ruleCont">
                  <h4 className="tit">제14조 (회사의 주소 및 연락처)</h4>
                  <ol className="num-circle-list">
                    <li>
                      회사의 상호, 주소 및 연락처는 아래와 같습니다.
                      <ol className="num-circle-list">
                        <li>상호 : 주식회사 인디제이</li>
                        <li>대표 : 정우주</li>
                        <li>주소 : 광주 북구 동문대로 136 (두암2동)</li>
                        <li>대표전화 : 070-7779-0733</li>
                      </ol>
                    </li>
                  </ol>
                </div>

                <div className="ruleBlock">
                  <h3>부칙</h3>
                  <div className="ruleBlockin">
                    <div className="ruleCont">
                      <h4 className="tit">제1조 (시행일)</h4>
                      <ol className="num-circle-list">
                        <li>본 약관은 2020년 6월 15일부터 시행됩니다.</li>
                      </ol>
                    </div>

                    <div className="ruleCont">
                      <h4 className="tit">제2조 (위치정보관리책임자 정보)</h4>
                      <ol className="num-circle-list">
                        <li>
                          회사는 개인위치정보를 적절히 관리·보호하고, 이용자의 불만을 원활히 처리할 수 있도록 실질적인
                          책임을 질 수 있는 지위에 있는 자를 위치정보관리책임자로 지정해 운영하고 있으며,
                          위치정보관리책임자는 위치기반서비스를 제공하는 부서의 부서장으로서 성명과 연락처는 아래와
                          같습니다.
                          <ol className="num-circle-list">
                            <li>성명 : 윤형만</li>
                            <li>대표전화 : 070-7779-0733</li>
                          </ol>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div onClick={() => toTop()}>
        <img className="uparr" src="/../images/up.png" alt="upImg"></img>
      </div>
    </div>
  );
};
export default Terms_loc;
