import React, { useEffect, useState } from 'react';
import './airdrop.scss';
import { useRecoilState } from 'recoil';
import { _footer } from 'recoil/state';
import { copyURL } from '../../Weather_test/Share';
import { message } from 'antd';
import LazyLoad from 'react-lazyload';
import { useParams } from 'react-router';

const Airdrop = () => {
  const { code } = useParams();

  const copyCode = () => {
    copyURL(code);
    message.config({
      duration: 2,
      maxCount: 1,
    });
    message.success('복사되었습니다.');
  };
  const goToMetaBx = () => {
    window.location.href = 'https://medium.com/@metabrox';
  };
  const goToGlim = () => {
    window.location.href = 'https://gleam.io/TXtfh/metabrox-';
  };
  const goToTelegram = () => {
    window.location.href = 'https://t.me/metabrox';
  };
  const goToTwitter = () => {
    window.location.href = 'https://twitter.com/meta_brox';
  };

  return (
    <div id="airdrop">
      {/* <img className="back-ground-img" src="/../images/airdrop/detail-page.png" /> */}
      <div className="content-wrap">
        <div className="airdrop-header-wrap">
          <div className="airdrop-header-top">
            <span>METABX</span>
          </div>
          <div className="airdrop-header-bottom">
            <span> 에어드랍 받는 방법! </span>
          </div>
        </div>
        {/* <hr className="bifurcation" /> */}
        <div className="event1">
          <img src="/../images/airdrop/planet.png" alt="planet" className="event1-planet"></img>
          <img src="/../images/airdrop/planet.png" alt="planet" className="event1-planet2"></img>
          <div className="event-info1">
            <span style={{ letterSpacing: '1px' }}>
              EVENT
              <img src="/../images/airdrop/one-finger.png" />
            </span>
          </div>
          <div className="event-info-top">
            <span>각 미션을 수행한 이용자에게</span>
          </div>

          <div className="event-info-bottom">
            <div className="evnet-info-bottom1">
              <span>30 METABX</span>
            </div>
            <div className="event-info-bottom2">
              <sapn>씩 증정</sapn>
            </div>
          </div>
          <div className="event1-info">
            <div className="event-info-01">01.</div>
            <div className="info-wrap">
              <div style={{ margin: '8px 0 0 0', letterSpacing: '1px' }}>Telegram 참여</div>
              <div className="all-btn-wrap">
                <div className="all-button" style={{ margin: '26px 0px' }}>
                  <button onClick={goToTelegram} className="all-button_btn">
                    <img className="button-img" src="/../images/airdrop/telegram.png" />
                    <span className="all-button_btn_text">참여 바로가기 &nbsp; &gt;</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="event-info-02">02.</div>
            <div className="info-wrap">
              <div style={{ margin: '8px 0 0 0 ', letterSpacing: '1px' }}>Twitter Channel 참여</div>
              <div className="all-btn-wrap">
                <div className="all-button" style={{ margin: '26px 0px' }}>
                  <button onClick={goToTwitter} className="all-button_btn">
                    <a href="https://twitter.com/meta_brox">
                      <img className="all-button" src="/../images/airdrop/twitter.png" alt="참여버튼" />
                      <span className="all-button_btn_text">참여 바로가기 &nbsp; &gt;</span>
                    </a>
                  </button>
                </div>
              </div>
            </div>
            <div className="event-info-03">03.</div>
            <div className="info-wrap">
              <div style={{ margin: '8px 0 0 0 ', letterSpacing: '1px' }}>Medium Follow</div>
              <div className="all-btn-wrap">
                <div className="all-button" style={{ margin: '26px 0px' }}>
                  <button onClick={goToMetaBx} className="all-button_btn">
                    <img className="button-img" src="/../images/airdrop/home.png" />
                    <span className="all-button_btn_text">참여 바로가기 &nbsp; &gt;</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="event-info-04">04.</div>
            <div className="info-wrap">
              <div style={{ margin: '8px 0 0 0 ', letterSpacing: '1px' }}>inDJ App 가입할 친구를 초대</div>
              <div className="code-copy" style={{ marginTop: '25.5px' }}>
                <span className="sugeest">추천인 코드</span>
                <div style={{ margin: '0 10px' }} className="code">
                  {code}
                </div>
                <button className="share-node" onClick={copyCode}>
                  <div className="share-img-wrap">
                    <img className="share-img" src="/../images/airdrop/copy.png" />
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="event2">
          <div className="event-info2">
            <span className="event-info2-evt" style={{ paddingBottom: '10px', letterSpacing: '1px' }}>
              EVENT
              <img src="/../images/airdrop/two-finger.png" />
            </span>
            <div className="event-info-top">
              <span>친구초대 TOP5</span>
            </div>
            <div className="event2-toplank">
              <div className="event2-toplank-text1">🥇1등 30,000 METABX</div>
              <div className="event2-toplank-text2">🥈2등 25,000 METABX</div>
              <div className="event2-toplank-text3">🥉3등 20,000 METABX</div>
              <div className="event2-toplank-text4">🤚4등 15,000 METABX</div>
              <div className="event2-toplank-text5">🖐5등 10,000 METABX</div>
            </div>
          </div>
          <div className="event2-info">어뷰징 및 다계정 확인 될 경우 지급대상 제외</div>
          <div className="event2-all">
            <div className="event2-date">
              <div className="event2-title">EVENT PERIOD</div>
              <div className="event2-con" style={{ letterSpacing: '1px' }}>
                2022.09.08(THUR) -09.30(FRI)
              </div>
              <div className="event2-con" style={{ letterSpacing: '1px' }}>
                METABX 예산 소진 시 이벤트 조기종료 가능
              </div>
            </div>
            <div className="event2-start">
              <div className="event2-title">에어드랍 시기</div>
              <div className="event2-con" style={{ letterSpacing: '1px' }}>
                2022.10월 초 중
              </div>
            </div>
            <div className="event2-how">
              <div className="event2-title">참여방법</div>
              <span className="event2-con2">아래 버튼을 눌러 글림 이벤트</span>
              <span className="event2-con">에서 참여해주세요.</span>
            </div>
          </div>
          <div style={{ padding: '36px 0 190px 0' }}>
            <button>
              <a href="https://gleam.io/TXtfh/metabrox-">
                <img className="event2-footerimg" src="/../images/airdrop/bottom-img.png" alt="" />
              </a>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Airdrop;
