/* global history */
/* global location */
/* global window */

/* eslint no-restricted-globals: ["off"] */
//삭제버튼 클릭시 전역변수문에러를 해결해줌

import React, { useState } from "react";
import axios from "axios";
import "antd/dist/antd.css"; // css를 가져온다

const ChangePw = ({ location, history }) => {
  const [newPw, setNewPw] = useState(""); // 바꿀 비번
  const [checkPw, setCheckPw] = useState(""); // 비번 확인
  const [rending, setRending] = useState(false);

  const token = location.search.substring(9);
  //   console.log(token);
  //   useEffect(() => {
  //     // 접속경로 체크
  //     axios
  //         .post(`https://api1.indj.me/api/v1/find/changepw`, {}, {})
  //       .catch((err) => {
  //         console.log(err);
  //       })
  //       .then((res) => (res.data.success ? setRending(true) : setRending(false)));
  //   }, []);

  const changedone = (e) => {
    e.preventDefault();
    if (newPw !== "" && checkPw !== "") {
      if (
        newPw.length >= 8 &&
        newPw.length <= 30 &&
        checkPw.length >= 8 &&
        checkPw.length <= 30
      ) {
        if (newPw === checkPw) {
          const check = confirm("수정하시겠습니까?");
          if (check === true) {
            axios
              .put(
                `https://api1.indj.club/api/v1/find/changepw`,
                {
                  pw: newPw,
                  evToken: token,
                },
                {}
              )
              .then((res) =>
                res.data.success
                  ? (alert("수정이 완료되었습니다."),
                    // window.location.reload(),
                    window.open("", "_self").close())
                  : (alert("실패하였습니다. 다시 시도해주세요"),
                    console.log(res))
              )
              .then((res) => console.log(res))
              .catch((err) => {
                console.log(err);
              })
              .finally(() => {});
          }
        } else {
          alert("비밀번호가 일치하지 않습니다.");
        }
      } else {
        alert("비밀번호는 8글자 이상 30글자 이하로 해주세요");
      }
    } else {
      alert("빈칸을 채워주세요");
    }
  };

  return (
    <div className="app dk" id="app">
      <div className="padding">
        <div className="navbar">
          <div className="center_a">
            <div className="navbar-brand md">
              <img src="/../images/indj.png" alt="." className=""></img>
              <span className="hidden-folded inline">
                &nbsp;&nbsp;InDJ 비밀번호 변경
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="b-t" style={{ height: "565px" }}>
        <div className="center-block w-auto-xs p-y-md text-center">
          <div className="join_center">
            <div className="join_wrap">
              <div className="join_con">
                <p className="join_tit">비밀번호를 변경해주세요!</p>
                <p className="join_plz">
                  안전한 사용을 위해 비밀번호를 변경해야합니다. <br></br> 아래에
                  새 비밀번호를 입력해주세요
                </p>
              </div>
              <form>
                <div className="join_btn">
                  <span className="ant-input-affix-wrapper ant-input-affix-wrapper-lg">
                    <input
                      onChange={(e) => setNewPw(e.target.value)}
                      placeholder="새 비밀번호 (8글자 이상 30글자 이하)"
                      type="password"
                      className="ant-input ant-input-lg"
                    />
                  </span>
                  <br></br>
                  <br></br>
                  <span className="ant-input-affix-wrapper ant-input-affix-wrapper-lg">
                    <input
                      onChange={(e) => setCheckPw(e.target.value)}
                      placeholder="비밀번호 확인"
                      type="password"
                      className="ant-input ant-input-lg"
                    />
                  </span>
                </div>
                <button
                  onClick={(e) => changedone(e)}
                  type="submit"
                  className="join_btn_home"
                >
                  변경 완료
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePw;
