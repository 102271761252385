import axios from 'axios';

// 토큰
const getToken = () => localStorage.getItem('currentUser');
const dev = false;
const API_URL = dev ? process.env.REACT_APP_API_URL2_DEV : process.env.REACT_APP_API_URL2;

// 첫번째 파라미터 : api주소의 엔드포인트 이후 주소

// get api 틀
// 두번째인자에 true를 주면 토큰이 유효하지 않아도 에러창 표시하지 않음
// 한 페이지에 여러개 api 사용할때 알림창 여러개 뜨는거 방지

// 관리자
export const getApi = async (url, nonErr) => {
  try {
    const data = await axios.get(`${API_URL}${url}`, {
      headers: { Authorization: `Bearer ${getToken()}` },
    });
    if (nonErr) {
      return data;
    }
    if (data.code === 446) {
      alert('토큰정보가 만료되었습니다. 다시 로그인해주세요');
      localStorage.removeItem('currentUser');
      window.location.reload();
    } else {
      return data;
    }
  } catch (err) {
    alert('문제가 발생했습니다. 다시 시도해주세요');
    console.log(err);
  } finally {
  }
};
// 서비스
export const getApi2 = async (url, nonErr) => {
  try {
    const data = await axios.get(`${process.env.REACT_APP_API_URL}${url}`, getToken() || null);
    console.log(`${process.env.REACT_APP_API_URL}${url}`);
    if (nonErr) {
      return data;
    }
    if (data.code === 446) {
      alert('토큰정보가 만료되었습니다. 다시 로그인해주세요');
      localStorage.removeItem('currentUser');
      window.location.reload();
    } else {
      return data;
    }
  } catch (err) {
    alert('문제가 발생했습니다. 다시 시도해주세요');
    console.log(err);
  } finally {
  }
};

// post api 틀
export const postApi = async (url, body) => {
  try {
    const data = await axios.post(`${API_URL}${url}`, body, {
      headers: { Authorization: `Bearer ${getToken()}` },
    });
    if (data.code === 446) {
      alert('토큰정보가 만료되었습니다. 다시 로그인해주세요');
      localStorage.removeItem('currentUser');
      window.location.reload();
      return;
    }
    return data;
  } catch (err) {
    alert('문제가 발생했습니다. 다시 시도해주세요');
    console.log(err);
  } finally {
  }
};
// post api 틀
export const postApi2 = async (url, body) => {
  try {
    const data = await axios.post(`${process.env.REACT_APP_API_URL}${url}`, body);
    if (data.code === 446) {
      alert('토큰정보가 만료되었습니다. 다시 로그인해주세요');
      localStorage.removeItem('currentUser');
      window.location.reload();
      return;
    }
    return data;
  } catch (err) {
    alert('문제가 발생했습니다. 다시 시도해주세요');
    console.log(err);
  } finally {
  }
};

// put api 틀
export const putApi = async (url, body) => {
  try {
    const data = await axios.put(`${process.env.REACT_APP_API_URL}${url}`, body, {
      headers: { Authorization: `Bearer ${getToken()}` },
    });
    if (data.code === 446) {
      alert('토큰정보가 만료되었습니다. 다시 로그인해주세요');
      localStorage.removeItem('currentUser');
      window.location.reload();
      return;
    }
    return data;
  } catch (err) {
    alert('문제가 발생했습니다. 다시 시도해주세요');
    console.log(err);
  } finally {
  }
};

// delete api 틀
export const deleteApi = async (url) => {
  try {
    const data = await axios.delete(`${process.env.REACT_APP_API_URL}${url}`, {
      headers: { Authorization: `Bearer ${getToken()}` },
    });
    if (data.code === 446) {
      alert('토큰정보가 만료되었습니다. 다시 로그인해주세요');
      localStorage.removeItem('currentUser');
      window.location.reload();
    } else {
      return data;
    }
  } catch (err) {
    alert('문제가 발생했습니다. 다시 시도해주세요');
    console.log(err);
  } finally {
  }
};
