import React, { useState, useEffect, useRef } from "react";

const WebtoonList = ({ webtoon_list, history }) => {
  useEffect(() => {}, [webtoon_list]);

  // 해당회차의  컨텐츠 페이지로 이동
  const goContents = (idx) => {
    history.push(`/webtooncon/${idx}`);
  };

  return (
    webtoon_list &&
    webtoon_list.map((el, idx) => {
      return (
        <li key={idx} className="webtoon_el_wrap">
          {idx !== 0 && <hr style={{ width: "100%", margin: "10px 0" }} />}
          <div onClick={() => goContents(el.idx)} className="webtoon_el">
            <img className="webtoon_el_miri" src={el.thumnail} alt="" />
            <span className="webtoon_el_title">{el.title}</span>
            {/* <span className="webtoon_el_date">{el.date}</span> */}
          </div>
        </li>
      );
    })
  );
};
export default WebtoonList;
