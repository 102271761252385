import React from "react";
import "./ticketNotice.scss";
import { defaultNotice } from "../notice";

const TicketNotice = () => {
  return (
    <div className="ticket_notice">
      <h3 className="title">이용권 구매 안내</h3>
      <ul>
        {defaultNotice.map((el, idx) => {
          return (
            <li className="ticket_notice_li" key={idx}>
              {el}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default TicketNotice;
