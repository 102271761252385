import React from 'react';
import ReactDOM, { hydrate, render } from 'react-dom';
import Root from './client/Root';
import * as serviceWorker from './serviceWorker';
import './index.css';

const rootElement = document.getElementById('root');

// ReactDOM.render(<Root />, document.getElementById("root"));
if (rootElement.hasChildNodes()) {
  hydrate(<Root />, rootElement);
} else {
  render(<Root />, rootElement);
}
serviceWorker.unregister();
