import React, { useState, useEffect } from 'react';
import list from './list.json';
import { addUserApi } from 'apis/mbti';
import ReactHelmet from '../Helmet/Helmet';

const Webtoon = ({ match }) => {
  const [imgList, setMiriList] = useState([]);

  useEffect(() => {
    for (let i = 0; i < list.list.length; i++) {
      if (+match.params.num === list.list[i].idx) {
        setMiriList(list.list[i].img);
        break;
      }
    }
    const num = 100 + +match.params.num;
    addUserApi(num);
  }, []);

  // 크기 측정

  return (
    <div id="contents">
      <ReactHelmet
        keywords="inDJ,음악취향,음악취향테스트,웹툰,음악추천,무료음악,음악어플,멜론,지니,벅스,유튜브뮤직,음악,music"
        description="차원이동을 하게 된 인기 프로듀서 제이든이 오합지졸 동료들과 공주를 구하러 가는 판타지 개그물"
        title="inDJ Toon"
        favicon="https://d3coxo32tidngo.cloudfront.net/image/webtoon/w_thum_1.png"
      />
      <div className="wrap">
        {imgList &&
          imgList.map((el, idx) => {
            return <img className="w_img" key={idx} src={el} alt="" />;
          })}
      </div>
    </div>
  );
};
export default Webtoon;
