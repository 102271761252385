import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { _payInfo } from "recoil/state";
import { immers } from "utils/util";
import "./payType.scss";

const PayType = () => {
  const [payInfo, setPayInfo] = useRecoilState(_payInfo);
  const [type, setType] = useState("card");
  const AWS_IMG =
    "https://indj.s3.ap-northeast-2.amazonaws.com/image/web_player/ticket/";

  const choicePay = (_type) => {
    // 휴대폰결제가 선택되어있고, 카드결제를 눌렀으면
    if (type === "phone" && _type === "card") {
      setType(_type);
      setPayInfo(immers(payInfo, ["pg", "pay_method"], ["danal_tpay", "card"]));
    }
    // 카드결제가 선택되어있고, 휴대폰결제를 눌렀으면
    if (type === "card" && _type === "phone") {
      setType(_type);
      setPayInfo(immers(payInfo, ["pg", "pay_method"], ["danal", "phone"]));
    }
  };

  return (
    <div className="pay_type">
      <button
        onClick={() => choicePay("card")}
        type="button"
        className={`pay_type_card ${type === "card" && "active"}`}
      >
        <img style={{ width: "55px" }} src={`${AWS_IMG}card.png`} alt="" />
        <span>카드결제</span>
      </button>
      <button
        onClick={() => choicePay("phone")}
        type="button"
        className={`pay_type_phone ${type === "phone" && "active"}`}
      >
        <img style={{ width: "55px" }} src={`${AWS_IMG}phone.png`} alt="" />
        <span>핸드폰 결제</span>
      </button>
    </div>
  );
};

export default PayType;
