import React from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useRecoilState } from 'recoil';
import { _footer } from 'recoil/state';

import './Newuser.scss';
const Newuser = () => {
  const [_, setFooter] = useRecoilState(_footer);
  useEffect(() => {
    setFooter(false);
  }, []);
  // 스토어 가기 오류나서 a태그로 대체
  // const goToapp = () => {
  //   window.location.href =
  //     'https://apps.apple.com/kr/app/indj-%EC%9E%90%EC%9C%A0%EB%A1%AD%EA%B2%8C-%EB%93%A3%EB%8A%94-%EC%83%81%ED%99%A9-%EA%B0%90%EC%84%B1-%EC%9D%B8%EA%B3%B5%EC%A7%80%EB%8A%A5-%EC%9D%8C%EC%95%85/id1513542512';
  // };
  // const goTogoogle = () => {
  //   window.location.href = 'https://play.google.com/store/apps/details?id=com.indj.music';
  // };
  const history = useHistory();

  function handle() {
    history.push('/redirect');
  }

  return (
    <div id="newuser">
      <div className="container">
        <img
          className="backcoin1"
          src="https://indj.s3.ap-northeast-2.amazonaws.com/image/event/newuser_backcoin1.png"
          alt=""
        ></img>
        <h1 className="header">
          inDJ <span className="header-color header-one">신규</span>
          <br />
          <span className="header-color header-two">회원가입</span> 이벤트!
        </h1>
        <h2 className="header2">회원가입만 해도 코인이!?</h2>
        <img
          className="backcoin2"
          src="https://indj.s3.ap-northeast-2.amazonaws.com/image/event/newuser_backcoin2.png"
          alt=""
        ></img>
        <div className="coin">
          <img
            className="coin-img"
            src="https://indj.s3.ap-northeast-2.amazonaws.com/image/event/newuser_coin.png"
            alt="middlecoin"
          ></img>
          <img
            className="backcoin3"
            src="https://indj.s3.ap-northeast-2.amazonaws.com/image/event/newuser_backcoin3.png"
            alt=""
          ></img>
        </div>

        <div className="reward">
          <img src="https://indj.s3.ap-northeast-2.amazonaws.com/image/event/newuser_onetwo.png" alt="reward" />
          {/* <div className="reward-join">
            <div>
              <img className="reward-icon-img" src="/../images/newuser/one.png" alt="fingerone"></img>
            </div>
            <div>
              <span className="reward-join-text">가입 시 30 METABX 100% 지급</span>
            </div>
          </div>
          <div className="reward-invite">
            <div>
              <img className="reward-icon-img" src="/../images/newuser/two.png" alt="fingertwo"></img>
            </div>
            <div>
              <span className="reward-invite-text">친구 초대 시 추가 30 METABX 지급</span>
            </div>
          </div> */}
        </div>

        <div className="notice">
          <img
            className="notice-img1"
            src="https://indj.s3.ap-northeast-2.amazonaws.com/image/event/newuser_middle1.png"
            alt="eventnotice-line1"
          ></img>
          <img
            className="notice-img2"
            src="https://indj.s3.ap-northeast-2.amazonaws.com/image/event/newuser_event.png"
            alt=""
          />
          {/* <span className="notice-text">이벤트 참고 및 유의사항</span> */}
          <img
            className="notice-img3"
            src="https://indj.s3.ap-northeast-2.amazonaws.com/image/event/newuser_middle2.png"
            alt="eventnotice-line2"
          ></img>
        </div>
        <img
          className="backcoin4"
          src="https://indj.s3.ap-northeast-2.amazonaws.com/image/event/newuser_backcoin4.png"
          alt=""
        ></img>
        <section className="invite">
          <h3 className="invite-header">친구 초대 보상 안내</h3>
          {/* <ul className="invite-rewardlist">
            <li className="listtext">
              초대 리워드 기존 보상 : <span className="listtext-yellow">10 METABX</span>
            </li>
            <li className="listtext">
              초대 리워드 이벤트 보상 : <span className="listtext-yellow">30 METABX</span>
            </li>
          </ul> */}
          {/* prettier-ignore */}
          <p className="invite-text">
            <span className="invite-weight">신규 가입자</span>는 회원가입 시 <span className="text-yellow">30 METABX</span> 가 자동으로<br/>
            지급되며 가입 후 <span className="invite-weight">추천인 코드</span> 추가 등록 시<br /><span className="text-yellow">10 METABX</span> 가 추가로
            지급됩니다.
        </p>
        </section>
        <section className="invite2">
          <h3 className="invite-header">친구 초대 리워드</h3>
          {/* prettier-ignore */}
          <p className='invite2-text'>
            inDJ 앱 내 고유 추천 코드를<br/>
            친구에게 전달해야 적용 가능하며 친구가 APP에 가입하<br/>
            고 전달받은 코드를 적었을 때 자동 지급됩니다.<br/>
            어뷰징 및 다계정이 확인 될 경우 <span className="text-yellow2">지급 대상 제외</span>합니다.
        </p>
          {/* prettier-ignore */}
          <li className='invite2-text2'>
            METABX 예산 소진 시 이벤트가 조기 종료될 수 있습니다.
          </li>
          <li className="invite2-text2">
            <span>특별 기간 이벤트 친구 초대 리워드 30 METABX 종료 (~22.09.30)</span>
            <br />
            <span style={{ marginLeft: '17px' }}>현재 친구 초대 리워드 10 METABX로 지급 (22.10.01~)</span>
          </li>
        </section>
        <div className="ref">
          <div onClick={handle}>
            <img
              className="ref-app"
              src="https://indj.s3.ap-northeast-2.amazonaws.com/image/event/appstore.png"
              alt="appstore"
            ></img>
          </div>
          <a
            className="down-btn-wrap"
            target="_blank"
            rel="noopener noreferrer"
            href="https://play.google.com/store/apps/details?id=com.indj.music"
          >
            <img
              className="ref-google"
              src="https://indj.s3.ap-northeast-2.amazonaws.com/image/event/playstore.png"
              alt="playstore"
            ></img>
          </a>
        </div>

        <div className="lastline">
          <img
            className="lastline_img"
            src="https://indj.s3.ap-northeast-2.amazonaws.com/image/event/newuser_lastline.png"
            alt="lastline"
          ></img>
        </div>
        <div className="last">
          <span className="last-text">
            음악은 당신의 <span className="last-text-white">상황</span>과 <span className="last-text-white">감정</span>
            을 잘 느끼고 소통합니다. 당신보다 더 당신을 잘 아는{' '}
            <span className="last-text-white">음악 메타버스 플랫폼</span>
            &nbsp; 이제 만나러 갑니다!
          </span>
        </div>
        <img
          className="backcoin5"
          src="https://indj.s3.ap-northeast-2.amazonaws.com/image/event/newuser_backcoin5.png"
          alt=""
        ></img>
      </div>
    </div>
  );
};

export default Newuser;
