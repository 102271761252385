import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { Modal } from "antd";
import "antd/dist/antd.css";
import "../css/terms.css";
import Wrong from "./Wrong";
import Loading from "./Loading";

const Enrollment = ({ match }) => {
  const ernBtn = useRef(null);
  const [loading, setLoading] = useState(true);
  const [rending, setRending] = useState(false);
  let result = "";

  const [numCheck, SetNumCheck] = useState(false);
  const [couponNum, SetCouponNum] = useState("");
  const token = match.params.idx.substring(4);
  const body = document.getElementsByTagName("body")[0];
  body.style.backgroundColor = "#181818";

  useEffect(() => {
    async function tokencheck() {
      try {
        const res = await axios.post(
          `https://api1.indj.club/api/v1/token_verify`,
          { token: token },
          {}
        );
        res.data.success ? setRending(true) : setRending(false);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    }
    tokencheck();
  }, []);

  const changeBtn = (e) => {
    // 16자리 입력 체크
    if (e.currentTarget.value.length >= 16) {
      // 입력된 글자가 16글자면
      SetNumCheck(true);
      ernBtn.current.classList.add("on");
    } else {
      SetNumCheck(false);
      ernBtn.current.classList.remove("on");
    }
    if (e.currentTarget.value.length > 16) {
      e.currentTarget.value = e.currentTarget.value.slice(0, 16);
    }
    SetCouponNum(e.currentTarget.value);
  };
  const success = (text) => {
    Modal.success({
      title: text,
      // content: 'some messages...some messages...',
    });
  };
  const error = (text) => {
    Modal.error({
      title: text,
      // content: 'some messages...some messages...',
    });
  };
  // 등록버튼 클릭시
  const enrollment = (e) => {
    e.preventDefault();
    if (numCheck) {
      //16자리 입력하고 버튼누르면
      axios
        .put(
          `https://api2.indj.club/coupon/api/use/${couponNum}`,
          {},
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .catch((err) => {
          // console.log(err.response.data.reason);
          // console.log(err.response.status);
          // console.log(err.response.headers);
          console.log(err);
        }) // 접근
        .then((res) => {
          // 등록 성공
          if (res.data.code === 2000) {
            result = res.data.result;
            console.log(res);
            success("쿠폰등록 완료!");
            SetCouponNum("");
            SetNumCheck(false);
            ernBtn.current.classList.remove("on");
          } else {
            // 실패
            result = res.data.result;
            error(result);
            console.log(res);
          }
        });
    } else {
      // 16자리 입력하지 않고 누르면
      error("쿠폰번호는 16자리입니다.");
    }
  };
  if (loading) {
    return (
      <div>
        <Loading></Loading>
      </div>
    );
  } else {
    if (rending) {
      return (
        <div
          style={{ backgroundColor: "#181818", height: "100vh" }}
          className="app dk"
          id="app"
        >
          <div className="enrollmentWrap">
            <div className="enrollHeader">
              <p className="contentTitle enTitle">
                <img
                  src="/../images/indjTextLogo.png"
                  alt="."
                  className="couponLogo"
                />
                <br className="mobile" />
                <span>
                  쿠폰을 등록하고 인디제이의 모든 서비스를 사용해 보세요.
                </span>
              </p>
            </div>
            <div className="enrollment">
              <img
                className="eimg"
                src="/../images/group.png"
                alt="쿠폰등록페이지이미지"
              ></img>
              <div className="coupon">
                <form className="coupon_form" action="" method="post">
                  <input
                    maxLength="16"
                    onChange={(e) => changeBtn(e)}
                    value={couponNum}
                    type="text"
                    placeholder="코드를 입력해주세요"
                  ></input>
                  <br />
                  <button
                    ref={ernBtn}
                    onClick={(e) => enrollment(e)}
                    className="enrBtn bf"
                  >
                    등록
                  </button>
                </form>
                <ul>
                  <li>• &nbsp;&nbsp; - 없이 입력해주세요.</li>
                  <li>
                    • &nbsp;&nbsp; 쿠폰번호는 16자리 영문과 숫자로 구성되어
                    있습니다.
                  </li>
                  <li>
                    • &nbsp;&nbsp; 쿠폰에 표기된 유효기간을 확인 바랍니다.
                  </li>
                  <li>• &nbsp;&nbsp; 쿠폰등록은 10분내로 해주시기 바랍니다.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <Wrong></Wrong>
        </div>
      );
    }
  }
};
export default Enrollment;
