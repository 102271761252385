import React from 'react';
import TermsUp from './Terms_up';
import { NavLink } from 'react-router-dom';
import '../css/terms.css';
import { Upload } from 'antd';

const toTop = () => {
  window.scroll({
    behavior: 'smooth',
    left: 0,
    top: 0,
  });
};

const Terms_loc = ({ match }) => {
  return (
    <div className="app dk" id="app">
      {/* <Sideheader></Sideheader> */}
      <div id="content" className="app-content mopt white bg box-shadow-z2" role="main">
        {/* <Play></Play> */}
        <div id="subContentsWrap" className="noDepth">
          {/* 사이드바 생성시 클래스에 wpl추가 */}
          <TermsUp></TermsUp>
          <h2 className="contentTitle nobar">inDJ 개인정보 처리 방침</h2>

          <div className="innerWrapin subContents">
            <div className="ruleBlock">
              <div className="ruleBlockin">
                <div className="ruleCont">
                  <h4 className="tit">제1조 (목적)</h4>
                  <ol className="number-list">
                    <li>
                      ㈜인디제이(이하 '회사'라 한다)의 유무선 인터넷 음악 서비스(웹, 모바일 웹•앱 서비스를
                      포함합니다.)인 ‘inDJ’서비스(이하 '서비스'라 한다)는 회원의 개인정보보호를 소중하게 생각하고,
                      회원의 개인정보를 보호하기 위하여 항상 최선을 다해 노력하고 있습니다. 회사는 개인정보 보호 관련
                      주요 법률인 개인정보 보호법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률을 비롯한 모든
                      개인정보보호 관련 법률규정 및 관련 국가기관 등이 제정한 정책 등을 준수하고 있습니다. 또한, 회사는
                      「개인정보처리방침」을 제정하여 이를 준수하고 있으며, 이를 홈페이지(indj.net) 첫 화면 하단에
                      공개하여 회원이 언제나 용이하게 열람할 수 있도록 하고 있습니다. 「개인정보처리방침」을 통하여
                      회원이 제공한 개인정보의 수집에서 이용 및 파기까지의 전반적인 사항에 대하여 알려드리며, 관련 법률
                      등의 변경 또는 회사 운영정책의 변경에 따라 개인정보처리방침은 변경될 수 있으며 변경될 경우에는
                      변경된 사항을 회사 홈페이지를 통하여 공지합니다.
                    </li>
                  </ol>
                </div>

                <div className="ruleCont">
                  <h4 className="tit">제2조 (개인정보의 수집∙이용에 대한 동의)</h4>
                  <ol className="number-list">
                    <li>
                      회사에서는 적법하고 공정한 방법으로 서비스 이용계약의 성립 및 이행에 필요한 최소한의 개인정보를
                      수집하며, 이용자의 개인 식별이 가능한 개인정보를 수집하기 위하여 회원가입시 개인정보수집∙이용
                      동의에 대한 내용을 제공하고 회원이 '동의' 버튼을 클릭하면 개인정보 수집∙이용에 대해 동의한 것으로
                      봅니다.
                    </li>
                  </ol>
                </div>

                <div className="ruleCont">
                  <h4 className="tit">제3조 (개인정보의 수집범위 및 수집방법)</h4>
                  <ol className="number-list">
                    <li>
                      1.회사는 회원가입, 본인인증, 프리미엄 유료 서비스 신청 등 서비스 제공 및 계약이행을 위해 아래와
                      같이 개인정보를 수집할 수 있습니다.
                      <ol className="num-circle-list">
                        <li>(1) 회원가입</li>
                        <li>- 수집항목: 아이디(이메일 주소), 비밀번호, 성별, 별명</li>
                        <li>(2) 본인인증</li>
                        <li>
                          - 수집목적: 연령확인 또는 관련 법률준수를 위해 본인인증이 필요한 경우 아래의 정보를 수집할 수
                          있습니다.
                        </li>
                        <li>- 수집내용: 휴대폰을 통한 본인인증</li>
                        <li>
                          - 수집항목: 이름, 생년월일, 성별, 내/외국인 여부, 휴대전화번호, 연계정보(CI), 중복확인정보(DI)
                        </li>
                        <li>(3) 프리미엄 유료 서비스</li>
                        <li>
                          - 수집목적: 회사는 회원이 유료 서비스를 이용하고자 하는 경우 결제 시점에 아래와 같이 결제에
                          필요한 정보를 수집할 수 있습니다.
                        </li>
                        <li>- 수집항목: 결제수단 소유자 정보(이름), 신용카드사정보, 휴대전화번호, 유선전화번호</li>
                      </ol>
                      <li>
                        2. 회사는 회원이 이벤트 및 프로모션에 참여하는 경우 별도 동의 후 아래 정보들을 수집 할 수도
                        있습니다.
                        <ol className="num-circle-list">
                          <li>- 이름, 이메일 주소, 휴대전화번호, 주소</li>
                        </ol>
                      </li>
                      <li>
                        3. 서비스 이용과정에서 아래와 같은 정보들이 생성되어 수집될 수 있습니다.
                        <ol className="num-circle-list">
                          <li>
                            - 휴대전화(스마트폰) &amp; 스마트OS 탑재 모바일 기기(Tablet PC 등) : 모델명, 기기별
                            고유번호(UDID 등), OS정보, 단말기 환경정보, GPS정보
                          </li>
                          <li>
                            - 행태정보 : 이용자가 서비스 이용중 생성된 정보를 분석 툴을 통해 수집하고, 수집된
                            정보(광고ID, 앱 정보)는 이용자를 특정할 수 없는 정보, 이 형태정보는 이용자 대상 맞춤형 광고
                            서비스 전송 목적으로 허용
                          </li>
                          <li>- 기타 정보 : 서비스 이용(정지) 기록, 접속 로그, 쿠키, 접속 IP정보</li>
                        </ol>
                      </li>
                      <li>
                        4. 회사는 다음과 같은 방식으로 개인정보를 수집합니다.
                        <ol className="num-circle-list">
                          <li>- 홈페이지 이용 및 서면양식, 상담 게시판, 경품 행사 응모, 배송요청</li>
                          <li>- 제휴사로부터의 제공 </li>
                          <li>- 생성정보수집 툴을 통한 수집</li>
                        </ol>
                      </li>
                      <li>
                        5. 기본적 인권침해의 우려가 있는 개인의 민감정보(인종 및 민족, 사상 및 신조, 출신지 및 본적지,
                        정치적 성향 및 범죄기록, 건강상태 및 성생활 등)는 요구하지 않으며, 위의 항목 이외에 다른 어떠한
                        목적으로도 수집, 사용하지 않음을 알려드립니다.
                      </li>
                      <li>
                        6. SNS 계정 연동 시 제공 받는 개인정보 처리 방침
                        <ol className="num-circle-list">
                          <li>(1). 제공받는 개인정보의 항목</li>
                          <li>
                            <img src="../images/Personal_Information.png" alt="제공받는 개인정보의 항목" />
                          </li>
                          <li>(2). 제공받는 자의 이용목적</li>
                          <li>회사는 수집한 개인정보를 다음의 목적으로 활용합니다.</li>
                          <li>
                            a. 서비스 제공에 관한 계약 이행 유료 서비스 제공에 따른 요금정산 콘텐츠 제공, 유료 서비스
                            이용에 대한 과금, 구매 및 요금 결제, 본인인증, 물품배송 또는 청구서 등 발송, 요금 추심
                          </li>
                          <li>
                            b. 회원관리 회원제 서비스 이용에 따른 아이디(이메일 주소), 성별, 분쟁 조정을 위한 기록보존,
                            불만처리 등 민원처리, 고지사항 전달
                          </li>
                          <li>
                            c. 마케팅 및 광고에 활용 신규 서비스 개발 및 맞춤 서비스 제공, 통계학적 특성에 따른 서비스
                            제공 및 광고 게재, 서비스 유효성 확인, 접속 빈도 파악, 회원의 서비스 이용에 대한 통계,
                            이벤트 및 광고성 정보와 참여기회 제공
                          </li>
                          <li>
                            - 마케팅 및 광고 목적을 위해 추가적으로 요구하는 정보는 선택항목으로 구분하여 수집 (선택적
                            목적에 동의하지 않아도 서비스 이용에는 제한 없음)
                          </li>
                          <li>(3) 제공받는 자의 개인정보 보유 및 이용기간</li>
                          <li>
                            a. 이용자의 개인정보는 개인정보의 수집목적 또는 제공받은 목적이 달성 시까지 보관됩니다.
                          </li>
                          <li>
                            b. 이벤트 목적으로 수집된 개인정보는 당첨자를 제외한 응모자 정보는 즉시 파기되고 당첨자는
                            발표 후 2개월 동안 보관 후 파기합니다.(제품 수급 및 배송 완료까지 감안한 기간)
                          </li>
                          <li>
                            c. 회원이 회원탈퇴를 하거나 개인정보 허위기재로 인해 회원 ID 삭제 처분을 받은 경우 수집된
                            개인정보는 완전히 삭제되며 어떠한 용도로도 이용할 수 없도록 처리됩니다. 다만, 도용으로 인한
                            원치 않는 회원탈퇴 등의 피해가 발생할 우려가 있으므로 탈퇴신청 후 5일의 유예기간 동안
                            개인정보를 임시 보관하게 되며, 이는 이용 해지 시 이용자에게 고지됩니다. 5일 후에는 회사의
                            회원정보 데이터베이스(DB)에서 완전히 삭제됩니다.
                          </li>
                          <li>
                            d. 회사는 정보통신망법 관련 조항에 따라 회원이 1년동안 서비스를 이용한 사실 또는 활동한
                            기록이 없는 경우, 개인정보의 안전한 보호 및 피해 발생을 방지하기 위해 '정보통신망 이용촉진
                            및 정보보호등에 관한 법률 제29조'에 근거하여 이용자에게 사전통지하고 개인정보를 별도로
                            분리하여 저장 관리합니다.
                          </li>
                          <li>
                            e. 4항의 이용한 사실 및 활동한 기록이라 함은 계정로그인, 서비스 이용료 자동결제, 전화상담,
                            고객 상담센터 등 고객이 서비스를 이용했다고 볼 수 있는 기록을 의미합니다.
                          </li>
                          <li>
                            f. 회사는 유효기간이 도래하기 30일 전까지 이메일, 문자메시지, 휴대전화 등 회원이 입력한
                            연락수단을 통하여 사전통지합니다. 휴면처리된 계정의 경우 휴면 전환일로부터 4년간 분리
                            저장되고, 저장기간 내 회원이 서비스 재이용을 원하실 경우에는 본인확인 절차를 거쳐 서비스를
                            재이용하실 수 있습니다. 분리 저장 기간 이후에는 회사의 회원정보 데이터베이스(DB)에서 완전히
                            삭제됩니다.
                          </li>
                          <li>
                            g. 또한 명의도용 등 분쟁 발생시 본인확인을 위해 제출 받은 신분증 사본은 본인확인 후 즉시
                            파기합니다. 만 19세 미만 아동 및 청소년의 법정대리인 정보는 해당 아동 및 청소년이 성년에
                            도달하거나 해당 아동 및 청소년의 회원탈퇴에 따른 개인정보 파기 시 함께 파기됩니다.
                          </li>
                          <li>
                            h. 이용자의 개인정보는 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기되나, 아래 각
                            항목에 해당하는 경우에는 명시한 기간 동안 보관할 수 있으며, 그 외 다른 목적으로는 사용하지
                            않습니다.
                          </li>
                          <li>① 회사 내부 방침에 의한 정보보유 사유</li>
                          <li>가. 불건전한 서비스 이용기록</li>
                          <li>- 보존 이유: 사법기관 수사의뢰를 하거나 다른 회원을 보호할 목적</li>
                          <li>- 보존 기간: 1년</li>
                          <li>
                            ⓶ 관계법령의 규정에 의하여 보관할 필요가 있는 경우 회사는 수집 및 이용 목적 달성 후에도
                            관계법령에서 정한 일정 기간 동안 회원의 개인정보를 보관할 수 있습니다.
                          </li>
                          <li>가. 웹사이트 방문기록</li>
                          <li>- 보존 이유 : 통신비밀보호법</li>
                          <li>- 보존 기간 : 3개월</li>
                          <li>나. 소비자의 불만 또는 분쟁처리에 관한 기록</li>
                          <li>- 보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률</li>
                          <li>- 보존 기간 : 3년</li>
                          <li>다. 계약 또는 청약철회 등에 관한 기록</li>
                          <li>- 보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률</li>
                          <li>- 보존 기간 : 5년</li>
                          <li>라. 대금결제 및 재화 등의 공급에 관한 기록</li>
                          <li>- 보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률</li>
                          <li>- 보존 기간 : 5년</li>
                          <li>마. 표시, 광고에 관한 기록</li>
                          <li>- 보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률</li>
                          <li>- 보존 기간 : 6개월</li>
                        </ol>
                      </li>
                    </li>
                  </ol>
                </div>

                <div className="ruleCont">
                  <h4 className="tit">제4조 (개인정보의 수집 및 이용목적)</h4>
                  <ol className="number-list">
                    <li>
                      회사는 수집한 개인정보를 다음의 목적으로 활용합니다.
                      <ol className="num-circle-list">
                        <li>
                          1. 서비스 제공에 관한 계약 이행 유료 서비스 제공에 따른 요금정산 콘텐츠 제공, 유료 서비스
                          이용에 대한 과금, 구매 및 요금 결제, 본인인증, 물품배송 또는 청구서 등 발송, 요금 추심
                        </li>
                        <li>
                          2. 회원관리 회원제 서비스 이용에 따른 아이디(이메일 주소), 성별, 분쟁 조정을 위한 기록보존,
                          불만처리 등 민원처리, 고지사항 전달
                        </li>
                        <li>
                          3. 마케팅 및 광고에 활용 신규 서비스 개발 및 맞춤 서비스 제공, 통계학적 특성에 따른 서비스
                          제공 및 광고 게재, 서비스 유효성 확인, 접속 빈도 파악, 회원의 서비스 이용에 대한 통계, 이벤트
                          및 광고성 정보와 참여기회 제공
                          <ol className="num-circle-list">
                            <li>
                              - 마케팅 및 광고 목적을 위해 추가적으로 요구하는 정보는 선택항목으로 구분하여 수집 (선택적
                              목적에 동의하지 않아도 서비스 이용에는 제한 없음)
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                  </ol>
                </div>

                <div className="ruleCont">
                  <h4 className="tit">제5조 (수집한 개인정보의 처리 위탁)</h4>
                  <ol className="num-circle-list">
                    <li>회사는 서비스 향상을 위해 아래와 같이 전문업체에 개인정보를 위탁하여 운영하고 있습니다.</li>
                    <li>
                      <img src="https://i.imgur.com/OPr7PyL.png" alt="pay" />
                    </li>
                  </ol>
                </div>

                <div className="ruleCont">
                  <h4 className="tit">제6조 (개인정보 제공)</h4>
                  <ol className="number-list">
                    <li>
                      1. 회사는 회원의 개인정보를 본 개인정보처리방침에서 명시된 범위를 초과하여 이용하거나 제 3자(타인
                      또는 타기업 기관)에 제공하지 않습니다. 다만, 회원의 동의가 있거나 다음 각호의 어느 하나에 해당하는
                      경우에는 예외로 합니다.
                      <ol className="num-circle-list">
                        <li>- 회원이 사전에 동의한 경우</li>
                        <li>- 서비스 제공에 따른 요금 정산을 위하여 필요한 경우나 환불이 필요한 경우</li>
                        <li>
                          - 관계법령에 의하여 수사, 재판 또는 행정상의 목적으로 관계기관으로부터의 요구가 있을 경우
                        </li>
                        <li>
                          - 금융실명거래및비밀보장에관한법률, 신용정보의이용및보호에관한법률, 전기통신기본법,
                          전기통신사업법, 지방세법, 소비자보호법, 한국은행법, 형사소송법 등 기타 관계법령에서 정한
                          절차에 따른 요청이 있는 경우
                        </li>
                      </ol>
                    </li>
                    <li>
                      2. 영업의 전부 또는 일부를 양도하거나, 합병/상속 등으로 서비스제공자의 권리,의무를 이전 승계하는
                      경우 개인정보보호 관련 회원의 권리를 보장하기 위하여 반드시 그 사실을 회원에게 통지합니다.
                    </li>
                    <li>
                      3. 회사는 보다 나은 서비스 제공을 위하여 회원의 개인정보를 제3자에게 제공하는 경우 회사는 사전에
                      제공 받는 회사명, 개인정보항목, 이용목적, 제공기간 등의 내용을 고지하고 동의를 구하는 절차를 거칠
                      것이며, 만약 동의하지 않는 정보는 제3자에 제공하지 않습니다. 이 경우 고지 및 동의방법은
                      홈페이지내의 공지사항을 통해 최소 7일 이전부터 고지함과 동시에 e-mail 등을 이용하여 1회 이상
                      개별적으로 고지하거나 홈페이지, 서면, 전화 또는 전자우편 등의 방법을 통하여 동의를 받습니다. 단
                      시간상 사전 공지가 불가능한 예외적인 경우는 공지와 동의가 동시에 이루어질 수 있습니다.
                    </li>
                    <li>
                      4. 회사는 휴대폰/ARS 결제와 관련하여 고객의 이의제기가 있는 경우 또는 이에 준하는 사안으로
                      휴대폰/ARS 결제 중재센터의 요청이 있는 경우, 문제 해결을 위해 필요한 범위에 한하여 서비스와 관련된
                      회원의 개인정보를 휴대폰/ARS 결제 중재센터에 제공할 수 있습니다.
                      <ol className="num-circle-list">
                        <li>- 홈페이지 : indj.net</li>
                        <li>- 대표번호 : 070-7779-0733</li>
                      </ol>
                    </li>
                  </ol>
                </div>

                <div className="ruleCont">
                  <h4 className="tit">제7조 (이용자 및 법정 대리인의 권리와 그 행사방법)</h4>
                  <ol className="number-list">
                    <li></li>
                    <li>
                      1. 회원 및 법정 대리인은 언제든지 등록되어 있는 자신 또는 당해 만 14세 미만 아동의 개인정보를
                      조회하거나 수정할 수 있으며 가입해지를 요청할 수도 있습니다.
                    </li>
                    <li>
                      2. 회원 또는 만 14세 미만 아동의 개인정보를 조회, 수정하기 위해서는 “개인정보변경”을, 가입해지를
                      위해서는 “회원탈퇴”를 클릭하여 본인 확인 절차를 거친 후 직접 조회, 수정 또는 탈퇴가 가능합니다.
                      또는 개인정보보호책임자에게 서면, 전화 또는 이메일로 연락하시면 지체 없이 조치하겠습니다.
                    </li>
                    <li>
                      3. 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용
                      또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를
                      제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다. 단, 다음과 같은 경우에는 예외적으로
                      개인정보의 열람 및 정정을 제한할 수 있습니다.
                      <ol className="num-circle-list">
                        <li>(1) 본인 또는 제 3 자의 생명,</li>
                        신체, 재산 또는 권익을 현저하게 해할 우려가 있는 경우
                        <li>(2) 당해 서비스 제공자의 업무에 현저한 지장을 미칠 우려가 있는 경우</li>
                        <li>(3) 법령에 위반하는 경우</li>
                      </ol>
                    </li>
                    <li>
                      4. 회사는 회원 또는 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 "수집한 개인정보의 보유
                      및 이용기간"에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고
                      있습니다.
                    </li>
                    <li>
                      5.inDJ는 타인의 개인정보를 도용하여 회원가입 등을 하였음을 알게 된 때 지체없이 해당 아이디에 대한
                      서비스 이용정지 또는 회원탈퇴 등 필요한 조치를 취합니다. 또한 자신의 개인정보 도용을 인지한
                      이용자가 해당 아이디에 대한 서비스 이용정지 또는 회원탈퇴를 요구하는 경우에도 inDJ는 즉시 조치를
                      취합니다.
                    </li>
                  </ol>
                </div>

                <div className="ruleCont">
                  <h4 className="tit">제8조 (개인정보의 보관기간)</h4>
                  <ol className="num-circle-list">
                    <li>1. 이용자의 개인정보는 개인정보의 수집목적 또는 제공받은 목적이 달성 시까지 보관됩니다.</li>
                    <li>
                      2. 이벤트 목적으로 수집된 개인정보는 당첨자를 제외한 응모자 정보는 즉시 파기되고 당첨자는 발표 후
                      2개월 동안 보관 후 파기합니다.(제품 수급 및 배송 완료까지 감안한 기간)
                    </li>
                    <li>
                      3. 회원이 회원탈퇴를 하거나 개인정보 허위기재로 인해 회원 ID 삭제 처분을 받은 경우 수집된
                      개인정보는 완전히 삭제되며 어떠한 용도로도 이용할 수 없도록 처리됩니다. 다만, 도용으로 인한 원치
                      않는 회원탈퇴 등의 피해가 발생할 우려가 있으므로 탈퇴신청 후 5일의 유예기간 동안 개인정보를 임시
                      보관하게 되며, 이는 이용 해지 시 이용자에게 고지됩니다. 5일 후에는 회사의 회원정보
                      데이터베이스(DB)에서 완전히 삭제됩니다.
                    </li>
                    <li>
                      4. 회사는 정보통신망법 관련 조항에 따라 회원이 1년동안 서비스를 이용한 사실 또는 활동한 기록이
                      없는 경우, 개인정보의 안전한 보호 및 피해 발생을 방지하기 위해 '정보통신망 이용촉진 및
                      정보보호등에 관한 법률 제29조'에 근거하여 이용자에게 사전통지하고 개인정보를 별도로 분리하여 저장
                      관리합니다.
                    </li>
                    <li>
                      5. 4항의 이용한 사실 및 활동한 기록이라 함은 계정로그인, 서비스 이용료 자동결제, 전화상담, 고객
                      상담센터 등 고객이 서비스를 이용했다고 볼 수 있는 기록을 의미합니다.
                    </li>
                    <li>
                      6. 회사는 유효기간이 도래하기 30일 전까지 이메일, 문자메시지, 휴대전화 등 회원이 입력한 연락수단을
                      통하여 사전통지합니다. 휴면처리된 계정의 경우 휴면 전환일로부터 4년간 분리 저장되고, 저장기간 내
                      회원이 서비스 재이용을 원하실 경우에는 본인확인 절차를 거쳐 서비스를 재이용하실 수 있습니다. 분리
                      저장 기간 이후에는 회사의 회원정보 데이터베이스(DB)에서 완전히 삭제됩니다.
                    </li>
                    <li>
                      7. 또한 명의도용 등 분쟁 발생시 본인확인을 위해 제출 받은 신분증 사본은 본인확인 후 즉시
                      파기합니다. 만 19세 미만 아동 및 청소년의 법정대리인 정보는 해당 아동 및 청소년이 성년에
                      도달하거나 해당 아동 및 청소년의 회원탈퇴에 따른 개인정보 파기 시 함께 파기됩니다.
                    </li>
                    <li>
                      8. 이용자의 개인정보는 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기되나, 아래 각 항목에
                      해당하는 경우에는 명시한 기간 동안 보관할 수 있으며, 그 외 다른 목적으로는 사용하지 않습니다.
                      <ol className="number-list">
                        <li>(1) 회사 내부 방침에 의한 정보보유 사유</li>
                        <li>가. 불건전한 서비스 이용기록</li>
                        <li>- 보존 이유: 사법기관 수사의뢰를 하거나 다른 회원을 보호할 목적</li>
                        <li>- 보존 기간: 1년</li>
                        <li>
                          (2) 관계법령의 규정에 의하여 보관할 필요가 있는 경우 회사는 수집 및 이용 목적 달성 후에도
                          관계법령에서 정한 일정 기간 동안 회원의 개인정보를 보관할 수 있습니다.
                        </li>
                        <li>가. 웹사이트 방문기록</li>
                        <li>- 보존 이유 : 통신비밀보호법</li>
                        <li>- 보존 기간 : 3개월</li>
                        <li> 나. 소비자의 불만 또는 분쟁처리에 관한 기록 </li>
                        <li>- 보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률</li>
                        <li> - 보존 기간 : 3년 </li>
                        <li> 다. 계약 또는 청약철회 등에 관한 기록 </li>
                        <li>- 보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률</li>
                        <li> - 보존 기간 : 5년 </li>
                        <li> 라. 대금결제 및 재화 등의 공급에 관한 기록 </li>
                        <li>- 보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률</li>
                        <li>- 보존 기간 : 5년 </li>
                        <li> 마. 표시, 광고에 관한 기록 </li>
                        <li>- 보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률</li>
                        <li> - 보존 기간 : 6개월</li>
                      </ol>
                    </li>
                  </ol>
                </div>

                <div className="ruleCont">
                  <h4 className="tit">제9조 (개인정보의 파기절차 및 방법)</h4>
                  <ol className="num-circle-list">
                    <li>
                      회사는 수집한 개인정보의 이용목적이 달성된 후에는 보관기간 및 이용기간에 따라 해당 정보를 지체없이
                      파기합니다. 파기대상, 파기절차 및 방법은 다음과 같습니다.{' '}
                    </li>
                    <li>1. 파기대상</li>
                    <ol className="number-list">
                      <li>(1) 회원가입 정보 </li>
                      <li>(2) 환불 등에 필요한 대금 입금의 목적으로 수집된 회원가입정보 이외의 계좌정보 등 </li>
                      <li>
                        (3) 설문조사, 이벤트 등의 목적을 위하여 수집한 회원가입정보에 포함된 주소와 다른 별도의 배송지
                        정보
                      </li>
                    </ol>
                    <li>
                      2. 파기절차 회원이 서비스 가입 등을 위해 기재한 개인정보는 서비스 해지 등 이용목적이 달성된 후
                      내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라 일정기간( 개인정보 보관기간 및 이용기간
                      참조) 동안 저장 보관된 후 삭제되거나 파기합니다.{' '}
                    </li>
                    <li>
                      3. 파기방법 신분증 사본 등 서면양식에 기재하거나, 종이에 출력된 개인정보는 분쇄기로 분쇄하여
                      파기하고, 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여
                      삭제합니다. 링크 사이트 회사는 홈페이지를 통하여 회원에게 다른 회사의 웹사이트 또는 자료에 대한
                      링크를 제공할 수 있습니다. 이 경우 해당 사이트의 「개인정보처리방침」은 회사의
                      「개인정보처리방침」과 무관하므로 새로 방문한 사이트의 「개인정보처리방침」을 확인하시기 바랍니다.
                    </li>
                  </ol>
                </div>

                <div className="ruleCont">
                  <h4 className="tit">제10조 (회원의 권리와 의무)</h4>
                  <ol className="num-circle-list">
                    <li>
                      회원은 본인의 개인정보를 최신의 상태로 정확하게 입력하여 불의의 사고를 예방해주시기 바랍니다.
                      입력한 부정확한 정보로 인해 발생하는 사고의 책임은 회원 자신에게 있으며 타인 정보의 도용 등
                      허위정보를 입력할 경우 계정의 이용이 제한될 수 있습니다. 회사 서비스를 이용하는 회원은 개인정보를
                      보호 받을 권리와 함께 스스로를 보호하고 타인의 정보를 침해하지 않을 의무도 가지고 있습니다. 회원은
                      아이디(ID), 비밀번호를 포함한 개인정보가 유출되지 않도록 조심하여야 하며, 게시물을 포함한 타인의
                      개인정보를 훼손하지 않도록 유의해야 합니다. 만약 이 같은 책임을 다하지 못하고 타인의 정보 및
                      타인의 존엄성을 훼손할 경우에는 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」등에 의해 처벌
                      받을 수 있습니다.
                    </li>
                  </ol>
                </div>

                <div className="ruleCont">
                  <h4 className="tit">제11조 (고지의 의무)</h4>
                  <ol className="num-circle-list">
                    <li>
                      현 「개인정보처리방침」 내용의 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일 전부터 홈페이지의
                      '공지사항'란을 통해 고지하거나 개정 내용이 이용자에게 불리할 경우에는 30일간 고지할 것입니다. 변경
                      관련 문의는 개인정보 관리 책임자 및 담당자에게 직접 문의하거나 벅스 고객센터를 통해 할 수
                      있습니다.
                    </li>
                  </ol>
                </div>

                <div className="ruleCont">
                  <h4 className="tit">제 12조 SNS 계정 연동 시 제공 받는 개인정보 처리 방침</h4>

                  <ol className="num-circle-list">
                    <li>1. 제공받는 개인정보의 항목</li>
                    <li>
                      <img src="../images/Personal_Information.png" alt="제공받는 개인정보의 항목" />
                    </li>
                    <li>2. 제공받는 자의 이용목적</li>
                    <li>회사는 수집한 개인정보를 다음의 목적으로 활용합니다.</li>
                    <li>
                      (1) 서비스 제공에 관한 계약 이행 유료 서비스 제공에 따른 요금정산 콘텐츠 제공, 유료 서비스 이용에
                      대한 과금, 구매 및 요금 결제, 본인인증, 물품배송 또는 청구서 등 발송, 요금 추심
                    </li>
                    <li>
                      (2) 회원관리 회원제 서비스 이용에 따른 아이디(이메일 주소), 성별, 분쟁 조정을 위한 기록보존,
                      불만처리 등 민원처리, 고지사항 전달
                    </li>
                    <li>
                      (3) 마케팅 및 광고에 활용 신규 서비스 개발 및 맞춤 서비스 제공, 통계학적 특성에 따른 서비스 제공
                      및 광고 게재, 서비스 유효성 확인, 접속 빈도 파악, 회원의 서비스 이용에 대한 통계, 이벤트 및 광고성
                      정보와 참여기회 제공
                    </li>
                    <li>
                      - 마케팅 및 광고 목적을 위해 추가적으로 요구하는 정보는 선택항목으로 구분하여 수집 (선택적 목적에
                      동의하지 않아도 서비스 이용에는 제한 없음)
                    </li>
                    <li>3. 제공받는 자의 개인정보 보유 및 이용기간</li>
                    <li>(1) 이용자의 개인정보는 개인정보의 수집목적 또는 제공받은 목적이 달성 시까지 보관됩니다.</li>
                    <li>
                      (2) 이벤트 목적으로 수집된 개인정보는 당첨자를 제외한 응모자 정보는 즉시 파기되고 당첨자는 발표 후
                      2개월 동안 보관 후 파기합니다.(제품 수급 및 배송 완료까지 감안한 기간)
                    </li>
                    <li>
                      (3) 회원이 회원탈퇴를 하거나 개인정보 허위기재로 인해 회원 ID 삭제 처분을 받은 경우 수집된
                      개인정보는 완전히 삭제되며 어떠한 용도로도 이용할 수 없도록 처리됩니다. 다만, 도용으로 인한 원치
                      않는 회원탈퇴 등의 피해가 발생할 우려가 있으므로 탈퇴신청 후 5일의 유예기간 동안 개인정보를 임시
                      보관하게 되며, 이는 이용 해지 시 이용자에게 고지됩니다. 5일 후에는 회사의 회원정보
                      데이터베이스(DB)에서 완전히 삭제됩니다.
                    </li>
                    <li>
                      (4) 회사는 정보통신망법 관련 조항에 따라 회원이 1년동안 서비스를 이용한 사실 또는 활동한 기록이
                      없는 경우, 개인정보의 안전한 보호 및 피해 발생을 방지하기 위해 '정보통신망 이용촉진 및
                      정보보호등에 관한 법률 제29조'에 근거하여 이용자에게 사전통지하고 개인정보를 별도로 분리하여 저장
                      관리합니다.
                    </li>
                    <li>
                      (5) 4항의 이용한 사실 및 활동한 기록이라 함은 계정로그인, 서비스 이용료 자동결제, 전화상담, 고객
                      상담센터 등 고객이 서비스를 이용했다고 볼 수 있는 기록을 의미합니다.
                    </li>
                    <li>
                      (6) 회사는 유효기간이 도래하기 30일 전까지 이메일, 문자메시지, 휴대전화 등 회원이 입력한
                      연락수단을 통하여 사전통지합니다. 휴면처리된 계정의 경우 휴면 전환일로부터 4년간 분리 저장되고,
                      저장기간 내 회원이 서비스 재이용을 원하실 경우에는 본인확인 절차를 거쳐 서비스를 재이용하실 수
                      있습니다. 분리 저장 기간 이후에는 회사의 회원정보 데이터베이스(DB)에서 완전히 삭제됩니다.
                    </li>
                    <li>
                      (7) 또한 명의도용 등 분쟁 발생시 본인확인을 위해 제출 받은 신분증 사본은 본인확인 후 즉시
                      파기합니다. 만 19세 미만 아동 및 청소년의 법정대리인 정보는 해당 아동 및 청소년이 성년에
                      도달하거나 해당 아동 및 청소년의 회원탈퇴에 따른 개인정보 파기 시 함께 파기됩니다.
                    </li>
                    <li>
                      (8) 이용자의 개인정보는 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기되나, 아래 각 항목에
                      해당하는 경우에는 명시한 기간 동안 보관할 수 있으며, 그 외 다른 목적으로는 사용하지 않습니다.
                    </li>
                    <li>① 회사 내부 방침에 의한 정보보유 사유</li>
                    <li>가. 불건전한 서비스 이용기록</li>
                    <li>- 보존 이유: 사법기관 수사의뢰를 하거나 다른 회원을 보호할 목적</li>
                    <li>- 보존 기간: 1년</li>
                    <li>
                      ⓶ 관계법령의 규정에 의하여 보관할 필요가 있는 경우 회사는 수집 및 이용 목적 달성 후에도
                      관계법령에서 정한 일정 기간 동안 회원의 개인정보를 보관할 수 있습니다.
                    </li>
                    <li>가. 웹사이트 방문기록</li>
                    <li>- 보존 이유 : 통신비밀보호법</li>
                    <li>- 보존 기간 : 3개월</li>
                    <li>나. 소비자의 불만 또는 분쟁처리에 관한 기록</li>
                    <li>- 보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률</li>
                    <li>- 보존 기간 : 3년</li>
                    <li>다. 계약 또는 청약철회 등에 관한 기록</li>
                    <li>- 보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률</li>
                    <li>- 보존 기간 : 5년</li>
                    <li>라. 대금결제 및 재화 등의 공급에 관한 기록</li>
                    <li>- 보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률</li>
                    <li>- 보존 기간 : 5년</li>
                    <li>마. 표시, 광고에 관한 기록</li>
                    <li>- 보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률</li>
                    <li>- 보존 기간 : 6개월</li>
                  </ol>
                </div>

                <div className="ruleCont">
                  <h4 className="tit">제13조 (개인정보보호를 위한 기술적/관리적 대책)</h4>
                  <ol className="num-circle-list">
                    <li>
                      1. 기술적 대책 회사는 회원의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 누출, 변조 또는
                      훼손되지 않도록 안전성 확보를 위하여 아래와 같은 기술적 대책을 적용하고 있습니다.{' '}
                    </li>
                    <ol className="number-list">
                      <li>
                        (1) 회원의 개인정보는 외부망에서 접근 및 침입이 불가능한 내부망을 활용하여 관리되고 있습니다.{' '}
                      </li>
                      <li>
                        (2) 회원의 개인정보는 오직 본인만이 알 수 있는 비밀번호에 의해 보호되고 있고 개인정보 확인 및
                        변경도 비밀번호를 알고 있는 본인에 의해서만 가능합니다.{' '}
                      </li>
                      <li>
                        (3) 회사는 백신프로그램을 이용하여 컴퓨터바이러스에 의한 피해를 방지하기 위한 조치를 취하고
                        있습니다. 백신프로그램은 주기적으로 업데이트되며, 개인정보가 침해되는 것을 방지하고 있습니다.{' '}
                      </li>
                      <li>
                        (4) 회사는 해킹 등 외부침입에 대비하여 귀하의 개인정보가 유출되는 것을 막기 위해 현재 외부로부터
                        침입을 차단하는 장치를 이용하여 외부로부터의 공격, 해킹 등을 막고 있습니다.{' '}
                      </li>
                    </ol>

                    <li> 2. 관리적 대책</li>
                    <ol className="number-list">
                      <li>
                        (1) 회사는 회원의 개인정보에 대한 접근 및 관리에 필요한 절차 등을 마련하여 소속 직원으로 하여금
                        이를 숙지하고 준수하도록 하고 있습니다.{' '}
                      </li>
                      <li>
                        {' '}
                        (2) 회사는 회원의 개인정보를 처리할 수 있는 자를 최소한으로 제한하고 있습니다. 회원의 개인정보를
                        처리할 수 있는 자는 아래와 같습니다. 가. 회원을 직/간접적으로 상대로 하여 마케팅 업무를 수행하는
                        자 나. 개인정보보호책임자 및 개인정보보호담당자 등 개인정보보호 및 보호업무를 수행하는 자 다.
                        기타 업무상 개인정보의 처리가 불가피한 자{' '}
                      </li>
                      <li>
                        {' '}
                        (3) 개인정보를 처리하는 직원을 대상으로 새로운 보안기술 습득 및 개인정보보호 의무 등에 관해
                        정기적인 사내 교육 및 외부 위탁교육을 실시하고 있습니다.{' '}
                      </li>
                      <li>
                        {' '}
                        (4) 개인정보를 처리하는 직원은 개인정보보호서약서에 서명함으로 직원에 의한 정보유출을 사전에
                        방지하고 개인정보처리방침에 대한 이행사항 및 직원의 준수여부를 감사하기 위한 내부절차를 마련하여
                        지속적으로 시행하고 있습니다.{' '}
                      </li>
                      <li>
                        {' '}
                        (5) 개인정보 처리자의 업무 인수인계는 보안이 유지된 상태에서 철저하게 이뤄지고 있으며, 입사 및
                        퇴사 후 개인정보 침해사고에 대한 책임을 명확하게 규정하고 있습니다. 또한 직원 퇴직시
                        비밀유지서약서에 서명함으로 회원의 개인정보를 처리하였던 자가 직무상 알게 된 개인정보를
                        훼손/침해 또는 누설하지 않도록 하고 있습니다.{' '}
                      </li>
                      <li>
                        {' '}
                        (6) 유료 서비스 이용계약 체결시 회원의 대금결재에 관한 정보를 수집하거나 또는 유료 서비스를
                        회원에게 제공하는 경우 당해 회원이 본인임을 확인하기 위하여 필요한 조치를 취하고 있습니다.{' '}
                      </li>
                      <li>
                        {' '}
                        (7) 회사는 회원 개인의 실수 혹은 인터넷의 본질적인 위험성으로 인하여 야기되는 개인정보유출에
                        대해 책임을 지지 않습니다. 회원은 본인의 개인정보를 보호하기 위해서 자신의 아이디와 비밀번호를
                        적절하게 관리하고, 그에 대한 책임을 져야 합니다.
                      </li>
                    </ol>
                  </ol>
                </div>

                <div className="ruleCont">
                  <h4 className="tit">제14조 (개인정보보호책임자 및 담당자)</h4>
                  <ol className="num-circle-list">
                    <li>
                      회사는 회원의 개인정보보호를 가장 중요시하며, 회원의 개인정보가 훼손, 침해 또는 누설되지 않도록
                      최선을 다하고 있습니다. 서비스를 이용하시면서 발생하는 모든 개인정보보호 관련 민원을
                      개인정보보호책임자 혹은 담당자에게 신고하시면 신속하게 답변해드리도록 하겠습니다.
                    </li>
                    <li>[개인정보보호책임자]</li>
                    <li>성 명 : 윤형만</li>
                    <li>직 위 : 이사</li>
                    <li>- 전자우편 : mst@indj.net</li>
                    <li>- 전화번호 : 070-7779-0733</li>
                  </ol>
                </div>

                <div className="ruleCont">
                  <h4 className="tit">제15조 (개인정보 침해 관련 상담 및 신고)</h4>
                  <ol className="num-circle-list">
                    <li>
                      {' '}
                      회사는 귀하의 의견을 소중하게 생각합니다. 회사에 대하여 문의사항이 있을 경우 고객센터 등에
                      문의하시면 신속/정확한 답변을 드리겠습니다. 회사는 귀하와의 원활환 의사소통을 위해 고객상담센터를
                      운영하고 있으며 연락처는 다음과 같습니다.
                    </li>
                    <li>[고객상담센터]</li>
                    <li>- 전화번호 : 070-7779-0733</li>
                    <li>- 전자우편 : mst@indj.net</li>
                    <li>
                      기타 개인정보에 관한 상담이 필요한 경우에는 방송통신위원회 산하 공공기관인 한국인터넷진흥원(KISA)
                      개인정보침해신고센터 또는 경찰청 사이버안전국으로 문의하시기 바랍니다.
                    </li>
                    <li>[한국인터넷진흥원 개인정보침해신고센터]</li>
                    <li>전화번호 : (국번없이) 118</li>
                    <li>홈페이지 주소 : http://privacy.kisa.or.kr</li>
                    <li>[개인정보 분쟁조정위원회]</li>
                    <li>전화번호 : 1833-6972</li>
                    <li>홈페이지 주소 : http://www.kopico.go.kr</li>
                    <li>[경찰청 사이버안전국]</li>
                    <li>전화번호 : (국번없이) 182</li>
                    <li>홈페이지 주소 : http://cyberbureau.police.go.kr</li>
                    <li>[대검찰청 사이버수사과]</li>
                    <li>전화번호 : (국번없이) 1301</li>
                    <li>홈페이지 주소 : http://www.spo.go.kr</li>
                  </ol>
                </div>

                <div className="ruleBlock">
                  <h3>부칙</h3>
                  <div className="ruleBlockin">
                    <div className="ruleCont">
                      <h4 className="tit">제1조 (시행일)</h4>
                      <ol className="num-circle-list">
                        <li>본 약관은 2020년 10월 20일부터 시행됩니다.</li>
                      </ol>
                    </div>

                    <div className="ruleCont">
                      <h4 className="tit">제2조 (개인정보관리책임자 정보)</h4>
                      <ol className="num-circle-list">
                        <li>
                          회사는 개인위치정보를 적절히 관리·보호하고, 이용자의 불만을 원활히 처리할 수 있도록 실질적인
                          책임을 질 수 있는 지위에 있는 자를 위치정보관리책임자로 지정해 운영하고 있으며,
                          위치정보관리책임자는 위치기반서비스를 제공하는 부서의 부서장으로서 성명과 연락처는 아래와
                          같습니다.
                          <ol className="num-circle-list">
                            <li>성명 : 윤형만</li>
                            <li>대표전화 : 070-7779-0733</li>
                          </ol>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div onClick={() => toTop()}>
        <img className="uparr" src="/../images/up.png" alt="upImg"></img>
      </div>
    </div>
  );
};
export default Terms_loc;
