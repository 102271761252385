import './ticketTab.scss';
import React, { useState, useEffect } from 'react';
import Ticket from './TicketList/ticket';
import TicketInfo from './ticketInfo/ticketInfo';
import { Tabs } from 'antd';
const { TabPane } = Tabs;

const TicketTab = ({ match }) => {
  const [key, setKey] = useState('1');
  const [nowTicket, setnowTicket] = useState({});
  const onChange = (key) => {
    setKey(key);
  };

  let start_x, end_x;

  const touch_start = (event) => {
    start_x = event.touches[0].pageX;
  };
  const touch_end = (event) => {
    end_x = event.changedTouches[0].pageX;
    if (start_x > end_x) {
      setKey('2');
    } else {
      setKey('1');
    }
  };

  // 터치 이벤트 생성
  const applyTouchEvent = () => {
    document.addEventListener('touchstart', touch_start);
    document.addEventListener('touchend', touch_end);
  };
  // 터치 이벤트 삭제
  const removeTouchEvent = () => {
    document.removeEventListener('touchstart', touch_start);
    document.removeEventListener('touchend', touch_end);
  };
  useEffect(() => {
    applyTouchEvent();
    return () => removeTouchEvent;
  }, []);
  return (
    <div className="wrap" id="ticketTab">
      <Tabs className="tab_web_view" activeKey={key} onChange={onChange}>
        <TabPane tab="이용권 구매" key="1">
          <Ticket match={match} setnowTicket={setnowTicket} />
        </TabPane>
        <TabPane tab="보유 이용권" key="2">
          <TicketInfo nowTicket={nowTicket} />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default TicketTab;
