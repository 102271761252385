import React, { useEffect } from 'react';
import './modaDetail.scss';
import { useRecoilState } from 'recoil';
import { _footer } from 'recoil/state';
import ModaFooter from '../footer';
import ModaHeader from '../header';

const ModaDetail = () => {
  const [_, setFooter] = useRecoilState(_footer);
  useEffect(() => {
    setFooter(false);
  }, []);

  const FITS = [
    {
      id: 'zuxeoni',
      fit_point: 10.0,
    },
    {
      id: 'reve_sunhwa',
      fit_point: 10.0,
    },
    {
      id: 'songda_',
      fit_point: 9.75,
    },
    {
      id: 'xeonddong',
      fit_point: 9.7,
    },
    {
      id: 'luvingyou',
      fit_point: 9.5,
    },
    {
      id: 'gomgommom',
      fit_point: 9.5,
    },
    {
      id: 'soutenuu',
      fit_point: 9.25,
    },
    {
      id: 'sean_jjy',
      fit_point: 9.2,
    },
    {
      id: 'ennnnnnnnnnn_',
      fit_point: 9.11,
    },
    {
      id: 'w_alxx',
      fit_point: 8.5,
    },
    {
      id: '1week_cook',
      fit_point: 8.5,
    },
    {
      id: 'i_ngg',
      fit_point: 8.45,
    },
    {
      id: 'mirxodnjs',
      fit_point: 8.44,
    },
    {
      id: '0_0_sy',
      fit_point: 8.4,
    },
    {
      id: 'on2_i',
      fit_point: 8.35,
    },
    {
      id: 'mochi_sh',
      fit_point: 8.3,
    },
    {
      id: 'u_312sv_',
      fit_point: 8.28,
    },
    {
      id: 'dahae_0',
      fit_point: 8.22,
    },
    {
      id: 'somandme',
      fit_point: 7.01,
    },
    {
      id: 'sonyunara',
      fit_point: 7.0,
    },
  ];

  return (
    <div id="moda_warap" className="detail_main">
      <ModaHeader />

      <main className="flex">
        <div className="left">
          <div className="title">
            <h2>[무등] 무돌이와 떠나는 무등산 윷놀이 세트</h2>
            <h3>광주 무등산에 사는 무등이와 함께 무등산을 여행해봐요!</h3>
          </div>
          <div className="people">
            <span>캠페인 정보</span>
            <span>참여인원 20 / 10 </span>
          </div>
          <div className="img">
            <img src="/images/designZoos/img.png" alt="" />
          </div>
          <table className="schedule vertical">
            <tbody>
              <tr>
                <td>제공 내역</td>
                <td>[무등] 무돌이 윷놀이 세트(1set)</td>
              </tr>
              <tr>
                <td>캠페인 안내</td>
                <td>
                  <ul>
                    <li>1. 본 캠페인은 무료배송으로 진행됩니다.</li>
                    <li>2. 포스팅 하단에 판매 링크 및 대가성 문구 기입을 필수적으로 해주시기 바랍니다.</li>
                    <li>3. 작성된 포스팅 내 사진은 업체 측 상세페이지에 활용될 수 있습니다.</li>
                    <li>
                      4. 제공내역안내 - 무돌이 윳놀이 세트 - 구성: 주상절리 윳가락, 틴케이스, 손수건 윷놀이판, 무돌이
                      윷말, 파우치
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>활동 미션</td>
                <td>
                  <ul>
                    <li>[블로그 미션]</li>
                    <li>1. 포스팅 제목은 메인 키워드를 모두 포함하여 작성해주세요</li>
                    <li>2. 제품 사진을 포함하여 이미지20장, 동영상 1개 이상 업로드 해주세요.</li>
                    <li>- 필수: 박스컷, 박스+구성품 단체컷</li>
                    <li>- 필수: 각 구성품별 디테일컷</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>키워드</td>
                <td>
                  <ul>
                    <li>메인 키워드: #광주굿즈 #무등 #무돌이</li>
                    <li>서브 키워드: #MMZ #윷놀이 #광주관광</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>안내사항</td>
                <td>
                  <ul>
                    <li>- 안내된 등록기간 내 등록된 콘텐츠만 활동으로 인정됩니다.</li>
                    <li>- 활동 미션이 준수되지 않을 시 수정 요청이 있을 수 있습니다.</li>
                    <li>- 성의 없는 콘텐츠의 경우 수정 요청이 있을 수 있습니다.</li>
                    <li>- 본 캠페인의 콘텐츠는 업체 홍보 목적으로 사용될 수 있습니다.</li>
                    <li>
                      - 작성된 콘텐츠는 캠페인 종료일로부터 최소 6개월동안 전체공개로 유지되어야 하며, 미유지시 비용청구
                      및 패널티가 적용됩니다.
                    </li>
                    <li>
                      - 제공받은 제품을 타인에게 양도 및 판매, 교환을 절대 허용하지 않으며 적발시 비용청구 및 참여제한이
                      됩니다.
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="right">
          <table className="duration vertical">
            <tbody>
              <tr>
                <td>캠페인 신청기간</td>
                <td>10.15. ~ 10.30.</td>
              </tr>
              <tr>
                <td>인플루언서 발표</td>
                <td>11.05.</td>
              </tr>
              <tr>
                <td>콘텐츠 등록기간</td>
                <td>11.06. ~ 11.25.</td>
              </tr>
              <tr>
                <td>캠페인 결과발표</td>
                <td>11.08.</td>
              </tr>
            </tbody>
          </table>
          <table className="fit">
            <thead>
              <tr>
                <td>인플루언서 명</td>
                <td>광고 적합도</td>
              </tr>
            </thead>
            <tbody>
              {FITS.map((fit, idx) => {
                return (
                  <tr key={fit.id}>
                    <td>
                      {idx + 1}. {fit.id}
                    </td>
                    <td>{fit.fit_point}점</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </main>
      <ModaFooter />
    </div>
  );
};
export default ModaDetail;
