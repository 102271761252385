import React, { useEffect } from 'react';
import './moda.scss';
import { useRecoilState } from 'recoil';
import { _footer } from 'recoil/state';
import ModaHeader from './header';
import { Link } from 'react-router-dom';
import ModaFooter from './footer';

const Moda = () => {
  const [_, setFooter] = useRecoilState(_footer);

  useEffect(() => {
    setFooter(false);
  }, []);

  const MOVE_INFO_List = [
    {
      idx: 1,
      title: '(주)디자인주스',
      desc: 'design****',
    },
    {
      idx: 2,
      title: '모집 캠페인',
      desc: '(1건)',
    },
    {
      idx: 3,
      title: '진행 캠페인',
      desc: '(0건)',
    },
    {
      idx: 4,
      title: '완료 캠페인',
      desc: '(3건)',
    },
    {
      idx: 5,
      title: '보유 포인트',
      desc: '(500,000p)',
    },
  ];

  return (
    <div id="moda_warap">
      <div className="moda">
        <ModaHeader />
        <main id="main">
          <div className="main">
            <div className="move">
              <h2>활동현황</h2>
              <div className="mode_node_wrap flex">
                {MOVE_INFO_List.map((info, idx) => (
                  <div className={`mode_node ${idx === 0 && 'on'} `} key={info.idx}>
                    <h4>{info.title}</h4>
                    <span>{info.desc}</span>
                  </div>
                ))}
              </div>
            </div>
            <div className="correct">
              <h2>모집 캠페인</h2>
              <table className="correct_table">
                <thead>
                  <tr>
                    <td>No.</td>
                    <td>캠페인 명</td>
                    <td>모집기간</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td className="cursor">
                      <Link to="/moda_detail">[무등] 무돌이와 떠나는 무등산 윷놀이 세트</Link>
                    </td>
                    <td>10.15 ~ 10.30</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="correct">
              <h2>진행 캠페인</h2>
              <table className="correct_table">
                <thead>
                  <tr>
                    <td>No.</td>
                    <td>캠페인 명</td>
                    <td>모집기간</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="correct">
              <h2>완료 캠페인</h2>
              <table className="correct_table">
                <thead>
                  <tr>
                    <td>No.</td>
                    <td>캠페인 명</td>
                    <td>모집기간</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td className="cursor">
                      <button>[무등] 캠페인A</button>
                    </td>
                    <td>09.01 ~ 09.15</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td className="cursor">
                      <button>[무등] 캠페인B</button>
                    </td>
                    <td>09.05 ~ 09.20</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td className="cursor">
                      <button>[무등] 캠페인C</button>
                    </td>
                    <td>09.30 ~ 10.10</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </main>
        <ModaFooter />
      </div>
    </div>
  );
};
export default Moda;
