export const shareTwitter = (url) => {
  const sendUrl = url; // 전달할 URL
  window.open('https://twitter.com/intent/tweet?text=&url=' + sendUrl);
};

export const shareFacebook = (url) => {
  const sendUrl = url; // 전달할 URL
  window.open('http://www.facebook.com/sharer/sharer.php?u=' + sendUrl);
};

export const copyURL = () => {
  var textarea = document.createElement('textarea');
  textarea.value = window.location.href;

  document.body.appendChild(textarea);
  textarea.select();
  textarea.setSelectionRange(0, 9999); // 추가

  document.execCommand('copy');
  document.body.removeChild(textarea);
  alert('복사 되었습니다.');
};
