import React, { useEffect, useState } from 'react';
import './calendar.scss';
import { getCheckEvtApi, postCheckApi } from 'apis/apis';
import { message } from 'antd';
import {throttle} from "lodash";
// import { debounce } from '@material-ui/core';

const CheckCalendar = () => {
  const [weeks, setWeeks] = useState([]);
  const WEEK = ['0', '일', '0', '월', '0', '화', '0', '수', '0', '목', '0', '금', '0', '토'];
  const nowMonth = new Date().getMonth();
  const nowYear = new Date().getFullYear();
  const nowYDate = new Date().getDate();
  const getApi = async () => {
    const {
      data: { date },
    } = await getCheckEvtApi(nowMonth + 1, nowYear);
    console.log(date);
    getData(date);
  };

  const getData = (data) => {
    const firDayIndex = new Date(nowYear, nowMonth, 1).getDay(); // 이번달 첫날 요일 인덱스
    const lastDate = new Date(nowYear, nowMonth + 1, 0).getDate(); // 이번달 마지막날짜

    const _days = [];
    const _weeks = [];

    for (let i = 0; i < firDayIndex; i++) {
      _days.push(-1);
    }

    for (let i = 1; i <= lastDate; i++) {
      const check = data.findIndex((el) => +el === i) > -1;
      const check_data = { date: i, check: check };
      _days.push(check_data);
    }

    let num = 0;

    while (true) {
      const week = _days.slice(num, num + 7);

      if (week.length === 0) break;
      if (week.length !== 7) {
        const last = 7 - week.length;
        for (let i = 0; i < last; i++) {
          week.push(-1);
        }
        _weeks.push(week);
        break;
      }
      _weeks.push(_days.slice(num, num + 7));
      num += 7;
    }

    setWeeks(_weeks);
  };

  const checkToDay = async () => {
    const { data } = await postCheckApi();
    if (!data.success) {
      message.config({
        duration: 2,
        maxCount:1,
      });
      message.warning(data.reason);
    } else {
      message.success(data.reason);
      getApi();
    }
  };
  const checkToDay2= throttle(checkToDay, 2000);

  useEffect(() => {
    getApi();
  }, []);
  return (
    <div id="calendar" className="mx-20">
      <h2 className="title">
        <span className="month">{nowMonth + 1}월</span>
        <br />
        <span className="check">출석체크</span>
      </h2>
      <div className="calendar">
        <div className="week">
          {WEEK.map((week, idx) => {
            if (idx !== 0) {
              return (
                <div className={idx !== 0 && idx % 2 !== 0 ? 'week_circle' : 'dash'} key={idx}>
                  {idx !== 0 && idx % 2 !== 0 ? <div className="week_circle">{week}</div> : null}
                </div>
              );
            } else {
              return null;
            }
          })}
        </div>
        <div className="day">
          {/* {days.map((el) => ( */}
          {weeks.map((week, idx) => (
            <div key={idx} className="week">
              {week.map((day, key) => (
                <div key={key} className={`day_node ${day === -1 && 'nope'}`}>
                  <img className="check" src={`/../images/check/check_${day.check ? 'yes' : 'no'}.png`} alt="" />
                  <div className={`day_span ${day.date === nowYDate && 'today'}`}>{day.date}</div>
                </div>
              ))}
            </div>
          ))}
        </div>
        {/* <button onClick={checkToDay} className="check_btn"></button> */}
        <button onClick={checkToDay2} className="check_btn">
          <span className="btn_text">출석체크</span>
          <img className="btn_img" src={`/../images/check/check_btn.png`} alt="" />
        </button>
      </div>
    </div>
  );
};

export default CheckCalendar;
