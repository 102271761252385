import React, { useEffect } from 'react';
import './Prizewinner.scss';
import data, * as asd from './data';
import '../../../index.css';
import { useState } from 'react';

const Prizewinner = () => {
  useEffect(() => {
    console.log(asd.top);
    console.log(data);
  }, []);
  const [searchTerm, setSearchTerm] = useState('');
  const docs = () => {
    window.location.href =
      'https://docs.google.com/spreadsheets/d/1PGBeZkj2u0RvSyPiZ6VXYRK_vIoWI-hJ/edit#gid=329657963';
  };
  console.log(searchTerm);
  return (
    <div id="prizewinnerwrap" className="eventwrap">
      <div className="prize_container">
        <h1 className="prize_header">METABX Token AIRDROP EVENT 당첨자</h1>
        <div className="notice">
          9.8~9.30일까지 진행되었던 <br />
          <span className="notice_bold">METABX 에어드랍 이벤트가 종료되었습니다!</span> <br />
          본 에어드랍 이벤트에 참여해주셔서 대단히 감사드립니다.
          <br />
          <span className="notice_bold">inDJ앱은 현재 한국,미국,호주 대상</span>으로 지원되고 있으며 어뷰징이 많이
          확인된 영국을 제외 한 타국가는 당첨자 선정에 적용하였으니 참고하시길 바랍니다.
        </div>
        <div className="topwin">
          <ul className="topwin_ul">
            {asd.top.map((item, idx) => (
              <>
                <span className="num">레퍼럴 {idx + 1}등 당첨자 </span>
                <li className="topwin_li">{item}</li>
              </>
            ))}
          </ul>
        </div>
        <div className="search">
          <input
            type="text"
            placeholder="Wallet Address Search"
            className="search_in"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
          />
        </div>
        <div className="missionwin">
          <ul className="missionwin_ul">
            <span className="missionwin_user">미션성공자</span>
            {data
              .filter((data) => data.includes(searchTerm))
              .map((item, idx) => {
                if (searchTerm === '') {
                  return null;
                } else {
                  return (
                    <li className="missionwin_li" key={item}>
                      {item}
                    </li>
                  );
                }
              })}
          </ul>
        </div>
        <div style={{ textAlign: 'center' }}>
          <button onClick={docs} style={{ width: '150px' }}>
            당첨자 명단
          </button>
        </div>
      </div>
    </div>
  );
};

export default Prizewinner;
