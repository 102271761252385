import { postApi2, getApi2 } from "./baseApis";

// 테스트 인원 가져오기
export const getUserApi = (type) => {
  const url = `/event/api/MBTILog?type=${type}`;
  const data = getApi2(url);
  return data;
};

// 테스트 인원 카운트하기
export const addUserApi = (type) => {
  const url = `/event/api/insertMBTILog`;
  const body = {
    type: type,
  };
  const data = postApi2(url, body);
  return data;
};
