import React, { useEffect } from 'react';
import './footer.scss';

const ModaFooter = () => {
  return (
    <footer>
      <div className="footer">
        <h1>MODA</h1>
        <div className="con flex">
          <div className="one">
            <p>(주)디자인주스</p>
            <p>Tel. 062-236-2226</p>
            <p>e-mail designzoos@gmail.com </p>
          </div>
          <div className="two">
            <p>대표이사 박현진</p>
            <p>사업자 등록번호 247-81-01418</p>
            <p>광주광역시 동구 금남로 193-22, 1동 2층 313호</p>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default ModaFooter;
