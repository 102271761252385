import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Row, Col } from 'antd';
import Footer from './pages/Footer';
import routes from './route';
import { RecoilRoot } from 'recoil';
import './css/animate.css/animate.min.css';

import './css/glyphicons/glyphicons.css';
import './css/font-awesome/css/font-awesome.min.css';
import './css/material-design-icons/material-design-icons.css';
import './css/bootstrap/dist/css/bootstrap.min.css';

import './css/styles/app.css';
import './css/styles/style.css';
import './css/styles/font.css';
import './css/join_suc.css';

import './css/owl.theme.css';
import './css/mediaelementplayer.min.css';
import './css/common.css';
import './css/background.css';
import './css/enrollment.css';
import './css/mep.css';
import './css/theme/primary.css';
import './css/footer.css';
import './css/bootstrap/scss/mixins/_tag.scss';
import './css/footers.scss';

const App = () => {
  return (
    <div>
      <RecoilRoot>
        <Switch>
          {routes.map((el, index) => (
            <Route
              exact={el.exact === true}
              path={el.path}
              name={el.name}
              component={el.component}
              key={`${index}-${el.path}`}
            />
          ))}
        </Switch>
        <Col className="policyfooter">
          <Footer></Footer>
        </Col>
      </RecoilRoot>
    </div>
  );
};

export default App;
