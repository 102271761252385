import React from 'react';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { _footer } from 'recoil/state';
import './Station.scss';

const Station = () => {
  const [_, setFooter] = useRecoilState(_footer);
  useEffect(() => {
    setFooter(false);
  }, []);
  return (
    <div id="station">
      <div className="stationweb container">
        <div className="header">
          스테이션 생성 <span className="header-white">이벤트!</span>
        </div>

        <div className="subheader">
          내<span className="subheader-orange"> 플레이리스트</span> 같이 들어볼래?
        </div>

        <div className="title">
          <img
            className="title-img"
            src="https://indj.s3.ap-northeast-2.amazonaws.com/image/event/station_title.png"
            alt="타이틀"
          ></img>
        </div>

        <div className="reward">
          <div className="reward-h3">
            <div className="reward-flex">
              <div>보상</div>&nbsp;
              <img
                src="https://indj.s3.ap-northeast-2.amazonaws.com/image/event/station_emo.png"
                alt=""
                style={{ width: '20px', height: '20px' }}
              />
            </div>
          </div>
          <div className="reward-text">
            스테이션 생성만 해도 <span className="reward-orange">5 METABX</span>
          </div>
        </div>

        <div className="event">
          <div className="event-h3">참여 방법</div>
          <div className="event-text">
            <div className="event-flex">
              inDJ APP → 하단 &nbsp;
              <img
                className="event-img"
                src="https://indj.s3.ap-northeast-2.amazonaws.com/image/event/station_plus.png"
                alt="더하기아이콘"
              />
              &nbsp; 버튼 누르기
            </div>
            → 해당 페이지에서 스테이션 생성
            <br />
            제목 짓고 → 태그 추가 → 곡담기 → <span className="event-orange">1분만에 끝!</span>
          </div>
        </div>

        <div className="notice">
          <div className="notice-h3">스테이션 제작 시 유의사항</div>
          <ul className="notice-list">
            <li>∙ 스테이션 총 시간이 1시간 이상, 곡 15개 이상</li>
            <li>∙ 1일 최대 5개 스테이션 제작 보상제한</li>
            <li>∙ 어뷰징 확인 될 경우 지급 대상에서 제외</li>
            <li>∙ METABX 예산 소진 시 이벤트가 조기 종료 가능</li>
          </ul>
        </div>
        <div className="Stationlastline">
          <img
            className="lastline-img"
            src="https://indj.s3.ap-northeast-2.amazonaws.com/image/event/station_line.png"
            alt="마지막선"
          ></img>
        </div>

        <div className="footer">
          {/* 다른기기 사이즈 가 안되어서 이미지 대체  */}
          {/* <img className="footer-img" src="../images/station/footer.png"></img> */}

          <div className="footer-text">
            음악은 당신의 <span className="footer-bold">상황</span>과 <span className="footer-bold">감정</span>을 잘
            느끼고 소통합니다.
            <br />
            당신보다 더 당신을 잘 아는 <span className="footer-bold">음악 메타버스 플랫폼</span>
            <br />
            이제 만나러 갑니다!
          </div>
        </div>
      </div>
    </div>
  );
};

export default Station;
