import React, { useState, useEffect } from "react";
import { result } from "./data";
import LazyLoad from "react-lazyload";
import KakaoShare from "./KakaoShare";
import { shareTwitter, shareFacebook, copyURL } from "./Share";
import RecoSong from "./RecoSong";
import ReactHelmet from "../Helmet/Helmet";
import { addUserApi } from "apis/mbti";
import styled from "styled-components";
import { upArrow, insta, blog } from "../svg";
import WebtoonImg from "./WebtoonImg";

const StyledDiv = styled.div`
  text-align: left;
  overflow: hidden;
  ${(props) =>
    props.fold
      ? `height:${props.beforeHeight}px`
      : `height:${props.afterHeight}px`}
`;

const Result = ({ match, history, location }) => {
  const [device, setdevice] = useState(0);
  const [beforeHeight, setBeforeHeight] = useState(0);
  const [afterHeight, setAgterHeight] = useState(0);
  const [folding, setFolding] = useState(true);
  const yourType = result[match.params.type];
  const [recoChannel, setRecoChannel] = useState({});
  const [loading, setLoading] = useState(true);
  const [num, setNum] = useState(0);
  const [easter, setEaster] = useState(false);

  useEffect(() => {
    addUserApi(1);
    checkDevice();
    document.getElementById("footer").style.display = "none";
    window.scrollTo({ top: 0, behavior: "auto" });
    resultData();
    fold();
  }, [loading]);

  // 결과데이터 저장
  const resultData = () => {
    try {
      setNum((old) => old + 1);
      if (num < 1) {
        const rannum = Math.floor(Math.random() * 3);
        setRecoChannel(result[match.params.type].channel[rannum]);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  // 추천채널 음원리스트 높이 변경
  const fold = () => {
    if (!loading) {
      const song = document.getElementsByClassName("song-node"); // 음원 접근
      const songHeight = song[0].clientHeight;

      // 음원 마진접근
      const nodeStyle = window.getComputedStyle(song[0]);
      const slideMarginRight = nodeStyle.getPropertyValue("margin-bottom");
      const mbottom = +slideMarginRight.replace("px", "");

      const bfHeight = 3 * (songHeight + mbottom);
      const afHeight = song.length * (songHeight + mbottom);
      setAgterHeight(afHeight);
      setBeforeHeight(bfHeight);
    }
  };

  // 기기체크
  const checkDevice = () => {
    const varUA = navigator.appVersion.toLowerCase(); //userAgent 값 얻기

    if (varUA.indexOf("android") > -1 || varUA.indexOf("window") > -1) {
      //안드로이드
      setdevice(1);
    } else if (
      varUA.indexOf("iphone") > -1 ||
      varUA.indexOf("ipad") > -1 ||
      varUA.indexOf("ipod") > -1 ||
      varUA.indexOf("mac") > -1
    ) {
      //IOS
      setdevice(2);
    }
  };

  // 처음으로 가기
  const goBack = () => {
    history.push("/weathertest");
  };
  // 스토어 가기
  const goToStore = () => {
    if (device === 2) {
      window.location.assign(
        "https://apps.apple.com/kr/app/indj-%EC%9E%90%EC%9C%A0%EB%A1%AD%EA%B2%8C-%EB%93%A3%EB%8A%94-%EC%83%81%ED%99%A9-%EA%B0%90%EC%84%B1-%EC%9D%B8%EA%B3%B5%EC%A7%80%EB%8A%A5-%EC%9D%8C%EC%95%85/id1513542512"
      );
    } else {
      window.location.assign(
        "https://play.google.com/store/apps/details?id=com.indj.music"
      );
    }
  };
  // 이스터에그
  const eaterEgg = (before) => {
    setEaster(before);
  };
  if (!loading) {
    return (
      <div className="weather_result">
        <ReactHelmet
          keywords="inDJ,음악취향,음악취향테스트,날씨,mbti,음악추천,무료음악,음악어플,멜론,지니,벅스,유튜브뮤직,음악,music"
          description="날씨 테스트로 알아보는 나의 성격"
          title={yourType.title}
          favicon={yourType.miri}
        />
        <div className="result-wrap">
          <LazyLoad offset={100}>
            <img
              className="result-img"
              src={`/../images/weatherTest/${yourType.img}.png`}
              alt="결과이미지"
            />
          </LazyLoad>
          <div className="result-desc">
            <div className="result-top">
              <p className="modifier">{yourType.mod}</p>
              <p className="title">{yourType.title}</p>
              {yourType.desc.map((el, idx) => {
                return (
                  <p key={idx} className="desc">
                    {el}
                    <br />
                    <br />
                  </p>
                );
              })}
            </div>
            <div className="webtoon">
              {!easter ? (
                <>
                  <WebtoonImg fuc={eaterEgg} />
                  <div className="webtoon_btn_wrap">
                    <button className="webtoon_btn">
                      <a
                        className="webtoon_link"
                        href="https://policy.indj.club/webtoon"
                      >
                        웹툰 보러가기
                      </a>
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <img
                    className="webtoon_img"
                    src="/../images/weatherTest/easter.gif"
                    useMap="#easterEgg"
                    onClick={() => eaterEgg(false)}
                    alt="홍보이미지"
                  />
                </>
              )}
            </div>
            <div className="result-channel">
              <p className="result-channel-text">인디제이 추천 채널!</p>
              <LazyLoad offset={100}>
                <img
                  className="result-channel-img"
                  src={recoChannel.ch_img}
                  alt="결과이미지"
                  onClick={() => goToStore()}
                />
              </LazyLoad>
              <p className="result-channel-name">{recoChannel.ch_name}</p>

              <StyledDiv
                fold={folding}
                beforeHeight={beforeHeight}
                afterHeight={afterHeight}
              >
                {recoChannel
                  ? recoChannel.ch_song.map((el, idx) => {
                      return (
                        <RecoSong
                          goToStore={goToStore}
                          key={`${el.title}-${idx}`}
                          img={el.thumbnail}
                          title={el.title}
                          singer={el.singer}
                        />
                      );
                    })
                  : null}
              </StyledDiv>
              <div className="show-more">
                <span className="other">
                  {folding
                    ? `외 ${recoChannel.ch_song.length - 3} 곡`
                    : `총 ${recoChannel.ch_song.length} 곡`}
                </span>
                <button
                  onClick={() => setFolding(!folding)}
                  className="see-all"
                >
                  {upArrow(folding ? "" : "down")}
                  {folding ? "모든 노래 봐볼래영" : "접을래영"}
                </button>
              </div>
              {/* <div
              className="result-channel-songs"
              style={{ height: recoHeight }}
            >
              {recoChannel
                ? recoChannel.ch_song.map((el, idx) => {
                    return (
                      <RecoSong
                        key={`${el.title}-${idx}`}
                        img={el.thumbnail}
                        title={el.title}
                        singer={el.singer}
                      />
                    );
                  })
                : null}
              <span>{`외 ${recoChannel.ch_song.length - 3} 가락`}</span>
            </div> */}
            </div>
            <div className="go-to-wrap">
              <button
                onClick={() => goBack()}
                className="go-to-store"
                type="button"
              >
                다시보기
              </button>
              <button
                onClick={() => goToStore()}
                className="go-to-store"
                type="button"
              >
                스토어 바로가기
              </button>
            </div>

            <div className="share">
              <p className="share-text">친구들에게 공유하기</p>
              <div className="share-btn-wrap">
                <KakaoShare desc={yourType.title} img={yourType.thum} />
                <button
                  style={{ backgroundColor: "#046BE3" }}
                  className="share-node"
                  onClick={() => shareFacebook(window.location.href)}
                >
                  <div className="share-img-wrap">
                    <LazyLoad offset={100}>
                      <img
                        className="share-img"
                        src="/../images/weatherTest/facebook.png"
                        alt="공유이미지"
                      />
                    </LazyLoad>
                  </div>
                  <span className="platform-text">페북으로 공유</span>
                </button>
                <button
                  style={{ backgroundColor: "#47ACDF" }}
                  className="share-node"
                  onClick={() => shareTwitter(window.location.href)}
                >
                  <div className="share-img-wrap">
                    <LazyLoad offset={100}>
                      <img
                        className="share-img"
                        src="/../images/weatherTest/twitter.png"
                        alt="공유이미지"
                      />
                    </LazyLoad>
                  </div>
                  <p className="platform-text">트위터로 공유</p>
                </button>
                <button
                  style={{ backgroundColor: "#333333" }}
                  onClick={() => copyURL()}
                  className="share-node"
                >
                  <div className="share-img-wrap">
                    <LazyLoad offset={100}>
                      <img
                        className="share-img"
                        src="/../images/weatherTest/link.png"
                        alt="공유이미지"
                      />
                    </LazyLoad>
                  </div>
                  <p className="platform-text">링크 복사</p>
                </button>
              </div>
            </div>
            <LazyLoad offset={100}>
              <img
                className="weather_logo"
                src="/../images/weatherTest/weather_logo.png"
                alt="인디제이 로고"
              />
            </LazyLoad>
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export default Result;
