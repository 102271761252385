import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import { useRecoilState } from 'recoil';
import { _footer } from 'recoil/state';

const Footer = () => {
  useEffect(() => {}, []);

  const [footer] = useRecoilState(_footer);

  const company_info = 'https://indj.ai/company_intro/kr'; // 회사소개
  const trems_info = 'https://policy.indj.club/terms_use'; // 이용약관
  const location_info = 'https://policy.indj.club/terms_loc'; // 위치정보 이용약관
  const privacy_info = 'https://policy.indj.club/terms_private'; // 개인정보 처리 방침
  const teenager_info = 'https://policy.indj.club/terms_teenager'; // 청소년 보호 정책
  const mailorder = 'https://policy.indj.club/mailorder'; // 통신판매정보 확인

  if (footer) {
    return (
      <div id="policyfooter">
        <footer id="footer">
          <div className="texts">
            <div className="text_topall">
              <a className="text_top" href={company_info} target="_blank" rel="noopener noreferrer">
                회사소개
              </a>
              &nbsp;<span className="text_line">|</span>&nbsp;
              <a className="text_top" href={trems_info} target="_blank" rel="noopener noreferrer">
                이용약관
              </a>
              &nbsp;<span className="text_line">|</span>&nbsp;
              <a className="text_top" href={location_info} target="_blank" rel="noopener noreferrer">
                위치정보 이용약관
              </a>
              &nbsp;<span className="text_line">|</span>&nbsp;
              <a className="text_top" href={teenager_info} target="_blank" rel="noopener noreferrer">
                청소년보호정책
              </a>
              &nbsp;<span className="text_line">|</span>&nbsp;
              <a className="text_top user_info" href={privacy_info} target="_blank" rel="noopener noreferrer">
                개인정보처리방침
              </a>
            </div>
            <div className="text_sub">
              <p className="text_bottom">
                상호명 : (주)인디제이 |&nbsp;대표 : 정우주&nbsp;| &nbsp;개인정보보호책임자 :
                윤형만&nbsp;|&nbsp;사업자등록번호 490-81-01351
              </p>
              <p className="text_bottom">
                통신판매신고업번호 : 2022-광주동구-0338{' '}
                <a className="mailorder" href={mailorder} target="_blank" rel="noopener noreferrer">
                  사업자정보 확인 {'>'}
                </a>{' '}
                &nbsp;| &nbsp; 호스팅서비스사업자 : AWS
              </p>
              <p className="text_bottom">
                주소 : 서울 강남구 역삼로 169, 6F / 광주광역시 동구 금남로 193-12, 404호 | 제휴 문의 : mst@indj.net
              </p>
            </div>
          </div>
          <div className="logo">
            <a href="https://twitter.com/IndjOfficial">
              <img
                className="logo_img"
                src="https://indj.s3.ap-northeast-2.amazonaws.com/image/web_player/footer_twitter.png"
                alt="트위터"
              />
            </a>
            <a href="https://www.instagram.com/indjofficial/?hl=ko">
              <img
                className="logo_img"
                src="https://indj.s3.ap-northeast-2.amazonaws.com/image/web_player/footer_instargram.png"
                alt="인스타그램"
              />
            </a>
            <a href="https://www.ces.tech/Innovation-Awards/Honorees/2023/Honorees/I/inDJ.aspx">
              <img
                className="logo_img ces"
                src="https://indj.s3.ap-northeast-2.amazonaws.com/image/web_player/footer_ces.png"
                alt="CES"
              />
            </a>
          </div>
        </footer>
      </div>
    );
  } else {
    return null;
  }
};

export default Footer;
