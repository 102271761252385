export const promotionNotice = [
  "6회차 까지 500원으로 결제되며, 이후 정상가로 결제됩니다",
  "7회차 이상 정기결제 유지 조건 상품입니다",
];
export const defaultNotice = [
  "이용권 금액은 부가세 10%가 포함된 가격입니다.",
  "‘정기’ 표시된 이용권은 1개월마다 자동으로 구독 갱신되며, 현재 구독 기간이 만료되기 최소 24시간 전에 취소하지 않으면 구독이 자동으로 갱신됩니다.",
  "이용권 기간 내에 자동결제를 취소 하더라도 남은 이용권 사용 기간까지는 서비스를 이용 할 수 있습니다.",
  "‘일반’ 표시된 이용권은 구매와 동시에 과금 및 적용 되며 사용이력에 따라 환불이 불가할 수 있습니다.",
  "음원 권리사의 요청으로 특정 음원의 경우 음악 듣기 서비스 이용이 제한 될 수 있습니다.",
  "모바일 3G/LTE/5G 네트워크 환경에서 음원 청취 시 과도한 데이터 요금이 부과될수 있습니다.",
  "이벤트 이용권의 경우 할인 기간 동안만 해당 가격으로 적용되며, 이후 정상가로 결제됩니다. 해당 이벤트는 사전 예고 없이 종료 될 수 있습니다.",
];
