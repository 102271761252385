import { getApi, postApi } from './baseApis';
import axios from 'axios';

// 파라미터 토큰 검증
export const postTokenCheckApi2 = async (token) => {
  const data = await axios.post(`https://api1.indj.club/api/v1/token_verify`, { token: token }, {});
  return data;
};

// 로컬스토리지 토큰 검증
export const postTokenCheckApi = () => {
  const url = `/api/v1/token_verify/sliding_token`;
  const data = postApi(url, null);
  return data;
};

// 구독 정보 가져오기
export const getUsingTypeApi = () => {
  const url = `/api/v2/setting/payment_type`;
  const data = getApi(url);
  return data;
};

// costomer_uid 발급
export const getCustomerUidApi = () => {
  const url = `/api/v3/payment/customer_uid`;
  const data = getApi(url);
  return data;
};

// 이용권리스트
export const getTicketListApi = () => {
  const url = `/api/v3/payment/v3_check_paid`;
  const data = getApi(url);
  return data;
};
// 이용권리스트2
export const getTicketListApi2 = () => {
  const url = `/api/v3/payment/v3_1_check_paid`;
  const data = getApi(url);
  return data;
};
// 일반결제
export const postNormalPayApi = (body) => {
  const url = `/api/v3/payment/v3_android`;
  const data = postApi(url, body);
  return data;
};
// 정기결제
export const postroutinPayApi = (body) => {
  const url = `/api/v3/payment/v3_iamport_monthly_pay`;
  const data = postApi(url, body);
  return data;
};

// banner
export const getBannerApi = () => {
  const url = `/api/v3/promotion/banner`;
  const data = getApi(url);
  return data;
};

// 출첵이벤트 현황
export const getCheckEvtApi = (month, year) => {
  const url = `/api/v3b/event/attendance?month=${month}&year=${year}`;
  const data = getApi(url);
  return data;
};

// 출첵하기
export const postCheckApi = () => {
  const url = `/api/v3b/event/attendance`;
  const data = postApi(url, null);
  return data;
};

// 인기 스테이션 투표
export const getAwardsPopular = () => {
  const url = `/api/v3b/event/station-awards/famous?max=5`;
  const data = getApi(url);
  return data;
};

// 최신 등록 스테이션 투표
export const getAwardsNew = () => {
  const url = `/api/v3b/event/station-awards/new?max=5`;
  const data = getApi(url);
  return data;
};
