import React, { useState, useRef } from "react";

const Wrong = () => {
  const [num, setNum] = useState(1);
  const [easter, setEaster] = useState(false);

  const err1 = useRef(null);
  const err2 = useRef(null);

  const change = () => {
    setNum(num + 1);
    if (num === 10) {
      setEaster(!easter);
      setNum(1);
    }
  };

  return (
    <div
      style={{ backgroundColor: "#181818", height: "100vh" }}
      className="app dk"
      id="app"
    >
      <div className="all">
        {easter ? (
          <img
            ref={err2}
            onClick={() => change()}
            src="/images/error2.png"
            alt=""
          ></img>
        ) : (
          <img
            ref={err1}
            onClick={() => change()}
            src="/images/error1.png"
            alt=""
          ></img>
        )}
      </div>
    </div>
  );
};
export default Wrong;
