import React, { useState, useEffect, useCallback } from "react";
import "./ticket.scss";
import { postTokenCheckApi, getTicketListApi2 } from "apis/apis";
import Wrong from "../../Wrong";
import TicketList from "./ticketList/ticketList";
import PayType from "./payType/payType";
import { useRecoilState } from "recoil";
import { _payInfo } from "recoil/state";
import { immers } from "utils/util";
import TicketModal from "./ticketModel/ticketModal";
import TicketInput from "./ticketInput/ticketInput";
import TicketNotice from "./ticketNotice/ticketNotice";

const Ticket = ({ match, setnowTicket }) => {
  const [ready, setReady] = useState(false);
  const [ticketList, setTicketList] = useState([]);
  const [payInfo, setPayInfo] = useRecoilState(_payInfo);
  const [visible, setVisible] = useState(false);

  // 토큰 검증
  const tokenCheck = useCallback(async () => {
    localStorage.setItem("currentUser", match.params.token);
    const { data } = await postTokenCheckApi();
    if (!data.success) {
      localStorage.removeItem("currentUser");
      alert("잘못된 접근입니다.");
      return;
    }
    setReady(true);
  }, [match.params.token]);
  // 이용권리스트, 현재이용권
  const getTicketList = async () => {
    const {
      data: { ticket_data, data },
    } = await getTicketListApi2();
    console.log(ticket_data);
    console.log(data);
    setTicketList(ticket_data);
    setnowTicket(data);
    const firstTicket = ticket_data[0];
    const val = firstTicket.dc_amount
      ? firstTicket.dc_amount
      : firstTicket.amount;
    setPayInfo(immers(payInfo, ["name", "amount"], [firstTicket.name, val]));
  };
  useEffect(() => {
    tokenCheck();
    getTicketList();
  }, [tokenCheck]);

  if (ready) {
    return (
      <div id="ticket">
        <TicketList ticketList={ticketList} />
        <PayType />
        <TicketInput />
        <div className="tickey_button_wrap mx-20">
          <button
            className="ticket_buy"
            onClick={() => setVisible(true)}
            type="button"
          >
            이용권 구매
          </button>
        </div>
        <TicketNotice />
        <TicketModal visible={visible} setVisible={setVisible} />
      </div>
    );
  } else {
    return <Wrong />;
  }
};

export default Ticket;
