import React, { useState, useEffect } from "react";
import Questions from "./Question";
import { question } from "./data";
import check from "./Waiting";
import { getUserApi } from "apis/mbti";
import ReactHelmet from "../Helmet/Helmet";
import LazyLoad from "react-lazyload";
import DropDown from "../parts/dropDown";

const BeforeTest = ({ history }) => {
  const [process, setProcess] = useState(0);
  const pgNow = (process / 9) * 100 + "%";
  const [answers, setanswers] = useState([]);
  const [contUser, setcontUser] = useState(0);
  const [loading, setloading] = useState(true); // 언마운트될때 false로 바뀌며 유저카운트 안불러옴

  useEffect(() => {
    testUser();
    finish();
    document.getElementById("footer").style.display = "none";
    return () => setloading(false);
  }, [answers, process, history, contUser]);

  // 집계유저
  const testUser = async () => {
    if (loading) {
      const { data } = await getUserApi(0);
      setcontUser(data.count);
    }
  };

  // 테스트 시작버튼
  const testStart = () => {
    // 시작페이지
    const startPage = document.getElementById("start-page");
    // 진행바
    const progress = document.getElementsByClassName("progress-wrap")[0];
    const header = document.getElementById("header"); // 헤더

    // 시작페이지 숨기고 진행바 나타남
    startPage.style.display = "none";
    // header.style.display = "none";
    progress.style.display = "block";
    // 질문페이지 렌딩
    setProcess(process + 1);
  };
  // 답변 완료
  const finish = () => {
    if (process === 10) {
      const result = check(answers);
      history.push(`/result/${result}`);
    }
  };

  // 대답버튼 클릭시
  const choiceAnswer = async (now, num) => {
    setanswers(answers.concat(num));
    setProcess(now + 1);
  };

  return (
    <div
      className="befor-test"
      style={{
        backgroundColor: "#fff",
        height: "100vh",
      }}
    >
      <ReactHelmet
        keywords="inDJ,음악취향,음악취향테스트,조선시대,계급,mbti,음악추천,무료음악,음악어플,멜론,지니,벅스,유튜브뮤직,음악,music"
        description="과연 나의 계급은?"
        title="음악취향으로 알아보는 나의 조선시대 계급"
        favicon="https://indj.s3.ap-northeast-2.amazonaws.com/image/mbti/main_miri.jpg"
      />
      <div className="wrap">
        <div id="header">
          <img
            className="mo-logo"
            src="/../images/text_logo.png"
            alt="인디제이 로고"
          />
          <div className="header-text">
            <DropDown />
            <button className="head-btn">
              <a href="https://policy.indj.club/webtoon">웹툰</a>
            </button>
          </div>
        </div>
        {/* 테스트 설명 텍스트 */}
        <div id="start-page">
          <LazyLoad offset={100}>
            <img
              className="main-img"
              src="https://indj.s3.ap-northeast-2.amazonaws.com/image/mbti/main.jpg"
              alt="mbti메인이미지"
            />
          </LazyLoad>
          <div className="desc-box">
            <p className="desc1">
              조선시대에 음악 추천 앱이 있었다면 나는 어떤 음악을 들었을까..?
            </p>
            <p className="desc2">
              상황 질문을 통해 자신의 음악 취향을 확인 후 음악 라디오 inDJ가
            </p>
            <p className="desc2">맞춤 플레이리스트를 추천해 드립니다!</p>
          </div>

          <button
            type="reset"
            onClick={() => testStart()}
            className="start-btn"
          >
            테스트 시작하기
          </button>
          <p className="total">{`현재 ${contUser}명 다녀감`}</p>
          <LazyLoad offset={100}>
            <img
              className="gentle_logo"
              src="/../images/gentle_logo.png"
              alt="인디제이 로고"
            />
          </LazyLoad>
          <p className="rnd">인공지능 가락 연구소</p>
        </div>
        {/* start-page 끝 */}

        {/* 질문페이지 렌딩 */}
        <div className="question-wrap">
          {question.map((el, idx) => {
            if (idx + 1 === process) {
              return (
                <Questions
                  key={idx}
                  title={el.title}
                  b1={el.b1}
                  b2={el.b2}
                  qnum={idx + 1}
                  choice={choiceAnswer}
                />
              );
            }
          })}
          {/* 진행바 */}
          <div className="progress-wrap" style={{ display: "none" }}>
            <span className="progres-num">{`${process}/9`}</span>
            <div className="progress-all">
              <div className="progress-now" style={{ width: pgNow }}></div>
            </div>
            <LazyLoad offset={100}>
              <img
                className="gentle_logo"
                src="/../images/gentle_logo.png"
                alt="인디제이 로고"
              />
            </LazyLoad>
            <p className="rnd">인공지능 가락 연구소</p>
          </div>
          {/* progress-wrap 끝 */}
        </div>
        {/* question-wrap 끝 */}
      </div>
    </div>
  );
};
export default BeforeTest;
