import React from 'react';
import TermsUp from './Terms_up';
import { NavLink } from 'react-router-dom';
import '../css/terms.css';

const Terms_error = ({ match }) => {
  return (
    <div className="app dk" id="app">
      {/* <Sideheader></Sideheader> */}
      <div id="content" className="app-content mopt white bg box-shadow-z2 terms_error" role="main">
        {/* <Play></Play> */}
        <div id="subContentsWrap" className="noDepth" style={{ minHeight: '660px' }}>
          {/* 사이드바 생성시 클래스에 wpl추가 */}
          <TermsUp></TermsUp>
          <h2 className="contentTitle nobar">inDJ 장애발생시 보상 조치</h2>

          <div className="innerWrapin subContents">
            <div className="ruleBlock">
              <div className="ruleBlockin">
                <div className="ruleCont">
                  <ol className="number-list">
                    <li>
                      1. 음악감상 전용 이용권 및 유료채널 및 방송 전용 이용권(감상 전용 이용권)의 경우 사업자가 서비스의
                      중지•장애에 대하여 사전고지 하지 않은 경우에 있어서 이용자의 피해구제 등은 다음 각호에 의합니다.
                      다만, 이용자의 책임 있는 사유로 인하여 서비스가 중지되거나 장애가 발생한 경우 서비스
                      중지•장애시간에 포함하지 아니합니다.
                      <ol className="num-circle-list">
                        <li>
                          1) 1개월 동안의 서비스 중지•장애발생 누적시간이 72시간을 초과한 경우 : 계약해제 또는 해지 및
                          미이용기간을 포함한 잔여기간에 대한 이용료 환급 및 손해배상(단, 사업자가 고의 또는 과실 없음을
                          입증한 경우 손해배상책임을 지지 않음)
                        </li>
                        <li>
                          2) 사업자의 책임 있는 사유로 인한 서비스 중지 또는 장애의 경우 : 서비스 중지•장애시간의 3배를
                          무료로 연장
                        </li>
                        <li>
                          3) 불가항력 또는 제3자의 불법행위 등으로 인해 서비스의 중지 또는 장애가 발생한 경우 : 계약을
                          해제할 수는 없지만, 서비스의 중지 또는 장애시간만큼 무료로 이용기간을 연장사업자가 서비스의
                          중지•장애에 대하여 사전고지한 경우에 있어서 이용자의 피해구제 등은 다음 각호에 의합니다. 다만,
                          서비스 개선을 목적으로 한 설비 점검 및 보수시 1개월을 기준으로 최대 24시간은 중지•장애 시간에
                          포함하지 아니합니다.
                          <ol className="dash-list">
                            <li>
                              가) 1개월을 기준으로 서비스 중지•장애시간이 10시간을 초과하는 경우 : 10시간과 이를 초과한
                              시간의 2배의 시간만큼 이용기간을 무료로 연장
                            </li>
                            <li>
                              나) 1개월을 기준으로 서비스 중지•장애시간이 10시간을 초과하지 않은 경우 : 중지•장애 시간에
                              해당하는 시간을 무료로 연장
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                    <li>
                      2. 다운로드 전용 이용권 및 유료채널 및 방송 전용 이용권(다운로드 전용 이용권)의 경우
                      음악콘텐츠(유료채널 및 방송 전용 이용권의 경우, 유료채널 및 방송 콘텐츠) 자체에 이용 장애를
                      유발하는 하자가 있는 경우 회사는 완전한 음악콘텐츠(유료채널 및 방송 전용 이용권의 경우, 유료채널
                      및 방송 콘텐츠) 를 회원에게 재공급해야 하며, 24시간 이내에 완전한 온라인콘텐츠를 공급하지 못하는
                      경우 이에 상응하는 이용대금을 지급합니다.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <Option></Option> */}
    </div>
  );
};
export default Terms_error;
