import React from "react";
import "./ticketInput.scss";
import { useRecoilState } from "recoil";
import { _payInfo } from "recoil/state";
import { immer } from "utils/util";
import { Input } from "antd";

const TicketInput = () => {
  const [payInfo, setPayInfo] = useRecoilState(_payInfo);

  return (
    <div className="mx-20" id="ticket_input">
      <Input
        value={payInfo.buyer_tel}
        onChange={(e) =>
          setPayInfo(immer(payInfo, "buyer_tel", e.target.value))
        }
        allowClear
        placeholder="전화번호 입력"
      />
    </div>
  );
};

export default TicketInput;
