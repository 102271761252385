import React, { useState, useEffect } from "react";

const RecoSong = ({ img, title, singer }) => {
  return (
    <div className="song-node">
      <img className="song-img" src={img} alt="추천채널 노래이미지" />
      <div className="song-info">
        <h3 className="song-title">{title}</h3>
        <h3 className="song-singer">{singer}</h3>
      </div>
    </div>
  );
};
export default RecoSong;
