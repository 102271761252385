import React from "react";
import "../style/err.scss";

const ErrMoment = () => {
  return (
    <div className="err">
      <div className="err-img moment" alt="">
        <h1 className="err-text title">일시적인 오류입니다.</h1>
        <h2 className="err-text sub-title">
          서비스 이용에 불편을 끼쳐드려서죄송합니다. <br />
          잠시 후 다시 시도해주세요
        </h2>
      </div>
    </div>
  );
};
export default ErrMoment;
