import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

/** @jsx jsx */
import { css, jsx } from "@emotion/react";

const install = css`
  display: flex;
  flex-wrap: wrap;

  background-image: url(../images/platform_back.jpg);
  background-size: cover;
  height: 150vw;
  .logo-intro {
    width: 100%;
    .logo-wrap {
      display: flex;
      align-items: center;
      height: 11.979vw;
      background-color: rgba(242, 242, 242, 0.44);

      .color {
        width: 13.151%;
        margin-left: 5%;
      }
    }

    .title {
      margin-top: 20px;
      margin-left: 5%;
      font-size: 5vw;
      font-family: Spoqa Han Sans;
      font-weight: 700;
    }
  }
  .btn-wrap {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
    margin-bottom: 15px;
    .go-store {
      width: 43.489%;
      .button {
        width: 100%;
      }
    }
  }
`;
const couponStyle = css`
  height: 92.317vw;
  border-bottom: 1px solid #eee;

  .gray-bar {
    height: 10px;
    background-color: rgba(242, 242, 242, 0.44);
  }

  .counpon-con {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: calc(92.317vw - 10px);
    padding: 15% 0 15%;

    .no-pay {
      width: 100%;
      text-align: center;
      font-size: 6vw;
      font-family: Spoqa Han Sans;
      font-weight: 700;
    }
    .free-sound {
      width: 100%;
      text-align: center;
      font-size: 5vw;
      font-family: Spoqa Han Sans;
    }

    .coupon-btn {
      border: 1px solid rgb(144, 29, 254);
      border-radius: 45px;
      background-color: transparent;
      width: 50%;
      height: 12vw;
      .coupon-text {
        display: block;
        height: 12vw;
        width: 100%;
        line-height: 12vw;
        font-family: Spoqa-Han-Sans-Regular;
        font-size: 4vw;
        color: rgb(144, 29, 254);
      }
    }
  }
`;

const Platform = ({ match }) => {
  const [up, setup] = useState(0);

  useEffect(() => {
    changeFooter();
  }, []);
  // 푸터 색깔 변신
  const changeFooter = () => {
    document.getElementById("footer").style.backgroundColor = "white";
    document.getElementsByClassName("kakao")[0].style.backgroundColor = "white";
    document.getElementsByClassName("info")[0].style.backgroundColor = "white";
    document.getElementsByClassName("footerWrap")[0].style.backgroundColor =
      "white";
    document.getElementsByClassName("indjLogo")[0].style.border = "none";
    document.getElementsByClassName("sns")[0].style.border = "none";
    document.getElementsByClassName("coInfo")[0].style.border = "none";
  };

  return (
    <div className="wrap">
      <div css={install}>
        <div className="logo-intro">
          <div className="logo-wrap">
            <img
              className="color"
              src="/../images/inDj_color_logo.png"
              alt="인디제이 컬러 로고"
            />
          </div>
          <h1 className="title">
            상황과 감정을 알아주는 <br /> 인공지능 음악플랫폼
          </h1>
        </div>
        <div className="btn-wrap">
          <a
            className="go-store"
            target="_blank"
            rel="noopener noreferrer"
            href="https://apps.apple.com/kr/app/indj-%EC%9E%90%EC%9C%A0%EB%A1%AD%EA%B2%8C-%EB%93%A3%EB%8A%94-%EC%83%81%ED%99%A9-%EA%B0%90%EC%84%B1-%EC%9D%B8%EA%B3%B5%EC%A7%80%EB%8A%A5-%EC%9D%8C%EC%95%85/id1513542512"
          >
            <img
              className="button appstore"
              src="/../images/appstore.png"
              alt="설치페이지 이미지"
            />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="go-store"
            href="https://play.google.com/store/apps/details?id=com.indj.music"
          >
            <img
              className="button playstore"
              src="/../images/playstore.png"
              alt="설치페이지 이미지"
            />
          </a>
        </div>
      </div>
      <div className="coupon" css={couponStyle}>
        <div className="gray-bar"></div>
        <div className="counpon-con">
          <h2 className="no-pay">
            결제 없이 광고를 기반으로
            <br /> 자유롭게 듣는 라디오
          </h2>
          <h3 className="free-sound">
            음악 라디오 채널을 자유롭게 청취할 수<br /> 있는 서비스에요!
          </h3>
          <button className="coupon-btn">
            <a className="coupon-text" href="https://player.indj.club/">
              쿠폰 등록하기
            </a>
          </button>
        </div>
      </div>
    </div>
  );
};
export default Platform;
