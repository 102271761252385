import React, { useState, useEffect } from 'react';

const KakaoShare = ({ desc, img }) => {
  useEffect(() => {
    createKakaoButton();
  }, []);

  const createKakaoButton = () => {
    // kakao sdk script이 정상적으로 불러와졌으면 window.Kakao로 접근이 가능합니다
    if (window.Kakao) {
      const kakao = window.Kakao;
      // 중복 initialization 방지
      if (!kakao.isInitialized()) {
        // 두번째 step 에서 가져온 javascript key 를 이용하여 initialize
        kakao.init(process.env.REACT_APP_KAKAO_KEY);
      }
      kakao.Link.createDefaultButton({
        // Render 부분 id=kakao-link-btn 을 찾아 그부분에 렌더링을 합니다
        container: '#kakao-link-btn',
        objectType: 'feed',
        content: {
          title: '당신의 전생은',
          description: `${desc}`,
          imageUrl: `${img}`,
          link: {
            mobileWebUrl: window.location.href,
            webUrl: window.location.href,
          },
        },
        buttons: [
          {
            title: '결과보기',
            link: {
              mobileWebUrl: window.location.href,
              webUrl: window.location.href,
            },
          },
          {
            title: '나도 하러가기',
            link: {
              mobileWebUrl: 'https://policy.indj.club/beforetest',
              webUrl: 'https://policy.indj.club/beforetest',
            },
          },
        ],
        serverCallbackArgs: {
          key: 'value',
        },
      });
    }
  };

  return (
    <button
      // onClick={() => createKakaoButton()}
      id="kakao-link-btn"
      className="share-node"
    >
      <div className="share-img-wrap">
        <img className="share-img" src="/../images/mbti/test_kakao.png" alt="공유이미지" />
      </div>
      <p className="platform-text">카톡으로 공유</p>
    </button>
  );
};
export default KakaoShare;
