import React, { useEffect, useState } from 'react';
import './StationList.scss';
import { getAwardsPopular, getAwardsNew } from 'apis/apis';
//import { postTokenCheckApi2 } from 'apis/apis';
import { exEmoji } from 'components/functions/functions';
const StationList = () => {
  const [popularstay, setPopular] = useState([]);
  const [newstay, setNewStay] = useState([]);
  const [mode, setMode] = useState('popular');

  // 모드 변경 [인기 , 최신]
  const onChangeMode = (e) => {
    if (mode === e) {
      setMode(e);
    } else if (mode !== e) {
      setMode(e);
    }
  };

  // 인기 스테이션 api 가져오기
  const getPopular = async () => {
    const data = await getAwardsPopular();
    setPopular(data.data.famous);
  };
  // 최신 스테이션 api 가져오기
  const getNew = async () => {
    const data = await getAwardsNew();
    console.log(data);
    setNewStay(data.data.new);
  };

  useEffect(() => {
    getPopular();
    getNew();
  }, []);

  return (
    <div className="station">
      <div className="select">
        <div className="select_all">
          <div
            className={'popularstay' + (mode === 'popular' ? ' active' : ' passive')}
            onClick={() => onChangeMode('popular')}
          >
            인기 &nbsp;스테이션
          </div>
          <div className={'newstay' + (mode === 'new' ? ' active' : ' passive')} onClick={() => onChangeMode('new')}>
            최신 &nbsp;스테이션
          </div>
        </div>
        <div className="list">
          {mode === 'popular'
            ? popularstay.map((item, idx) => (
                <div className="toplist" key={idx}>
                  <img
                    src={
                      item.profile !== null
                        ? item.profile
                        : `https://indj.s3.ap-northeast-2.amazonaws.com/image/default_profile/user-default-${item.profile_code}.png`
                    }
                    alt="유저사진"
                    className="toplist_user"
                  ></img>
                  <img className="toplist_img" src={item.thumbnail} alt="스테이션타이틀" />
                  <div className="toplist_text">
                    <span className="toplist_title">
                      {idx + 1}. &nbsp;{exEmoji(item.station_name)}
                    </span>
                    <span className="toplist_count">투표수 {item.vote_count}표</span>
                    <span className="toplist_date">
                      등록일 {item.created_date.substring(0, 10).replace(/-/g, ' /')}
                    </span>
                  </div>
                </div>
              ))
            : newstay.map((item, idx) => (
                <div className="toplist" key={idx}>
                  <img
                    src={
                      item.profile !== null
                        ? item.profile
                        : `https://indj.s3.ap-northeast-2.amazonaws.com/image/default_profile/user-default-${item.profile_code}.png`
                    }
                    alt="유저사진"
                    className="toplist_user"
                  ></img>
                  <img className="toplist_img" src={item.thumbnail} alt="스테이션타이틀" />
                  <div className="toplist_text">
                    <span className="toplist_title">
                      {idx + 1}. &nbsp;{exEmoji(item.station_name)}
                    </span>
                    <span className="toplist_count">투표수 {item.vote_count}표</span>
                    <span className="toplist_date">
                      등록일 {item.created_date.substring(0, 10).replace(/-/g, ' /')}
                    </span>
                  </div>
                </div>
              ))}
        </div>
      </div>
    </div>
  );
};

export default StationList;
