import emojis from 'components/emoji/emoji';

export const exEmoji = (string) => {
  let real = string;
  //   console.log(string);
  while (true) {
    const startEmoji = real.indexOf('u{');
    const endEmoji = real.indexOf('}');
    if (startEmoji === -1) {
      return real;
    }
    const uni = real.substring(startEmoji - 1, endEmoji + 1);
    console.log(uni);
    for (let i = 0; i < emojis.length; i++) {
      if (uni === emojis[i].code2) {
        real = real.replace(uni, emojis[i].code1);
        break;
      }
    }
  }
};
