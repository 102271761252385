import React, { useState, useEffect, useCallback } from "react";
import { getUsingTypeApi } from "apis/apis";
import "./ticketInfo.scss";

const TicketInfo = () => {
  const [using, setUsing] = useState({});
  const [typeName, setTypeName] = useState("");
  const [date, setDate] = useState("");
  const [content, setContent] = useState("");

  const getUsingType = useCallback(async () => {
    const { data } = await getUsingTypeApi();
    console.log(data);
    setUsing(data);
    setName(data.type, data.month);
    settingContent(data);
  }, []);

  // 이용권 이름
  const setName = (type, month) => {
    if (type === 0) setTypeName("무료 서비스 사용중");
    else if (type === 1) setTypeName("inDJ 이용권");
    else if (type === 2) {
      if (month === 0) setTypeName("신규 가입 프리미엄");
      else setTypeName("inDJ 쿠폰");
    }
  };
  // 이용권 내용 (기간, 만료일)
  const settingContent = (data) => {
    let date_replace = data.expDate.replaceAll("-", ". ");
    setDate(date_replace);

    if (data.type === 1) {
      //이용권 결제
      if (data.temp === 1) setContent(`${data.month}개월`);
      else if (data.temp === 2) setContent(`${data.month}개월 (정기결제)`);
    } else if (data.type === 2) {
      //쿠폰 및 가입 프리미엄
      if (data.month === 0) setContent("1주 프리미엄");
      else {
        if (data.month === 14) setContent("2주 프리미엄");
        else setContent(`${data.month}개월 프리미엄`);
      }
    }
  };

  useEffect(() => {
    getUsingType();
  }, [getUsingType]);

  return (
    <div className="body">
      <div className="using">
        <div className="using_body">
          <div className="img_div">
            <img
              alt="d"
              className="radioImg"
              src={
                using.type === 0
                  ? "/images/ticket/green.png"
                  : "/images/ticket/red.png"
              }
            />
          </div>
          <div className="content">
            <h3 className="typeName">{typeName}</h3>
            <div className="content_sub">
              {using.type === 0 ? (
                <span className="free_text">
                  저렴한 가격으로 <br />
                  프리미엄을 이용해보세요
                </span>
              ) : (
                <div className="text_wrap">
                  <span className="month">{content}</span>
                  <br />
                  <span className="expDate">{date} 만료예정</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketInfo;
