import React from 'react';
import './StationVote.scss';
import { useHistory } from 'react-router-dom';
import '../../../index.css';
import StationList from './StationList';
import ShareLink from './ShareLink';

const StationVote = () => {
  const goVote = () => {
    window.location.href = `https://indj.ai/stationvote`;
  };
  return (
    <div id="StationVoteWrap" className="eventwrap">
      <div className="header">
        <img
          className="header_img"
          src="https://indj.s3.ap-northeast-2.amazonaws.com/image/event/vote_mainimg.png"
          alt="헤더 이미지"
        />
      </div>

      <div className="stationvote_container">
        <div className="stationvote_item">이벤트 경품</div>
        <img
          className="stationvote_item_img"
          src="https://indj.s3.ap-northeast-2.amazonaws.com/image/event/vote_item.png"
          alt=""
        />
        <StationList />
        <div className="btnall">
          <button className="govote" onClick={goVote}>
            스테이션 듣고 투표하러 가기
          </button>
        </div>
      </div>

      <div className="stationvote_container2">
        <div className="explan">
          <div className="explan_h1">이벤트 &nbsp;참여방법</div>
          <div className="explan_h2">스테이션 생성 또는 투표 진행</div>
          <div className="explan_prize">
            <span className="explan_award">인기 스테이션 1등: &nbsp;에어팟 맥스</span>
            <span className="explan_award">인기 스테이션 2등: &nbsp;에어팟 프로2</span>
            <span className="explan_award">인기 스테이션 3등: &nbsp;에어팟3</span>
            <span className="explan_award">투표자 중 추첨 1명: &nbsp;에어팟3</span>
            <span className="explan_award">투표자 중 추첨 100명: &nbsp;커피 기프티콘</span>
            <span className="explan_award">스테이션과 투표를 할 때마다 METABX 10개씩 지급</span>
            <span className="explan_date">기간: 2022년 11월 29일 까지</span>
            <span className="explan_ann">발표: 2022년 12월 06일 (당첨자 개별 앱 알림)</span>
          </div>
        </div>
        {/* <div className="share">
          <span className="share_h1">행사소식 &nbsp;공유</span>
          <ShareLink></ShareLink>
        </div> */}
        <div className="vote_notice">
          <span className="vote_notice_h1">유의사항</span>
          <div className="vote_notice_h2">
            <span className="vote_notice_h2_text">
              - 욕설, 비방 등 부적절 언어 포함되는 댓글과 스테이션명은 사전 고지 없이 삭제될수 있습니다.{' '}
            </span>
            <span className="vote_notice_h2_text">
              - 당첨 안내를 위해 앱 내 알림으로 당첨자에게 연락을 할 예정이며, 7일내 응답 없을 시 당첨 대상에서
              제외됩니다.
            </span>
            <span className="vote_notice_h2_text">
              - 해당 프로모션 및 경품은 사정에 따라 사전 고지 없이 변경될 수 있습니다.
            </span>
            <span className="vote_notice_h2_text">
              - 이벤트 관련 문의 : 앱 설정 {'>'} 고객센터 {'>'} 문의 및 버그신고 {'>'} 1:1 문의 이용
            </span>
            <span className="vote_notice_h2_text">- 어뷰징 및 다 계정 확인될 경우 보상/경품에서 제외됩니다.</span>
            <span className="vote_notice_h2_text">
              - 이번 행사는 한국인 대상, 한국에서 서비스를 사용, 한국에서 경품 수령이 가능한 사용자를 대상으로 합니다.{' '}
            </span>
            <span className="vote_notice_h2_text">
              - 이벤트 참여시 개인정보 미입력으로 인한 당첨안내 및 오배송에 대한 책임은 본인에게 있습니다.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StationVote;
