import React from "react";

const Questions = (props) => {
  const pushAnswer = (num, answer) => {
    props.choice(num, answer);
  };

  return (
    <div className="questions">
      <div className="question-box">
        <div className="question-text">
          <p>{`Q . ${props.qnum}`}</p>
          <p>{`${props.title}`}</p>
        </div>
      </div>

      <div className="btn-box">
        <button
          onClick={() => pushAnswer(props.qnum, 1)}
          className="choice-btn"
        >
          {`${props.b1}`}
        </button>
        <button
          onClick={() => pushAnswer(props.qnum, 0)}
          className="choice-btn"
        >
          {`${props.b2}`}
        </button>
      </div>
    </div>
  );
};
export default Questions;
