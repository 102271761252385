import React from 'react';
import { NavLink } from 'react-router-dom';
import '../css/terms.css';

const activeStyle = {
  fontWeight: '700',
};

const Terms_up = ({ match }) => {
  return (
    <ul className="upTerms">
      <li>
        <NavLink to="/terms_use" activeStyle={activeStyle}>
          이용약관
        </NavLink>
      </li>
      <li>
        <NavLink to="/terms_error" activeStyle={activeStyle}>
          장애발생시 보상 조치
        </NavLink>
      </li>
      <br className="seDisplay"></br>
      <li>
        <NavLink to="/terms_pay" activeStyle={activeStyle}>
          유료서비스 약관
        </NavLink>
      </li>
      <li>
        <NavLink to="/terms_loc" activeStyle={activeStyle}>
          위치정보 이용 약관
        </NavLink>
      </li>
      <li>
        <NavLink to="/terms_private" activeStyle={activeStyle}>
          개인정보 처리 방침
        </NavLink>
      </li>
      <li>
        <NavLink to="/terms_teenager" activeStyle={activeStyle}>
          청소년보호정책
        </NavLink>
      </li>
    </ul>
  );
};
export default Terms_up;
