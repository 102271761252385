export const question = [
  {
    title: "처음 만나는 사람과 섞여 노래방에 갔을 때",
    b1: "쑥스럽지만 스타트는 내가 끊는다..!",
    b2: "조금있다 불러야지...",
  },
  {
    title: "음악은 이렇게 들어야 제 맛!",
    b1: "시원한 맥주와 함께 야외 페스티벌 즐기기",
    b2: "은은한 조명과 스피커로 집에서 무드있게",
  },
  {
    title: "나의 에너지 충전방식은?",
    b1: "밖에서 친구를 만나거나 운동을 한다",
    b2: "집순이도 바빠! 집에서 푹 쉰다",
  },
  {
    title: "친구가 요즘 자신이 힘들때 듣는 노래라며 음악 추천을 해주었다",
    b1: "맞아 이노래 좋더라!",
    b2: "요즘 힘들어?",
  },
  {
    title: "들었을 때 더 듣기 좋은말은?",
    b1: "넌 정말 모르는 게 없구나",
    b2: "넌 내 정신적 지주야",
  },
  {
    title: "사촌동생이 내 물건을 잃어버렸다",
    b1: "이모를 찾아가서 비용을 청구한다",
    b2: "동생이 그런건데 어쩔 수 없지..(ㅠ)",
  },
  {
    title: "신곡을 들을 때 중요한 것은?",
    b1: "멜로디 좋은데??",
    b2: "작사가와 영접하여 가사를 이해하려 한다",
  },
  {
    title: "음악을 들으면서 주로 하는 생각은?",
    b1: " (..아무 생각없음)",
    b2: "떠오르는 대로 끊임없이 생각을 한다",
  },
  {
    title: "내 인생음악을 친구에게 추천할 때",
    b1: `"첫 가사는 이렇구.. 난 2절 도입부가 너무 좋더라!"
꽂힌 파트에 대해 이야기한다`,
    b2: `"너무좋아!" 
전체적인 느낌에 대해 이야기한다`,
  },
];
export const result = [
  {
    mod: "당신의 과거는",
    title: "재즈 그루브를 타며 무술연습을 하는 호위무사",
    good: 4,
    bad: 2,
    desc: [
      "자신감이 넘치고 적극적인 성격이라 무리 안에서 인싸일 경우가 높아요. 눈치가 빨라 대화 안에서 흐름을 금방 파악해요. 개방적이라 웬만한 것은 ’그럴 수 있지‘하고 넘겨 대체로 대인관계가 좋은 편이에요. 하지만 외로움을 많이 타요.",

      "고집이 세면서도 딱 잘라 결단을 내리지 못하는 우유부단함의 양면성도 가지고 있어요. 다른 사람의 눈치를 보기보다 자신이 하고 싶은 대로 하며 숨은 관종끼를 조금씩 드러냅니다.",

      "싸울 때만큼은 누구보다 현실적이고 이성적이며, 싸우는 걸 싫어하지만 지는 건 더 싫어해요. 나랑 상관이 없는 남에게는 큰 관심이 없어요. 스트레스 관리를 혼자서도 잘해요. 상황판단이 좋고 시원시원해 리더의 역할도 잘 해냅니다. ",
    ],
    img: "https://d3coxo32tidngo.cloudfront.net/image/mbti/warrior.jpg",
    thum: "https://d3coxo32tidngo.cloudfront.net/image/mbti/warrior_miri.jpg",
    channel: [
      {
        ch_name: "경쾌함을 더해주는 쨰즈",
        other: 25,
        ch_img: "https://api2.indj.club/file/fetchImage/thumbnail/6911.png",
        ch_song: [
          {
            title: "Back At The Chicken Snack",
            singer: "Jimmy Smith(지미 스미스)",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Jimmy%20Smith(%EC%A7%80%EB%AF%B8%20%EC%8A%A4%EB%AF%B8%EC%8A%A4)-Back%20At%20The%20Chicken%20Shack%20(Rudy%20Van%20Gelder%20Edition).jpg",
          },
          {
            title: "Dat Dere",
            singer: "Bobby Durham Trio",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Bobby%20Durham%20Trio-Tribute%20To%20My%20Friend%20Bobby%20timmons.jpg",
          },
          {
            title: "Timing",
            singer: "Marcus Strickland Twi-Life",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Marcus%20Strickland%20Twi-Life-Timing.jpg",
          },
        ],
      },
      {
        ch_name: "따뜻한 봄날, 편안하게 듣는 재즈 음악",
        other: 25,
        ch_img:
          "https://api2.indj.club/file/fetchImage/thumbnail/7252_album.png",
        ch_song: [
          {
            title: "When It Comes To Love (Album Version)",
            singer: "Big Bad Voodoo Daddy(빅 배드 부두 대디)",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Big%20Bad%20Voodoo%20Daddy(%EB%B9%85%20%EB%B0%B0%EB%93%9C%20%EB%B6%80%EB%91%90%20%EB%8C%80%EB%94%94)-This%20Beautiful%20Life.jpg",
          },
          {
            title: "Walkin'",
            singer: "Bobby McFerrin(바비 맥퍼린)",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Bobby%20McFerrin(%EB%B0%94%EB%B9%84%20%EB%A7%A5%ED%8D%BC%EB%A6%B0)-Spontaneous%20Inventions.jpg",
          },
          {
            title: "You Got It",
            singer: "윈터플레이",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%9C%88%ED%84%B0%ED%94%8C%EB%A0%88%EC%9D%B4-Hot%20Summerplay.jpg",
          },
        ],
      },
      {
        ch_name: "카페에서 틀어놓기 좋은 달달한 곡 모음",
        other: 14,
        ch_img:
          "https://api2.indj.club/file/fetchImage/thumbnail/8103_album.png",
        ch_song: [
          {
            title: "Deep Night (Rudy Van Gelder Edition-1998 Remaster)",
            singer: "Sonny Clark(소니 클락)",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Sonny%20Clark(%EC%86%8C%EB%8B%88%20%ED%81%B4%EB%9D%BD)-Cool%20Struttin'%20(Remastered%20-%20Rudy%20Van%20Gelder%20Edition).jpg",
          },
          {
            title: "Summer Recipe",
            singer: "김성우",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EA%B9%80%EC%84%B1%EC%9A%B0-Summer%20Recipe.jpg",
          },
          {
            title: "Thou Swell",
            singer: "Stan Getz(스탄 게츠)",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Stan%20Getz(%EC%8A%A4%ED%83%84%20%EA%B2%8C%EC%B8%A0)-Stan%20Getz%20At%20Storyville_2020_11_25.jpg",
          },
        ],
      },
    ],
  },

  {
    mod: "당신의 과거는",
    title: "숨겨진 아이돌끼를 먹방으로 분출하는 기미상궁",
    good: 7,
    bad: 5,
    desc: [
      "센스가 좋고 유머러스해 사람들에게 인기가 많은 편입니다. 자신 때문에 누가 웃으면 뿌듯함을 느껴요. 사교적인 성격과 동시에 사람을 깊게 사귀지 않아 진짜 친구가 많이 없다고 느껴 고독할 수 있어요. 사람들과 잘 지내면서도 혼자만의 시간이 필요할 때가 있어요. ",

      "끈기와 책임감으로 남에게 힘든 얘기를 잘 하지 않고 의존하지 않으려 해요. 그래서 친구들이 서운해할 때가 있어요. 한번 싫은 것은 끝까지 싫어하며 쉽게 바뀌지 않아요. 심한 감정 기복으로 변덕쟁이라는 말을 종종 듣곤 합니다. ",

      "문제가 생기더라도 당황하지 않고 논리적으로 해결책을 찾아냅니다. 평소에 남 의식을 별로 하지 않아 마이웨이를 걸어요. 혼자 밥을 먹거나 카페를 가거나 영화를 보러 잘 다녀요.",
    ],
    img: "https://d3coxo32tidngo.cloudfront.net/image/mbti/kimi.jpg",
    thum: "https://d3coxo32tidngo.cloudfront.net/image/mbti/kimi_miri.jpg",
    channel: [
      {
        ch_name: "명곡만 쏙! 추억의 노래",
        other: 19,
        ch_img:
          "https://api2.indj.club/file/fetchImage/thumbnail/명곡만 쏙_ 추억의 노래.png",
        ch_song: [
          {
            title: "Candy (캔디)",
            singer: "H.O.T",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/H.O.T.-We%20Hate%20All%20Kinds%20Of%20Violence.jpg",
          },
          {
            title: "날개 잃은 천사",
            singer: "룰라(Roo'Ra)",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%A3%B0%EB%9D%BC(Roo'Ra)-%EB%82%A0%EA%B0%9C%20%EC%9E%83%EC%9D%80%20%EC%B2%9C%EC%82%AC.jpg",
          },
          {
            title: "일과 이분의 일",
            singer: "투투(Two Two)",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%ED%88%AC%ED%88%AC(Two%20Two)-Two%20Two.jpg",
          },
        ],
      },
      {
        ch_name: "운동할 때 듣기 좋은 신나는 아이돌 댄스음악 모음",
        other: 28,
        ch_img:
          "https://api2.indj.club/file/fetchImage/thumbnail/운동할 때 듣기 좋은 신나는 아이돌 댄스음악 모음.png",
        ch_song: [
          {
            title: "얼굴 찌푸리지 말아요",
            singer: "하이라이트(Highlight)",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%ED%95%98%EC%9D%B4%EB%9D%BC%EC%9D%B4%ED%8A%B8(Highlight)-CAN%20YOU%20FEEL%20IT.jpg",
          },
          {
            title: "아주 NICE",
            singer: "세븐틴(Seventeen)",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%84%B8%EB%B8%90%ED%8B%B4(Seventeen)-Love&Letter%20Repackage%20Album_2020_11_24.jpg",
          },
          {
            title: "다시 만난 세계 (Into The New World)",
            singer: "소녀시대",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%86%8C%EB%85%80%EC%8B%9C%EB%8C%80%20(GIRLS'%20GENERATION)-%EB%8B%A4%EC%8B%9C%20%EB%A7%8C%EB%82%9C%20%EC%84%B8%EA%B3%84.jpg",
          },
        ],
      },
      {
        ch_name: "집에서 혼자 춤추고 싶을 때",
        other: 35,
        ch_img:
          "https://api2.indj.club/file/fetchImage/thumbnail/집에서 혼자 춤추고 싶을 때.png",
        ch_song: [
          {
            title: "Bubble Pop!",
            singer: "현아",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%ED%98%84%EC%95%84-Bubble%20Pop!_2020_11_18.jpg",
          },
          {
            title: "Ring Ding Dong",
            singer: "샤이니",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/SHINee%20(%EC%83%A4%EC%9D%B4%EB%8B%88)-2009,%20Year%20Of%20Us%20-%20The%20Third%20Mini%20Album.jpg",
          },
          {
            title: "REALLY REALLY",
            singer: "WINNER",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/WINNER-FATE%20NUMBER%20FOR.jpg",
          },
        ],
      },
    ],
  },

  {
    mod: "당신의 과거는",
    title: "물 건너 온 서양 노래에 중독된 내시",
    good: 3,
    bad: 6,
    desc: [
      "낙관적이고 애교가 많이 주위에 사람은 많지만, 인간관계는 보수적인 편이라 쉽게 정을 주지 않아요. 약간의 오지랖으로 조언을 해주는 것을 좋아하고 다른 사람 일에 관심이 많아 친구들 사이에서 상담가 역할을 하기도 해요. ",

      "자기애가 넘치고 어떤 일이 자기 위주로 진행되는 걸 좋아해요. 하기 싫은 건 안 하는 성격에 주위에서 생각 없이 산다는 소리를 듣곤 해요. 기본적으로 사람을 좋아해 새로운 사람을 만나는 걸 두려워하지 않고 좋아해요. 친구의 친구는 곧 나의 친구예요.",

      "풍부한 감수성으로 노래를 듣다가 가사가 좋으면 혼자 심취해 노래를 부르기도 해요. 혼자 노래방에 가서 부르고 싶은 노래를 다 부르는 것도 좋아합니다. 친구들과 노는 것을 좋아하는 자유로운 영혼이지만 가끔은 내 시간이 필요하다고 느껴요. ",
    ],
    img: "https://d3coxo32tidngo.cloudfront.net/image/mbti/eunuch.jpg",
    thum: "https://d3coxo32tidngo.cloudfront.net/image/mbti/eunuch_miri.jpg",
    channel: [
      {
        ch_name: "여름밤의 한강 드라이빙 팝송",
        other: 26,
        ch_img:
          "https://api2.indj.club/file/fetchImage/thumbnail/6500_album.png",
        ch_song: [
          {
            title: "Groovin'",
            singer: "Jeff Bernat(제프 버넷)",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Jeff%20Bernat(%EC%A0%9C%ED%94%84%20%EB%B2%84%EB%84%B7)-The%20Gentleman%20Approach_2020_11_11.jpg",
          },
          {
            title: "Beyond",
            singer: "Daft Punk(다프트 펑크)",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Daft%20Punk(%EB%8B%A4%ED%94%84%ED%8A%B8%20%ED%8E%91%ED%81%AC)-Random%20Access%20Memories_2020_11_25.jpg",
          },
          {
            title: "Her Morning Elegance",
            singer: "Oren Lavie(오렌 라비)",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Oren%20Lavie(%EC%98%A4%EB%A0%8C%20%EB%9D%BC%EB%B9%84)-Bedroom%20Crimes.jpg",
          },
        ],
      },
      {
        ch_name: "오전에 카페에서 듣기 좋은 팝송",
        other: 33,
        ch_img:
          "https://api2.indj.club/file/fetchImage/thumbnail/6835_album.png",
        ch_song: [
          {
            title: "I'm Yours",
            singer: "Jason Mraz(제이슨 므라즈)",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Jason%20Mraz(%EC%A0%9C%EC%9D%B4%EC%8A%A8%20%EB%AF%80%EB%9D%BC%EC%A6%88)-Yours%20Truly-%20The%20I'm%20Yours%20Collection.jpg",
          },
          {
            title: "Call You Mine (feat. Geologic Of The Blue Scholars)",
            singer: "Jeff Bernat(제프 버넷)",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Jeff%20Bernat(%EC%A0%9C%ED%94%84%20%EB%B2%84%EB%84%B7)-The%20Gentleman%20Approach_2020_11_11.jpg",
          },
          {
            title: "All Of The Stars",
            singer: "Ed Sheeran(에드 시런)",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Ed%20Sheeran(%EC%97%90%EB%93%9C%20%EC%8B%9C%EB%9F%B0)-%EC%95%88%EB%85%95,%20%ED%97%A4%EC%9D%B4%EC%A6%90%20(The%20Fault%20In%20Our%20Stars)%20OST.jpg",
          },
        ],
      },
      {
        ch_name: "해질무렵 노을과 함께듣는 그루브한 팝송",
        other: 17,
        ch_img:
          "https://api2.indj.club/file/fetchImage/thumbnail/9379_album.png",
        ch_song: [
          {
            title: "Sober",
            singer: "Mahalia(마할리아)",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Mahalia(%EB%A7%88%ED%95%A0%EB%A6%AC%EC%95%84)-Sober_2020_11_25.jpg",
          },
          {
            title: "Remember Yourself",
            singer: "delush",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/delush-Remember%20Yourself.jpg",
          },
          {
            title: "All Good",
            singer: "Jesse Elvis",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Jesse%20Elvis-Paint%20The%20Picture.jpg",
          },
        ],
      },
    ],
  },

  {
    mod: "당신의 과거는",
    title: "힙한 그루브로 저잣거리를 누비는 힙스터 왕초",
    good: 2,
    bad: 7,
    desc: [
      "처음 만난 사람에게 낯을 적당히 가리면서 타인에게 나를 완벽히 보여주기까지 시간이 좀 걸려요. 친해진 후에는 사람들에게 첫인상과 다르다는 말을 많이 들어요.",

      "남에게 자신이 어떻게 비치는지 약간은 신경 쓰지만 결국은 내가 하고 싶은 대로 해요. 자기에 대한 걱정도 많은데 높은 공감 능력으로 주변 사람들에 대한 걱정도 많아요. 그래서 전에 들었던 얘기가 어떻게 됐는지 결론을 꼭 들어야 해요.",

      "여러 분야에 관심이 많아 이것저것 시도해보지만 쉽게 몰두하는 만큼 쉽게 싫증이 나요. 그래서 깊지 않은 여러 개의 취미가 많아요. 멘탈이 강한 편이라 웬만한 일로 상처를 받지 않고 상처를 받아도 자신에게 좋지 않다고 판단하고 금방 잊어버려요. ",
    ],
    img: "https://d3coxo32tidngo.cloudfront.net/image/mbti/padrone.jpg",
    thum: "https://d3coxo32tidngo.cloudfront.net/image/mbti/padrone_miri.jpg",
    channel: [
      {
        ch_name: "혼자 듣긴 아까운 감성힙합",
        other: 24,
        ch_img:
          "https://api2.indj.club/file/fetchImage/thumbnail/혼자 듣긴 아까운 감성힙합 .png",
        ch_song: [
          {
            title: "감아 (feat. Crush)",
            singer: "로꼬",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%A1%9C%EA%BC%AC-%EA%B0%90%EC%95%84.jpg",
          },
          {
            title: "Wash Away (feat. 에일리)",
            singer: "비스트(Beast)",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EA%B8%B1%EC%8A%A4(Geeks)-Backpack.jpg",
          },
          {
            title: "Click Me (feat. Dok2)",
            singer: "긱스(Geeks)",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EA%B8%B1%EC%8A%A4(Geeks)-Backpack.jpg",
          },
        ],
      },
      {
        ch_name: "홍대 빈티지샵에 흘러나올 트렌디한 국내 힙합/알앤비",
        other: 25,
        ch_img:
          "https://api2.indj.club/file/fetchImage/thumbnail/6853_album.png",
        ch_song: [
          {
            title:
              "WATER (Feat. Woodie Gochild, pH-1, 김하온 (HAON), Jay Park) (Prod. GooseBumps)",
            singer: "식케이 (Sik-K)",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%8B%9D%EC%BC%80%EC%9D%B4%20(Sik-K)-WATER.jpg",
          },
          {
            title: "COCO BOTTLE",
            singer: "페노메코(PENOMECO)",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%ED%8E%98%EB%85%B8%EB%A9%94%EC%BD%94(PENOMECO)-COCO%20BOTTLE.jpg",
          },
          {
            title: "MIRO",
            singer: "Hoody (후디)",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Hoody%20(%ED%9B%84%EB%94%94)-MIRO.jpg",
          },
        ],
      },
      {
        ch_name: "외힙 입문시 꼭 들어봐야 할 외국힙합 모음집",
        other: 30,
        ch_img:
          "https://api2.indj.club/file/fetchImage/thumbnail/외힙 입문시 꼭 들어봐야 할 외국힙합 모음집.png",
        ch_song: [
          {
            title: "Money In The Grave (feat. Rick Ross)",
            singer: "Drake(드레이크)",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Drake(%EB%93%9C%EB%A0%88%EC%9D%B4%ED%81%AC)-The%20Best%20In%20The%20World%20Pack.jpg",
          },
          {
            title: "Shimmy",
            singer: "Aminé(아미네)",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Amin%C3%A9(%EC%95%84%EB%AF%B8%EB%84%A4)-Shimmy.jpg",
          },
          {
            title: "VIBEZ",
            singer: "DaBaby",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/DaBaby-KIRK.jpg",
          },
        ],
      },
    ],
  },

  {
    mod: "당신의 과거는",
    title: "신분의 한계를 소울풀한 알앤비로 풀어내는 노비",
    good: 1,
    bad: 3,
    desc: [
      "혼자 있는 시간을 좋아해 밖에 나가는 걸 귀찮아해요. 막상 나가면 누구보다 신나게 놀지만 사람 많은 곳에 가면 기가 빨려요. 개인주의적인 성향으로 친해지는 데 다소 오래 걸려요. 속에 숨겨진 관종끼가 있지만, 표출을 잘 하지 않아 무리에서 자발적인 아싸가 되기도 합니다. ",

      "원칙과 원리를 중요하게 생각해 자기주관이 뚜렷한 사람이에요. 다른 사람 말에 잘 휘둘리지 않고 자신의 원칙에 맞지 않은 사람을 싫어해요. 그래서 단호박이라는 말을 많이 들어요. 독립적인 성격을 보이지만 은근 어리바리한 허당끼도 있답니다.",

      "남한테 관심이 없는 만큼 내 일에 관여하는 것도 싫어해 혼자 일하는 게 제일 좋아요. 일 이외에도 누군가가 간섭하는 걸 제일 싫어해요.",
    ],
    img: "https://d3coxo32tidngo.cloudfront.net/image/mbti/novi.jpg",
    thum: "https://d3coxo32tidngo.cloudfront.net/image/mbti/novi_miri.jpg",
    channel: [
      {
        ch_name: "그루브한 알앤비 비트에 나도 모르게 몸이 흔들린다",
        other: 25,
        ch_img:
          "https://api2.indj.club/file/fetchImage/thumbnail/그루브한 알앤비 비트에 나도 모르게 몸이 흔들린다.png",
        ch_song: [
          {
            title: "Talk",
            singer: "Khalid(칼리드)",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Khalid(%EC%B9%BC%EB%A6%AC%EB%93%9C),%20Disclosure(%EB%94%94%EC%8A%A4%ED%81%B4%EB%A1%9C%EC%A0%80)-Free%20Spirit.jpg",
          },
          {
            title: "No Guidance (feat. Drake)",
            singer: "Chris Brown(크리스 브라운)",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Chris%20Brown(%ED%81%AC%EB%A6%AC%EC%8A%A4%20%EB%B8%8C%EB%9D%BC%EC%9A%B4)-Indigo.jpg",
          },
          {
            title: "The Way (feat. MAC MILLER)",
            singer: "Ariana Grande(아리아나 그란데)",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Ariana%20Grande(%EC%95%84%EB%A6%AC%EC%95%84%EB%82%98%20%EA%B7%B8%EB%9E%80%EB%8D%B0)-The%20Way.jpg",
          },
        ],
      },
      {
        ch_name: "저녁.. 드라이브하며 야경을 즐기다",
        other: 19,
        ch_img:
          "https://api2.indj.club/file/fetchImage/thumbnail/저녁.. 드라이브하며 야경을 즐기다.gif",
        ch_song: [
          {
            title: "comfort zone",
            singer: "eli.",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/eli.-comfort%20zone.jpg",
          },
          {
            title: "Tell Me When",
            singer: "Christian Kuria",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/high/Christian%20Kuria-Borderline.jpg",
          },
          {
            title: "Stay Home",
            singer: "Fousheé",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Foushe%C3%A9-Stay%20Home.jpg",
          },
        ],
      },
      {
        ch_name: "들으면 딱! 느낌오는 감각적인 국내 알앤비",
        other: 28,
        ch_img:
          "https://api2.indj.club/file/fetchImage/thumbnail/6832_album.png",
        ch_song: [
          {
            title: "Type (K) (Feat. 김효은)",
            singer: "아이디(Eyedi)",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%95%84%EC%9D%B4%EB%94%94(Eyedi)-CHAPTER%2021.jpg",
          },
          {
            title: "And July (feat. DEAN, DJ Friz)",
            singer: "헤이즈(Heize)",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%ED%97%A4%EC%9D%B4%EC%A6%88(Heize)-And%20July_2020_11_24.jpg",
          },
          {
            title: "Syndrome",
            singer: "CIKI",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/CIKI-Syndrome_2020_11_18.jpg",
          },
        ],
      },
    ],
  },

  {
    mod: "당신의 과거는",
    title: "헤비메탈을 들으며 현란한 칼춤을 뽐내는 망나니",
    good: 0,
    bad: 1,
    desc: [
      "자신만의 세계에 푹 빠져있어서 남에게 관심이 그리 많지 않아요. 그래서 새로운 사람을 만나는 걸 반가워하지 않고, 사람의 얼굴과 이름을 잘 기억하지 못해요. 특정 분야에 깊은 지식을 가진 사람이 많으며 창의적입니다.",

      "자신의 감정 표현에 서툴러 주변 사람들에게 시크하고 차가워 보일 수 있어요. 하지만 본인은 그걸로 딱히 스트레스를 받지도 않습니다. 친한 사람과 있을 때는 말이 많아져요. 개인주의적인 성향이 강하고 하기 싫은 건 죽어도 안 해요. 그래도 해야 하는 일은 또 완벽히 해내려 하는 완벽주의자의 모습을 보이기도 합니다.",

      "매사에 진지한 편이라 유머에는 소질이 없어요. 만약 본인이 재밌는 사람이라고 생각한다면 그건 다른 사람의 말도 들어봐야 합니다. ",
    ],
    img: "https://d3coxo32tidngo.cloudfront.net/image/mbti/headsman.jpg",
    thum: "https://d3coxo32tidngo.cloudfront.net/image/mbti/headsman_miri.jpg",
    channel: [
      {
        ch_name: "머리 풀어헤치고 즐겨 WITH 락",
        other: 22,
        ch_img:
          "https://api2.indj.club/file/fetchImage/thumbnail/저절로 머리가 흔들어지는 락과 함께 스트레스 쏴악 풀자.png",
        ch_song: [
          {
            title: "The Hell Song",
            singer: "Sum 41",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Sum%2041-Does%20This%20Look%20Infected_2020_11_25.jpg",
          },
          {
            title: "One Night Only",
            singer: "The Struts(더 스트럿츠)",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/The%20Struts(%EB%8D%94%20%EC%8A%A4%ED%8A%B8%EB%9F%BF%EC%B8%A0)-One%20Night%20Only.jpg",
          },
          {
            title: "Take My Hand",
            singer: "Simple Plan(심플 플랜)",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Simple%20Plan(%EC%8B%AC%ED%94%8C%20%ED%94%8C%EB%9E%9C)-Simple%20Plan.jpg",
          },
        ],
      },
      {
        ch_name: "듣기만 해도 벤치프레스 중량 늘려주는 국내 락 음악",
        other: 23,
        ch_img:
          "https://api2.indj.club/file/fetchImage/thumbnail/듣기만 해도 벤치프레스 중량 늘려주는 국내 락 음악.png",
        ch_song: [
          {
            title: "Crashday ",
            singer: "크래쉬(Crash)",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%ED%81%AC%EB%9E%98%EC%89%AC(Crash)-The%20Paragon%20Of%20Animals.jpg",
          },
          {
            title: "밤이 깊었네",
            singer: "크라잉넛(Crying Nut)",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%ED%81%AC%EB%9D%BC%EC%9E%89%EB%84%9B(Crying%20Nut)-%ED%95%98%EC%88%98%EC%97%B0%EA%B0%80(%E4%B8%8B%E6%B0%B4%EF%A6%90%E6%AD%8C).jpg",
          },
          {
            title: "Push Off",
            singer: "YB",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/YB-Reel%20Impulse.jpg",
          },
        ],
      },
      {
        ch_name: "이것이 밴드다! 사이다같은 밴드 노래",
        other: 27,
        ch_img:
          "https://api2.indj.club/file/fetchImage/thumbnail/6775_album.png",
        ch_song: [
          {
            title: "민들레 (single ver.)",
            singer: "우효(Oohyo)",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%9A%B0%ED%9A%A8(Oohyo)-%EB%AF%BC%EB%93%A4%EB%A0%88_2020_11_21.jpg",
          },
          {
            title: "HONG KONG",
            singer: "잔나비",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%9E%94%EB%82%98%EB%B9%84-MONKEY%20HOTEL_2021_01_20.jpg",
          },
          {
            title: "지구가 태양을 네 번",
            singer: "넬(NELL)",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%84%AC(NELL)-Newton's%20Apple.jpg",
          },
        ],
      },
    ],
  },

  {
    mod: "당신의 과거는",
    title: "보름달빛 아래에 인디감성을 느끼는 선비",
    good: 5,
    bad: 4,
    desc: [
      "창의력이 뛰어나지만 가지고 있는 재능에 비해 스스로에 대한 평가는 낮게 생각해요. 좀 더 자신에 대해 긍정의 힘을 불어넣는 것이 필요해요. 남에게 피해를 주는 걸 제일 싫어하면서 관심은 받고 싶은 조용한 관종이에요.",

      "친한 사람에게도 분명한 선이 존재하지만, 또 남에게 싫은 소리는 못해서 속으로만 생각하다가 혼자 서서히 마음이 멀어져요. 결정을 해야할 땐 주변 의견이나 분위기에 맞추는 편이라 선택을 잘못해요. 그래서 혼자 결정할 땐 최대한 미뤘다가 최후에 합니다.",

      "게으른 완벽주의자예요. 일을 완벽하게 해야 직성이 풀리지만 일을 시작하기까지가 오래 걸립니다. 날씨에 따라 듣고 싶은 음악이 달라요. 생각이 많을 땐 레몬사탕 보다 내 마음을 헤아려주는 음악을 들으며 산책하는 걸 좋아해요.",
    ],
    img: "https://d3coxo32tidngo.cloudfront.net/image/mbti/scholar.jpg",
    thum: "https://d3coxo32tidngo.cloudfront.net/image/mbti/scholar_miri.jpg",
    channel: [
      {
        ch_name: "여름 밤, 감성 자극 인디 MUSIC",
        other: 19,
        ch_img:
          "https://api2.indj.club/file/fetchImage/thumbnail/여름 밤, 감성 자극 인디 Music.png",
        ch_song: [
          {
            title: "독백",
            singer: "오왠(O.WHEN)",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%98%A4%EC%99%A0(O.WHEN)-When%20I%20Begin_2020_11_23.jpg",
          },
          {
            title: "Vineyard (빈야드)",
            singer: "우효(Oohyo)",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%9A%B0%ED%9A%A8(Oohyo)-%EC%86%8C%EB%85%80%EA%B0%90%EC%84%B1_2020_11_11.jpg",
          },
          {
            title: "감아줘",
            singer: "라이언(Ryan)",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%9D%BC%EC%9D%B4%EC%96%B8(Ryan)-The%20legendary%20close%20up.jpg",
          },
        ],
      },
      {
        ch_name: "봄이라는 손님이 오네요",
        other: 67,
        ch_img:
          "https://api2.indj.club/file/fetchImage/thumbnail/봄이라는 손님이 오네요.png",
        ch_song: [
          {
            title: "봄 사랑 벚꽃 말고",
            singer: "하이포(HIGH4), 아이유(IU)",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%ED%95%98%EC%9D%B4%ED%8F%AC(HIGH4),%20%EC%95%84%EC%9D%B4%EC%9C%A0(IU)-%EB%B4%84%20%EC%82%AC%EB%9E%91%20%EB%B2%9A%EA%BD%83%20%EB%A7%90%EA%B3%A0.jpg",
          },
          {
            title: "Madeleine Love",
            singer: "CHEEZE(치즈)",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/CHEEZE(%EC%B9%98%EC%A6%88)-CHEEZE%201.5%EC%A7%91%20PLAIN_2021_01_20.jpg",
          },
          {
            title: "Picnic",
            singer: "오왠(O.WHEN)",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%98%A4%EC%99%A0(O.WHEN)-When%20I%20Begin_2020_11_23.jpg",
          },
        ],
      },
      {
        ch_name: "긴장을 풀어주는 인디 노래",
        other: 24,
        ch_img:
          "https://api2.indj.club/file/fetchImage/thumbnail/인디 감성 노래와 함께라면 뭉친 근육들도 녹아내린다.png",
        ch_song: [
          {
            title: "As I am",
            singer: "백예린",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%B0%B1%EC%98%88%EB%A6%B0-FRANK_2021_01_20.jpg",
          },
          {
            title: "너를 만나",
            singer: "폴킴(Paul Kim)",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%ED%8F%B4%ED%82%B4(Paul%20Kim)-%EB%84%88%EB%A5%BC%20%EB%A7%8C%EB%82%98_2021_01_19.jpg",
          },
          {
            title: "밤하늘의 별들은",
            singer: "안녕하신가영",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%95%88%EB%85%95%ED%95%98%EC%8B%A0%EA%B0%80%EC%98%81-%EB%B0%A4%ED%95%98%EB%8A%98%EC%9D%98%20%EB%B3%84%EB%93%A4%EC%9D%80_2020_11_20.jpg",
          },
        ],
      },
    ],
  },

  {
    mod: "당신의 과거는",
    title: "정세 한탄을 구성진 발라드로 승화시키는 왕",
    good: 6,
    bad: 0,
    desc: [
      "감수성이 풍부해 감정 기복이 종종 있고 혼자만의 사색을 즐깁니다. 다른 사람의 감정도 잘 캐치해 배려심이 깊다는 말을 종종 듣습니다. 둥글둥글한 성격에 카멜레온 같이 어디든 잘 어울릴 수 있지만 남몰래 속으로 혼자 상처를 받기도 합니다.",

      "노래방에 가면 이별을 한 것도 아닌데 이별한 사람 마냥 애절해져요. 집을 좋아해 나가는 게 귀찮고 약속이 있으면 상대방이 취소해줬으면 하는 마음이에요. 하지만 놀 때는 활발하고 활동적이에요.",

      "다수보다는 소수의 멤버끼리 깊은 관계를 유지하는 것을 좋아합니다. 내 사람이라고 느끼는 사람에게 잘하려고 노력합니다. 감성이 터져버리면 추억팔이를 많이 하고 과거의 사람에게 연락하고 싶은 충동을 자주 느껴요. ",
    ],
    img: "https://d3coxo32tidngo.cloudfront.net/image/mbti/king.jpg",
    thum: "https://d3coxo32tidngo.cloudfront.net/image/mbti/king_miri.jpg",
    channel: [
      {
        ch_name: "감성적인 가사를 듣고 싶을 때의 노래",
        other: 20,
        ch_img:
          "https://api2.indj.club/file/fetchImage/thumbnail/6431_album.png",
        ch_song: [
          {
            title: "너무 보고싶어",
            singer: "어쿠스틱 콜라보(Acoustic Collabo)",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%96%B4%EC%BF%A0%EC%8A%A4%ED%8B%B1%20%EC%BD%9C%EB%9D%BC%EB%B3%B4(Acoustic%20Collabo)-%EC%97%B0%EC%95%A0%EC%9D%98%20%EB%B0%9C%EA%B2%AC%20(KBS%20%EC%9B%94%ED%99%94%EB%93%9C%EB%9D%BC%EB%A7%88)%20OST%20-%20Part%206_2020_11_20.jpg",
          },
          {
            title: "추억은 사랑을 닮아",
            singer: "박효신",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%B0%95%ED%9A%A8%EC%8B%A0-The%20Breeze%20Of%20Sea_2021_01_20.jpg",
          },
          {
            title: "가을 안부",
            singer: "먼데이 키즈(Monday Kiz)",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%A8%BC%EB%8D%B0%EC%9D%B4%20%ED%82%A4%EC%A6%88(Monday%20Kiz)-%EA%B0%80%EC%9D%84%20%EC%95%88%EB%B6%80.jpg",
          },
        ],
      },
      {
        ch_name: "댄스,발라드 둘다 듣고 싶을때",
        other: 75,
        ch_img:
          "https://api2.indj.club/file/fetchImage/thumbnail/댄스,발라드 둘다 듣고 싶을때.png",
        ch_song: [
          {
            title: "눈, 코, 입",
            singer: "태양",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%ED%83%9C%EC%96%91-RISE.jpg",
          },
          {
            title: "All I Wanna Do (K) (Feat. Hoody, Loco)",
            singer: "박재범(Jay Park)",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%B0%95%EC%9E%AC%EB%B2%94(Jay%20Park)-EVERYTHING%20YOU%20WANTED.jpg",
          },
          {
            title: "팔레트 (Feat. G-DRAGON)",
            singer: "아이유(IU)",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%95%84%EC%9D%B4%EC%9C%A0(IU)-Palette_2020_11_24.jpg",
          },
        ],
      },
      {
        ch_name: "너에게 보내는 아련한 초대장",
        other: 23,
        ch_img:
          "https://api2.indj.club/file/fetchImage/thumbnail/7362_album.png",
        ch_song: [
          {
            title: "기억을 걷는 시간",
            singer: "넬(NELL)",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%84%AC(NELL)-%EB%A9%80%EC%96%B4%EC%A7%80%EB%8B%A4_2020_11_16.jpg",
          },
          {
            title: "오래된 노래",
            singer: "스탠딩 에그(Standing Egg)",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%8A%A4%ED%83%A0%EB%94%A9%20%EC%97%90%EA%B7%B8(Standing%20Egg)-%EC%98%A4%EB%9E%98%EB%90%9C%20%EB%85%B8%EB%9E%98.jpg",
          },
          {
            title: "지쳤니",
            singer: "바닐라 어쿠스틱(Vanilla Acoustic)",
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%B0%94%EB%8B%90%EB%9D%BC%20%EC%96%B4%EC%BF%A0%EC%8A%A4%ED%8B%B1(Vanilla%20Acoustic)-%EC%A7%80%EC%B3%A4%EB%8B%88_2020_11_16.jpg",
          },
        ],
      },
    ],
  },
];
