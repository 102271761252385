// 유형 검사
const check = (answers) => {
  let result = 0;
  // 외향 / 내향
  const extrovert = answers.slice(0, 3).reduce((a, b) => a + b) > 1;
  // 논리 / 감정
  const logical = answers.slice(3, 6).reduce((a, b) => a + b) > 1;
  // 직관 / 창의
  const intuitive = answers.slice(6).reduce((a, b) => a + b) > 1;

  // 외향, 논리, 직관 : 재즈
  if (extrovert && logical && intuitive) {
    result = 0;
    // 외향, 논리, 창의 : 아이돌, 댄스
  } else if (extrovert && logical && !intuitive) {
    result = 1;
    // 외향, 감정, 직관 : 팝
  } else if (extrovert && !logical && intuitive) {
    result = 2;
    // 외향, 감정, 창의 : 랩, 힙합
  } else if (extrovert && !logical && !intuitive) {
    result = 3;
    // 내향, 논리, 직관 : 알앤비
  } else if (!extrovert && logical && intuitive) {
    result = 4;
    // 내향, 논리, 창의 : 락/ 헤비메탈
  } else if (!extrovert && logical && !intuitive) {
    result = 5;
    // 내향, 감정, 직관 : 인디
  } else if (!extrovert && !logical && intuitive) {
    result = 6;
    // 내향, 감정, 창의 : 발라드
  } else if (!extrovert && !logical && !intuitive) {
    result = 7;
  } else {
    alert("에러났져");
  }

  return result;
};

export default check;
