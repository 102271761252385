// obj immer
export const immer = (obj, key, val) => {
  const _obj = { ...obj };
  _obj[key] = val;
  return _obj;
};
// obj immer 여러개
export const immers = (obj, key, val) => {
  const _obj = { ...obj };
  for (let i in key) {
    _obj[key[i]] = val[i];
  }
  return _obj;
};

// 숫자에 콤마찍기
export const comma = (number) => {
  // const num = number.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  const num = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return num;
};
