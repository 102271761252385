import React, { useState, useEffect } from 'react';
import LazyLoad from 'react-lazyload';
import '../style/main.scss';
import '../style/before_test.scss';
import '../style/weather_test.scss';
import '../style/webtoon.scss';
import '../style/header.scss';

const Main = () => {
  useEffect(() => {}, []);

  return (
    <div className="main">
      {/* =========================  헤더 시작 ========================== */}
      <header className="header">
        <div className="mobile mo-header">
          <img className="mo-logo" src="../images/main/main_logo.png" alt="인디제이 로고" />
          {/* <img
            className="mo-menu"
            src="../images/main/main_menu.png"
            alt="인디제이 메뉴"
          /> */}
        </div>
        <div className="pc pc-header">
          <img className="pc-logo" src="../images/main/main_logo_pc.png" alt="인디제이 로고" />
          {/* <div className="header-text">
            <button className="head-btn">회사소개</button>
            <button className="head-btn">문의</button>
            <button className="head-btn">채용</button>
          </div> */}
        </div>
      </header>

      {/* =========================  스토어버튼 페이지 시작 ========================== */}
      <div className="one">
        <div className="mobile mo-one">
          <img className="mo-top-img" src="../images/main/main_top.jpg" alt="상단이미지" />

          <h2 className="one-title">
            나보다 나를 더 <br />
            잘아는 음악친구
          </h2>
          <div className="left">인공지능 음악추천 플랫폼 inDJ</div>
          <div className="right">
            <img className="right-img" src="../images/main/main_ai_music.png" alt="모바일 설치페이지 백그라운드" />
          </div>
          <div className="store-btn-wrap">
            <a
              className="down-btn-wrap"
              target="_blank"
              rel="noopener noreferrer"
              href="https://apps.apple.com/kr/app/indj-%EC%9E%90%EC%9C%A0%EB%A1%AD%EA%B2%8C-%EB%93%A3%EB%8A%94-%EC%83%81%ED%99%A9-%EA%B0%90%EC%84%B1-%EC%9D%B8%EA%B3%B5%EC%A7%80%EB%8A%A5-%EC%9D%8C%EC%95%85/id1513542512"
            >
              <img className="down-btn" src="../images/main/main_apple.png" alt="앱스토어 버튼" />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="down-btn-wrap"
              href="https://play.google.com/store/apps/details?id=com.indj.music"
            >
              <img className="down-btn" src="../images/main/main_play.png" alt="플레이스토어 버튼" />
            </a>
          </div>
        </div>
        <div className="pc pc-one">
          <div className="one-con">
            <p className="one-title">
              나보다 나를 더<br /> 잘아는 음악친구
            </p>
            <div className="store-btn-wrap">
              <a
                className="down-btn-wrap"
                target="_blank"
                rel="noopener noreferrer"
                href="https://apps.apple.com/kr/app/indj-%EC%9E%90%EC%9C%A0%EB%A1%AD%EA%B2%8C-%EB%93%A3%EB%8A%94-%EC%83%81%ED%99%A9-%EA%B0%90%EC%84%B1-%EC%9D%B8%EA%B3%B5%EC%A7%80%EB%8A%A5-%EC%9D%8C%EC%95%85/id1513542512"
              >
                <img className="down-btn" src="../images/main/main_apple_pc.png" alt="앱스토어 버튼" />
                <br />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="down-btn-wrap"
                href="https://play.google.com/store/apps/details?id=com.indj.music"
              >
                <img className="down-btn" src="../images/main/main_play_pc.png" alt="플레이스토어 버튼" />
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* =========================  SITUATION 페이지 시작 ========================== */}
      <div className="two">
        <div className="pc-wrap">
          <div className="two-text-wrap text-wrap">
            <p className="two-title card-title">SITUATION</p>
            <p className="two-con card-con">
              <span className="spoqa-bold">달리고 싶은</span> 새벽 <br />
              <span className="spoqa-bold">여행을 떠나는</span> 아침 <br />나
              <span className="spoqa-bold">혼자만의</span> 오후 <br />
              비오는 밤 <span className="spoqa-bold">위로가 필요한</span> 저녁
            </p>
            <p className="two-foot card-foot">
              inDJ는 사용자의 <span className="foot-color">상황과 감정을</span>
              인공지능이 분석해 음악을 추천해요
            </p>
          </div>
          <div className="two-img-wrap">
            <LazyLoad offset={100}>
              <img className="two-img" src="../images/main/main_card.png" alt="인디제이 어플 상단 카드" />
            </LazyLoad>
          </div>
        </div>
      </div>
      {/* =========================  AI 페이지 시작 ========================== */}
      <div className="three">
        <div className="mobile mo-three">
          <div className="three-text-wrap text-wrap">
            <p className="three-title card-title">A.I</p>
            <p className="three-con card-con">
              인공지능 <span className="con-color">DJ 제이든</span>이
              <br />
              골라주는 수 많은 채널
            </p>
            <p className="three-foot card-foot">
              지금 나의 상황과 감정에 맞는 채널을 소개해드립니다.
              <br />
              저희와 일주일만 함께해 주신다면 말이죠.
            </p>
          </div>
        </div>
        <div className="pc pc-three">
          <div className="three-text-wrap text-wrap">
            <p className="three-title card-title">A.I</p>
            <p className="three-con card-con">
              인공지능 <span className="con-color">DJ 제이든</span>이
              <br />
              골라주는 수 많은 채널
            </p>
            <p className="three-foot card-foot">
              지금 나의 상황과 감정에 맞는 채널을 소개해드립니다.
              <br />
              저희와 일주일만 함께해 주신다면 말이죠.
            </p>
          </div>
        </div>
      </div>
      {/* =========================  MOOD 페이지 시작 ========================== */}
      <div className="four">
        <div className="pc-wrap">
          <div className="four-text-wrap text-wrap">
            <p className="four-title card-title">MOOD</p>
            <p className="four-con card-con">
              오늘 기분은 어때요?
              <br /> 기분에 따른 <span className="con-color">음악 추천</span>
            </p>
            <p className="four-foot card-foot">
              당신의 기분을 저희에게 알려주세요.
              <br /> 인공지능이 당신의 감성을 저격해드릴거에요.
            </p>
          </div>
          <div className="four-img-wrap">
            <LazyLoad offset={100}>
              <img className="four-img" src="../images/main/main_mood.png" alt="기분선택카드" />
            </LazyLoad>
          </div>
        </div>
      </div>
      {/* =========================  FREEDOM 페이지 시작 ========================== */}
      <div className="five">
        <div className="pc-wrap">
          <div className="five-text-wrap text-wrap">
            <p className="five-title card-title">FREEDOM</p>
            <p className="five-con card-con">
              광고를 기반으로 <span className="con-color">자유롭게</span>
              <br /> 듣는 라디오
            </p>
            <p className="five-foot card-foot">
              음악 라디오 채널을 자유롭게
              <br /> 청취할 수 있는 서비스에요!
            </p>
          </div>
          <div className="five-img-wrap">
            <LazyLoad offset={100}>
              <img className="mobile five-img-action" src="../images/main/main_action.png" alt="인디제이 어플 화면" />
            </LazyLoad>
            <img className="five-img-phone" src="../images/main/main_phone.png" alt="인디제이 어플 화면" />
          </div>
        </div>
      </div>
      {/* =========================  DJ 페이지 시작 ========================== */}
      <div className="six">
        <div className="six-text-wrap text-wrap">
          <p className="six-title card-title">DJ</p>
          <p className="six-con card-con">
            <span className="con-color">내가 만들고</span> <br />
            모두가 듣는 채널
          </p>
          <p className="six-foot card-foot">
            직접 DJ가 되어 당신의 취향으로 채널을 만들어
            <br /> 다른 사용자와 당신의 음악을 공유해보세요.
          </p>
        </div>
        <div className="seven-img-wrap">
          <LazyLoad offset={100}>
            <img className="seven-img" src="../images/main/main_dj.png" alt="인디제이 어플 화면" />
          </LazyLoad>
        </div>
      </div>
      {/* =========================  마지막 페이지 시작 ========================== */}
      <div className="seven">
        <div className="pc-wrap">
          <div className="seven-text-wrap text-wrap">
            <LazyLoad offset={100}>
              <img className="seven-title-img" src="../images/main/main_wing.png" alt="특허텍스트이미지" />
            </LazyLoad>
            <p className="seven-con card-con">
              <span className="con-color">inDJ의 독보적 기술!</span> <br />그 가치를 인정받고 있습니다.
            </p>
            <p className="seven-foot card-foot">
              inDJ는 특허 분석을 통한 특허확보 및 전세계적으로
              <br /> 독보적 기술을 확보하고 있습니다.
            </p>
            <a
              href="https://drive.google.com/file/d/1vOaov1GG6_Z1rB4fP-O7DL7AMLrYW4Hh/view"
              target="_blank"
              rel="noopener noreferrer"
              className="enter-text"
            >
              <button className="enter-indj">
                <LazyLoad offset={100}>
                  <img className="seven-btn-img" src="../images/main/main_circle.png" alt="입사버튼 써클이미지" />
                </LazyLoad>
                <span>입사지원하기</span>
              </button>
            </a>
          </div>
          <div className="seven-img-wrap">
            <LazyLoad offset={100}>
              <img className="seven-img" src="../images/main/main_music.png" alt="이쁜음표" />
            </LazyLoad>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
