// 유형 검사
const check = (answers) => {
  let result = 0;
  // 외향 / 내향
  const extrovert =
    answers
      .filter((el) => el.type === 1)
      .map((el) => el.score)
      .reduce((a, b) => a + b) > 1;
  // 감정 / 논리
  const emotion =
    answers
      .filter((el) => el.type === 2)
      .map((el) => el.score)
      .reduce((a, b) => a + b) > 1;
  // 계획 / 즉흥
  const plan =
    answers
      .filter((el) => el.type === 3)
      .map((el) => el.score)
      .reduce((a, b) => a + b) > 1;

  // EFP 외향/감정/즉흥
  if (extrovert && emotion && plan) {
    result = 0;
    // EFJ 외향/감정/계획
  } else if (extrovert && emotion && !plan) {
    result = 1;
    // ETP 외향/논리/즉흥
  } else if (extrovert && !emotion && plan) {
    result = 2;
    // ETJ  외향/논리/계획
  } else if (extrovert && !emotion && !plan) {
    result = 3;
    // IFP  내향/감정/즉흥
  } else if (!extrovert && emotion && plan) {
    result = 4;
    // IFJ 내향/감정/계획
  } else if (!extrovert && emotion && !plan) {
    result = 5;
    // ITP 내향/논리/즉흥
  } else if (!extrovert && !emotion && plan) {
    result = 6;
    // ITJ 내향/논리/계획
  } else if (!extrovert && !emotion && !plan) {
    result = 7;
  } else {
    alert("에러났져");
  }

  return result;
};

export default check;

const a = [
  { type: 1, score: 1 },
  { type: 3, score: 1 },
  { type: 3, score: 1 },
  { type: 1, score: 1 },
  { type: 1, score: 1 },
  { type: 3, score: 1 },
  { type: 2, score: 1 },
  { type: 2, score: 1 },
  { type: 2, score: 1 },
];
