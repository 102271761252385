import React, { useEffect } from 'react';
import '../css/mailorder.css';
import { useRecoilState } from 'recoil';
import { _footer } from 'recoil/state';
import { Table } from 'antd';

function MailOrder() {
  const [_, setFooter] = useRecoilState(_footer);
  useEffect(() => {
    setFooter(false);
  }, []);

  return (
    <div id="mailorder">
      <table id="container">
        <tbody>
          <tr>
            <th scope="row">통신판매번호</th>
            <td>2022-광주동구-0338</td>
            <th scope="row">사업자등록번호</th>
            <td>490-81-01351</td>
          </tr>

          <tr>
            <th scope="row">신고현황</th>
            <td>통신판매업 신고</td>
            <th scope="row">법인여부</th>
            <td>법인</td>
          </tr>

          <tr>
            <th scope="row">상호</th>
            <td colSpan={3}>주식회사 인디제이</td>
          </tr>

          <tr>
            <th scope="row">대표자명</th>
            <td>정우주</td>
            <th scope="row">대표 전화번호</th>
            <td>070-7779-0733</td>
          </tr>

          <tr>
            <th scope="row">판매방식</th>
            <td>기타</td>
            <th scope="row">취급품목</th>
            <td>상품권, 기타</td>
          </tr>

          <tr>
            <th scope="row">전자우편(E-mail)</th>
            <td>mst@indj.net</td>
            <th scope="row">신고일자</th>
            <td>20200522</td>
          </tr>

          <tr>
            <th scope="row">사업자소재지</th>
            <td colSpan={3}>광주광역시 동구 금남로4가 6</td>
          </tr>

          <tr>
            <th scope="row">사업자소재지(도로명)</th>
            <td colSpan={3}>광주광역시 동구 금남로 193-12, 2동 4층 404호 (금남로4가)</td>
          </tr>

          <tr>
            <th scope="row">인터넷도메인</th>
            <td colSpan={3}></td>
          </tr>

          <tr>
            <th scope="row">호스트서버소재지</th>
            <td colSpan={3}></td>
          </tr>

          <tr>
            <th scope="row">통신판매업 신고기관명</th>
            <td colSpan={3}>광주광역시 동구청 (062-608-2735)</td>
          </tr>
        </tbody>
      </table>
      <div className="box type4">
        <p>
          본자료는 전자상거래시장에서 소비자가 정확한 사업자 정보를 가지고 안전한 거래를 할 수 있도록 전국 시,군,구에
          신고된 <strong>통신판매업자</strong>의 신원정보를
          <em class="em_blue">전자상거래소비자보 호법 제12조4항</em> 에 따라 제공하는 정보입니다. 사업자 정보에 대한
          궁금한 사항이나 사업자의 신원정보가 정보공개 내용과 불일치할 경우에는 사업자 정보검색시 확인되는 해당{' '}
          <strong>신고기관(지방자치단체)</strong>에 문의하여 주시기 바랍니다.
          <br />
          <br />
          일부 사업자의 경우, 부가가치세법상 사업자 폐업 신고는 하였으나 전자상거래법상 통신판매업 폐업 신고는 하지 않은
          사례가 있을 수 있습니다. 소비자 피해를 방지하기 위해 부가가치세법상
          <strong>사업자 폐업 여부</strong>도
          <a href="http://www.hometax.go.kr" target="_blank" title="새창">
            <strong>국세청 홈택스 페이지</strong>(www.hometax.go.kr)
          </a>
          의 사업자등록상태조회 코너를 통해 확인하시기 바랍니다.
          <a
            href="https://teht.hometax.go.kr/websquare/websquare.html?w2xPath=/ui/ab/a/a/UTEABAAA13.xml"
            target="_blank"
            title="새창"
          >
            [바로가기 링크]
          </a>
        </p>
      </div>
    </div>
  );
}

export default MailOrder;
