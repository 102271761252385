import React, { useState, useEffect, useRef } from "react";
import Questions from "./Question";
import { question } from "./data";
import check from "./Waiting";
import { getUserApi } from "apis/mbti";
import { kong } from "../svg";
import ReactHelmet from "../Helmet/Helmet";
import LazyLoad from "react-lazyload";
import DropDown from "../parts/dropDown";

const WeatherTest = ({ history }) => {
  const [process, setProcess] = useState(1);
  const pgNow = (process / 9) * 100 + "%";
  const [answers, setanswers] = useState([]);
  const [contUser, setcontUser] = useState(0);
  const [loading, setloading] = useState(true); // 언마운트될때 false로 바뀌며 유저카운트 안불러옴
  const [ranquestion, setRanquestion] = useState([]);
  const [limit, setLimit] = useState(0);
  const [imgHeight, setImgHeight] = useState(0);

  useEffect(() => {
    randomQuestion();
    testUser();
    finish();
    topSize();
    document.getElementById("footer").style.display = "none";
    window.scrollTo({ top: 0, behavior: "auto" });
    return () => setloading(false);
  }, [answers, process, history, contUser]);
  // 집계유저
  const testUser = async () => {
    if (loading) {
      const { data } = await getUserApi(1);
      setcontUser(data.count);
    }
  };
  // 테스트 시작버튼
  const testStart = () => {
    const startPage = document.getElementById("start-page"); // 시작페이지

    const progress = document.getElementsByClassName("question-wrap")[0]; // 질문
    const header = document.getElementById("header"); // 헤더
    // 시작페이지 숨기고 질문 나타남
    startPage.style.display = "none";
    header.style.display = "none";
    progress.style.display = "flex";
  };
  // 답변 완료
  const finish = () => {
    if (process === 10) {
      const result = check(answers);
      history.push(`/weatherresult/${result}`);
    }
  };
  // 대답버튼 클릭시
  const choiceAnswer = (now, num) => {
    setanswers(answers.concat(num));
    setProcess(now + 1);
  };
  // 질문순서 랜덤지정
  const randomQuestion = () => {
    // 최초렌딩시에만 실행
    setLimit((lim) => lim + 1);
    if (limit < 1) {
      const arr = [0, 1, 2, 3, 4, 5, 6, 7, 8];
      arr.sort(() => Math.random() - 0.5);
      setRanquestion(arr);
    }
  };
  // 상단 이미지 높이 구하기
  const topSize = () => {
    const topImgHeight =
      document.getElementsByClassName("wrap")[0].clientWidth *
      0.748792270531401;
    setImgHeight(-topImgHeight);
  };

  return (
    <div
      className="weather_test"
      style={{
        backgroundColor: "#fff",
        minHeight: "100vh",
      }}
    >
      <ReactHelmet
        keywords="inDJ,음악취향,음악취향테스트,날씨,mbti,음악추천,무료음악,음악어플,멜론,지니,벅스,유튜브뮤직,음악,music"
        description="과연 나의 날씨은?"
        title="날씨 테스트로 알아보는 나의 성격"
        favicon="https://d3coxo32tidngo.cloudfront.net/image/mbti/main_miri.png"
      />
      <div className="wrap">
        <div id="header">
          <img
            className="mo-logo"
            src="/../images/text_logo.png"
            alt="인디제이 로고"
          />
          <div className="header-text">
            <DropDown />
            <button className="head-btn">
              <a href="https://policy.indj.club/webtoon">웹툰</a>
            </button>
          </div>
        </div>
        {/* 테스트 설명 텍스트 */}
        <div id="start-page">
          <LazyLoad offset={100}>
            <img
              className="main-img"
              src="https://d3coxo32tidngo.cloudfront.net/image/mbti/main.png"
              alt="날씨테스트 메인이미지"
            />
          </LazyLoad>
          <div className="desc-box">
            <p className="desc1">제목은 내 날씨 테스트로 하겠습니다.</p>
            <p className="desc2"> 근데 이제 음악을 곁들인...</p>
            <p className="desc2"> 나를정의하는 날씨를 알려주고</p>
            <span className="desc2" role="img" aria-label="웃는이모지">
              그에 따른 음악추천까지 해드릴게요😀
            </span>
          </div>

          <button
            type="button"
            onClick={() => testStart()}
            className="start-btn"
          >
            테스트 시작하기
          </button>
          <p className="total">{`현재 ${contUser}명 다녀감`}</p>
          <LazyLoad offset={100}>
            <img
              className="weather_logo"
              src="/../images/weatherTest/weather_logo.png"
              alt="인디제이 로고"
            />
          </LazyLoad>
        </div>
        {/* start-page 끝 */}

        {/* 질문페이지 렌딩 */}
        <div className="question-wrap" style={{ display: "none" }}>
          {/* 진행바 */}
          <img
            className="q_top"
            src="/../images/weatherTest/q_top.png"
            alt="질문 상단 구름"
          />
          <div style={{ marginTop: imgHeight }} className="progress-wrap">
            <p className="progress-desc">인공지능 음악 연구소</p>
            <div className="kong-all">
              <div className="kong" style={{ width: pgNow }}>
                {kong()}
              </div>
            </div>
            <div className="progress-all">
              <div className="progress-now" style={{ width: pgNow }}></div>
            </div>
            {process < 10 ? (
              <p className="progres-num">{`${process}/9`}</p>
            ) : null}
          </div>
          {/* progress-wrap 끝 */}
          {ranquestion.length !== 0 && process < 10 && (
            <Questions
              item={question[ranquestion[process - 1]]}
              qnum={process}
              choice={choiceAnswer}
            />
          )}
          <div className="q_bottom">
            <LazyLoad offset={100}>
              <img
                style={{ width: "100%", marginBottom: "-10px" }}
                src="/../images/weatherTest/q_bottom.png"
                alt="질문 하단 산"
              />
            </LazyLoad>
          </div>
        </div>
        {/* question-wrap 끝 */}
      </div>
    </div>
  );
};
export default WeatherTest;
