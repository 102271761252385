import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { getCustomerUidApi } from "apis/apis";
import "./ticketModal.scss";
import { useRecoilValue } from "recoil";
import { _payInfo } from "recoil/state";

const TicketModal = ({ visible, setVisible }) => {
  const payInfo = useRecoilValue(_payInfo);

  const IMP = window.IMP;
  // 아임포트 init
  const IMPInit = () => {
    IMP.init("imp73083982");
  };
  // 결제
  const regularPayment = async () => {
    // customer_uid 발급
    const {
      data: { merchant_uid, customer_uid },
    } = await getCustomerUidApi();
    const body = {
      ...payInfo,
      merchant_uid: merchant_uid, // 상점에서 관리하는 주문 번호
      customer_uid: customer_uid, // 필수 입력.
    };
    console.log(body);
    IMP.request_pay(body, function (rsp) {
      if (rsp.success) {
        console.log("빌링키 발급 성공");
      } else {
        console.log("빌링키 발급 실패");
      }
    });
  };
  useEffect(() => {
    IMPInit();
  }, []);
  return (
    <Modal
      wrapClassName="musiclist-back"
      footer={null}
      title={false}
      visible={visible}
      onCancel={() => setVisible(false)}
      forceRender={true}
    >
      <h2>이용권 정보</h2>

      <button onClick={regularPayment}>구매하기</button>
    </Modal>
  );
};

export default TicketModal;
