const data = [
  {
    code1: '\u{00a9}',
    code2: '\\u{00a9}',
  },
  {
    code1: '\u{00ae}',
    code2: '\\u{00ae}',
  },
  {
    code1: '\u{203c}',
    code2: '\\u{203c}',
  },
  {
    code1: '\u{2049}',
    code2: '\\u{2049}',
  },
  {
    code1: '\u{2122}',
    code2: '\\u{2122}',
  },
  {
    code1: '\u{2139}',
    code2: '\\u{2139}',
  },
  {
    code1: '\u{2194}',
    code2: '\\u{2194}',
  },
  {
    code1: '\u{2195}',
    code2: '\\u{2195}',
  },
  {
    code1: '\u{2196}',
    code2: '\\u{2196}',
  },
  {
    code1: '\u{2197}',
    code2: '\\u{2197}',
  },
  {
    code1: '\u{2198}',
    code2: '\\u{2198}',
  },
  {
    code1: '\u{2199}',
    code2: '\\u{2199}',
  },
  {
    code1: '\u{21a9}',
    code2: '\\u{21a9}',
  },
  {
    code1: '\u{21aa}',
    code2: '\\u{21aa}',
  },
  {
    code1: '\u{231a}',
    code2: '\\u{231a}',
  },
  {
    code1: '\u{231b}',
    code2: '\\u{231b}',
  },
  {
    code1: '\u{2328}',
    code2: '\\u{2328}',
  },
  {
    code1: '\u{23cf}',
    code2: '\\u{23cf}',
  },
  {
    code1: '\u{23e9}',
    code2: '\\u{23e9}',
  },
  {
    code1: '\u{23ea}',
    code2: '\\u{23ea}',
  },
  {
    code1: '\u{23eb}',
    code2: '\\u{23eb}',
  },
  {
    code1: '\u{23ec}',
    code2: '\\u{23ec}',
  },
  {
    code1: '\u{23ed}',
    code2: '\\u{23ed}',
  },
  {
    code1: '\u{23ee}',
    code2: '\\u{23ee}',
  },
  {
    code1: '\u{23ef}',
    code2: '\\u{23ef}',
  },
  {
    code1: '\u{23f0}',
    code2: '\\u{23f0}',
  },
  {
    code1: '\u{23f1}',
    code2: '\\u{23f1}',
  },
  {
    code1: '\u{23f2}',
    code2: '\\u{23f2}',
  },
  {
    code1: '\u{23f3}',
    code2: '\\u{23f3}',
  },
  {
    code1: '\u{23f8}',
    code2: '\\u{23f8}',
  },
  {
    code1: '\u{23f9}',
    code2: '\\u{23f9}',
  },
  {
    code1: '\u{23fa}',
    code2: '\\u{23fa}',
  },
  {
    code1: '\u{24c2}',
    code2: '\\u{24c2}',
  },
  {
    code1: '\u{25aa}',
    code2: '\\u{25aa}',
  },
  {
    code1: '\u{25ab}',
    code2: '\\u{25ab}',
  },
  {
    code1: '\u{25b6}',
    code2: '\\u{25b6}',
  },
  {
    code1: '\u{25c0}',
    code2: '\\u{25c0}',
  },
  {
    code1: '\u{25fb}',
    code2: '\\u{25fb}',
  },
  {
    code1: '\u{25fc}',
    code2: '\\u{25fc}',
  },
  {
    code1: '\u{25fd}',
    code2: '\\u{25fd}',
  },
  {
    code1: '\u{25fe}',
    code2: '\\u{25fe}',
  },
  {
    code1: '\u{2600}',
    code2: '\\u{2600}',
  },
  {
    code1: '\u{2601}',
    code2: '\\u{2601}',
  },
  {
    code1: '\u{2602}',
    code2: '\\u{2602}',
  },
  {
    code1: '\u{2603}',
    code2: '\\u{2603}',
  },
  {
    code1: '\u{2604}',
    code2: '\\u{2604}',
  },
  {
    code1: '\u{260e}',
    code2: '\\u{260e}',
  },
  {
    code1: '\u{2611}',
    code2: '\\u{2611}',
  },
  {
    code1: '\u{2614}',
    code2: '\\u{2614}',
  },
  {
    code1: '\u{2615}',
    code2: '\\u{2615}',
  },
  {
    code1: '\u{2618}',
    code2: '\\u{2618}',
  },
  {
    code1: '\u{261d}',
    code2: '\\u{261d}',
  },
  {
    code1: '\u{2620}',
    code2: '\\u{2620}',
  },
  {
    code1: '\u{2622}',
    code2: '\\u{2622}',
  },
  {
    code1: '\u{2623}',
    code2: '\\u{2623}',
  },
  {
    code1: '\u{2626}',
    code2: '\\u{2626}',
  },
  {
    code1: '\u{262a}',
    code2: '\\u{262a}',
  },
  {
    code1: '\u{262e}',
    code2: '\\u{262e}',
  },
  {
    code1: '\u{262f}',
    code2: '\\u{262f}',
  },
  {
    code1: '\u{2638}',
    code2: '\\u{2638}',
  },
  {
    code1: '\u{2639}',
    code2: '\\u{2639}',
  },
  {
    code1: '\u{263a}',
    code2: '\\u{263a}',
  },
  {
    code1: '\u{2640}',
    code2: '\\u{2640}',
  },
  {
    code1: '\u{2642}',
    code2: '\\u{2642}',
  },
  {
    code1: '\u{2648}',
    code2: '\\u{2648}',
  },
  {
    code1: '\u{2649}',
    code2: '\\u{2649}',
  },
  {
    code1: '\u{264a}',
    code2: '\\u{264a}',
  },
  {
    code1: '\u{264b}',
    code2: '\\u{264b}',
  },
  {
    code1: '\u{264c}',
    code2: '\\u{264c}',
  },
  {
    code1: '\u{264d}',
    code2: '\\u{264d}',
  },
  {
    code1: '\u{264e}',
    code2: '\\u{264e}',
  },
  {
    code1: '\u{264f}',
    code2: '\\u{264f}',
  },
  {
    code1: '\u{2650}',
    code2: '\\u{2650}',
  },
  {
    code1: '\u{2651}',
    code2: '\\u{2651}',
  },
  {
    code1: '\u{2652}',
    code2: '\\u{2652}',
  },
  {
    code1: '\u{2653}',
    code2: '\\u{2653}',
  },
  {
    code1: '\u{265f}',
    code2: '\\u{265f}',
  },
  {
    code1: '\u{2660}',
    code2: '\\u{2660}',
  },
  {
    code1: '\u{2663}',
    code2: '\\u{2663}',
  },
  {
    code1: '\u{2665}',
    code2: '\\u{2665}',
  },
  {
    code1: '\u{2666}',
    code2: '\\u{2666}',
  },
  {
    code1: '\u{2668}',
    code2: '\\u{2668}',
  },
  {
    code1: '\u{267b}',
    code2: '\\u{267b}',
  },
  {
    code1: '\u{267e}',
    code2: '\\u{267e}',
  },
  {
    code1: '\u{267f}',
    code2: '\\u{267f}',
  },
  {
    code1: '\u{2692}',
    code2: '\\u{2692}',
  },
  {
    code1: '\u{2693}',
    code2: '\\u{2693}',
  },
  {
    code1: '\u{2694}',
    code2: '\\u{2694}',
  },
  {
    code1: '\u{2695}',
    code2: '\\u{2695}',
  },
  {
    code1: '\u{2696}',
    code2: '\\u{2696}',
  },
  {
    code1: '\u{2697}',
    code2: '\\u{2697}',
  },
  {
    code1: '\u{2699}',
    code2: '\\u{2699}',
  },
  {
    code1: '\u{269b}',
    code2: '\\u{269b}',
  },
  {
    code1: '\u{269c}',
    code2: '\\u{269c}',
  },
  {
    code1: '\u{26a0}',
    code2: '\\u{26a0}',
  },
  {
    code1: '\u{26a1}',
    code2: '\\u{26a1}',
  },
  {
    code1: '\u{26a7}',
    code2: '\\u{26a7}',
  },
  {
    code1: '\u{26aa}',
    code2: '\\u{26aa}',
  },
  {
    code1: '\u{26ab}',
    code2: '\\u{26ab}',
  },
  {
    code1: '\u{26b0}',
    code2: '\\u{26b0}',
  },
  {
    code1: '\u{26b1}',
    code2: '\\u{26b1}',
  },
  {
    code1: '\u{26bd}',
    code2: '\\u{26bd}',
  },
  {
    code1: '\u{26be}',
    code2: '\\u{26be}',
  },
  {
    code1: '\u{26c4}',
    code2: '\\u{26c4}',
  },
  {
    code1: '\u{26c5}',
    code2: '\\u{26c5}',
  },
  {
    code1: '\u{26c8}',
    code2: '\\u{26c8}',
  },
  {
    code1: '\u{26ce}',
    code2: '\\u{26ce}',
  },
  {
    code1: '\u{26cf}',
    code2: '\\u{26cf}',
  },
  {
    code1: '\u{26d1}',
    code2: '\\u{26d1}',
  },
  {
    code1: '\u{26d3}',
    code2: '\\u{26d3}',
  },
  {
    code1: '\u{26d4}',
    code2: '\\u{26d4}',
  },
  {
    code1: '\u{26e9}',
    code2: '\\u{26e9}',
  },
  {
    code1: '\u{26ea}',
    code2: '\\u{26ea}',
  },
  {
    code1: '\u{26f0}',
    code2: '\\u{26f0}',
  },
  {
    code1: '\u{26f1}',
    code2: '\\u{26f1}',
  },
  {
    code1: '\u{26f2}',
    code2: '\\u{26f2}',
  },
  {
    code1: '\u{26f3}',
    code2: '\\u{26f3}',
  },
  {
    code1: '\u{26f4}',
    code2: '\\u{26f4}',
  },
  {
    code1: '\u{26f5}',
    code2: '\\u{26f5}',
  },
  {
    code1: '\u{26f7}',
    code2: '\\u{26f7}',
  },
  {
    code1: '\u{26f8}',
    code2: '\\u{26f8}',
  },
  {
    code1: '\u{26f9}',
    code2: '\\u{26f9}',
  },
  {
    code1: '\u{26fa}',
    code2: '\\u{26fa}',
  },
  {
    code1: '\u{26fd}',
    code2: '\\u{26fd}',
  },
  {
    code1: '\u{2702}',
    code2: '\\u{2702}',
  },
  {
    code1: '\u{2705}',
    code2: '\\u{2705}',
  },
  {
    code1: '\u{2708}',
    code2: '\\u{2708}',
  },
  {
    code1: '\u{2709}',
    code2: '\\u{2709}',
  },
  {
    code1: '\u{270a}',
    code2: '\\u{270a}',
  },
  {
    code1: '\u{270b}',
    code2: '\\u{270b}',
  },
  {
    code1: '\u{270c}',
    code2: '\\u{270c}',
  },
  {
    code1: '\u{270d}',
    code2: '\\u{270d}',
  },
  {
    code1: '\u{270f}',
    code2: '\\u{270f}',
  },
  {
    code1: '\u{2712}',
    code2: '\\u{2712}',
  },
  {
    code1: '\u{2714}',
    code2: '\\u{2714}',
  },
  {
    code1: '\u{2716}',
    code2: '\\u{2716}',
  },
  {
    code1: '\u{271d}',
    code2: '\\u{271d}',
  },
  {
    code1: '\u{2721}',
    code2: '\\u{2721}',
  },
  {
    code1: '\u{2728}',
    code2: '\\u{2728}',
  },
  {
    code1: '\u{2733}',
    code2: '\\u{2733}',
  },
  {
    code1: '\u{2734}',
    code2: '\\u{2734}',
  },
  {
    code1: '\u{2744}',
    code2: '\\u{2744}',
  },
  {
    code1: '\u{2747}',
    code2: '\\u{2747}',
  },
  {
    code1: '\u{274c}',
    code2: '\\u{274c}',
  },
  {
    code1: '\u{274e}',
    code2: '\\u{274e}',
  },
  {
    code1: '\u{2753}',
    code2: '\\u{2753}',
  },
  {
    code1: '\u{2754}',
    code2: '\\u{2754}',
  },
  {
    code1: '\u{2755}',
    code2: '\\u{2755}',
  },
  {
    code1: '\u{2757}',
    code2: '\\u{2757}',
  },
  {
    code1: '\u{2763}',
    code2: '\\u{2763}',
  },
  {
    code1: '\u{2764}',
    code2: '\\u{2764}',
  },
  {
    code1: '\u{2795}',
    code2: '\\u{2795}',
  },
  {
    code1: '\u{2796}',
    code2: '\\u{2796}',
  },
  {
    code1: '\u{2797}',
    code2: '\\u{2797}',
  },
  {
    code1: '\u{27a1}',
    code2: '\\u{27a1}',
  },
  {
    code1: '\u{27b0}',
    code2: '\\u{27b0}',
  },
  {
    code1: '\u{27bf}',
    code2: '\\u{27bf}',
  },
  {
    code1: '\u{2934}',
    code2: '\\u{2934}',
  },
  {
    code1: '\u{2935}',
    code2: '\\u{2935}',
  },
  {
    code1: '\u{2b05}',
    code2: '\\u{2b05}',
  },
  {
    code1: '\u{2b06}',
    code2: '\\u{2b06}',
  },
  {
    code1: '\u{2b07}',
    code2: '\\u{2b07}',
  },
  {
    code1: '\u{2b1b}',
    code2: '\\u{2b1b}',
  },
  {
    code1: '\u{2b1c}',
    code2: '\\u{2b1c}',
  },
  {
    code1: '\u{2b50}',
    code2: '\\u{2b50}',
  },
  {
    code1: '\u{2b55}',
    code2: '\\u{2b55}',
  },
  {
    code1: '\u{3030}',
    code2: '\\u{3030}',
  },
  {
    code1: '\u{303d}',
    code2: '\\u{303d}',
  },
  {
    code1: '\u{3297}',
    code2: '\\u{3297}',
  },
  {
    code1: '\u{3299}',
    code2: '\\u{3299}',
  },
  {
    code1: '\u{1f004}',
    code2: '\\u{1f004}',
  },
  {
    code1: '\u{1f0cf}',
    code2: '\\u{1f0cf}',
  },
  {
    code1: '\u{1f170}',
    code2: '\\u{1f170}',
  },
  {
    code1: '\u{1f171}',
    code2: '\\u{1f171}',
  },
  {
    code1: '\u{1f17e}',
    code2: '\\u{1f17e}',
  },
  {
    code1: '\u{1f17f}',
    code2: '\\u{1f17f}',
  },
  {
    code1: '\u{1f18e}',
    code2: '\\u{1f18e}',
  },
  {
    code1: '\u{1f191}',
    code2: '\\u{1f191}',
  },
  {
    code1: '\u{1f192}',
    code2: '\\u{1f192}',
  },
  {
    code1: '\u{1f193}',
    code2: '\\u{1f193}',
  },
  {
    code1: '\u{1f194}',
    code2: '\\u{1f194}',
  },
  {
    code1: '\u{1f195}',
    code2: '\\u{1f195}',
  },
  {
    code1: '\u{1f196}',
    code2: '\\u{1f196}',
  },
  {
    code1: '\u{1f197}',
    code2: '\\u{1f197}',
  },
  {
    code1: '\u{1f198}',
    code2: '\\u{1f198}',
  },
  {
    code1: '\u{1f199}',
    code2: '\\u{1f199}',
  },
  {
    code1: '\u{1f19a}',
    code2: '\\u{1f19a}',
  },
  {
    code1: '\u{1f201}',
    code2: '\\u{1f201}',
  },
  {
    code1: '\u{1f202}',
    code2: '\\u{1f202}',
  },
  {
    code1: '\u{1f21a}',
    code2: '\\u{1f21a}',
  },
  {
    code1: '\u{1f22f}',
    code2: '\\u{1f22f}',
  },
  {
    code1: '\u{1f232}',
    code2: '\\u{1f232}',
  },
  {
    code1: '\u{1f233}',
    code2: '\\u{1f233}',
  },
  {
    code1: '\u{1f234}',
    code2: '\\u{1f234}',
  },
  {
    code1: '\u{1f235}',
    code2: '\\u{1f235}',
  },
  {
    code1: '\u{1f236}',
    code2: '\\u{1f236}',
  },
  {
    code1: '\u{1f237}',
    code2: '\\u{1f237}',
  },
  {
    code1: '\u{1f238}',
    code2: '\\u{1f238}',
  },
  {
    code1: '\u{1f239}',
    code2: '\\u{1f239}',
  },
  {
    code1: '\u{1f23a}',
    code2: '\\u{1f23a}',
  },
  {
    code1: '\u{1f250}',
    code2: '\\u{1f250}',
  },
  {
    code1: '\u{1f251}',
    code2: '\\u{1f251}',
  },
  {
    code1: '\u{1f300}',
    code2: '\\u{1f300}',
  },
  {
    code1: '\u{1f301}',
    code2: '\\u{1f301}',
  },
  {
    code1: '\u{1f302}',
    code2: '\\u{1f302}',
  },
  {
    code1: '\u{1f303}',
    code2: '\\u{1f303}',
  },
  {
    code1: '\u{1f304}',
    code2: '\\u{1f304}',
  },
  {
    code1: '\u{1f305}',
    code2: '\\u{1f305}',
  },
  {
    code1: '\u{1f306}',
    code2: '\\u{1f306}',
  },
  {
    code1: '\u{1f307}',
    code2: '\\u{1f307}',
  },
  {
    code1: '\u{1f308}',
    code2: '\\u{1f308}',
  },
  {
    code1: '\u{1f309}',
    code2: '\\u{1f309}',
  },
  {
    code1: '\u{1f30a}',
    code2: '\\u{1f30a}',
  },
  {
    code1: '\u{1f30b}',
    code2: '\\u{1f30b}',
  },
  {
    code1: '\u{1f30c}',
    code2: '\\u{1f30c}',
  },
  {
    code1: '\u{1f30d}',
    code2: '\\u{1f30d}',
  },
  {
    code1: '\u{1f30e}',
    code2: '\\u{1f30e}',
  },
  {
    code1: '\u{1f30f}',
    code2: '\\u{1f30f}',
  },
  {
    code1: '\u{1f310}',
    code2: '\\u{1f310}',
  },
  {
    code1: '\u{1f311}',
    code2: '\\u{1f311}',
  },
  {
    code1: '\u{1f312}',
    code2: '\\u{1f312}',
  },
  {
    code1: '\u{1f313}',
    code2: '\\u{1f313}',
  },
  {
    code1: '\u{1f314}',
    code2: '\\u{1f314}',
  },
  {
    code1: '\u{1f315}',
    code2: '\\u{1f315}',
  },
  {
    code1: '\u{1f316}',
    code2: '\\u{1f316}',
  },
  {
    code1: '\u{1f317}',
    code2: '\\u{1f317}',
  },
  {
    code1: '\u{1f318}',
    code2: '\\u{1f318}',
  },
  {
    code1: '\u{1f319}',
    code2: '\\u{1f319}',
  },
  {
    code1: '\u{1f31a}',
    code2: '\\u{1f31a}',
  },
  {
    code1: '\u{1f31b}',
    code2: '\\u{1f31b}',
  },
  {
    code1: '\u{1f31c}',
    code2: '\\u{1f31c}',
  },
  {
    code1: '\u{1f31d}',
    code2: '\\u{1f31d}',
  },
  {
    code1: '\u{1f31e}',
    code2: '\\u{1f31e}',
  },
  {
    code1: '\u{1f31f}',
    code2: '\\u{1f31f}',
  },
  {
    code1: '\u{1f320}',
    code2: '\\u{1f320}',
  },
  {
    code1: '\u{1f321}',
    code2: '\\u{1f321}',
  },
  {
    code1: '\u{1f324}',
    code2: '\\u{1f324}',
  },
  {
    code1: '\u{1f325}',
    code2: '\\u{1f325}',
  },
  {
    code1: '\u{1f326}',
    code2: '\\u{1f326}',
  },
  {
    code1: '\u{1f327}',
    code2: '\\u{1f327}',
  },
  {
    code1: '\u{1f328}',
    code2: '\\u{1f328}',
  },
  {
    code1: '\u{1f329}',
    code2: '\\u{1f329}',
  },
  {
    code1: '\u{1f32a}',
    code2: '\\u{1f32a}',
  },
  {
    code1: '\u{1f32b}',
    code2: '\\u{1f32b}',
  },
  {
    code1: '\u{1f32c}',
    code2: '\\u{1f32c}',
  },
  {
    code1: '\u{1f32d}',
    code2: '\\u{1f32d}',
  },
  {
    code1: '\u{1f32e}',
    code2: '\\u{1f32e}',
  },
  {
    code1: '\u{1f32f}',
    code2: '\\u{1f32f}',
  },
  {
    code1: '\u{1f330}',
    code2: '\\u{1f330}',
  },
  {
    code1: '\u{1f331}',
    code2: '\\u{1f331}',
  },
  {
    code1: '\u{1f332}',
    code2: '\\u{1f332}',
  },
  {
    code1: '\u{1f333}',
    code2: '\\u{1f333}',
  },
  {
    code1: '\u{1f334}',
    code2: '\\u{1f334}',
  },
  {
    code1: '\u{1f335}',
    code2: '\\u{1f335}',
  },
  {
    code1: '\u{1f336}',
    code2: '\\u{1f336}',
  },
  {
    code1: '\u{1f337}',
    code2: '\\u{1f337}',
  },
  {
    code1: '\u{1f338}',
    code2: '\\u{1f338}',
  },
  {
    code1: '\u{1f339}',
    code2: '\\u{1f339}',
  },
  {
    code1: '\u{1f33a}',
    code2: '\\u{1f33a}',
  },
  {
    code1: '\u{1f33b}',
    code2: '\\u{1f33b}',
  },
  {
    code1: '\u{1f33c}',
    code2: '\\u{1f33c}',
  },
  {
    code1: '\u{1f33d}',
    code2: '\\u{1f33d}',
  },
  {
    code1: '\u{1f33e}',
    code2: '\\u{1f33e}',
  },
  {
    code1: '\u{1f33f}',
    code2: '\\u{1f33f}',
  },
  {
    code1: '\u{1f340}',
    code2: '\\u{1f340}',
  },
  {
    code1: '\u{1f341}',
    code2: '\\u{1f341}',
  },
  {
    code1: '\u{1f342}',
    code2: '\\u{1f342}',
  },
  {
    code1: '\u{1f343}',
    code2: '\\u{1f343}',
  },
  {
    code1: '\u{1f344}',
    code2: '\\u{1f344}',
  },
  {
    code1: '\u{1f345}',
    code2: '\\u{1f345}',
  },
  {
    code1: '\u{1f346}',
    code2: '\\u{1f346}',
  },
  {
    code1: '\u{1f347}',
    code2: '\\u{1f347}',
  },
  {
    code1: '\u{1f348}',
    code2: '\\u{1f348}',
  },
  {
    code1: '\u{1f349}',
    code2: '\\u{1f349}',
  },
  {
    code1: '\u{1f34a}',
    code2: '\\u{1f34a}',
  },
  {
    code1: '\u{1f34b}',
    code2: '\\u{1f34b}',
  },
  {
    code1: '\u{1f34c}',
    code2: '\\u{1f34c}',
  },
  {
    code1: '\u{1f34d}',
    code2: '\\u{1f34d}',
  },
  {
    code1: '\u{1f34e}',
    code2: '\\u{1f34e}',
  },
  {
    code1: '\u{1f34f}',
    code2: '\\u{1f34f}',
  },
  {
    code1: '\u{1f350}',
    code2: '\\u{1f350}',
  },
  {
    code1: '\u{1f351}',
    code2: '\\u{1f351}',
  },
  {
    code1: '\u{1f352}',
    code2: '\\u{1f352}',
  },
  {
    code1: '\u{1f353}',
    code2: '\\u{1f353}',
  },
  {
    code1: '\u{1f354}',
    code2: '\\u{1f354}',
  },
  {
    code1: '\u{1f355}',
    code2: '\\u{1f355}',
  },
  {
    code1: '\u{1f356}',
    code2: '\\u{1f356}',
  },
  {
    code1: '\u{1f357}',
    code2: '\\u{1f357}',
  },
  {
    code1: '\u{1f358}',
    code2: '\\u{1f358}',
  },
  {
    code1: '\u{1f359}',
    code2: '\\u{1f359}',
  },
  {
    code1: '\u{1f35a}',
    code2: '\\u{1f35a}',
  },
  {
    code1: '\u{1f35b}',
    code2: '\\u{1f35b}',
  },
  {
    code1: '\u{1f35c}',
    code2: '\\u{1f35c}',
  },
  {
    code1: '\u{1f35d}',
    code2: '\\u{1f35d}',
  },
  {
    code1: '\u{1f35e}',
    code2: '\\u{1f35e}',
  },
  {
    code1: '\u{1f35f}',
    code2: '\\u{1f35f}',
  },
  {
    code1: '\u{1f360}',
    code2: '\\u{1f360}',
  },
  {
    code1: '\u{1f361}',
    code2: '\\u{1f361}',
  },
  {
    code1: '\u{1f362}',
    code2: '\\u{1f362}',
  },
  {
    code1: '\u{1f363}',
    code2: '\\u{1f363}',
  },
  {
    code1: '\u{1f364}',
    code2: '\\u{1f364}',
  },
  {
    code1: '\u{1f365}',
    code2: '\\u{1f365}',
  },
  {
    code1: '\u{1f366}',
    code2: '\\u{1f366}',
  },
  {
    code1: '\u{1f367}',
    code2: '\\u{1f367}',
  },
  {
    code1: '\u{1f368}',
    code2: '\\u{1f368}',
  },
  {
    code1: '\u{1f369}',
    code2: '\\u{1f369}',
  },
  {
    code1: '\u{1f36a}',
    code2: '\\u{1f36a}',
  },
  {
    code1: '\u{1f36b}',
    code2: '\\u{1f36b}',
  },
  {
    code1: '\u{1f36c}',
    code2: '\\u{1f36c}',
  },
  {
    code1: '\u{1f36d}',
    code2: '\\u{1f36d}',
  },
  {
    code1: '\u{1f36e}',
    code2: '\\u{1f36e}',
  },
  {
    code1: '\u{1f36f}',
    code2: '\\u{1f36f}',
  },
  {
    code1: '\u{1f370}',
    code2: '\\u{1f370}',
  },
  {
    code1: '\u{1f371}',
    code2: '\\u{1f371}',
  },
  {
    code1: '\u{1f372}',
    code2: '\\u{1f372}',
  },
  {
    code1: '\u{1f373}',
    code2: '\\u{1f373}',
  },
  {
    code1: '\u{1f374}',
    code2: '\\u{1f374}',
  },
  {
    code1: '\u{1f375}',
    code2: '\\u{1f375}',
  },
  {
    code1: '\u{1f376}',
    code2: '\\u{1f376}',
  },
  {
    code1: '\u{1f377}',
    code2: '\\u{1f377}',
  },
  {
    code1: '\u{1f378}',
    code2: '\\u{1f378}',
  },
  {
    code1: '\u{1f379}',
    code2: '\\u{1f379}',
  },
  {
    code1: '\u{1f37a}',
    code2: '\\u{1f37a}',
  },
  {
    code1: '\u{1f37b}',
    code2: '\\u{1f37b}',
  },
  {
    code1: '\u{1f37c}',
    code2: '\\u{1f37c}',
  },
  {
    code1: '\u{1f37d}',
    code2: '\\u{1f37d}',
  },
  {
    code1: '\u{1f37e}',
    code2: '\\u{1f37e}',
  },
  {
    code1: '\u{1f37f}',
    code2: '\\u{1f37f}',
  },
  {
    code1: '\u{1f380}',
    code2: '\\u{1f380}',
  },
  {
    code1: '\u{1f381}',
    code2: '\\u{1f381}',
  },
  {
    code1: '\u{1f382}',
    code2: '\\u{1f382}',
  },
  {
    code1: '\u{1f383}',
    code2: '\\u{1f383}',
  },
  {
    code1: '\u{1f384}',
    code2: '\\u{1f384}',
  },
  {
    code1: '\u{1f385}',
    code2: '\\u{1f385}',
  },
  {
    code1: '\u{1f386}',
    code2: '\\u{1f386}',
  },
  {
    code1: '\u{1f387}',
    code2: '\\u{1f387}',
  },
  {
    code1: '\u{1f388}',
    code2: '\\u{1f388}',
  },
  {
    code1: '\u{1f389}',
    code2: '\\u{1f389}',
  },
  {
    code1: '\u{1f38a}',
    code2: '\\u{1f38a}',
  },
  {
    code1: '\u{1f38b}',
    code2: '\\u{1f38b}',
  },
  {
    code1: '\u{1f38c}',
    code2: '\\u{1f38c}',
  },
  {
    code1: '\u{1f38d}',
    code2: '\\u{1f38d}',
  },
  {
    code1: '\u{1f38e}',
    code2: '\\u{1f38e}',
  },
  {
    code1: '\u{1f38f}',
    code2: '\\u{1f38f}',
  },
  {
    code1: '\u{1f390}',
    code2: '\\u{1f390}',
  },
  {
    code1: '\u{1f391}',
    code2: '\\u{1f391}',
  },
  {
    code1: '\u{1f392}',
    code2: '\\u{1f392}',
  },
  {
    code1: '\u{1f393}',
    code2: '\\u{1f393}',
  },
  {
    code1: '\u{1f396}',
    code2: '\\u{1f396}',
  },
  {
    code1: '\u{1f397}',
    code2: '\\u{1f397}',
  },
  {
    code1: '\u{1f399}',
    code2: '\\u{1f399}',
  },
  {
    code1: '\u{1f39a}',
    code2: '\\u{1f39a}',
  },
  {
    code1: '\u{1f39b}',
    code2: '\\u{1f39b}',
  },
  {
    code1: '\u{1f39e}',
    code2: '\\u{1f39e}',
  },
  {
    code1: '\u{1f39f}',
    code2: '\\u{1f39f}',
  },
  {
    code1: '\u{1f3a0}',
    code2: '\\u{1f3a0}',
  },
  {
    code1: '\u{1f3a1}',
    code2: '\\u{1f3a1}',
  },
  {
    code1: '\u{1f3a2}',
    code2: '\\u{1f3a2}',
  },
  {
    code1: '\u{1f3a3}',
    code2: '\\u{1f3a3}',
  },
  {
    code1: '\u{1f3a4}',
    code2: '\\u{1f3a4}',
  },
  {
    code1: '\u{1f3a5}',
    code2: '\\u{1f3a5}',
  },
  {
    code1: '\u{1f3a6}',
    code2: '\\u{1f3a6}',
  },
  {
    code1: '\u{1f3a7}',
    code2: '\\u{1f3a7}',
  },
  {
    code1: '\u{1f3a8}',
    code2: '\\u{1f3a8}',
  },
  {
    code1: '\u{1f3a9}',
    code2: '\\u{1f3a9}',
  },
  {
    code1: '\u{1f3aa}',
    code2: '\\u{1f3aa}',
  },
  {
    code1: '\u{1f3ab}',
    code2: '\\u{1f3ab}',
  },
  {
    code1: '\u{1f3ac}',
    code2: '\\u{1f3ac}',
  },
  {
    code1: '\u{1f3ad}',
    code2: '\\u{1f3ad}',
  },
  {
    code1: '\u{1f3ae}',
    code2: '\\u{1f3ae}',
  },
  {
    code1: '\u{1f3af}',
    code2: '\\u{1f3af}',
  },
  {
    code1: '\u{1f3b0}',
    code2: '\\u{1f3b0}',
  },
  {
    code1: '\u{1f3b1}',
    code2: '\\u{1f3b1}',
  },
  {
    code1: '\u{1f3b2}',
    code2: '\\u{1f3b2}',
  },
  {
    code1: '\u{1f3b3}',
    code2: '\\u{1f3b3}',
  },
  {
    code1: '\u{1f3b4}',
    code2: '\\u{1f3b4}',
  },
  {
    code1: '\u{1f3b5}',
    code2: '\\u{1f3b5}',
  },
  {
    code1: '\u{1f3b6}',
    code2: '\\u{1f3b6}',
  },
  {
    code1: '\u{1f3b7}',
    code2: '\\u{1f3b7}',
  },
  {
    code1: '\u{1f3b8}',
    code2: '\\u{1f3b8}',
  },
  {
    code1: '\u{1f3b9}',
    code2: '\\u{1f3b9}',
  },
  {
    code1: '\u{1f3ba}',
    code2: '\\u{1f3ba}',
  },
  {
    code1: '\u{1f3bb}',
    code2: '\\u{1f3bb}',
  },
  {
    code1: '\u{1f3bc}',
    code2: '\\u{1f3bc}',
  },
  {
    code1: '\u{1f3bd}',
    code2: '\\u{1f3bd}',
  },
  {
    code1: '\u{1f3be}',
    code2: '\\u{1f3be}',
  },
  {
    code1: '\u{1f3bf}',
    code2: '\\u{1f3bf}',
  },
  {
    code1: '\u{1f3c0}',
    code2: '\\u{1f3c0}',
  },
  {
    code1: '\u{1f3c1}',
    code2: '\\u{1f3c1}',
  },
  {
    code1: '\u{1f3c2}',
    code2: '\\u{1f3c2}',
  },
  {
    code1: '\u{1f3c3}',
    code2: '\\u{1f3c3}',
  },
  {
    code1: '\u{1f3c4}',
    code2: '\\u{1f3c4}',
  },
  {
    code1: '\u{1f3c5}',
    code2: '\\u{1f3c5}',
  },
  {
    code1: '\u{1f3c6}',
    code2: '\\u{1f3c6}',
  },
  {
    code1: '\u{1f3c7}',
    code2: '\\u{1f3c7}',
  },
  {
    code1: '\u{1f3c8}',
    code2: '\\u{1f3c8}',
  },
  {
    code1: '\u{1f3c9}',
    code2: '\\u{1f3c9}',
  },
  {
    code1: '\u{1f3ca}',
    code2: '\\u{1f3ca}',
  },
  {
    code1: '\u{1f3cb}',
    code2: '\\u{1f3cb}',
  },
  {
    code1: '\u{1f3cc}',
    code2: '\\u{1f3cc}',
  },
  {
    code1: '\u{1f3cd}',
    code2: '\\u{1f3cd}',
  },
  {
    code1: '\u{1f3ce}',
    code2: '\\u{1f3ce}',
  },
  {
    code1: '\u{1f3cf}',
    code2: '\\u{1f3cf}',
  },
  {
    code1: '\u{1f3d0}',
    code2: '\\u{1f3d0}',
  },
  {
    code1: '\u{1f3d1}',
    code2: '\\u{1f3d1}',
  },
  {
    code1: '\u{1f3d2}',
    code2: '\\u{1f3d2}',
  },
  {
    code1: '\u{1f3d3}',
    code2: '\\u{1f3d3}',
  },
  {
    code1: '\u{1f3d4}',
    code2: '\\u{1f3d4}',
  },
  {
    code1: '\u{1f3d5}',
    code2: '\\u{1f3d5}',
  },
  {
    code1: '\u{1f3d6}',
    code2: '\\u{1f3d6}',
  },
  {
    code1: '\u{1f3d7}',
    code2: '\\u{1f3d7}',
  },
  {
    code1: '\u{1f3d8}',
    code2: '\\u{1f3d8}',
  },
  {
    code1: '\u{1f3d9}',
    code2: '\\u{1f3d9}',
  },
  {
    code1: '\u{1f3da}',
    code2: '\\u{1f3da}',
  },
  {
    code1: '\u{1f3db}',
    code2: '\\u{1f3db}',
  },
  {
    code1: '\u{1f3dc}',
    code2: '\\u{1f3dc}',
  },
  {
    code1: '\u{1f3dd}',
    code2: '\\u{1f3dd}',
  },
  {
    code1: '\u{1f3de}',
    code2: '\\u{1f3de}',
  },
  {
    code1: '\u{1f3df}',
    code2: '\\u{1f3df}',
  },
  {
    code1: '\u{1f3e0}',
    code2: '\\u{1f3e0}',
  },
  {
    code1: '\u{1f3e1}',
    code2: '\\u{1f3e1}',
  },
  {
    code1: '\u{1f3e2}',
    code2: '\\u{1f3e2}',
  },
  {
    code1: '\u{1f3e3}',
    code2: '\\u{1f3e3}',
  },
  {
    code1: '\u{1f3e4}',
    code2: '\\u{1f3e4}',
  },
  {
    code1: '\u{1f3e5}',
    code2: '\\u{1f3e5}',
  },
  {
    code1: '\u{1f3e6}',
    code2: '\\u{1f3e6}',
  },
  {
    code1: '\u{1f3e7}',
    code2: '\\u{1f3e7}',
  },
  {
    code1: '\u{1f3e8}',
    code2: '\\u{1f3e8}',
  },
  {
    code1: '\u{1f3e9}',
    code2: '\\u{1f3e9}',
  },
  {
    code1: '\u{1f3ea}',
    code2: '\\u{1f3ea}',
  },
  {
    code1: '\u{1f3eb}',
    code2: '\\u{1f3eb}',
  },
  {
    code1: '\u{1f3ec}',
    code2: '\\u{1f3ec}',
  },
  {
    code1: '\u{1f3ed}',
    code2: '\\u{1f3ed}',
  },
  {
    code1: '\u{1f3ee}',
    code2: '\\u{1f3ee}',
  },
  {
    code1: '\u{1f3ef}',
    code2: '\\u{1f3ef}',
  },
  {
    code1: '\u{1f3f0}',
    code2: '\\u{1f3f0}',
  },
  {
    code1: '\u{1f3f3}',
    code2: '\\u{1f3f3}',
  },
  {
    code1: '\u{1f3f4}',
    code2: '\\u{1f3f4}',
  },
  {
    code1: '\u{1f3f5}',
    code2: '\\u{1f3f5}',
  },
  {
    code1: '\u{1f3f7}',
    code2: '\\u{1f3f7}',
  },
  {
    code1: '\u{1f3f8}',
    code2: '\\u{1f3f8}',
  },
  {
    code1: '\u{1f3f9}',
    code2: '\\u{1f3f9}',
  },
  {
    code1: '\u{1f3fa}',
    code2: '\\u{1f3fa}',
  },
  {
    code1: '\u{1f400}',
    code2: '\\u{1f400}',
  },
  {
    code1: '\u{1f401}',
    code2: '\\u{1f401}',
  },
  {
    code1: '\u{1f402}',
    code2: '\\u{1f402}',
  },
  {
    code1: '\u{1f403}',
    code2: '\\u{1f403}',
  },
  {
    code1: '\u{1f404}',
    code2: '\\u{1f404}',
  },
  {
    code1: '\u{1f405}',
    code2: '\\u{1f405}',
  },
  {
    code1: '\u{1f406}',
    code2: '\\u{1f406}',
  },
  {
    code1: '\u{1f407}',
    code2: '\\u{1f407}',
  },
  {
    code1: '\u{1f408}',
    code2: '\\u{1f408}',
  },
  {
    code1: '\u{1f409}',
    code2: '\\u{1f409}',
  },
  {
    code1: '\u{1f40a}',
    code2: '\\u{1f40a}',
  },
  {
    code1: '\u{1f40b}',
    code2: '\\u{1f40b}',
  },
  {
    code1: '\u{1f40c}',
    code2: '\\u{1f40c}',
  },
  {
    code1: '\u{1f40d}',
    code2: '\\u{1f40d}',
  },
  {
    code1: '\u{1f40e}',
    code2: '\\u{1f40e}',
  },
  {
    code1: '\u{1f40f}',
    code2: '\\u{1f40f}',
  },
  {
    code1: '\u{1f410}',
    code2: '\\u{1f410}',
  },
  {
    code1: '\u{1f411}',
    code2: '\\u{1f411}',
  },
  {
    code1: '\u{1f412}',
    code2: '\\u{1f412}',
  },
  {
    code1: '\u{1f413}',
    code2: '\\u{1f413}',
  },
  {
    code1: '\u{1f414}',
    code2: '\\u{1f414}',
  },
  {
    code1: '\u{1f415}',
    code2: '\\u{1f415}',
  },
  {
    code1: '\u{1f416}',
    code2: '\\u{1f416}',
  },
  {
    code1: '\u{1f417}',
    code2: '\\u{1f417}',
  },
  {
    code1: '\u{1f418}',
    code2: '\\u{1f418}',
  },
  {
    code1: '\u{1f419}',
    code2: '\\u{1f419}',
  },
  {
    code1: '\u{1f41a}',
    code2: '\\u{1f41a}',
  },
  {
    code1: '\u{1f41b}',
    code2: '\\u{1f41b}',
  },
  {
    code1: '\u{1f41c}',
    code2: '\\u{1f41c}',
  },
  {
    code1: '\u{1f41d}',
    code2: '\\u{1f41d}',
  },
  {
    code1: '\u{1f41e}',
    code2: '\\u{1f41e}',
  },
  {
    code1: '\u{1f41f}',
    code2: '\\u{1f41f}',
  },
  {
    code1: '\u{1f420}',
    code2: '\\u{1f420}',
  },
  {
    code1: '\u{1f421}',
    code2: '\\u{1f421}',
  },
  {
    code1: '\u{1f422}',
    code2: '\\u{1f422}',
  },
  {
    code1: '\u{1f423}',
    code2: '\\u{1f423}',
  },
  {
    code1: '\u{1f424}',
    code2: '\\u{1f424}',
  },
  {
    code1: '\u{1f425}',
    code2: '\\u{1f425}',
  },
  {
    code1: '\u{1f426}',
    code2: '\\u{1f426}',
  },
  {
    code1: '\u{1f427}',
    code2: '\\u{1f427}',
  },
  {
    code1: '\u{1f428}',
    code2: '\\u{1f428}',
  },
  {
    code1: '\u{1f429}',
    code2: '\\u{1f429}',
  },
  {
    code1: '\u{1f42a}',
    code2: '\\u{1f42a}',
  },
  {
    code1: '\u{1f42b}',
    code2: '\\u{1f42b}',
  },
  {
    code1: '\u{1f42c}',
    code2: '\\u{1f42c}',
  },
  {
    code1: '\u{1f42d}',
    code2: '\\u{1f42d}',
  },
  {
    code1: '\u{1f42e}',
    code2: '\\u{1f42e}',
  },
  {
    code1: '\u{1f42f}',
    code2: '\\u{1f42f}',
  },
  {
    code1: '\u{1f430}',
    code2: '\\u{1f430}',
  },
  {
    code1: '\u{1f431}',
    code2: '\\u{1f431}',
  },
  {
    code1: '\u{1f432}',
    code2: '\\u{1f432}',
  },
  {
    code1: '\u{1f433}',
    code2: '\\u{1f433}',
  },
  {
    code1: '\u{1f434}',
    code2: '\\u{1f434}',
  },
  {
    code1: '\u{1f435}',
    code2: '\\u{1f435}',
  },
  {
    code1: '\u{1f436}',
    code2: '\\u{1f436}',
  },
  {
    code1: '\u{1f437}',
    code2: '\\u{1f437}',
  },
  {
    code1: '\u{1f438}',
    code2: '\\u{1f438}',
  },
  {
    code1: '\u{1f439}',
    code2: '\\u{1f439}',
  },
  {
    code1: '\u{1f43a}',
    code2: '\\u{1f43a}',
  },
  {
    code1: '\u{1f43b}',
    code2: '\\u{1f43b}',
  },
  {
    code1: '\u{1f43c}',
    code2: '\\u{1f43c}',
  },
  {
    code1: '\u{1f43d}',
    code2: '\\u{1f43d}',
  },
  {
    code1: '\u{1f43e}',
    code2: '\\u{1f43e}',
  },
  {
    code1: '\u{1f43f}',
    code2: '\\u{1f43f}',
  },
  {
    code1: '\u{1f440}',
    code2: '\\u{1f440}',
  },
  {
    code1: '\u{1f441}',
    code2: '\\u{1f441}',
  },
  {
    code1: '\u{1f442}',
    code2: '\\u{1f442}',
  },
  {
    code1: '\u{1f443}',
    code2: '\\u{1f443}',
  },
  {
    code1: '\u{1f444}',
    code2: '\\u{1f444}',
  },
  {
    code1: '\u{1f445}',
    code2: '\\u{1f445}',
  },
  {
    code1: '\u{1f446}',
    code2: '\\u{1f446}',
  },
  {
    code1: '\u{1f447}',
    code2: '\\u{1f447}',
  },
  {
    code1: '\u{1f448}',
    code2: '\\u{1f448}',
  },
  {
    code1: '\u{1f449}',
    code2: '\\u{1f449}',
  },
  {
    code1: '\u{1f44a}',
    code2: '\\u{1f44a}',
  },
  {
    code1: '\u{1f44b}',
    code2: '\\u{1f44b}',
  },
  {
    code1: '\u{1f44c}',
    code2: '\\u{1f44c}',
  },
  {
    code1: '\u{1f44d}',
    code2: '\\u{1f44d}',
  },
  {
    code1: '\u{1f44e}',
    code2: '\\u{1f44e}',
  },
  {
    code1: '\u{1f44f}',
    code2: '\\u{1f44f}',
  },
  {
    code1: '\u{1f450}',
    code2: '\\u{1f450}',
  },
  {
    code1: '\u{1f451}',
    code2: '\\u{1f451}',
  },
  {
    code1: '\u{1f452}',
    code2: '\\u{1f452}',
  },
  {
    code1: '\u{1f453}',
    code2: '\\u{1f453}',
  },
  {
    code1: '\u{1f454}',
    code2: '\\u{1f454}',
  },
  {
    code1: '\u{1f455}',
    code2: '\\u{1f455}',
  },
  {
    code1: '\u{1f456}',
    code2: '\\u{1f456}',
  },
  {
    code1: '\u{1f457}',
    code2: '\\u{1f457}',
  },
  {
    code1: '\u{1f458}',
    code2: '\\u{1f458}',
  },
  {
    code1: '\u{1f459}',
    code2: '\\u{1f459}',
  },
  {
    code1: '\u{1f45a}',
    code2: '\\u{1f45a}',
  },
  {
    code1: '\u{1f45b}',
    code2: '\\u{1f45b}',
  },
  {
    code1: '\u{1f45c}',
    code2: '\\u{1f45c}',
  },
  {
    code1: '\u{1f45d}',
    code2: '\\u{1f45d}',
  },
  {
    code1: '\u{1f45e}',
    code2: '\\u{1f45e}',
  },
  {
    code1: '\u{1f45f}',
    code2: '\\u{1f45f}',
  },
  {
    code1: '\u{1f460}',
    code2: '\\u{1f460}',
  },
  {
    code1: '\u{1f461}',
    code2: '\\u{1f461}',
  },
  {
    code1: '\u{1f462}',
    code2: '\\u{1f462}',
  },
  {
    code1: '\u{1f463}',
    code2: '\\u{1f463}',
  },
  {
    code1: '\u{1f464}',
    code2: '\\u{1f464}',
  },
  {
    code1: '\u{1f465}',
    code2: '\\u{1f465}',
  },
  {
    code1: '\u{1f466}',
    code2: '\\u{1f466}',
  },
  {
    code1: '\u{1f467}',
    code2: '\\u{1f467}',
  },
  {
    code1: '\u{1f468}',
    code2: '\\u{1f468}',
  },
  {
    code1: '\u{1f469}',
    code2: '\\u{1f469}',
  },
  {
    code1: '\u{1f46a}',
    code2: '\\u{1f46a}',
  },
  {
    code1: '\u{1f46b}',
    code2: '\\u{1f46b}',
  },
  {
    code1: '\u{1f46c}',
    code2: '\\u{1f46c}',
  },
  {
    code1: '\u{1f46d}',
    code2: '\\u{1f46d}',
  },
  {
    code1: '\u{1f46e}',
    code2: '\\u{1f46e}',
  },
  {
    code1: '\u{1f46f}',
    code2: '\\u{1f46f}',
  },
  {
    code1: '\u{1f470}',
    code2: '\\u{1f470}',
  },
  {
    code1: '\u{1f471}',
    code2: '\\u{1f471}',
  },
  {
    code1: '\u{1f472}',
    code2: '\\u{1f472}',
  },
  {
    code1: '\u{1f473}',
    code2: '\\u{1f473}',
  },
  {
    code1: '\u{1f474}',
    code2: '\\u{1f474}',
  },
  {
    code1: '\u{1f475}',
    code2: '\\u{1f475}',
  },
  {
    code1: '\u{1f476}',
    code2: '\\u{1f476}',
  },
  {
    code1: '\u{1f477}',
    code2: '\\u{1f477}',
  },
  {
    code1: '\u{1f478}',
    code2: '\\u{1f478}',
  },
  {
    code1: '\u{1f479}',
    code2: '\\u{1f479}',
  },
  {
    code1: '\u{1f47a}',
    code2: '\\u{1f47a}',
  },
  {
    code1: '\u{1f47b}',
    code2: '\\u{1f47b}',
  },
  {
    code1: '\u{1f47c}',
    code2: '\\u{1f47c}',
  },
  {
    code1: '\u{1f47d}',
    code2: '\\u{1f47d}',
  },
  {
    code1: '\u{1f47e}',
    code2: '\\u{1f47e}',
  },
  {
    code1: '\u{1f47f}',
    code2: '\\u{1f47f}',
  },
  {
    code1: '\u{1f480}',
    code2: '\\u{1f480}',
  },
  {
    code1: '\u{1f481}',
    code2: '\\u{1f481}',
  },
  {
    code1: '\u{1f482}',
    code2: '\\u{1f482}',
  },
  {
    code1: '\u{1f483}',
    code2: '\\u{1f483}',
  },
  {
    code1: '\u{1f484}',
    code2: '\\u{1f484}',
  },
  {
    code1: '\u{1f485}',
    code2: '\\u{1f485}',
  },
  {
    code1: '\u{1f486}',
    code2: '\\u{1f486}',
  },
  {
    code1: '\u{1f487}',
    code2: '\\u{1f487}',
  },
  {
    code1: '\u{1f488}',
    code2: '\\u{1f488}',
  },
  {
    code1: '\u{1f489}',
    code2: '\\u{1f489}',
  },
  {
    code1: '\u{1f48a}',
    code2: '\\u{1f48a}',
  },
  {
    code1: '\u{1f48b}',
    code2: '\\u{1f48b}',
  },
  {
    code1: '\u{1f48c}',
    code2: '\\u{1f48c}',
  },
  {
    code1: '\u{1f48d}',
    code2: '\\u{1f48d}',
  },
  {
    code1: '\u{1f48e}',
    code2: '\\u{1f48e}',
  },
  {
    code1: '\u{1f48f}',
    code2: '\\u{1f48f}',
  },
  {
    code1: '\u{1f490}',
    code2: '\\u{1f490}',
  },
  {
    code1: '\u{1f491}',
    code2: '\\u{1f491}',
  },
  {
    code1: '\u{1f492}',
    code2: '\\u{1f492}',
  },
  {
    code1: '\u{1f493}',
    code2: '\\u{1f493}',
  },
  {
    code1: '\u{1f494}',
    code2: '\\u{1f494}',
  },
  {
    code1: '\u{1f495}',
    code2: '\\u{1f495}',
  },
  {
    code1: '\u{1f496}',
    code2: '\\u{1f496}',
  },
  {
    code1: '\u{1f497}',
    code2: '\\u{1f497}',
  },
  {
    code1: '\u{1f498}',
    code2: '\\u{1f498}',
  },
  {
    code1: '\u{1f499}',
    code2: '\\u{1f499}',
  },
  {
    code1: '\u{1f49a}',
    code2: '\\u{1f49a}',
  },
  {
    code1: '\u{1f49b}',
    code2: '\\u{1f49b}',
  },
  {
    code1: '\u{1f49c}',
    code2: '\\u{1f49c}',
  },
  {
    code1: '\u{1f49d}',
    code2: '\\u{1f49d}',
  },
  {
    code1: '\u{1f49e}',
    code2: '\\u{1f49e}',
  },
  {
    code1: '\u{1f49f}',
    code2: '\\u{1f49f}',
  },
  {
    code1: '\u{1f4a0}',
    code2: '\\u{1f4a0}',
  },
  {
    code1: '\u{1f4a1}',
    code2: '\\u{1f4a1}',
  },
  {
    code1: '\u{1f4a2}',
    code2: '\\u{1f4a2}',
  },
  {
    code1: '\u{1f4a3}',
    code2: '\\u{1f4a3}',
  },
  {
    code1: '\u{1f4a4}',
    code2: '\\u{1f4a4}',
  },
  {
    code1: '\u{1f4a5}',
    code2: '\\u{1f4a5}',
  },
  {
    code1: '\u{1f4a6}',
    code2: '\\u{1f4a6}',
  },
  {
    code1: '\u{1f4a7}',
    code2: '\\u{1f4a7}',
  },
  {
    code1: '\u{1f4a8}',
    code2: '\\u{1f4a8}',
  },
  {
    code1: '\u{1f4a9}',
    code2: '\\u{1f4a9}',
  },
  {
    code1: '\u{1f4aa}',
    code2: '\\u{1f4aa}',
  },
  {
    code1: '\u{1f4ab}',
    code2: '\\u{1f4ab}',
  },
  {
    code1: '\u{1f4ac}',
    code2: '\\u{1f4ac}',
  },
  {
    code1: '\u{1f4ad}',
    code2: '\\u{1f4ad}',
  },
  {
    code1: '\u{1f4ae}',
    code2: '\\u{1f4ae}',
  },
  {
    code1: '\u{1f4af}',
    code2: '\\u{1f4af}',
  },
  {
    code1: '\u{1f4b0}',
    code2: '\\u{1f4b0}',
  },
  {
    code1: '\u{1f4b1}',
    code2: '\\u{1f4b1}',
  },
  {
    code1: '\u{1f4b2}',
    code2: '\\u{1f4b2}',
  },
  {
    code1: '\u{1f4b3}',
    code2: '\\u{1f4b3}',
  },
  {
    code1: '\u{1f4b4}',
    code2: '\\u{1f4b4}',
  },
  {
    code1: '\u{1f4b5}',
    code2: '\\u{1f4b5}',
  },
  {
    code1: '\u{1f4b6}',
    code2: '\\u{1f4b6}',
  },
  {
    code1: '\u{1f4b7}',
    code2: '\\u{1f4b7}',
  },
  {
    code1: '\u{1f4b8}',
    code2: '\\u{1f4b8}',
  },
  {
    code1: '\u{1f4b9}',
    code2: '\\u{1f4b9}',
  },
  {
    code1: '\u{1f4ba}',
    code2: '\\u{1f4ba}',
  },
  {
    code1: '\u{1f4bb}',
    code2: '\\u{1f4bb}',
  },
  {
    code1: '\u{1f4bc}',
    code2: '\\u{1f4bc}',
  },
  {
    code1: '\u{1f4bd}',
    code2: '\\u{1f4bd}',
  },
  {
    code1: '\u{1f4be}',
    code2: '\\u{1f4be}',
  },
  {
    code1: '\u{1f4bf}',
    code2: '\\u{1f4bf}',
  },
  {
    code1: '\u{1f4c0}',
    code2: '\\u{1f4c0}',
  },
  {
    code1: '\u{1f4c1}',
    code2: '\\u{1f4c1}',
  },
  {
    code1: '\u{1f4c2}',
    code2: '\\u{1f4c2}',
  },
  {
    code1: '\u{1f4c3}',
    code2: '\\u{1f4c3}',
  },
  {
    code1: '\u{1f4c4}',
    code2: '\\u{1f4c4}',
  },
  {
    code1: '\u{1f4c5}',
    code2: '\\u{1f4c5}',
  },
  {
    code1: '\u{1f4c6}',
    code2: '\\u{1f4c6}',
  },
  {
    code1: '\u{1f4c7}',
    code2: '\\u{1f4c7}',
  },
  {
    code1: '\u{1f4c8}',
    code2: '\\u{1f4c8}',
  },
  {
    code1: '\u{1f4c9}',
    code2: '\\u{1f4c9}',
  },
  {
    code1: '\u{1f4ca}',
    code2: '\\u{1f4ca}',
  },
  {
    code1: '\u{1f4cb}',
    code2: '\\u{1f4cb}',
  },
  {
    code1: '\u{1f4cc}',
    code2: '\\u{1f4cc}',
  },
  {
    code1: '\u{1f4cd}',
    code2: '\\u{1f4cd}',
  },
  {
    code1: '\u{1f4ce}',
    code2: '\\u{1f4ce}',
  },
  {
    code1: '\u{1f4cf}',
    code2: '\\u{1f4cf}',
  },
  {
    code1: '\u{1f4d0}',
    code2: '\\u{1f4d0}',
  },
  {
    code1: '\u{1f4d1}',
    code2: '\\u{1f4d1}',
  },
  {
    code1: '\u{1f4d2}',
    code2: '\\u{1f4d2}',
  },
  {
    code1: '\u{1f4d3}',
    code2: '\\u{1f4d3}',
  },
  {
    code1: '\u{1f4d4}',
    code2: '\\u{1f4d4}',
  },
  {
    code1: '\u{1f4d5}',
    code2: '\\u{1f4d5}',
  },
  {
    code1: '\u{1f4d6}',
    code2: '\\u{1f4d6}',
  },
  {
    code1: '\u{1f4d7}',
    code2: '\\u{1f4d7}',
  },
  {
    code1: '\u{1f4d8}',
    code2: '\\u{1f4d8}',
  },
  {
    code1: '\u{1f4d9}',
    code2: '\\u{1f4d9}',
  },
  {
    code1: '\u{1f4da}',
    code2: '\\u{1f4da}',
  },
  {
    code1: '\u{1f4db}',
    code2: '\\u{1f4db}',
  },
  {
    code1: '\u{1f4dc}',
    code2: '\\u{1f4dc}',
  },
  {
    code1: '\u{1f4dd}',
    code2: '\\u{1f4dd}',
  },
  {
    code1: '\u{1f4de}',
    code2: '\\u{1f4de}',
  },
  {
    code1: '\u{1f4df}',
    code2: '\\u{1f4df}',
  },
  {
    code1: '\u{1f4e0}',
    code2: '\\u{1f4e0}',
  },
  {
    code1: '\u{1f4e1}',
    code2: '\\u{1f4e1}',
  },
  {
    code1: '\u{1f4e2}',
    code2: '\\u{1f4e2}',
  },
  {
    code1: '\u{1f4e3}',
    code2: '\\u{1f4e3}',
  },
  {
    code1: '\u{1f4e4}',
    code2: '\\u{1f4e4}',
  },
  {
    code1: '\u{1f4e5}',
    code2: '\\u{1f4e5}',
  },
  {
    code1: '\u{1f4e6}',
    code2: '\\u{1f4e6}',
  },
  {
    code1: '\u{1f4e7}',
    code2: '\\u{1f4e7}',
  },
  {
    code1: '\u{1f4e8}',
    code2: '\\u{1f4e8}',
  },
  {
    code1: '\u{1f4e9}',
    code2: '\\u{1f4e9}',
  },
  {
    code1: '\u{1f4ea}',
    code2: '\\u{1f4ea}',
  },
  {
    code1: '\u{1f4eb}',
    code2: '\\u{1f4eb}',
  },
  {
    code1: '\u{1f4ec}',
    code2: '\\u{1f4ec}',
  },
  {
    code1: '\u{1f4ed}',
    code2: '\\u{1f4ed}',
  },
  {
    code1: '\u{1f4ee}',
    code2: '\\u{1f4ee}',
  },
  {
    code1: '\u{1f4ef}',
    code2: '\\u{1f4ef}',
  },
  {
    code1: '\u{1f4f0}',
    code2: '\\u{1f4f0}',
  },
  {
    code1: '\u{1f4f1}',
    code2: '\\u{1f4f1}',
  },
  {
    code1: '\u{1f4f2}',
    code2: '\\u{1f4f2}',
  },
  {
    code1: '\u{1f4f3}',
    code2: '\\u{1f4f3}',
  },
  {
    code1: '\u{1f4f4}',
    code2: '\\u{1f4f4}',
  },
  {
    code1: '\u{1f4f5}',
    code2: '\\u{1f4f5}',
  },
  {
    code1: '\u{1f4f6}',
    code2: '\\u{1f4f6}',
  },
  {
    code1: '\u{1f4f7}',
    code2: '\\u{1f4f7}',
  },
  {
    code1: '\u{1f4f8}',
    code2: '\\u{1f4f8}',
  },
  {
    code1: '\u{1f4f9}',
    code2: '\\u{1f4f9}',
  },
  {
    code1: '\u{1f4fa}',
    code2: '\\u{1f4fa}',
  },
  {
    code1: '\u{1f4fb}',
    code2: '\\u{1f4fb}',
  },
  {
    code1: '\u{1f4fc}',
    code2: '\\u{1f4fc}',
  },
  {
    code1: '\u{1f4fd}',
    code2: '\\u{1f4fd}',
  },
  {
    code1: '\u{1f4ff}',
    code2: '\\u{1f4ff}',
  },
  {
    code1: '\u{1f500}',
    code2: '\\u{1f500}',
  },
  {
    code1: '\u{1f501}',
    code2: '\\u{1f501}',
  },
  {
    code1: '\u{1f502}',
    code2: '\\u{1f502}',
  },
  {
    code1: '\u{1f503}',
    code2: '\\u{1f503}',
  },
  {
    code1: '\u{1f504}',
    code2: '\\u{1f504}',
  },
  {
    code1: '\u{1f505}',
    code2: '\\u{1f505}',
  },
  {
    code1: '\u{1f506}',
    code2: '\\u{1f506}',
  },
  {
    code1: '\u{1f507}',
    code2: '\\u{1f507}',
  },
  {
    code1: '\u{1f508}',
    code2: '\\u{1f508}',
  },
  {
    code1: '\u{1f509}',
    code2: '\\u{1f509}',
  },
  {
    code1: '\u{1f50a}',
    code2: '\\u{1f50a}',
  },
  {
    code1: '\u{1f50b}',
    code2: '\\u{1f50b}',
  },
  {
    code1: '\u{1f50c}',
    code2: '\\u{1f50c}',
  },
  {
    code1: '\u{1f50d}',
    code2: '\\u{1f50d}',
  },
  {
    code1: '\u{1f50e}',
    code2: '\\u{1f50e}',
  },
  {
    code1: '\u{1f50f}',
    code2: '\\u{1f50f}',
  },
  {
    code1: '\u{1f510}',
    code2: '\\u{1f510}',
  },
  {
    code1: '\u{1f511}',
    code2: '\\u{1f511}',
  },
  {
    code1: '\u{1f512}',
    code2: '\\u{1f512}',
  },
  {
    code1: '\u{1f513}',
    code2: '\\u{1f513}',
  },
  {
    code1: '\u{1f514}',
    code2: '\\u{1f514}',
  },
  {
    code1: '\u{1f515}',
    code2: '\\u{1f515}',
  },
  {
    code1: '\u{1f516}',
    code2: '\\u{1f516}',
  },
  {
    code1: '\u{1f517}',
    code2: '\\u{1f517}',
  },
  {
    code1: '\u{1f518}',
    code2: '\\u{1f518}',
  },
  {
    code1: '\u{1f519}',
    code2: '\\u{1f519}',
  },
  {
    code1: '\u{1f51a}',
    code2: '\\u{1f51a}',
  },
  {
    code1: '\u{1f51b}',
    code2: '\\u{1f51b}',
  },
  {
    code1: '\u{1f51c}',
    code2: '\\u{1f51c}',
  },
  {
    code1: '\u{1f51d}',
    code2: '\\u{1f51d}',
  },
  {
    code1: '\u{1f51e}',
    code2: '\\u{1f51e}',
  },
  {
    code1: '\u{1f51f}',
    code2: '\\u{1f51f}',
  },
  {
    code1: '\u{1f520}',
    code2: '\\u{1f520}',
  },
  {
    code1: '\u{1f521}',
    code2: '\\u{1f521}',
  },
  {
    code1: '\u{1f522}',
    code2: '\\u{1f522}',
  },
  {
    code1: '\u{1f523}',
    code2: '\\u{1f523}',
  },
  {
    code1: '\u{1f524}',
    code2: '\\u{1f524}',
  },
  {
    code1: '\u{1f525}',
    code2: '\\u{1f525}',
  },
  {
    code1: '\u{1f526}',
    code2: '\\u{1f526}',
  },
  {
    code1: '\u{1f527}',
    code2: '\\u{1f527}',
  },
  {
    code1: '\u{1f528}',
    code2: '\\u{1f528}',
  },
  {
    code1: '\u{1f529}',
    code2: '\\u{1f529}',
  },
  {
    code1: '\u{1f52a}',
    code2: '\\u{1f52a}',
  },
  {
    code1: '\u{1f52b}',
    code2: '\\u{1f52b}',
  },
  {
    code1: '\u{1f52c}',
    code2: '\\u{1f52c}',
  },
  {
    code1: '\u{1f52d}',
    code2: '\\u{1f52d}',
  },
  {
    code1: '\u{1f52e}',
    code2: '\\u{1f52e}',
  },
  {
    code1: '\u{1f52f}',
    code2: '\\u{1f52f}',
  },
  {
    code1: '\u{1f530}',
    code2: '\\u{1f530}',
  },
  {
    code1: '\u{1f531}',
    code2: '\\u{1f531}',
  },
  {
    code1: '\u{1f532}',
    code2: '\\u{1f532}',
  },
  {
    code1: '\u{1f533}',
    code2: '\\u{1f533}',
  },
  {
    code1: '\u{1f534}',
    code2: '\\u{1f534}',
  },
  {
    code1: '\u{1f535}',
    code2: '\\u{1f535}',
  },
  {
    code1: '\u{1f536}',
    code2: '\\u{1f536}',
  },
  {
    code1: '\u{1f537}',
    code2: '\\u{1f537}',
  },
  {
    code1: '\u{1f538}',
    code2: '\\u{1f538}',
  },
  {
    code1: '\u{1f539}',
    code2: '\\u{1f539}',
  },
  {
    code1: '\u{1f53a}',
    code2: '\\u{1f53a}',
  },
  {
    code1: '\u{1f53b}',
    code2: '\\u{1f53b}',
  },
  {
    code1: '\u{1f53c}',
    code2: '\\u{1f53c}',
  },
  {
    code1: '\u{1f53d}',
    code2: '\\u{1f53d}',
  },
  {
    code1: '\u{1f549}',
    code2: '\\u{1f549}',
  },
  {
    code1: '\u{1f54a}',
    code2: '\\u{1f54a}',
  },
  {
    code1: '\u{1f54b}',
    code2: '\\u{1f54b}',
  },
  {
    code1: '\u{1f54c}',
    code2: '\\u{1f54c}',
  },
  {
    code1: '\u{1f54d}',
    code2: '\\u{1f54d}',
  },
  {
    code1: '\u{1f54e}',
    code2: '\\u{1f54e}',
  },
  {
    code1: '\u{1f550}',
    code2: '\\u{1f550}',
  },
  {
    code1: '\u{1f551}',
    code2: '\\u{1f551}',
  },
  {
    code1: '\u{1f552}',
    code2: '\\u{1f552}',
  },
  {
    code1: '\u{1f553}',
    code2: '\\u{1f553}',
  },
  {
    code1: '\u{1f554}',
    code2: '\\u{1f554}',
  },
  {
    code1: '\u{1f555}',
    code2: '\\u{1f555}',
  },
  {
    code1: '\u{1f556}',
    code2: '\\u{1f556}',
  },
  {
    code1: '\u{1f557}',
    code2: '\\u{1f557}',
  },
  {
    code1: '\u{1f558}',
    code2: '\\u{1f558}',
  },
  {
    code1: '\u{1f559}',
    code2: '\\u{1f559}',
  },
  {
    code1: '\u{1f55a}',
    code2: '\\u{1f55a}',
  },
  {
    code1: '\u{1f55b}',
    code2: '\\u{1f55b}',
  },
  {
    code1: '\u{1f55c}',
    code2: '\\u{1f55c}',
  },
  {
    code1: '\u{1f55d}',
    code2: '\\u{1f55d}',
  },
  {
    code1: '\u{1f55e}',
    code2: '\\u{1f55e}',
  },
  {
    code1: '\u{1f55f}',
    code2: '\\u{1f55f}',
  },
  {
    code1: '\u{1f560}',
    code2: '\\u{1f560}',
  },
  {
    code1: '\u{1f561}',
    code2: '\\u{1f561}',
  },
  {
    code1: '\u{1f562}',
    code2: '\\u{1f562}',
  },
  {
    code1: '\u{1f563}',
    code2: '\\u{1f563}',
  },
  {
    code1: '\u{1f564}',
    code2: '\\u{1f564}',
  },
  {
    code1: '\u{1f565}',
    code2: '\\u{1f565}',
  },
  {
    code1: '\u{1f566}',
    code2: '\\u{1f566}',
  },
  {
    code1: '\u{1f567}',
    code2: '\\u{1f567}',
  },
  {
    code1: '\u{1f56f}',
    code2: '\\u{1f56f}',
  },
  {
    code1: '\u{1f570}',
    code2: '\\u{1f570}',
  },
  {
    code1: '\u{1f573}',
    code2: '\\u{1f573}',
  },
  {
    code1: '\u{1f574}',
    code2: '\\u{1f574}',
  },
  {
    code1: '\u{1f575}',
    code2: '\\u{1f575}',
  },
  {
    code1: '\u{1f576}',
    code2: '\\u{1f576}',
  },
  {
    code1: '\u{1f577}',
    code2: '\\u{1f577}',
  },
  {
    code1: '\u{1f578}',
    code2: '\\u{1f578}',
  },
  {
    code1: '\u{1f579}',
    code2: '\\u{1f579}',
  },
  {
    code1: '\u{1f57a}',
    code2: '\\u{1f57a}',
  },
  {
    code1: '\u{1f587}',
    code2: '\\u{1f587}',
  },
  {
    code1: '\u{1f58a}',
    code2: '\\u{1f58a}',
  },
  {
    code1: '\u{1f58b}',
    code2: '\\u{1f58b}',
  },
  {
    code1: '\u{1f58c}',
    code2: '\\u{1f58c}',
  },
  {
    code1: '\u{1f58d}',
    code2: '\\u{1f58d}',
  },
  {
    code1: '\u{1f590}',
    code2: '\\u{1f590}',
  },
  {
    code1: '\u{1f595}',
    code2: '\\u{1f595}',
  },
  {
    code1: '\u{1f596}',
    code2: '\\u{1f596}',
  },
  {
    code1: '\u{1f5a4}',
    code2: '\\u{1f5a4}',
  },
  {
    code1: '\u{1f5a5}',
    code2: '\\u{1f5a5}',
  },
  {
    code1: '\u{1f5a8}',
    code2: '\\u{1f5a8}',
  },
  {
    code1: '\u{1f5b1}',
    code2: '\\u{1f5b1}',
  },
  {
    code1: '\u{1f5b2}',
    code2: '\\u{1f5b2}',
  },
  {
    code1: '\u{1f5bc}',
    code2: '\\u{1f5bc}',
  },
  {
    code1: '\u{1f5c2}',
    code2: '\\u{1f5c2}',
  },
  {
    code1: '\u{1f5c3}',
    code2: '\\u{1f5c3}',
  },
  {
    code1: '\u{1f5c4}',
    code2: '\\u{1f5c4}',
  },
  {
    code1: '\u{1f5d1}',
    code2: '\\u{1f5d1}',
  },
  {
    code1: '\u{1f5d2}',
    code2: '\\u{1f5d2}',
  },
  {
    code1: '\u{1f5d3}',
    code2: '\\u{1f5d3}',
  },
  {
    code1: '\u{1f5dc}',
    code2: '\\u{1f5dc}',
  },
  {
    code1: '\u{1f5dd}',
    code2: '\\u{1f5dd}',
  },
  {
    code1: '\u{1f5de}',
    code2: '\\u{1f5de}',
  },
  {
    code1: '\u{1f5e1}',
    code2: '\\u{1f5e1}',
  },
  {
    code1: '\u{1f5e3}',
    code2: '\\u{1f5e3}',
  },
  {
    code1: '\u{1f5e8}',
    code2: '\\u{1f5e8}',
  },
  {
    code1: '\u{1f5ef}',
    code2: '\\u{1f5ef}',
  },
  {
    code1: '\u{1f5f3}',
    code2: '\\u{1f5f3}',
  },
  {
    code1: '\u{1f5fa}',
    code2: '\\u{1f5fa}',
  },
  {
    code1: '\u{1f5fb}',
    code2: '\\u{1f5fb}',
  },
  {
    code1: '\u{1f5fc}',
    code2: '\\u{1f5fc}',
  },
  {
    code1: '\u{1f5fd}',
    code2: '\\u{1f5fd}',
  },
  {
    code1: '\u{1f5fe}',
    code2: '\\u{1f5fe}',
  },
  {
    code1: '\u{1f5ff}',
    code2: '\\u{1f5ff}',
  },
  {
    code1: '\u{1f600}',
    code2: '\\u{1f600}',
  },
  {
    code1: '\u{1f601}',
    code2: '\\u{1f601}',
  },
  {
    code1: '\u{1f602}',
    code2: '\\u{1f602}',
  },
  {
    code1: '\u{1f603}',
    code2: '\\u{1f603}',
  },
  {
    code1: '\u{1f604}',
    code2: '\\u{1f604}',
  },
  {
    code1: '\u{1f605}',
    code2: '\\u{1f605}',
  },
  {
    code1: '\u{1f606}',
    code2: '\\u{1f606}',
  },
  {
    code1: '\u{1f607}',
    code2: '\\u{1f607}',
  },
  {
    code1: '\u{1f608}',
    code2: '\\u{1f608}',
  },
  {
    code1: '\u{1f609}',
    code2: '\\u{1f609}',
  },
  {
    code1: '\u{1f60a}',
    code2: '\\u{1f60a}',
  },
  {
    code1: '\u{1f60b}',
    code2: '\\u{1f60b}',
  },
  {
    code1: '\u{1f60c}',
    code2: '\\u{1f60c}',
  },
  {
    code1: '\u{1f60d}',
    code2: '\\u{1f60d}',
  },
  {
    code1: '\u{1f60e}',
    code2: '\\u{1f60e}',
  },
  {
    code1: '\u{1f60f}',
    code2: '\\u{1f60f}',
  },
  {
    code1: '\u{1f610}',
    code2: '\\u{1f610}',
  },
  {
    code1: '\u{1f611}',
    code2: '\\u{1f611}',
  },
  {
    code1: '\u{1f612}',
    code2: '\\u{1f612}',
  },
  {
    code1: '\u{1f613}',
    code2: '\\u{1f613}',
  },
  {
    code1: '\u{1f614}',
    code2: '\\u{1f614}',
  },
  {
    code1: '\u{1f615}',
    code2: '\\u{1f615}',
  },
  {
    code1: '\u{1f616}',
    code2: '\\u{1f616}',
  },
  {
    code1: '\u{1f617}',
    code2: '\\u{1f617}',
  },
  {
    code1: '\u{1f618}',
    code2: '\\u{1f618}',
  },
  {
    code1: '\u{1f619}',
    code2: '\\u{1f619}',
  },
  {
    code1: '\u{1f61a}',
    code2: '\\u{1f61a}',
  },
  {
    code1: '\u{1f61b}',
    code2: '\\u{1f61b}',
  },
  {
    code1: '\u{1f61c}',
    code2: '\\u{1f61c}',
  },
  {
    code1: '\u{1f61d}',
    code2: '\\u{1f61d}',
  },
  {
    code1: '\u{1f61e}',
    code2: '\\u{1f61e}',
  },
  {
    code1: '\u{1f61f}',
    code2: '\\u{1f61f}',
  },
  {
    code1: '\u{1f620}',
    code2: '\\u{1f620}',
  },
  {
    code1: '\u{1f621}',
    code2: '\\u{1f621}',
  },
  {
    code1: '\u{1f622}',
    code2: '\\u{1f622}',
  },
  {
    code1: '\u{1f623}',
    code2: '\\u{1f623}',
  },
  {
    code1: '\u{1f624}',
    code2: '\\u{1f624}',
  },
  {
    code1: '\u{1f625}',
    code2: '\\u{1f625}',
  },
  {
    code1: '\u{1f626}',
    code2: '\\u{1f626}',
  },
  {
    code1: '\u{1f627}',
    code2: '\\u{1f627}',
  },
  {
    code1: '\u{1f628}',
    code2: '\\u{1f628}',
  },
  {
    code1: '\u{1f629}',
    code2: '\\u{1f629}',
  },
  {
    code1: '\u{1f62a}',
    code2: '\\u{1f62a}',
  },
  {
    code1: '\u{1f62b}',
    code2: '\\u{1f62b}',
  },
  {
    code1: '\u{1f62c}',
    code2: '\\u{1f62c}',
  },
  {
    code1: '\u{1f62d}',
    code2: '\\u{1f62d}',
  },
  {
    code1: '\u{1f62e}',
    code2: '\\u{1f62e}',
  },
  {
    code1: '\u{1f62f}',
    code2: '\\u{1f62f}',
  },
  {
    code1: '\u{1f630}',
    code2: '\\u{1f630}',
  },
  {
    code1: '\u{1f631}',
    code2: '\\u{1f631}',
  },
  {
    code1: '\u{1f632}',
    code2: '\\u{1f632}',
  },
  {
    code1: '\u{1f633}',
    code2: '\\u{1f633}',
  },
  {
    code1: '\u{1f634}',
    code2: '\\u{1f634}',
  },
  {
    code1: '\u{1f635}',
    code2: '\\u{1f635}',
  },
  {
    code1: '\u{1f636}',
    code2: '\\u{1f636}',
  },
  {
    code1: '\u{1f637}',
    code2: '\\u{1f637}',
  },
  {
    code1: '\u{1f638}',
    code2: '\\u{1f638}',
  },
  {
    code1: '\u{1f639}',
    code2: '\\u{1f639}',
  },
  {
    code1: '\u{1f63a}',
    code2: '\\u{1f63a}',
  },
  {
    code1: '\u{1f63b}',
    code2: '\\u{1f63b}',
  },
  {
    code1: '\u{1f63c}',
    code2: '\\u{1f63c}',
  },
  {
    code1: '\u{1f63d}',
    code2: '\\u{1f63d}',
  },
  {
    code1: '\u{1f63e}',
    code2: '\\u{1f63e}',
  },
  {
    code1: '\u{1f63f}',
    code2: '\\u{1f63f}',
  },
  {
    code1: '\u{1f640}',
    code2: '\\u{1f640}',
  },
  {
    code1: '\u{1f641}',
    code2: '\\u{1f641}',
  },
  {
    code1: '\u{1f642}',
    code2: '\\u{1f642}',
  },
  {
    code1: '\u{1f643}',
    code2: '\\u{1f643}',
  },
  {
    code1: '\u{1f644}',
    code2: '\\u{1f644}',
  },
  {
    code1: '\u{1f645}',
    code2: '\\u{1f645}',
  },
  {
    code1: '\u{1f646}',
    code2: '\\u{1f646}',
  },
  {
    code1: '\u{1f647}',
    code2: '\\u{1f647}',
  },
  {
    code1: '\u{1f648}',
    code2: '\\u{1f648}',
  },
  {
    code1: '\u{1f649}',
    code2: '\\u{1f649}',
  },
  {
    code1: '\u{1f64a}',
    code2: '\\u{1f64a}',
  },
  {
    code1: '\u{1f64b}',
    code2: '\\u{1f64b}',
  },
  {
    code1: '\u{1f64c}',
    code2: '\\u{1f64c}',
  },
  {
    code1: '\u{1f64d}',
    code2: '\\u{1f64d}',
  },
  {
    code1: '\u{1f64e}',
    code2: '\\u{1f64e}',
  },
  {
    code1: '\u{1f64f}',
    code2: '\\u{1f64f}',
  },
  {
    code1: '\u{1f680}',
    code2: '\\u{1f680}',
  },
  {
    code1: '\u{1f681}',
    code2: '\\u{1f681}',
  },
  {
    code1: '\u{1f682}',
    code2: '\\u{1f682}',
  },
  {
    code1: '\u{1f683}',
    code2: '\\u{1f683}',
  },
  {
    code1: '\u{1f684}',
    code2: '\\u{1f684}',
  },
  {
    code1: '\u{1f685}',
    code2: '\\u{1f685}',
  },
  {
    code1: '\u{1f686}',
    code2: '\\u{1f686}',
  },
  {
    code1: '\u{1f687}',
    code2: '\\u{1f687}',
  },
  {
    code1: '\u{1f688}',
    code2: '\\u{1f688}',
  },
  {
    code1: '\u{1f689}',
    code2: '\\u{1f689}',
  },
  {
    code1: '\u{1f68a}',
    code2: '\\u{1f68a}',
  },
  {
    code1: '\u{1f68b}',
    code2: '\\u{1f68b}',
  },
  {
    code1: '\u{1f68c}',
    code2: '\\u{1f68c}',
  },
  {
    code1: '\u{1f68d}',
    code2: '\\u{1f68d}',
  },
  {
    code1: '\u{1f68e}',
    code2: '\\u{1f68e}',
  },
  {
    code1: '\u{1f68f}',
    code2: '\\u{1f68f}',
  },
  {
    code1: '\u{1f690}',
    code2: '\\u{1f690}',
  },
  {
    code1: '\u{1f691}',
    code2: '\\u{1f691}',
  },
  {
    code1: '\u{1f692}',
    code2: '\\u{1f692}',
  },
  {
    code1: '\u{1f693}',
    code2: '\\u{1f693}',
  },
  {
    code1: '\u{1f694}',
    code2: '\\u{1f694}',
  },
  {
    code1: '\u{1f695}',
    code2: '\\u{1f695}',
  },
  {
    code1: '\u{1f696}',
    code2: '\\u{1f696}',
  },
  {
    code1: '\u{1f697}',
    code2: '\\u{1f697}',
  },
  {
    code1: '\u{1f698}',
    code2: '\\u{1f698}',
  },
  {
    code1: '\u{1f699}',
    code2: '\\u{1f699}',
  },
  {
    code1: '\u{1f69a}',
    code2: '\\u{1f69a}',
  },
  {
    code1: '\u{1f69b}',
    code2: '\\u{1f69b}',
  },
  {
    code1: '\u{1f69c}',
    code2: '\\u{1f69c}',
  },
  {
    code1: '\u{1f69d}',
    code2: '\\u{1f69d}',
  },
  {
    code1: '\u{1f69e}',
    code2: '\\u{1f69e}',
  },
  {
    code1: '\u{1f69f}',
    code2: '\\u{1f69f}',
  },
  {
    code1: '\u{1f6a0}',
    code2: '\\u{1f6a0}',
  },
  {
    code1: '\u{1f6a1}',
    code2: '\\u{1f6a1}',
  },
  {
    code1: '\u{1f6a2}',
    code2: '\\u{1f6a2}',
  },
  {
    code1: '\u{1f6a3}',
    code2: '\\u{1f6a3}',
  },
  {
    code1: '\u{1f6a4}',
    code2: '\\u{1f6a4}',
  },
  {
    code1: '\u{1f6a5}',
    code2: '\\u{1f6a5}',
  },
  {
    code1: '\u{1f6a6}',
    code2: '\\u{1f6a6}',
  },
  {
    code1: '\u{1f6a7}',
    code2: '\\u{1f6a7}',
  },
  {
    code1: '\u{1f6a8}',
    code2: '\\u{1f6a8}',
  },
  {
    code1: '\u{1f6a9}',
    code2: '\\u{1f6a9}',
  },
  {
    code1: '\u{1f6aa}',
    code2: '\\u{1f6aa}',
  },
  {
    code1: '\u{1f6ab}',
    code2: '\\u{1f6ab}',
  },
  {
    code1: '\u{1f6ac}',
    code2: '\\u{1f6ac}',
  },
  {
    code1: '\u{1f6ad}',
    code2: '\\u{1f6ad}',
  },
  {
    code1: '\u{1f6ae}',
    code2: '\\u{1f6ae}',
  },
  {
    code1: '\u{1f6af}',
    code2: '\\u{1f6af}',
  },
  {
    code1: '\u{1f6b0}',
    code2: '\\u{1f6b0}',
  },
  {
    code1: '\u{1f6b1}',
    code2: '\\u{1f6b1}',
  },
  {
    code1: '\u{1f6b2}',
    code2: '\\u{1f6b2}',
  },
  {
    code1: '\u{1f6b3}',
    code2: '\\u{1f6b3}',
  },
  {
    code1: '\u{1f6b4}',
    code2: '\\u{1f6b4}',
  },
  {
    code1: '\u{1f6b5}',
    code2: '\\u{1f6b5}',
  },
  {
    code1: '\u{1f6b6}',
    code2: '\\u{1f6b6}',
  },
  {
    code1: '\u{1f6b7}',
    code2: '\\u{1f6b7}',
  },
  {
    code1: '\u{1f6b8}',
    code2: '\\u{1f6b8}',
  },
  {
    code1: '\u{1f6b9}',
    code2: '\\u{1f6b9}',
  },
  {
    code1: '\u{1f6ba}',
    code2: '\\u{1f6ba}',
  },
  {
    code1: '\u{1f6bb}',
    code2: '\\u{1f6bb}',
  },
  {
    code1: '\u{1f6bc}',
    code2: '\\u{1f6bc}',
  },
  {
    code1: '\u{1f6bd}',
    code2: '\\u{1f6bd}',
  },
  {
    code1: '\u{1f6be}',
    code2: '\\u{1f6be}',
  },
  {
    code1: '\u{1f6bf}',
    code2: '\\u{1f6bf}',
  },
  {
    code1: '\u{1f6c0}',
    code2: '\\u{1f6c0}',
  },
  {
    code1: '\u{1f6c1}',
    code2: '\\u{1f6c1}',
  },
  {
    code1: '\u{1f6c2}',
    code2: '\\u{1f6c2}',
  },
  {
    code1: '\u{1f6c3}',
    code2: '\\u{1f6c3}',
  },
  {
    code1: '\u{1f6c4}',
    code2: '\\u{1f6c4}',
  },
  {
    code1: '\u{1f6c5}',
    code2: '\\u{1f6c5}',
  },
  {
    code1: '\u{1f6cb}',
    code2: '\\u{1f6cb}',
  },
  {
    code1: '\u{1f6cc}',
    code2: '\\u{1f6cc}',
  },
  {
    code1: '\u{1f6cd}',
    code2: '\\u{1f6cd}',
  },
  {
    code1: '\u{1f6ce}',
    code2: '\\u{1f6ce}',
  },
  {
    code1: '\u{1f6cf}',
    code2: '\\u{1f6cf}',
  },
  {
    code1: '\u{1f6d0}',
    code2: '\\u{1f6d0}',
  },
  {
    code1: '\u{1f6d1}',
    code2: '\\u{1f6d1}',
  },
  {
    code1: '\u{1f6d2}',
    code2: '\\u{1f6d2}',
  },
  {
    code1: '\u{1f6d5}',
    code2: '\\u{1f6d5}',
  },
  {
    code1: '\u{1f6d6}',
    code2: '\\u{1f6d6}',
  },
  {
    code1: '\u{1f6d7}',
    code2: '\\u{1f6d7}',
  },
  {
    code1: '\u{1f6e0}',
    code2: '\\u{1f6e0}',
  },
  {
    code1: '\u{1f6e1}',
    code2: '\\u{1f6e1}',
  },
  {
    code1: '\u{1f6e2}',
    code2: '\\u{1f6e2}',
  },
  {
    code1: '\u{1f6e3}',
    code2: '\\u{1f6e3}',
  },
  {
    code1: '\u{1f6e4}',
    code2: '\\u{1f6e4}',
  },
  {
    code1: '\u{1f6e5}',
    code2: '\\u{1f6e5}',
  },
  {
    code1: '\u{1f6e9}',
    code2: '\\u{1f6e9}',
  },
  {
    code1: '\u{1f6eb}',
    code2: '\\u{1f6eb}',
  },
  {
    code1: '\u{1f6ec}',
    code2: '\\u{1f6ec}',
  },
  {
    code1: '\u{1f6f0}',
    code2: '\\u{1f6f0}',
  },
  {
    code1: '\u{1f6f3}',
    code2: '\\u{1f6f3}',
  },
  {
    code1: '\u{1f6f4}',
    code2: '\\u{1f6f4}',
  },
  {
    code1: '\u{1f6f5}',
    code2: '\\u{1f6f5}',
  },
  {
    code1: '\u{1f6f6}',
    code2: '\\u{1f6f6}',
  },
  {
    code1: '\u{1f6f7}',
    code2: '\\u{1f6f7}',
  },
  {
    code1: '\u{1f6f8}',
    code2: '\\u{1f6f8}',
  },
  {
    code1: '\u{1f6f9}',
    code2: '\\u{1f6f9}',
  },
  {
    code1: '\u{1f6fa}',
    code2: '\\u{1f6fa}',
  },
  {
    code1: '\u{1f6fb}',
    code2: '\\u{1f6fb}',
  },
  {
    code1: '\u{1f6fc}',
    code2: '\\u{1f6fc}',
  },
  {
    code1: '\u{1f7e0}',
    code2: '\\u{1f7e0}',
  },
  {
    code1: '\u{1f7e1}',
    code2: '\\u{1f7e1}',
  },
  {
    code1: '\u{1f7e2}',
    code2: '\\u{1f7e2}',
  },
  {
    code1: '\u{1f7e3}',
    code2: '\\u{1f7e3}',
  },
  {
    code1: '\u{1f7e4}',
    code2: '\\u{1f7e4}',
  },
  {
    code1: '\u{1f7e5}',
    code2: '\\u{1f7e5}',
  },
  {
    code1: '\u{1f7e6}',
    code2: '\\u{1f7e6}',
  },
  {
    code1: '\u{1f7e7}',
    code2: '\\u{1f7e7}',
  },
  {
    code1: '\u{1f7e8}',
    code2: '\\u{1f7e8}',
  },
  {
    code1: '\u{1f7e9}',
    code2: '\\u{1f7e9}',
  },
  {
    code1: '\u{1f7ea}',
    code2: '\\u{1f7ea}',
  },
  {
    code1: '\u{1f7eb}',
    code2: '\\u{1f7eb}',
  },
  {
    code1: '\u{1f90c}',
    code2: '\\u{1f90c}',
  },
  {
    code1: '\u{1f90d}',
    code2: '\\u{1f90d}',
  },
  {
    code1: '\u{1f90e}',
    code2: '\\u{1f90e}',
  },
  {
    code1: '\u{1f90f}',
    code2: '\\u{1f90f}',
  },
  {
    code1: '\u{1f910}',
    code2: '\\u{1f910}',
  },
  {
    code1: '\u{1f911}',
    code2: '\\u{1f911}',
  },
  {
    code1: '\u{1f912}',
    code2: '\\u{1f912}',
  },
  {
    code1: '\u{1f913}',
    code2: '\\u{1f913}',
  },
  {
    code1: '\u{1f914}',
    code2: '\\u{1f914}',
  },
  {
    code1: '\u{1f915}',
    code2: '\\u{1f915}',
  },
  {
    code1: '\u{1f916}',
    code2: '\\u{1f916}',
  },
  {
    code1: '\u{1f917}',
    code2: '\\u{1f917}',
  },
  {
    code1: '\u{1f918}',
    code2: '\\u{1f918}',
  },
  {
    code1: '\u{1f919}',
    code2: '\\u{1f919}',
  },
  {
    code1: '\u{1f91a}',
    code2: '\\u{1f91a}',
  },
  {
    code1: '\u{1f91b}',
    code2: '\\u{1f91b}',
  },
  {
    code1: '\u{1f91c}',
    code2: '\\u{1f91c}',
  },
  {
    code1: '\u{1f91d}',
    code2: '\\u{1f91d}',
  },
  {
    code1: '\u{1f91e}',
    code2: '\\u{1f91e}',
  },
  {
    code1: '\u{1f91f}',
    code2: '\\u{1f91f}',
  },
  {
    code1: '\u{1f920}',
    code2: '\\u{1f920}',
  },
  {
    code1: '\u{1f921}',
    code2: '\\u{1f921}',
  },
  {
    code1: '\u{1f922}',
    code2: '\\u{1f922}',
  },
  {
    code1: '\u{1f923}',
    code2: '\\u{1f923}',
  },
  {
    code1: '\u{1f924}',
    code2: '\\u{1f924}',
  },
  {
    code1: '\u{1f925}',
    code2: '\\u{1f925}',
  },
  {
    code1: '\u{1f926}',
    code2: '\\u{1f926}',
  },
  {
    code1: '\u{1f927}',
    code2: '\\u{1f927}',
  },
  {
    code1: '\u{1f928}',
    code2: '\\u{1f928}',
  },
  {
    code1: '\u{1f929}',
    code2: '\\u{1f929}',
  },
  {
    code1: '\u{1f92a}',
    code2: '\\u{1f92a}',
  },
  {
    code1: '\u{1f92b}',
    code2: '\\u{1f92b}',
  },
  {
    code1: '\u{1f92c}',
    code2: '\\u{1f92c}',
  },
  {
    code1: '\u{1f92d}',
    code2: '\\u{1f92d}',
  },
  {
    code1: '\u{1f92e}',
    code2: '\\u{1f92e}',
  },
  {
    code1: '\u{1f92f}',
    code2: '\\u{1f92f}',
  },
  {
    code1: '\u{1f930}',
    code2: '\\u{1f930}',
  },
  {
    code1: '\u{1f931}',
    code2: '\\u{1f931}',
  },
  {
    code1: '\u{1f932}',
    code2: '\\u{1f932}',
  },
  {
    code1: '\u{1f933}',
    code2: '\\u{1f933}',
  },
  {
    code1: '\u{1f934}',
    code2: '\\u{1f934}',
  },
  {
    code1: '\u{1f935}',
    code2: '\\u{1f935}',
  },
  {
    code1: '\u{1f936}',
    code2: '\\u{1f936}',
  },
  {
    code1: '\u{1f937}',
    code2: '\\u{1f937}',
  },
  {
    code1: '\u{1f938}',
    code2: '\\u{1f938}',
  },
  {
    code1: '\u{1f939}',
    code2: '\\u{1f939}',
  },
  {
    code1: '\u{1f93a}',
    code2: '\\u{1f93a}',
  },
  {
    code1: '\u{1f93c}',
    code2: '\\u{1f93c}',
  },
  {
    code1: '\u{1f93d}',
    code2: '\\u{1f93d}',
  },
  {
    code1: '\u{1f93e}',
    code2: '\\u{1f93e}',
  },
  {
    code1: '\u{1f93f}',
    code2: '\\u{1f93f}',
  },
  {
    code1: '\u{1f940}',
    code2: '\\u{1f940}',
  },
  {
    code1: '\u{1f941}',
    code2: '\\u{1f941}',
  },
  {
    code1: '\u{1f942}',
    code2: '\\u{1f942}',
  },
  {
    code1: '\u{1f943}',
    code2: '\\u{1f943}',
  },
  {
    code1: '\u{1f944}',
    code2: '\\u{1f944}',
  },
  {
    code1: '\u{1f945}',
    code2: '\\u{1f945}',
  },
  {
    code1: '\u{1f947}',
    code2: '\\u{1f947}',
  },
  {
    code1: '\u{1f948}',
    code2: '\\u{1f948}',
  },
  {
    code1: '\u{1f949}',
    code2: '\\u{1f949}',
  },
  {
    code1: '\u{1f94a}',
    code2: '\\u{1f94a}',
  },
  {
    code1: '\u{1f94b}',
    code2: '\\u{1f94b}',
  },
  {
    code1: '\u{1f94c}',
    code2: '\\u{1f94c}',
  },
  {
    code1: '\u{1f94d}',
    code2: '\\u{1f94d}',
  },
  {
    code1: '\u{1f94e}',
    code2: '\\u{1f94e}',
  },
  {
    code1: '\u{1f94f}',
    code2: '\\u{1f94f}',
  },
  {
    code1: '\u{1f950}',
    code2: '\\u{1f950}',
  },
  {
    code1: '\u{1f951}',
    code2: '\\u{1f951}',
  },
  {
    code1: '\u{1f952}',
    code2: '\\u{1f952}',
  },
  {
    code1: '\u{1f953}',
    code2: '\\u{1f953}',
  },
  {
    code1: '\u{1f954}',
    code2: '\\u{1f954}',
  },
  {
    code1: '\u{1f955}',
    code2: '\\u{1f955}',
  },
  {
    code1: '\u{1f956}',
    code2: '\\u{1f956}',
  },
  {
    code1: '\u{1f957}',
    code2: '\\u{1f957}',
  },
  {
    code1: '\u{1f958}',
    code2: '\\u{1f958}',
  },
  {
    code1: '\u{1f959}',
    code2: '\\u{1f959}',
  },
  {
    code1: '\u{1f95a}',
    code2: '\\u{1f95a}',
  },
  {
    code1: '\u{1f95b}',
    code2: '\\u{1f95b}',
  },
  {
    code1: '\u{1f95c}',
    code2: '\\u{1f95c}',
  },
  {
    code1: '\u{1f95d}',
    code2: '\\u{1f95d}',
  },
  {
    code1: '\u{1f95e}',
    code2: '\\u{1f95e}',
  },
  {
    code1: '\u{1f95f}',
    code2: '\\u{1f95f}',
  },
  {
    code1: '\u{1f960}',
    code2: '\\u{1f960}',
  },
  {
    code1: '\u{1f961}',
    code2: '\\u{1f961}',
  },
  {
    code1: '\u{1f962}',
    code2: '\\u{1f962}',
  },
  {
    code1: '\u{1f963}',
    code2: '\\u{1f963}',
  },
  {
    code1: '\u{1f964}',
    code2: '\\u{1f964}',
  },
  {
    code1: '\u{1f965}',
    code2: '\\u{1f965}',
  },
  {
    code1: '\u{1f966}',
    code2: '\\u{1f966}',
  },
  {
    code1: '\u{1f967}',
    code2: '\\u{1f967}',
  },
  {
    code1: '\u{1f968}',
    code2: '\\u{1f968}',
  },
  {
    code1: '\u{1f969}',
    code2: '\\u{1f969}',
  },
  {
    code1: '\u{1f96a}',
    code2: '\\u{1f96a}',
  },
  {
    code1: '\u{1f96b}',
    code2: '\\u{1f96b}',
  },
  {
    code1: '\u{1f96c}',
    code2: '\\u{1f96c}',
  },
  {
    code1: '\u{1f96d}',
    code2: '\\u{1f96d}',
  },
  {
    code1: '\u{1f96e}',
    code2: '\\u{1f96e}',
  },
  {
    code1: '\u{1f96f}',
    code2: '\\u{1f96f}',
  },
  {
    code1: '\u{1f970}',
    code2: '\\u{1f970}',
  },
  {
    code1: '\u{1f971}',
    code2: '\\u{1f971}',
  },
  {
    code1: '\u{1f972}',
    code2: '\\u{1f972}',
  },
  {
    code1: '\u{1f973}',
    code2: '\\u{1f973}',
  },
  {
    code1: '\u{1f974}',
    code2: '\\u{1f974}',
  },
  {
    code1: '\u{1f975}',
    code2: '\\u{1f975}',
  },
  {
    code1: '\u{1f976}',
    code2: '\\u{1f976}',
  },
  {
    code1: '\u{1f977}',
    code2: '\\u{1f977}',
  },
  {
    code1: '\u{1f978}',
    code2: '\\u{1f978}',
  },
  {
    code1: '\u{1f97a}',
    code2: '\\u{1f97a}',
  },
  {
    code1: '\u{1f97b}',
    code2: '\\u{1f97b}',
  },
  {
    code1: '\u{1f97c}',
    code2: '\\u{1f97c}',
  },
  {
    code1: '\u{1f97d}',
    code2: '\\u{1f97d}',
  },
  {
    code1: '\u{1f97e}',
    code2: '\\u{1f97e}',
  },
  {
    code1: '\u{1f97f}',
    code2: '\\u{1f97f}',
  },
  {
    code1: '\u{1f980}',
    code2: '\\u{1f980}',
  },
  {
    code1: '\u{1f981}',
    code2: '\\u{1f981}',
  },
  {
    code1: '\u{1f982}',
    code2: '\\u{1f982}',
  },
  {
    code1: '\u{1f983}',
    code2: '\\u{1f983}',
  },
  {
    code1: '\u{1f984}',
    code2: '\\u{1f984}',
  },
  {
    code1: '\u{1f985}',
    code2: '\\u{1f985}',
  },
  {
    code1: '\u{1f986}',
    code2: '\\u{1f986}',
  },
  {
    code1: '\u{1f987}',
    code2: '\\u{1f987}',
  },
  {
    code1: '\u{1f988}',
    code2: '\\u{1f988}',
  },
  {
    code1: '\u{1f989}',
    code2: '\\u{1f989}',
  },
  {
    code1: '\u{1f98a}',
    code2: '\\u{1f98a}',
  },
  {
    code1: '\u{1f98b}',
    code2: '\\u{1f98b}',
  },
  {
    code1: '\u{1f98c}',
    code2: '\\u{1f98c}',
  },
  {
    code1: '\u{1f98d}',
    code2: '\\u{1f98d}',
  },
  {
    code1: '\u{1f98e}',
    code2: '\\u{1f98e}',
  },
  {
    code1: '\u{1f98f}',
    code2: '\\u{1f98f}',
  },
  {
    code1: '\u{1f990}',
    code2: '\\u{1f990}',
  },
  {
    code1: '\u{1f991}',
    code2: '\\u{1f991}',
  },
  {
    code1: '\u{1f992}',
    code2: '\\u{1f992}',
  },
  {
    code1: '\u{1f993}',
    code2: '\\u{1f993}',
  },
  {
    code1: '\u{1f994}',
    code2: '\\u{1f994}',
  },
  {
    code1: '\u{1f995}',
    code2: '\\u{1f995}',
  },
  {
    code1: '\u{1f996}',
    code2: '\\u{1f996}',
  },
  {
    code1: '\u{1f997}',
    code2: '\\u{1f997}',
  },
  {
    code1: '\u{1f998}',
    code2: '\\u{1f998}',
  },
  {
    code1: '\u{1f999}',
    code2: '\\u{1f999}',
  },
  {
    code1: '\u{1f99a}',
    code2: '\\u{1f99a}',
  },
  {
    code1: '\u{1f99b}',
    code2: '\\u{1f99b}',
  },
  {
    code1: '\u{1f99c}',
    code2: '\\u{1f99c}',
  },
  {
    code1: '\u{1f99d}',
    code2: '\\u{1f99d}',
  },
  {
    code1: '\u{1f99e}',
    code2: '\\u{1f99e}',
  },
  {
    code1: '\u{1f99f}',
    code2: '\\u{1f99f}',
  },
  {
    code1: '\u{1f9a0}',
    code2: '\\u{1f9a0}',
  },
  {
    code1: '\u{1f9a1}',
    code2: '\\u{1f9a1}',
  },
  {
    code1: '\u{1f9a2}',
    code2: '\\u{1f9a2}',
  },
  {
    code1: '\u{1f9a3}',
    code2: '\\u{1f9a3}',
  },
  {
    code1: '\u{1f9a4}',
    code2: '\\u{1f9a4}',
  },
  {
    code1: '\u{1f9a5}',
    code2: '\\u{1f9a5}',
  },
  {
    code1: '\u{1f9a6}',
    code2: '\\u{1f9a6}',
  },
  {
    code1: '\u{1f9a7}',
    code2: '\\u{1f9a7}',
  },
  {
    code1: '\u{1f9a8}',
    code2: '\\u{1f9a8}',
  },
  {
    code1: '\u{1f9a9}',
    code2: '\\u{1f9a9}',
  },
  {
    code1: '\u{1f9aa}',
    code2: '\\u{1f9aa}',
  },
  {
    code1: '\u{1f9ab}',
    code2: '\\u{1f9ab}',
  },
  {
    code1: '\u{1f9ac}',
    code2: '\\u{1f9ac}',
  },
  {
    code1: '\u{1f9ad}',
    code2: '\\u{1f9ad}',
  },
  {
    code1: '\u{1f9ae}',
    code2: '\\u{1f9ae}',
  },
  {
    code1: '\u{1f9af}',
    code2: '\\u{1f9af}',
  },
  {
    code1: '\u{1f9b0}',
    code2: '\\u{1f9b0}',
  },
  {
    code1: '\u{1f9b1}',
    code2: '\\u{1f9b1}',
  },
  {
    code1: '\u{1f9b2}',
    code2: '\\u{1f9b2}',
  },
  {
    code1: '\u{1f9b3}',
    code2: '\\u{1f9b3}',
  },
  {
    code1: '\u{1f9b4}',
    code2: '\\u{1f9b4}',
  },
  {
    code1: '\u{1f9b5}',
    code2: '\\u{1f9b5}',
  },
  {
    code1: '\u{1f9b6}',
    code2: '\\u{1f9b6}',
  },
  {
    code1: '\u{1f9b7}',
    code2: '\\u{1f9b7}',
  },
  {
    code1: '\u{1f9b8}',
    code2: '\\u{1f9b8}',
  },
  {
    code1: '\u{1f9b9}',
    code2: '\\u{1f9b9}',
  },
  {
    code1: '\u{1f9ba}',
    code2: '\\u{1f9ba}',
  },
  {
    code1: '\u{1f9bb}',
    code2: '\\u{1f9bb}',
  },
  {
    code1: '\u{1f9bc}',
    code2: '\\u{1f9bc}',
  },
  {
    code1: '\u{1f9bd}',
    code2: '\\u{1f9bd}',
  },
  {
    code1: '\u{1f9be}',
    code2: '\\u{1f9be}',
  },
  {
    code1: '\u{1f9bf}',
    code2: '\\u{1f9bf}',
  },
  {
    code1: '\u{1f9c0}',
    code2: '\\u{1f9c0}',
  },
  {
    code1: '\u{1f9c1}',
    code2: '\\u{1f9c1}',
  },
  {
    code1: '\u{1f9c2}',
    code2: '\\u{1f9c2}',
  },
  {
    code1: '\u{1f9c3}',
    code2: '\\u{1f9c3}',
  },
  {
    code1: '\u{1f9c4}',
    code2: '\\u{1f9c4}',
  },
  {
    code1: '\u{1f9c5}',
    code2: '\\u{1f9c5}',
  },
  {
    code1: '\u{1f9c6}',
    code2: '\\u{1f9c6}',
  },
  {
    code1: '\u{1f9c7}',
    code2: '\\u{1f9c7}',
  },
  {
    code1: '\u{1f9c8}',
    code2: '\\u{1f9c8}',
  },
  {
    code1: '\u{1f9c9}',
    code2: '\\u{1f9c9}',
  },
  {
    code1: '\u{1f9ca}',
    code2: '\\u{1f9ca}',
  },
  {
    code1: '\u{1f9cb}',
    code2: '\\u{1f9cb}',
  },
  {
    code1: '\u{1f9cd}',
    code2: '\\u{1f9cd}',
  },
  {
    code1: '\u{1f9ce}',
    code2: '\\u{1f9ce}',
  },
  {
    code1: '\u{1f9cf}',
    code2: '\\u{1f9cf}',
  },
  {
    code1: '\u{1f9d0}',
    code2: '\\u{1f9d0}',
  },
  {
    code1: '\u{1f9d1}',
    code2: '\\u{1f9d1}',
  },
  {
    code1: '\u{1f9d2}',
    code2: '\\u{1f9d2}',
  },
  {
    code1: '\u{1f9d3}',
    code2: '\\u{1f9d3}',
  },
  {
    code1: '\u{1f9d4}',
    code2: '\\u{1f9d4}',
  },
  {
    code1: '\u{1f9d5}',
    code2: '\\u{1f9d5}',
  },
  {
    code1: '\u{1f9d6}',
    code2: '\\u{1f9d6}',
  },
  {
    code1: '\u{1f9d7}',
    code2: '\\u{1f9d7}',
  },
  {
    code1: '\u{1f9d8}',
    code2: '\\u{1f9d8}',
  },
  {
    code1: '\u{1f9d9}',
    code2: '\\u{1f9d9}',
  },
  {
    code1: '\u{1f9da}',
    code2: '\\u{1f9da}',
  },
  {
    code1: '\u{1f9db}',
    code2: '\\u{1f9db}',
  },
  {
    code1: '\u{1f9dc}',
    code2: '\\u{1f9dc}',
  },
  {
    code1: '\u{1f9dd}',
    code2: '\\u{1f9dd}',
  },
  {
    code1: '\u{1f9de}',
    code2: '\\u{1f9de}',
  },
  {
    code1: '\u{1f9df}',
    code2: '\\u{1f9df}',
  },
  {
    code1: '\u{1f9e0}',
    code2: '\\u{1f9e0}',
  },
  {
    code1: '\u{1f9e1}',
    code2: '\\u{1f9e1}',
  },
  {
    code1: '\u{1f9e2}',
    code2: '\\u{1f9e2}',
  },
  {
    code1: '\u{1f9e3}',
    code2: '\\u{1f9e3}',
  },
  {
    code1: '\u{1f9e4}',
    code2: '\\u{1f9e4}',
  },
  {
    code1: '\u{1f9e5}',
    code2: '\\u{1f9e5}',
  },
  {
    code1: '\u{1f9e6}',
    code2: '\\u{1f9e6}',
  },
  {
    code1: '\u{1f9e7}',
    code2: '\\u{1f9e7}',
  },
  {
    code1: '\u{1f9e8}',
    code2: '\\u{1f9e8}',
  },
  {
    code1: '\u{1f9e9}',
    code2: '\\u{1f9e9}',
  },
  {
    code1: '\u{1f9ea}',
    code2: '\\u{1f9ea}',
  },
  {
    code1: '\u{1f9eb}',
    code2: '\\u{1f9eb}',
  },
  {
    code1: '\u{1f9ec}',
    code2: '\\u{1f9ec}',
  },
  {
    code1: '\u{1f9ed}',
    code2: '\\u{1f9ed}',
  },
  {
    code1: '\u{1f9ee}',
    code2: '\\u{1f9ee}',
  },
  {
    code1: '\u{1f9ef}',
    code2: '\\u{1f9ef}',
  },
  {
    code1: '\u{1f9f0}',
    code2: '\\u{1f9f0}',
  },
  {
    code1: '\u{1f9f1}',
    code2: '\\u{1f9f1}',
  },
  {
    code1: '\u{1f9f2}',
    code2: '\\u{1f9f2}',
  },
  {
    code1: '\u{1f9f3}',
    code2: '\\u{1f9f3}',
  },
  {
    code1: '\u{1f9f4}',
    code2: '\\u{1f9f4}',
  },
  {
    code1: '\u{1f9f5}',
    code2: '\\u{1f9f5}',
  },
  {
    code1: '\u{1f9f6}',
    code2: '\\u{1f9f6}',
  },
  {
    code1: '\u{1f9f7}',
    code2: '\\u{1f9f7}',
  },
  {
    code1: '\u{1f9f8}',
    code2: '\\u{1f9f8}',
  },
  {
    code1: '\u{1f9f9}',
    code2: '\\u{1f9f9}',
  },
  {
    code1: '\u{1f9fa}',
    code2: '\\u{1f9fa}',
  },
  {
    code1: '\u{1f9fb}',
    code2: '\\u{1f9fb}',
  },
  {
    code1: '\u{1f9fc}',
    code2: '\\u{1f9fc}',
  },
  {
    code1: '\u{1f9fd}',
    code2: '\\u{1f9fd}',
  },
  {
    code1: '\u{1f9fe}',
    code2: '\\u{1f9fe}',
  },
  {
    code1: '\u{1f9ff}',
    code2: '\\u{1f9ff}',
  },
  {
    code1: '\u{1fa70}',
    code2: '\\u{1fa70}',
  },
  {
    code1: '\u{1fa71}',
    code2: '\\u{1fa71}',
  },
  {
    code1: '\u{1fa72}',
    code2: '\\u{1fa72}',
  },
  {
    code1: '\u{1fa73}',
    code2: '\\u{1fa73}',
  },
  {
    code1: '\u{1fa74}',
    code2: '\\u{1fa74}',
  },
  {
    code1: '\u{1fa78}',
    code2: '\\u{1fa78}',
  },
  {
    code1: '\u{1fa79}',
    code2: '\\u{1fa79}',
  },
  {
    code1: '\u{1fa7a}',
    code2: '\\u{1fa7a}',
  },
  {
    code1: '\u{1fa80}',
    code2: '\\u{1fa80}',
  },
  {
    code1: '\u{1fa81}',
    code2: '\\u{1fa81}',
  },
  {
    code1: '\u{1fa82}',
    code2: '\\u{1fa82}',
  },
  {
    code1: '\u{1fa83}',
    code2: '\\u{1fa83}',
  },
  {
    code1: '\u{1fa84}',
    code2: '\\u{1fa84}',
  },
  {
    code1: '\u{1fa85}',
    code2: '\\u{1fa85}',
  },
  {
    code1: '\u{1fa86}',
    code2: '\\u{1fa86}',
  },
  {
    code1: '\u{1fa90}',
    code2: '\\u{1fa90}',
  },
  {
    code1: '\u{1fa91}',
    code2: '\\u{1fa91}',
  },
  {
    code1: '\u{1fa92}',
    code2: '\\u{1fa92}',
  },
  {
    code1: '\u{1fa93}',
    code2: '\\u{1fa93}',
  },
  {
    code1: '\u{1fa94}',
    code2: '\\u{1fa94}',
  },
  {
    code1: '\u{1fa95}',
    code2: '\\u{1fa95}',
  },
  {
    code1: '\u{1fa96}',
    code2: '\\u{1fa96}',
  },
  {
    code1: '\u{1fa97}',
    code2: '\\u{1fa97}',
  },
  {
    code1: '\u{1fa98}',
    code2: '\\u{1fa98}',
  },
  {
    code1: '\u{1fa99}',
    code2: '\\u{1fa99}',
  },
  {
    code1: '\u{1fa9a}',
    code2: '\\u{1fa9a}',
  },
  {
    code1: '\u{1fa9b}',
    code2: '\\u{1fa9b}',
  },
  {
    code1: '\u{1fa9c}',
    code2: '\\u{1fa9c}',
  },
  {
    code1: '\u{1fa9d}',
    code2: '\\u{1fa9d}',
  },
  {
    code1: '\u{1fa9e}',
    code2: '\\u{1fa9e}',
  },
  {
    code1: '\u{1fa9f}',
    code2: '\\u{1fa9f}',
  },
  {
    code1: '\u{1faa0}',
    code2: '\\u{1faa0}',
  },
  {
    code1: '\u{1faa1}',
    code2: '\\u{1faa1}',
  },
  {
    code1: '\u{1faa2}',
    code2: '\\u{1faa2}',
  },
  {
    code1: '\u{1faa3}',
    code2: '\\u{1faa3}',
  },
  {
    code1: '\u{1faa4}',
    code2: '\\u{1faa4}',
  },
  {
    code1: '\u{1faa5}',
    code2: '\\u{1faa5}',
  },
  {
    code1: '\u{1faa6}',
    code2: '\\u{1faa6}',
  },
  {
    code1: '\u{1faa7}',
    code2: '\\u{1faa7}',
  },
  {
    code1: '\u{1faa8}',
    code2: '\\u{1faa8}',
  },
  {
    code1: '\u{1fab0}',
    code2: '\\u{1fab0}',
  },
  {
    code1: '\u{1fab1}',
    code2: '\\u{1fab1}',
  },
  {
    code1: '\u{1fab2}',
    code2: '\\u{1fab2}',
  },
  {
    code1: '\u{1fab3}',
    code2: '\\u{1fab3}',
  },
  {
    code1: '\u{1fab4}',
    code2: '\\u{1fab4}',
  },
  {
    code1: '\u{1fab5}',
    code2: '\\u{1fab5}',
  },
  {
    code1: '\u{1fab6}',
    code2: '\\u{1fab6}',
  },
  {
    code1: '\u{1fac0}',
    code2: '\\u{1fac0}',
  },
  {
    code1: '\u{1fac1}',
    code2: '\\u{1fac1}',
  },
  {
    code1: '\u{1fac2}',
    code2: '\\u{1fac2}',
  },
  {
    code1: '\u{1fad0}',
    code2: '\\u{1fad0}',
  },
  {
    code1: '\u{1fad1}',
    code2: '\\u{1fad1}',
  },
  {
    code1: '\u{1fad2}',
    code2: '\\u{1fad2}',
  },
  {
    code1: '\u{1fad3}',
    code2: '\\u{1fad3}',
  },
  {
    code1: '\u{1fad4}',
    code2: '\\u{1fad4}',
  },
  {
    code1: '\u{1fad5}',
    code2: '\\u{1fad5}',
  },
  {
    code1: '\u{1fad6}',
    code2: '\\u{1fad6}',
  },
];

export default data;
