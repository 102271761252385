import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import './Step.scss';
import '../Advert.scss';

const Step = () => {
  const context = [
    {
      text: 'inDJ APP 실행',
    },
    {
      text: '듣고싶은 스테이션 선택',
    },
    {
      text: '플레이어 모듈 선택',
    },
    {
      text: `화면 좌측 하단\n시간 확인 후 클릭`,
    },
    {
      text: '광고 끝까지 보기',
    },
  ];
  const [scrollY, setScrollY] = useState(0);
  const handleScroll = () => {
    const scrollPosition = window.pageYOffset;
    setScrollY(scrollPosition);
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  console.log(scrollY < 300 && scrollY < 1100);
  return (
    <>
      <div className="way">
        <div className="way_main">참여방법</div>
        {context.map((text, idx) => (
          <>
            <div className="wayall">
              <div className="wayall_step">
                <span className="waystep">STEP {idx + 1}</span>
                <br />
                <span className="waytext">{text.text}</span>
              </div>
              <div className="wayall_img">
                <img
                  src={`https://indj.s3.ap-northeast-2.amazonaws.com/image/event/advert_way${idx + 1}.png`}
                  alt={`참여방법${idx + 1}`}
                />
              </div>
            </div>
            <img
              className="middleline"
              alt="이미지밑선"
              src="https://indj.s3.ap-northeast-2.amazonaws.com/image/event/advert_middleline.png"
            />
          </>
        ))}
      </div>
      {/* <div className="way">
        <div className="way_main">참여방법</div>
        <div className="way1">
          <div className="way1_all">
            <span className="waystep">STEP 1</span>
            <br />
            <span className="waytext">inDJ APP 실행</span>
          </div>
          <div className="way1_img">
            <img src="https://indj.s3.ap-northeast-2.amazonaws.com/image/event/adevert_way1.png" alt="참여방법1" />
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Step;
