import React, { useState, useEffect } from "react";

const Questions = ({ item, choice, qnum }) => {
  useEffect(() => {}, []);

  const pushAnswer = (num, answer) => {
    choice(num, answer);
  };

  return (
    <div className="questions">
      <div className="question-box">
        <div className="question-text">
          <p className="qnum">{`Q . ${qnum}`}</p>
          <p>{`${item.title}`}</p>
        </div>
        {item.img ? (
          <img
            className="q-img"
            src={`/../images/weatherTest/${item.img}.png`}
            alt="인디제이 로고"
          />
        ) : null}
      </div>

      <div className="btn-box">
        <button
          onClick={() => pushAnswer(qnum, { type: item.type, score: 1 })}
          className="choice-btn"
        >
          {`${item.b1}`}
        </button>
        <button
          onClick={() => pushAnswer(qnum, { type: item.type, score: 0 })}
          className="choice-btn"
        >
          {`${item.b2}`}
        </button>
      </div>
    </div>
  );
};
export default Questions;
