import { atom } from 'recoil';

// 결제 정보
export const _payInfo = atom({
  key: 'PAYINFO',
  default: {
    pg: 'danal_tpay',
    pay_method: 'card', // 결제 방식
    buyer_tel: '',
    name: '',
    amount: 0, // 빌링키 발급만 진행하며 결제승인을 하지 않습니다.
  },
});

// 푸터
export const _footer = atom({
  key: 'FOOTER',
  default: true,
});
