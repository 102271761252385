export const question = [
  {
    title: "나를 더 설레게 하는 것",
    b1: "캠핑/기차여행 + 바베큐",
    b2: "넷플릭스+내가 좋아하는 음식 ",
    img: "love",
    type: 1,
  },
  {
    title: "비가 퍼붓는 날 우산없는 사람을 발견했다",
    b1: "제가 저기까지 씌워드릴까요?",
    b2: "저런..~ (빨리 집에가자)",
    type: 1,
  },
  {
    title: "이번 겨울은 화이트 크리스마스란다!",
    b1: "그래도 나가서 크리스마스를 즐겨야 하지 않겠어? ",
    b2: "사람많은데 집에서 귤이나 까먹자..",
    img: "santa",
    type: 1,
  },

  {
    title: "모처럼 쉬는 날, 예보에 없던 비가 내린다",
    b1: "비오는 날은 파전이지..",
    b2: "일기예보가 왜 틀렸을까;;?",
    type: 2,
  },
  {
    title: "추운 겨울날, 붕어빵을 파시는 할머니를 보았다 ",
    b1: "오, 붕세권이다! 사먹어야지!",
    b2: "날도 추운데 할머니 추우시겠다... 좀 사드려야지",
    img: "fish",
    type: 2,
  },
  {
    title: "더운 여름날, 아이스크림을 사준 친구에게",
    b1: "친구야 넌 최고야",
    b2: "나 단거 안좋아하는데",
    img: "icecream",
    type: 2,
  },

  {
    title: "피크닉 가는 내 짐가방은",
    b1: "최대한 간단하게!",
    b2: "필요할 지 모르니 일단 다 챙긴다",
    type: 3,
  },
  {
    title: "여행모임에서 나는?",
    b1: "헤헤 아무데나 가주세요.. ",
    b2: "총무/일정 담당",
    type: 3,
  },
  {
    title: "매일 날씨가 흐리다 중요한 시험 전날만 날씨가 너무 좋다",
    b1: "시험따위가 내 인생의 찬란한 순간을 막을 수 없지",
    b2: "내 인생을 위해 시험 공부를 해야지",
    type: 3,
  },
];
export const result = [
  {
    mod: "당신은",
    title: "아이디어 뱅크 번개",
    desc: [
      "눈치가 빨라 대화 안에서 흐름을 금방 파악해요. 개방적이라 웬만한 것은 '그럴 수 있지' 하고 넘겨 대체로 대인관계가 좋은 편이지만 혼자만의 시간도 중요하게 여기는 당신!",
      "직설적이고 솔직하게 내맘을 표현하는 것에 거리낌이 없어요. 관심있는 분야에 대해선 끊임없이 파고들지만 관심없는 분야는 단 1도 몰라요! 고집이 세면서도 딱 잘라 결단을 내리지 못하는 우유부단함의 양면성도 가지고 있네요. 다른 누구의 권유나 참견은 좋아하지 않는 편이며, 다른 사람의 눈치를 보기보다 자신이 하고 싶은 대로 하며 숨은 관종끼를 조금씩 드러냅니다.",
      "싸울 때만큼은 누구보다 현실적이고 이성적이며, 싸우는 걸 싫어하지만 지는 건 더 싫어! 나랑 상관이 없는 남에게는 큰 관심이 없어요. 스트레스 관리를 혼자서도 잘해요. 상황판단이 좋고 시원시원해 리더의 역할도 잘 해냅니다.",
    ],
    img: "thunder",
    thum: "https://d3coxo32tidngo.cloudfront.net/image/mbti/thunder.png",
    miri: "https://d3coxo32tidngo.cloudfront.net/image/mbti/thunder_miri.png",
    channel: [
      {
        ch_name:
          "[MBTI] INTP - 아이디어형, 비평적 관점을 가진 당신에게 톡톡튀는 걸그룹 댄스",
        ch_img:
          "https://api2.indj.club/file/fetchImage/thumbnail/[MBTI] INTP - 아이디어형, 비평적 관점을 가진 당신에게 톡톡튀는 걸그룹 댄스.png",
        ch_song: [
          {
            title: "빠빠빠",
            singer: ["크레용팝(Crayon Pop)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%ED%81%AC%EB%A0%88%EC%9A%A9%ED%8C%9D(Crayon%20Pop)-%EB%B9%A0%EB%B9%A0%EB%B9%A0.jpg",
          },
          {
            title: "뿜뿜",
            singer: ["모모랜드(MOMOLAND)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%AA%A8%EB%AA%A8%EB%9E%9C%EB%93%9C(MOMOLAND)-GREAT!.jpg",
          },
          {
            title: "BAAM",
            singer: ["모모랜드(MOMOLAND)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%AA%A8%EB%AA%A8%EB%9E%9C%EB%93%9C(MOMOLAND)-Fun%20to%20The%20World.jpg",
          },
          {
            title: "PICK ME",
            singer: ["PRODUCE 101(프로듀스 101)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/PRODUCE%20101(%ED%94%84%EB%A1%9C%EB%93%80%EC%8A%A4%20101)-PRODUCE%20101%20-%20PICK%20ME.jpg",
          },
          {
            title: "Darling",
            singer: ["걸스데이(Girl's Day)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EA%B1%B8%EC%8A%A4%EB%8D%B0%EC%9D%B4(Girl's%20Day)-Girl's%20Day%20Everyday%20#4.jpg",
          },
          {
            title: "립스틱 (Lipstick)",
            singer: ["오렌지캬라멜(Orange Caramel)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%98%A4%EB%A0%8C%EC%A7%80%EC%BA%AC%EB%9D%BC%EB%A9%9C(Orange%20Caramel)-%EB%A6%BD%EC%8A%A4%ED%8B%B1%20(Lipstick).jpg",
          },
          {
            title: "LIKEY",
            singer: ["TWICE (트와이스)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/TWICE%20(%ED%8A%B8%EC%99%80%EC%9D%B4%EC%8A%A4)-twicetagram_2020_11_18.jpg",
          },
          {
            title: "여름여름해 (Sunny Summer)",
            singer: ["여자친구(GFRIEND)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%97%AC%EC%9E%90%EC%B9%9C%EA%B5%AC(GFRIEND)-%EC%97%AC%EC%9E%90%EC%B9%9C%EA%B5%AC%20Summer%20Mini%20Album%20'Sunny%20Summer'_2020_11_24.jpg",
          },
          {
            title: "SHAKE IT",
            singer: ["씨스타(Sistar)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%94%A8%EC%8A%A4%ED%83%80(Sistar)-SHAKE%20IT_2020_11_23.jpg",
          },
          {
            title: "ICY",
            singer: ["ITZY (있지)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/ITZY%20(%EC%9E%88%EC%A7%80)-IT'z%20ICY_2020_11_24.jpg",
          },
          {
            title: "Why Don’t You Know (Feat. 넉살)",
            singer: ["청하"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%B2%AD%ED%95%98-Hands%20on%20Me.jpg",
          },
          {
            title: "1도 없어",
            singer: ["Apink (에이핑크)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Apink%20(%EC%97%90%EC%9D%B4%ED%95%91%ED%81%AC)-ONE%20&%20SIX.jpg",
          },
          {
            title: "Bubble Pop!",
            singer: ["현아"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%ED%98%84%EC%95%84-Bubble%20Pop!_2020_11_18.jpg",
          },
          {
            title: "Gee",
            singer: ["소녀시대 (GIRLS' GENERATION)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%86%8C%EB%85%80%EC%8B%9C%EB%8C%80%20(GIRLS'%20GENERATION)-The%20First%20Mini%20Album%20-%20Gee_2020_11_18.jpg",
          },
          {
            title: "Joa Yo!",
            singer: ["에이오에이(AOA)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%97%90%EC%9D%B4%EC%98%A4%EC%97%90%EC%9D%B4(AOA)-%EB%8B%A8%EB%B0%9C%EB%A8%B8%EB%A6%AC.jpg",
          },
          {
            title: "살짝 설렜어 (Nonstop)",
            singer: ["오마이걸(OH MY GIRL)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%98%A4%EB%A7%88%EC%9D%B4%EA%B1%B8(OH%20MY%20GIRL)-NONSTOP_2021_06_25.jpg",
          },
          {
            title: "CHEER UP",
            singer: ["TWICE (트와이스)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/TWICE%20(%ED%8A%B8%EC%99%80%EC%9D%B4%EC%8A%A4)-PAGE%20TWO_2020_11_24.jpg",
          },
          {
            title: "Be Yourself",
            singer: ["청하"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%B2%AD%ED%95%98-Be%20Yourself.newwav.jpg",
          },
          {
            title: "마지막처럼",
            singer: ["BLACKPINK"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/BLACKPINK-%EB%A7%88%EC%A7%80%EB%A7%89%EC%B2%98%EB%9F%BC_2021_01_19.jpg",
          },
          {
            title: "Hot Issue",
            singer: ["포미닛(4minute)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%ED%8F%AC%EB%AF%B8%EB%8B%9B(4minute)-For%20Muzik.jpg",
          },
          {
            title: "빙글뱅글 (Bingle Bangle)",
            singer: ["에이오에이(AOA)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%97%90%EC%9D%B4%EC%98%A4%EC%97%90%EC%9D%B4(AOA)-BINGLE%20BANGLE_2020_11_25.jpg",
          },
          {
            title: "A",
            singer: ["레인보우(Rainbow)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%A0%88%EC%9D%B8%EB%B3%B4%EC%9A%B0(Rainbow)-So%20%E5%A5%B3.jpg",
          },
          {
            title: "FANCY",
            singer: ["TWICE (트와이스)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/TWICE%20(%ED%8A%B8%EC%99%80%EC%9D%B4%EC%8A%A4)-FANCY%20YOU_2021_01_20.jpg",
          },
          {
            title: "LIAR LIAR (Chinese Ver.)",
            singer: ["오마이걸(OH MY GIRL)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%98%A4%EB%A7%88%EC%9D%B4%EA%B1%B8(OH%20MY%20GIRL)-WINDY%20DAY.jpg",
          },
          {
            title: "YooHoo (유후)",
            singer: ["시크릿(Secret)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%8B%9C%ED%81%AC%EB%A6%BF(Secret)-Letter%20From%20Secret.jpg",
          },
          {
            title: "심쿵해 (Heart Attack)",
            singer: ["에이오에이(AOA)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%97%90%EC%9D%B4%EC%98%A4%EC%97%90%EC%9D%B4(AOA)-Heart%20Attack.jpg",
          },
          {
            title: "Roly-Poly",
            singer: ["티아라(T-ara)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%ED%8B%B0%EC%95%84%EB%9D%BC(T-ara)-%EC%A1%B4%ED%8A%B8%EB%9D%BC%EB%B3%BC%ED%83%80%20%EC%9B%8C%EB%84%88%EB%B9%84_2020_11_24.jpg",
          },
          {
            title: "Shampoo",
            singer: ["애프터 스쿨(After School)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%95%A0%ED%94%84%ED%84%B0%20%EC%8A%A4%EC%BF%A8(After%20School)-Virgin.jpg",
          },
          {
            title: "HAPPY",
            singer: ["우주소녀"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%9A%B0%EC%A3%BC%EC%86%8C%EB%85%80-HAPPY%20MOMENT.jpg",
          },
          {
            title: "Lip & Hip",
            singer: ["현아"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%ED%98%84%EC%95%84-Lip%20&%20Hip_2020_11_17.jpg",
          },
          {
            title: "빨개요",
            singer: ["현아"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%ED%98%84%EC%95%84-A%20Talk.jpg",
          },
          {
            title: "벌써 12시",
            singer: ["청하"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%B2%AD%ED%95%98-%EB%B2%8C%EC%8D%A8%2012%EC%8B%9C.jpg",
          },
          {
            title: "Roller Coaster",
            singer: ["청하"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%B2%AD%ED%95%98-Offset.jpg",
          },
        ],
      },
      {
        ch_name: "생각이 많아질 때 들으면 좋은 팝",
        ch_img:
          "https://api2.indj.club/file/fetchImage/thumbnail/12732_album.png",
        ch_song: [
          {
            title: "Rocket Man (In The Style Of Elton John)",
            singer: ["Simply The Best Sax: The Hits Of Elton John"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Simply%20The%20Best%20Sax-%20The%20Hits%20Of%20Elton%20John-Simply%20The%20Best%20Sax-%20The%20Hits%20Of%20Elton%20John.jpg",
          },
          {
            title: "Thank You",
            singer: ["Dido(다이도)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Dido(%EB%8B%A4%EC%9D%B4%EB%8F%84)-No%20Angel.jpg",
          },
          {
            title: "Love Is A Losing Game",
            singer: ["Amy Winehouse(에이미 와인하우스)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Amy%20Winehouse(%EC%97%90%EC%9D%B4%EB%AF%B8%20%EC%99%80%EC%9D%B8%ED%95%98%EC%9A%B0%EC%8A%A4)-Back%20To%20Black%20(Deluxe%20Edition).jpg",
          },
          {
            title: "All You Need Is Love (Remastered 2015)",
            singer: ["The Beatles(비틀스)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/The%20Beatles(%EB%B9%84%ED%8B%80%EC%8A%A4)-1%20(Remastered).jpg",
          },
          {
            title: "Piano Man",
            singer: ["Billy Joel(빌리 조엘)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Billy%20Joel(%EB%B9%8C%EB%A6%AC%20%EC%A1%B0%EC%97%98)-Piano%20Man.jpg",
          },
          {
            title: "Love Yourself",
            singer: ["Justin Bieber(저스틴 비버)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Justin%20Bieber(%EC%A0%80%EC%8A%A4%ED%8B%B4%20%EB%B9%84%EB%B2%84)-Purpose%20(Deluxe)_2021_06_25.jpg",
          },
          {
            title: "Julia (Remastered 2009)",
            singer: ["The Beatles(비틀스)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/The%20Beatles(%EB%B9%84%ED%8B%80%EC%8A%A4)-The%20Beatles%20(Remastered)_2020_11_25.jpg",
          },
          {
            title: "Wish You Were Here",
            singer: ["Pink Floyd(핑크 플로이드)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Pink%20Floyd(%ED%95%91%ED%81%AC%20%ED%94%8C%EB%A1%9C%EC%9D%B4%EB%93%9C)-Wish%20You%20Were%20Here.jpg",
          },
          {
            title: "Paint It, Black",
            singer: ["The Rolling Stones(롤링 스톤즈)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/The%20Rolling%20Stones(%EB%A1%A4%EB%A7%81%20%EC%8A%A4%ED%86%A4%EC%A6%88)-Aftermath.jpg",
          },
          {
            title: "Somebody To Love (2011 Mix)",
            singer: ["Queen(퀸)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Queen(%ED%80%B8)-%EB%B3%B4%ED%97%A4%EB%AF%B8%EC%95%88%20%EB%9E%A9%EC%86%8C%EB%94%94%20(Bohemian%20Rhapsody)%20OST_2020_11_25.jpg",
          },
          {
            title: "American Pie (Richard 'Humpty' Vission Radio Mix)",
            singer: ["Madonna(마돈나)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Madonna(%EB%A7%88%EB%8F%88%EB%82%98)-American%20Pie.jpg",
          },
          {
            title: "Yesterday (Remastered 2009)",
            singer: ["The Beatles(비틀스)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/The%20Beatles(%EB%B9%84%ED%8B%80%EC%8A%A4)-Help!%20(Remastered).jpg",
          },
          {
            title: "Sun In My Morning",
            singer: ["Bee Gees(비지스)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Bee%20Gees(%EB%B9%84%EC%A7%80%EC%8A%A4)-Tales%20From%20The%20Brothers%20Gibb.jpg",
          },
          {
            title: "Homesick",
            singer: ["Kings Of Convenience(킹스 오브 컨비니언스)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Kings%20Of%20Convenience(%ED%82%B9%EC%8A%A4%20%EC%98%A4%EB%B8%8C%20%EC%BB%A8%EB%B9%84%EB%8B%88%EC%96%B8%EC%8A%A4)-Riot%20On%20An%20Empty%20Street_2020_11_12.jpg",
          },
          {
            title: "Just The Way You Are",
            singer: ["Diana Krall(다이애나 크롤)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Diana%20Krall(%EB%8B%A4%EC%9D%B4%EC%95%A0%EB%82%98%20%ED%81%AC%EB%A1%A4)-Live%20In%20Paris.jpg",
          },
          {
            title: "Bohemian Rhapsody (2011 Mix)",
            singer: ["Queen(퀸)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Queen(%ED%80%B8)-%EB%B3%B4%ED%97%A4%EB%AF%B8%EC%95%88%20%EB%9E%A9%EC%86%8C%EB%94%94%20(Bohemian%20Rhapsody)%20OST_2020_11_25.jpg",
          },
          {
            title: "It's So Hard To Say Goodbye To Yesterday",
            singer: ["Jason Mraz(제이슨 므라즈)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Jason%20Mraz(%EC%A0%9C%EC%9D%B4%EC%8A%A8%20%EB%AF%80%EB%9D%BC%EC%A6%88)-Yes!_2020_11_25.jpg",
          },
          {
            title: "Stay With Me",
            singer: ["Sam Smith(샘 스미스)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Sam%20Smith(%EC%83%98%20%EC%8A%A4%EB%AF%B8%EC%8A%A4)-In%20The%20Lonely%20Hour_2021_06_25.jpg",
          },
          {
            title: "Love On The Brain",
            singer: ["Rihanna(리아나)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Rihanna(%EB%A6%AC%EC%95%84%EB%82%98)-ANTI%20(Deluxe)_2020_11_11.jpg",
          },
          {
            title: "My Sweet Lady",
            singer: ["John Denver(존 덴버)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/John%20Denver(%EC%A1%B4%20%EB%8D%B4%EB%B2%84)-Poems,%20Prayers%20And%20Promises.jpg",
          },
          {
            title: "Sunshine On My Shoulders",
            singer: ["John Denver(존 덴버)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/John%20Denver(%EC%A1%B4%20%EB%8D%B4%EB%B2%84)-Poems,%20Prayers%20And%20Promises.jpg",
          },
          {
            title: "Let It Be (Remastered 2015)",
            singer: ["The Beatles(비틀스)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/The%20Beatles(%EB%B9%84%ED%8B%80%EC%8A%A4)-1%20(Remastered).jpg",
          },
          {
            title: "Wonderwall",
            singer: ["Oasis(오아시스)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Oasis(%EC%98%A4%EC%95%84%EC%8B%9C%EC%8A%A4)-(What's%20The%20Story)%20Morning%20Glory_2021_06_25.jpg",
          },
          {
            title: "Make You Feel My Love",
            singer: ["Adele(아델)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Adele(%EC%95%84%EB%8D%B8)-19_2020_11_12.jpg",
          },
          {
            title: "Where Does The Time Go",
            singer: ["A Great Big World(어 그레이트 빅 월드)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/A%20Great%20Big%20World(%EC%96%B4%20%EA%B7%B8%EB%A0%88%EC%9D%B4%ED%8A%B8%20%EB%B9%85%20%EC%9B%94%EB%93%9C)-When%20The%20Morning%20Comes.jpg",
          },
          {
            title: "Maxwell's Silver Hammer (Remastered 2009)",
            singer: ["The Beatles(비틀스)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/The%20Beatles(%EB%B9%84%ED%8B%80%EC%8A%A4)-Abbey%20Road%20(Remastered).jpg",
          },
          {
            title: "i hate u, i love u (feat. olivia o'brien) (album ver.)",
            singer: ["gnash(내쉬)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/gnash(%EB%82%B4%EC%89%AC)-us.jpg",
          },
          {
            title: "Help! (Remastered 2009)",
            singer: ["The Beatles(비틀스)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/The%20Beatles(%EB%B9%84%ED%8B%80%EC%8A%A4)-Help!%20(Remastered).jpg",
          },
          {
            title: "The Blower's Daughter",
            singer: ["Damien Rice(데미안 라이스)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Damien%20Rice(%EB%8D%B0%EB%AF%B8%EC%95%88%20%EB%9D%BC%EC%9D%B4%EC%8A%A4)-O.jpg",
          },
        ],
      },
      {
        ch_name: "자꾸자꾸 생각나",
        ch_img:
          "https://api2.indj.club/file/fetchImage/thumbnail/151966_album.png",
        ch_song: [
          {
            title: "상상해봄",
            singer: ["헬로제이(Hello J)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%ED%97%AC%EB%A1%9C%EC%A0%9C%EC%9D%B4(Hello%20J)-%EC%83%81%EC%83%81%ED%95%B4%EB%B4%84.jpg",
          },
          {
            title: "백설공주",
            singer: ["로맨틱멜로디초비"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%A1%9C%EB%A7%A8%ED%8B%B1%EB%A9%9C%EB%A1%9C%EB%94%94%EC%B4%88%EB%B9%84-%EB%B0%B1%EC%84%A4%EA%B3%B5%EC%A3%BC.jpg",
          },
          {
            title: "사랑해",
            singer: ["스윗소로우(SWEET SORROW)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%8A%A4%EC%9C%97%EC%86%8C%EB%A1%9C%EC%9A%B0(SWEET%20SORROW)-SweeticS.jpg",
          },
          {
            title: "시작은 왈츠로",
            singer: ["스웨터(Sweater)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%8A%A4%EC%9B%A8%ED%84%B0(Sweater)-Highlights.jpg",
          },
          {
            title: "바다",
            singer: ["우쿨렐레 피크닉(Ukulele Picnic)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%9A%B0%EC%BF%A8%EB%A0%90%EB%A0%88%20%ED%94%BC%ED%81%AC%EB%8B%89(Ukulele%20Picnic)-%EC%95%8C%EB%A1%9C%ED%95%98,%20%EA%B8%B0%EB%B6%84%20%EC%A2%8B%EC%9D%80%20%EC%9D%B8%EC%82%AC.jpg",
          },
          {
            title: "My Favorite Song",
            singer: ["소규모 아카시아 밴드"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%86%8C%EA%B7%9C%EB%AA%A8%20%EC%95%84%EC%B9%B4%EC%8B%9C%EC%95%84%20%EB%B0%B4%EB%93%9C-%EC%9A%B0%EB%A6%AC%EB%8A%94%20%EC%86%8C%EA%B7%9C%EB%AA%A8%20%EC%95%84%EC%B9%B4%EC%8B%9C%EC%95%84%20%EB%B0%B4%EB%93%9C%EC%9E%85%EB%8B%88%EB%8B%A4.jpg",
          },
          {
            title: "깊은 새벽으로 달려가는 우리의 꿈",
            singer: ["망각화"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%A7%9D%EA%B0%81%ED%99%94-The%20Rumor.jpg",
          },
          {
            title: "Cherry",
            singer: ["미스티 블루(Misty Blue)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%AF%B8%EC%8A%A4%ED%8B%B0%20%EB%B8%94%EB%A3%A8(Misty%20Blue)-%EB%84%88%EC%9D%98%20%EB%B3%84%20%EC%9D%B4%EB%A6%84%EC%9D%80%20%EC%8B%9C%EB%A6%AC%EC%9A%B0%EC%8A%A4%20B.jpg",
          },
          {
            title: "두근두근",
            singer: ["브로콜리너마저"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%B8%8C%EB%A1%9C%EC%BD%9C%EB%A6%AC%EB%84%88%EB%A7%88%EC%A0%80-%EA%B3%A8%EB%93%A0-%ED%9E%9B%ED%8A%B8%20%EB%AA%A8%EC%9D%8C%EC%A7%91%20%5B%EC%95%B5%EC%BD%9C%EC%9A%94%EC%B2%AD%EA%B8%88%EC%A7%80.%5D_2020_11_25.jpg",
          },
          {
            title: "콩깍지",
            singer: ["하은"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%ED%95%98%EC%9D%80-Finding%20For%20Love.jpg",
          },
          {
            title: "Show Me Love",
            singer: ["라이너스의 담요(Linus' Blanket)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%9D%BC%EC%9D%B4%EB%84%88%EC%8A%A4%EC%9D%98%20%EB%8B%B4%EC%9A%94(Linus'%20Blanket)-Show%20Me%20Love_2020_11_18.jpg",
          },
          {
            title: "향을 담은 비 (For Haru)",
            singer: ["파니핑크(Fanny Fink)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%ED%8C%8C%EB%8B%88%ED%95%91%ED%81%AC(Fanny%20Fink)-Mr.Romance.jpg",
          },
          {
            title: "짜릿한 입맞춤 (feat. 이한철)",
            singer: ["소히(Sorri)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%86%8C%ED%9E%88(Sorri)-Mingle.jpg",
          },
          {
            title: "사랑해",
            singer: ["요조(Yozoh)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%9A%94%EC%A1%B0(Yozoh)-%EC%9A%B0%EB%A6%AC%EB%8A%94%20%EC%84%A0%EC%B2%98%EB%9F%BC%20%EA%B0%80%EB%A7%8C%ED%9E%88%20%EB%88%84%EC%9B%8C_2020_11_23.jpg",
          },
          {
            title: "입술이 달빛 (어쿠스틱 버전) (Bonus Track)",
            singer: ["소규모 아카시아 밴드"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%86%8C%EA%B7%9C%EB%AA%A8%20%EC%95%84%EC%B9%B4%EC%8B%9C%EC%95%84%20%EB%B0%B4%EB%93%9C-%EC%9E%85%EC%88%A0%EC%9D%B4%20%EB%8B%AC%EB%B9%9B.jpg",
          },
          {
            title: "Acoustic Breath",
            singer: ["한희정"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%ED%95%9C%ED%9D%AC%EC%A0%95-%EB%81%88.jpg",
          },
          {
            title: "Shooting Star (feat. Yozoh)",
            singer: ["킹스턴 루디스카(Kingston Rudieska)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%ED%82%B9%EC%8A%A4%ED%84%B4%20%EB%A3%A8%EB%94%94%EC%8A%A4%EC%B9%B4(Kingston%20Rudieska)-Skafiction.jpg",
          },
          {
            title: "좋아요",
            singer: ["좋아서하는밴드"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%A2%8B%EC%95%84%EC%84%9C%ED%95%98%EB%8A%94%EB%B0%B4%EB%93%9C-%EC%9D%B8%EC%83%9D%EC%9D%80%20%EC%95%8C%20%EC%88%98%EA%B0%80%20%EC%97%86%EC%96%B4.jpg",
          },
          {
            title: "봄의 시작",
            singer: ["이진우"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%9D%B4%EC%A7%84%EC%9A%B0-%EC%A3%BC%EB%B3%80%EC%9D%B8.jpg",
          },
          {
            title: "Sunshine",
            singer: ["짙은(Zitten)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%A7%99%EC%9D%80(Zitten)-SAVe%20tHE%20AiR-%20Green%20Concert.jpg",
          },
          {
            title: "선택 (Original Song. Afternoon 박경환)",
            singer: ["사보(Sabo)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%82%AC%EB%B3%B4(Sabo)-Ten%20Years%20After-%205th%20Single%20(10th%20Anniversary%20Pastel%20Music).jpg",
          },
          {
            title: "정원 (New)",
            singer: ["달에닿아"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%8B%AC%EC%97%90%EB%8B%BF%EC%95%84-%EB%84%88%EB%A5%BC%20%EB%85%B8%EB%9E%98%ED%95%B4.jpg",
          },
        ],
      },
    ],
  },

  {
    mod: "당신은",
    title: "열정맨 폭염",
    desc: [
      "태양같이 이글거리는 열정맨 이시네요..! 매사에 따뜻하고 적극적인 사람입니다. 책임감이 있어 자신의 몫과 역할에 매우 충실합니다.",
      "남에게 자신이 어떻게 비치는지 약간은 신경 쓰지만 결국은 내가 하고 싶은 대로 해요. 자기에 대한 걱정도 많은데 높은 공감 능력으로 주변 사람들에 대한 걱정도 많아요. 그래서 전에 들었던 얘기가 어떻게 됐는지 결론을 꼭 들어야 해요.",
      "여러 분야에 관심이 많아 이것저것 시도해보지만 쉽게 몰두하는 만큼 쉽게 싫증이 나요. 그래서 깊지 않은 여러 개의 취미가 많아요. 멘탈이 강한 편이라 웬만한 일로 상처를 받지 않고 상처를 받아도 자신에게 좋지 않다고 판단하고 금방 잊어버려요.",
    ],
    img: "sun",
    thum: "https://d3coxo32tidngo.cloudfront.net/image/mbti/sun_miri.png",
    miri: "https://d3coxo32tidngo.cloudfront.net/image/mbti/sun_miri.png",
    channel: [
      {
        ch_name: "쇼미더열정!! 힙한 그루브로 중량의 고통을 이겨보아요",
        ch_img: "https://api2.indj.club/file/fetchImage/thumbnail/6747.png",
        ch_song: [
          {
            title: "CCTV (Feat. Leellamarz)",
            singer: ["Lil tachi", "BIG Naughty (서동현)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Lil%20tachi,%20BIG%20Naughty%20(%EC%84%9C%EB%8F%99%ED%98%84)-%EC%87%BC%EB%AF%B8%EB%8D%94%EB%A8%B8%EB%8B%88%208%20Episode%203.jpg",
          },
          {
            title: "BERMUDA TRIANGLE (Feat. Crush, DEAN)",
            singer: ["지코"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%A7%80%EC%BD%94-BERMUDA%20TRIANGLE.jpg",
          },
          {
            title: "감아 (feat. Crush)",
            singer: ["로꼬"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%A1%9C%EA%BC%AC-%EA%B0%90%EC%95%84.jpg",
          },
          {
            title: "미뤄 (Feat. Jayci yucca(제이씨 유카))",
            singer: ["뎁트(Dept)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%8E%81%ED%8A%B8(Dept)-%EB%AF%B8%EB%A4%84.jpg",
          },
          {
            title: "POSE! (Feat. 염따)",
            singer: ["사이먼 도미닉"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%82%AC%EC%9D%B4%EB%A8%BC%20%EB%8F%84%EB%AF%B8%EB%8B%89-%ED%99%94%EA%B8%B0%EC%97%84%EA%B8%88_2020_11_17.jpg",
          },
          {
            title: "파티피플 (Feat. 염따, Uneducated Kid)",
            singer: ["기리보이"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EA%B8%B0%EB%A6%AC%EB%B3%B4%EC%9D%B4-%EA%B0%91%EB%B6%84%EA%B8%B0.jpg",
          },
          {
            title: "[19금]자화상",
            singer: ["비와이(BewhY)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%B9%84%EC%99%80%EC%9D%B4(BewhY)-Time%20Travel.jpg",
          },
          {
            title: "필라멘트 (Feat. BSK A.K.A 김범수)",
            singer: ["넉살(Nucksal)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%84%89%EC%82%B4(Nucksal)-%EC%87%BC%EB%AF%B8%EB%8D%94%EB%A8%B8%EB%8B%88%206%20-%20Episode%204.jpg",
          },
          {
            title: "[19금]BAND",
            singer: [
              "창모(CHANGMO)",
              "해쉬 스완(Hash Swan)",
              "ASH ISLAND",
              "김효은",
            ],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%B0%BD%EB%AA%A8(CHANGMO),%20%ED%95%B4%EC%89%AC%20%EC%8A%A4%EC%99%84(Hash%20Swan),%20ASH%20ISLAND,%20%EA%B9%80%ED%9A%A8%EC%9D%80-BAND.jpg",
          },
          {
            title: "1llusion",
            singer: ["도끼(Dok2)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%8F%84%EB%81%BC(Dok2)-1llusion.jpg",
          },
          {
            title: "[19금]BO$$ (feat. Yultron, 로꼬 & Ugly Duck)",
            singer: ["박재범(Jay Park)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%B0%95%EC%9E%AC%EB%B2%94(Jay%20Park)-WORLDWIDE.jpg",
          },
          {
            title: "MTLA (Feat. Masta Wu)",
            singer: ["E SENS"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/E%20SENS-MTLA.jpg",
          },
          {
            title: "Flip (Feat. Beenzino)",
            singer: ["프니엘(PENIEL)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%ED%94%84%EB%8B%88%EC%97%98(PENIEL)-Flip%20(Feat.%20Beenzino).jpg",
          },
          {
            title: "요즘것들 (Feat. ZICO, DEAN)",
            singer: [
              "행주",
              "양홍원",
              "해쉬 스완(Hash Swan)",
              "킬라그램(Killagramz)",
            ],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%ED%96%89%EC%A3%BC,%20%EC%96%91%ED%99%8D%EC%9B%90,%20%ED%95%B4%EC%89%AC%20%EC%8A%A4%EC%99%84(Hash%20Swan),%20%ED%82%AC%EB%9D%BC%EA%B7%B8%EB%9E%A8(Killagramz)-%EC%87%BC%EB%AF%B8%EB%8D%94%EB%A8%B8%EB%8B%88%206%20-%20Episode%201.jpg",
          },
          {
            title: "Day Day (Feat. 박재범) (Prod. by GRAY)",
            singer: ["비와이(BewhY)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%B9%84%EC%99%80%EC%9D%B4(BewhY)-%EC%87%BC%EB%AF%B8%EB%8D%94%EB%A8%B8%EB%8B%88%205%20-%20Episode%204.jpg",
          },
          {
            title: "방사능 (Bangsaneung)",
            singer: ["리듬파워"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%A6%AC%EB%93%AC%ED%8C%8C%EC%9B%8C-%EB%B0%A9%EC%82%AC%EB%8A%A5%20(Bangsaneung).jpg",
          },
          {
            title: "띵 (Prod. By 기리보이)",
            singer: ["Jvcki Wai", "양홍원", "Osshun Gum", "한요한"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Jvcki%20Wai,%20%EC%96%91%ED%99%8D%EC%9B%90,%20Osshun%20Gum,%20%ED%95%9C%EC%9A%94%ED%95%9C-Dingo%20X%20Indigo%20Music_2021_03_24.jpg",
          },
          {
            title: "Momo (Feat. 개리)",
            singer: ["저스디스(JUSTHIS)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%A0%80%EC%8A%A4%EB%94%94%EC%8A%A4(JUSTHIS)-Momo.jpg",
          },
          {
            title: "[19금]Weathermen",
            singer: ["메킷레인(MKIT RAIN)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%A9%94%ED%82%B7%EB%A0%88%EC%9D%B8(MKIT%20RAIN)-Weathermen.jpg",
          },
          {
            title: "Fader (feat. 김효은)",
            singer: ["Jayci yucca(제이씨 유카)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Jayci%20yucca(%EC%A0%9C%EC%9D%B4%EC%94%A8%20%EC%9C%A0%EC%B9%B4)-Fader%20(feat.%20%EA%B9%80%ED%9A%A8%EC%9D%80).jpg",
          },
        ],
      },
      {
        ch_name: "폭염에서 나를 구해줄 여름맛 EDM ",
        ch_img:
          "https://api2.indj.club/file/fetchImage/thumbnail/7420_album.png",
        ch_song: [
          {
            title: "Ur Eyez (feat. Al Wright)",
            singer: ["Peking Duk(패킹 덕)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Peking%20Duk(%ED%8C%A8%ED%82%B9%20%EB%8D%95)-Ur%20Eyez%20(feat.%20Al%20Wright).jpg",
          },
          {
            title: "Dream Dream Dream",
            singer: ["Madeon(마데온)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Madeon(%EB%A7%88%EB%8D%B0%EC%98%A8)-Dream%20Dream%20Dream.jpg",
          },
          {
            title: "Summer Lover (feat. Devin & Nile Rodgers)",
            singer: ["Oliver Heldens(올리버 헬든스)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Oliver%20Heldens(%EC%98%AC%EB%A6%AC%EB%B2%84%20%ED%97%AC%EB%93%A0%EC%8A%A4)-Summer%20Lover%20(feat.%20Devin%20&%20Nile%20Rodgers).jpg",
          },
          {
            title: "Kind of Blue",
            singer: ["Hellberg"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Hellberg-Kind%20Of%20Blue.jpg",
          },
          {
            title: "With You (Kaskade Club Mix)",
            singer: ["Kaskade(캐스케이드)", "Meghan Trainor(메간 트레이너)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Kaskade(%EC%BA%90%EC%8A%A4%EC%BC%80%EC%9D%B4%EB%93%9C),%20Meghan%20Trainor(%EB%A9%94%EA%B0%84%20%ED%8A%B8%EB%A0%88%EC%9D%B4%EB%84%88)-With%20You%20-%20The%20Remixes.jpg",
          },
          {
            title: "Higher Love",
            singer: ["Kygo(카이고)", "Whitney Houston(휘트니 휴스턴)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Kygo(%EC%B9%B4%EC%9D%B4%EA%B3%A0),%20Whitney%20Houston(%ED%9C%98%ED%8A%B8%EB%8B%88%20%ED%9C%B4%EC%8A%A4%ED%84%B4)-Higher%20Love.jpg",
          },
          {
            title: "Wish You Well",
            singer: ["Sigala(시갈라)", "Becky Hill(베키 힐)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Sigala(%EC%8B%9C%EA%B0%88%EB%9D%BC),%20Becky%20Hill(%EB%B2%A0%ED%82%A4%20%ED%9E%90)-Wish%20You%20Well.jpg",
          },
          {
            title: "Born To Be Yours",
            singer: ["Kygo(카이고)", "Imagine Dragons(이매진 드래곤스)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Kygo(%EC%B9%B4%EC%9D%B4%EA%B3%A0),%20Imagine%20Dragons(%EC%9D%B4%EB%A7%A4%EC%A7%84%20%EB%93%9C%EB%9E%98%EA%B3%A4%EC%8A%A4)-Born%20To%20Be%20Yours_2020_11_24.jpg",
          },
          {
            title: "Learn To Lose (feat. Alex Clare)",
            singer: ["Bakermat(베이커맷)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Bakermat(%EB%B2%A0%EC%9D%B4%EC%BB%A4%EB%A7%B7)-Learn%20To%20Lose%20(feat.%20Alex%20Clare).jpg",
          },
          {
            title: "Things Fall Apart",
            singer: ["ayokay(에이요케이)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/ayokay(%EC%97%90%EC%9D%B4%EC%9A%94%EC%BC%80%EC%9D%B4)-Things%20Fall%20Apart.jpg",
          },
          {
            title: "Lalala",
            singer: ["Y2K", "bbno$"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Y2K,%20bbno$-Lalala.jpg",
          },
          {
            title: "Lullaby",
            singer: ["Sigala(시갈라)", "Paloma Faith(팔로마 페이스)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Sigala(%EC%8B%9C%EA%B0%88%EB%9D%BC),%20Paloma%20Faith(%ED%8C%94%EB%A1%9C%EB%A7%88%20%ED%8E%98%EC%9D%B4%EC%8A%A4)-Brighter%20Days.jpg",
          },
          {
            title: "Who Do You Love",
            singer: [
              "The Chainsmokers(체인스모커스)",
              "5 Seconds Of Summer(파이브 세컨즈 오브 썸머)",
            ],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/The%20Chainsmokers(%EC%B2%B4%EC%9D%B8%EC%8A%A4%EB%AA%A8%EC%BB%A4%EC%8A%A4),%205%20Seconds%20Of%20Summer(%ED%8C%8C%EC%9D%B4%EB%B8%8C%20%EC%84%B8%EC%BB%A8%EC%A6%88%20%EC%98%A4%EB%B8%8C%20%EC%8D%B8%EB%A8%B8)-World%20War%20Joy_2020_11_24.jpg",
          },
          {
            title: "Instagram (feat. Afro Bros, Natti Natasha, Dimitri Vegas)",
            singer: [
              "Dimitri Vegas & Like Mike(디미트리 베가스 & 라이크 마이크)",
              "David Guetta(데이빗 게타)",
              "Daddy Yankee(대디 양키)",
            ],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Dimitri%20Vegas%20&%20Like%20Mike(%EB%94%94%EB%AF%B8%ED%8A%B8%EB%A6%AC%20%EB%B2%A0%EA%B0%80%EC%8A%A4%20&%20%EB%9D%BC%EC%9D%B4%ED%81%AC%20%EB%A7%88%EC%9D%B4%ED%81%AC),%20David%20Guetta(%EB%8D%B0%EC%9D%B4%EB%B9%97%20%EA%B2%8C%ED%83%80),%20Daddy%20Yankee(%EB%8C%80%EB%94%94%20%EC%96%91%ED%82%A4)-Instagram%20(feat.%20Afro%20Bros,%20Natti%20Natasha,%20Dimitri%20Vegas).jpg",
          },
          {
            title: "On My Way (배틀그라운드 모바일 배경음악)",
            singer: [
              "Alan Walker(알렌 워커)",
              "Sabrina Carpenter(사브리나 카펜터)",
              "Farruko",
            ],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Alan%20Walker(%EC%95%8C%EB%A0%8C%20%EC%9B%8C%EC%BB%A4),%20Sabrina%20Carpenter(%EC%82%AC%EB%B8%8C%EB%A6%AC%EB%82%98%20%EC%B9%B4%ED%8E%9C%ED%84%B0),%20Farruko-On%20My%20Way.jpg",
          },
          {
            title: "Rave (feat. Kris Kiss)",
            singer: [
              "Steve Aoki(스티브 아오키)",
              "Showtek(쇼텍)",
              "MAKJ(맥제이)",
            ],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Steve%20Aoki(%EC%8A%A4%ED%8B%B0%EB%B8%8C%20%EC%95%84%EC%98%A4%ED%82%A4),%20Showtek(%EC%87%BC%ED%85%8D),%20MAKJ(%EB%A7%A5%EC%A0%9C%EC%9D%B4)-Rave%20(feat.%20Kris%20Kiss).jpg",
          },
          {
            title: "Things Fall Apart",
            singer: ["ayokay(에이요케이)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/ayokay(%EC%97%90%EC%9D%B4%EC%9A%94%EC%BC%80%EC%9D%B4)-we%20come%20alive.%20(Side%20A).jpg",
          },
          {
            title: "Let Me Love You",
            singer: ["Campsite Dream(캠프사이트 드림)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Campsite%20Dream(%EC%BA%A0%ED%94%84%EC%82%AC%EC%9D%B4%ED%8A%B8%20%EB%93%9C%EB%A6%BC)-Let%20Me%20Love%20You.jpg",
          },
          {
            title: "All My Friends",
            singer: ["Madeon(마데온)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Madeon(%EB%A7%88%EB%8D%B0%EC%98%A8)-Good%20Faith_2020_11_25.jpg",
          },
          {
            title: "Afterhours",
            singer: [
              "AJ Mitchell(에이제이 미첼)",
              "Nina Nesbitt(니나 네스빗)",
              "teamwork",
            ],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/AJ%20Mitchell(%EC%97%90%EC%9D%B4%EC%A0%9C%EC%9D%B4%20%EB%AF%B8%EC%B2%BC),%20Nina%20Nesbitt(%EB%8B%88%EB%82%98%20%EB%84%A4%EC%8A%A4%EB%B9%97),%20teamwork-Afterhours.jpg",
          },
          {
            title: "HELP",
            singer: ["AREA21"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/AREA21-HELP.jpg",
          },
          {
            title:
              "[19금]Summer Days (feat. Macklemore & Patrick Stump of Fall Out Boy) (feat. Macklemore, Patrick Stump) (시몬스 침대 광고 삽입곡)",
            singer: [
              "Martin Garrix(마틴 개릭스)",
              "Macklemore(맥클모어)",
              "Fall Out Boy(폴 아웃 보이)",
            ],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Martin%20Garrix(%EB%A7%88%ED%8B%B4%20%EA%B0%9C%EB%A6%AD%EC%8A%A4),%20Macklemore(%EB%A7%A5%ED%81%B4%EB%AA%A8%EC%96%B4),%20Fall%20Out%20Boy(%ED%8F%B4%20%EC%95%84%EC%9B%83%20%EB%B3%B4%EC%9D%B4)-Summer%20Days%20(feat.%20Macklemore%20&%20Patrick%20Stump%20of%20Fall%20Out%20Boy)_2020_11_24.jpg",
          },
          {
            title: "The Ocean (feat. Shy Martin) (Radio Edit)",
            singer: ["Mike Perry(마이크 페리)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Mike%20Perry(%EB%A7%88%EC%9D%B4%ED%81%AC%20%ED%8E%98%EB%A6%AC)-The%20Ocean%20(Radio%20Edit).jpg",
          },
        ],
      },
      {
        ch_name: "당신의 운동 부스터! 열정 가득한 신인 아이돌 뮤직",
        ch_img:
          "https://api2.indj.club/file/fetchImage/thumbnail/152786_album.png",
        ch_song: [
          {
            title: "WISH WISH",
            singer: ["리미트리스(LIMITLESS)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%A6%AC%EB%AF%B8%ED%8A%B8%EB%A6%AC%EC%8A%A4(LIMITLESS)-Wish%20Wish.jpg",
          },
          {
            title: "BLIND FOR LOVE",
            singer: ["AB6IX (에이비식스)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/AB6IX%20(%EC%97%90%EC%9D%B4%EB%B9%84%EC%8B%9D%EC%8A%A4)-6IXENSE.jpg",
          },
          {
            title: "BIRTHDAY",
            singer: ["전소미"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%A0%84%EC%86%8C%EB%AF%B8-BIRTHDAY_2020_11_18.jpg",
          },
          {
            title: "WAVE",
            singer: ["ATEEZ(에이티즈)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/ATEEZ(%EC%97%90%EC%9D%B4%ED%8B%B0%EC%A6%88)-TREASURE%20EP.3%20-%20One%20To%20All.jpg",
          },
          {
            title: "몽환극 (Dream Play)",
            singer: ["리미트리스(LIMITLESS)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%A6%AC%EB%AF%B8%ED%8A%B8%EB%A6%AC%EC%8A%A4(LIMITLESS)-%EB%AA%BD%ED%99%98%EA%B7%B9%20(Dream%20Play).jpg",
          },
          {
            title: "Jopping",
            singer: ["SuperM"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/SuperM-SuperM%20-%20The%201st%20Mini%20Album_2020_11_17.jpg",
          },
          {
            title: "Movie Star",
            singer: ["CIX (씨아이엑스)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/CIX%20(%EC%94%A8%EC%95%84%EC%9D%B4%EC%97%91%EC%8A%A4)-CIX%201st%20EP%20ALBUM%20'HELLO'%20Chapter%201.%20Hello,%20Stranger_2020_11_10.jpg",
          },
          {
            title: "I Like That",
            singer: ["이진혁"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%9D%B4%EC%A7%84%ED%98%81-S.O.L.jpg",
          },
          {
            title: "뭐해 (What are you up to)",
            singer: ["강다니엘(KANG DANIEL)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EA%B0%95%EB%8B%A4%EB%8B%88%EC%97%98(KANG%20DANIEL)-color%20on%20me.jpg",
          },
          {
            title: "어느날 머리에서 뿔이 자랐다 (CROWN)",
            singer: ["투모로우바이투게더"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%ED%88%AC%EB%AA%A8%EB%A1%9C%EC%9A%B0%EB%B0%94%EC%9D%B4%ED%88%AC%EA%B2%8C%EB%8D%94-%EA%BF%88%EC%9D%98%20%EC%9E%A5-%20STAR_2020_11_25.jpg",
          },
          {
            title: "WANT IT",
            singer: ["ITZY (있지)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/ITZY%20(%EC%9E%88%EC%A7%80)-IT'z%20Different_2021_01_20.jpg",
          },
          {
            title: "네가 참 좋아 (Really Really)",
            singer: ["체리블렛(Cherry Bullet)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%B2%B4%EB%A6%AC%EB%B8%94%EB%A0%9B(Cherry%20Bullet)-LOVE%20ADVENTURE_2020_11_24.jpg",
          },
          {
            title: "BLUE",
            singer: ["하성운"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%ED%95%98%EC%84%B1%EC%9A%B4-BXXX.jpg",
          },
          {
            title: "가자 (LIT)",
            singer: ["원어스(ONEUS)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%9B%90%EC%96%B4%EC%8A%A4(ONEUS)-FLY%20WITH%20US.jpg",
          },
          {
            title: "9와 4분의 3 승강장에서 너를 기다려 (Run Away)",
            singer: ["투모로우바이투게더"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%ED%88%AC%EB%AA%A8%EB%A1%9C%EC%9A%B0%EB%B0%94%EC%9D%B4%ED%88%AC%EA%B2%8C%EB%8D%94-%EA%BF%88%EC%9D%98%20%EC%9E%A5-%20MAGIC.jpg",
          },
          {
            title: "360",
            singer: ["박지훈"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%B0%95%EC%A7%80%ED%9B%88-360.jpg",
          },
          {
            title: "BREATHE",
            singer: ["AB6IX (에이비식스)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/AB6IX%20(%EC%97%90%EC%9D%B4%EB%B9%84%EC%8B%9D%EC%8A%A4)-B-COMPLETE.jpg",
          },
          {
            title: "TOUCHIN'",
            singer: ["강다니엘(KANG DANIEL)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EA%B0%95%EB%8B%A4%EB%8B%88%EC%97%98(KANG%20DANIEL)-TOUCHIN'.jpg",
          },
          {
            title: "ICY",
            singer: ["ITZY (있지)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/ITZY%20(%EC%9E%88%EC%A7%80)-IT'z%20ICY_2020_11_24.jpg",
          },
          {
            title: "HOLLYWOOD",
            singer: ["AB6IX (에이비식스)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/AB6IX%20(%EC%97%90%EC%9D%B4%EB%B9%84%EC%8B%9D%EC%8A%A4)-B-COMPLETE.jpg",
          },
        ],
      },
    ],
  },

  {
    mod: "당신은",
    title: "주변까지 맑은 기운을 퍼트리는 햇살",
    desc: [
      "센스가 넘치고 뛰어난 사교성으로 인기가 많고 어떤 그룹에서도 밝고 재미있는 분위기를 이끌어갑니다. 약간의 오지랖으로 조언을 해주는 것을 좋아하고 다른 사람 일에 관심이 많아 친구들 사이에서 상담가 역할을 하기도 해요.",
      "어색한 분위기를 싫어해요. 싸우는것도 싫어해요. 그래서 무리에서 중재자 역할을 자주하곤 해요.",
      "자기애가 넘치고 어떤 일이 자기 위주로 진행되는 걸 좋아해요. 하기 싫은 건 안 하는 성격에 주위에서 생각 없이 산다는 소리를 듣곤 해요. 기본적으로 사람을 좋아해 새로운 사람을 만나는 걸 두려워하지 않고 좋아해요. 친구의 친구는 곧 나의 친구예요.",
      "풍부한 감수성으로 노래를 듣다가 가사가 좋으면 혼자 심취해 노래를 부르기도 해요. 혼자 노래방에 가서 부르고 싶은 노래를 다 부르는 것도 좋아합니다. 친구들과 노는 것을 좋아하는 자유로운 영혼이지만 가끔은 내 시간이 필요하다고 느껴요.",
    ],
    img: "shine",
    thum: "https://d3coxo32tidngo.cloudfront.net/image/mbti/shine_miri.png",
    miri: "https://d3coxo32tidngo.cloudfront.net/image/mbti/shine_miri.png",
    channel: [
      {
        ch_name: "다정한 햇살같은 그대, 그리고 봄",
        ch_img:
          "https://api2.indj.club/file/fetchImage/thumbnail/6414_album.png",
        ch_song: [
          {
            title: "괜찮아",
            singer: ["닐로(Nilo)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%8B%90%EB%A1%9C(Nilo)-%EA%B4%9C%EC%B0%AE%EC%95%84.jpg",
          },
          {
            title: "여름밤",
            singer: ["스웨덴세탁소"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%8A%A4%EC%9B%A8%EB%8D%B4%EC%84%B8%ED%83%81%EC%86%8C-%EC%97%AC%EB%A6%84%EB%B0%A4.jpg",
          },
          {
            title: "그 날 (feat. 헤이즐)",
            singer: ["에이치코드(H:Code)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%97%90%EC%9D%B4%EC%B9%98%EC%BD%94%EB%93%9C(H-Code)-%EC%B2%AB%20%EB%B2%88%EC%A7%B8%20%EC%9D%B4%EC%95%BC%EA%B8%B0.jpg",
          },
          {
            title: "그대는",
            singer: ["스웨덴세탁소"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%8A%A4%EC%9B%A8%EB%8D%B4%EC%84%B8%ED%83%81%EC%86%8C-%EC%97%AC%EB%A6%84%EB%B0%A4.jpg",
          },
          {
            title: "나를 좋아하지 않는 그대에게",
            singer: ["박원"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%B0%95%EC%9B%90-Like%20A%20Wonder_2020_11_20.jpg",
          },
          {
            title: "담담하게",
            singer: ["Lucia(심규선)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Lucia(%EC%8B%AC%EA%B7%9C%EC%84%A0)-%EA%BD%83%EA%B7%B8%EB%8A%98.jpg",
          },
          {
            title: "꿀차",
            singer: ["우효(Oohyo)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%9A%B0%ED%9A%A8(Oohyo)-%EA%BF%80%EC%B0%A8.jpg",
          },
          {
            title: "밤이라서 하는 말 (feat. Jozu)",
            singer: ["정바스(J.Bass)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%A0%95%EB%B0%94%EC%8A%A4(J.Bass)-27.107.jpg",
          },
          {
            title: "썸 탈꺼야",
            singer: ["볼빨간사춘기"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%B3%BC%EB%B9%A8%EA%B0%84%EC%82%AC%EC%B6%98%EA%B8%B0-Red%20Diary%20Page.1_2020_11_25.jpg",
          },
          {
            title: "해바라기",
            singer: ["짙은(Zitten)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%A7%99%EC%9D%80(Zitten)-diaspora%20-%20%ED%9D%A9%EC%96%B4%EC%A7%84%20%EC%82%AC%EB%9E%8C%EB%93%A4_2020_11_21.jpg",
          },
          {
            title: "봄바람",
            singer: ["단칸방 로맨스"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%8B%A8%EC%B9%B8%EB%B0%A9%20%EB%A1%9C%EB%A7%A8%EC%8A%A4-%EB%B4%84%EB%B0%94%EB%9E%8C.jpg",
          },
          {
            title: "사랑한다는 말로도 위로가 되지 않는",
            singer: ["브로콜리너마저"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%B8%8C%EB%A1%9C%EC%BD%9C%EB%A6%AC%EB%84%88%EB%A7%88%EC%A0%80-%EC%A1%B8%EC%97%85_2020_11_18.jpg",
          },
          {
            title: "느낌적",
            singer: ["파스칼(PASCOL)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%ED%8C%8C%EC%8A%A4%EC%B9%BC(PASCOL)-%EB%8A%90%EB%82%8C%EC%A0%81.jpg",
          },
          {
            title: "너무 보고 싶어",
            singer: ["이민혁"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%9D%B4%EB%AF%BC%ED%98%81-%EB%81%9D%EA%B9%8C%EC%A7%80%20%EC%82%AC%EB%9E%91%20(KBS2%20%EC%9D%BC%EC%9D%BC%EB%93%9C%EB%9D%BC%EB%A7%88)%20OST%20-%20Part.6_2020_11_12.jpg",
          },
          {
            title: "있어 줄게",
            singer: ["이성경X이루리"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%9D%B4%EC%84%B1%EA%B2%BDX%EC%9D%B4%EB%A3%A8%EB%A6%AC-%EC%9E%88%EC%96%B4%20%EC%A4%84%EA%B2%8C.jpg",
          },
          {
            title: "백야",
            singer: ["짙은(Zitten)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%A7%99%EC%9D%80(Zitten)-%EB%B0%B1%EC%95%BC_2021_01_19.jpg",
          },
          {
            title: "고양이",
            singer: ["넬(NELL)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%84%AC(NELL)-Let%20It%20Rain.jpg",
          },
          {
            title: "사랑한다 말해도 (Feat. 이소라)",
            singer: ["김동률"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EA%B9%80%EB%8F%99%EB%A5%A0-%EB%8B%B5%EC%9E%A5_2020_11_12.jpg",
          },
          {
            title: "보편적인 노래",
            singer: ["브로콜리너마저"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%B8%8C%EB%A1%9C%EC%BD%9C%EB%A6%AC%EB%84%88%EB%A7%88%EC%A0%80-%EA%B3%A8%EB%93%A0-%ED%9E%9B%ED%8A%B8%20%EB%AA%A8%EC%9D%8C%EC%A7%91%20%5B%EC%95%B5%EC%BD%9C%EC%9A%94%EC%B2%AD%EA%B8%88%EC%A7%80.%5D_2020_11_25.jpg",
          },
          {
            title: "Rain",
            singer: ["스웨덴세탁소"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%8A%A4%EC%9B%A8%EB%8D%B4%EC%84%B8%ED%83%81%EC%86%8C-Rain_2020_11_21.jpg",
          },
          {
            title: "어떤 날도, 어떤 말도",
            singer: ["Lucia(심규선)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Lucia(%EC%8B%AC%EA%B7%9C%EC%84%A0)-%EC%9E%90%EA%B8%B0%EB%A7%8C%EC%9D%98%20%EB%B0%A9_2020_11_16.jpg",
          },
          {
            title: "두 손, 너에게 (feat. 최백호)",
            singer: ["스웨덴세탁소"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%8A%A4%EC%9B%A8%EB%8D%B4%EC%84%B8%ED%83%81%EC%86%8C-%EB%91%90%20%EC%86%90,%20%EB%84%88%EC%97%90%EA%B2%8C%20(feat.%20%EC%B5%9C%EB%B0%B1%ED%98%B8).jpg",
          },
          {
            title: "봄이 오면",
            singer: ["서툰"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%84%9C%ED%88%B0-%EB%B4%84%EC%9D%B4%20%EC%98%A4%EB%A9%B4.jpg",
          },
          {
            title: "월광욕",
            singer: ["톰톰(Tomtom)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%ED%86%B0%ED%86%B0(Tomtom)-%EC%9B%94%EA%B4%91%EC%9A%95.jpg",
          },
          {
            title: "민들레 (single ver.)",
            singer: ["우효(Oohyo)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%9A%B0%ED%9A%A8(Oohyo)-%EB%AF%BC%EB%93%A4%EB%A0%88_2020_11_21.jpg",
          },
          {
            title: "숨길 수 없어요",
            singer: ["롤러코스터(Rollercoaster)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%A1%A4%EB%9F%AC%EC%BD%94%EC%8A%A4%ED%84%B0(Rollercoaster)-Triangle_2020_11_18.jpg",
          },
          {
            title: "서로의 조각 (with 기리보이)",
            singer: ["프롬(Fromm)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%ED%94%84%EB%A1%AC(Fromm)-Erica_2020_11_11.jpg",
          },
        ],
      },
      {
        ch_name: "따스한 햇살, 딱 좋은 트랙, 완벽한 봄날",
        ch_img: "https://api2.indj.club/file/fetchImage/thumbnail/6421_97.png",
        ch_song: [
          {
            title: "pet",
            singer: ["10CM"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/10CM-4.0_2020_11_23.jpg",
          },
          {
            title: "Lay back",
            singer: ["케이윌"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%BC%80%EC%9D%B4%EC%9C%8C-The%203rd%20Album%20Part.2%20-%20Love%20Blossom%20(%EB%9F%AC%EB%B8%8C%EB%B8%94%EB%9F%AC%EC%8D%B8).jpg",
          },
          {
            title: "Get (feat. Beenzino)",
            singer: ["어반자카파"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%96%B4%EB%B0%98%EC%9E%90%EC%B9%B4%ED%8C%8C-Get.jpg",
          },
          {
            title: "Be There",
            singer: ["CHEEZE(치즈)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/CHEEZE(%EC%B9%98%EC%A6%88)-Be%20There_2020_11_24.jpg",
          },
          {
            title: "Finesse (Remix) (feat. Cardi B)",
            singer: ["Bruno Mars(브루노 마스)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Bruno%20Mars(%EB%B8%8C%EB%A3%A8%EB%85%B8%20%EB%A7%88%EC%8A%A4)-Finesse%20(Remix)_2020_11_18.jpg",
          },
          {
            title: "기억을 걷는 시간",
            singer: ["넬(NELL)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%84%AC(NELL)-%EB%A9%80%EC%96%B4%EC%A7%80%EB%8B%A4_2020_11_16.jpg",
          },
          {
            title: "Angel",
            singer: ["Fifth Harmony(피프스 하모니)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Fifth%20Harmony(%ED%94%BC%ED%94%84%EC%8A%A4%20%ED%95%98%EB%AA%A8%EB%8B%88)-Fifth%20Harmony.jpg",
          },
          {
            title: "봄봄봄",
            singer: ["피터팬 컴플렉스(Peterpan Complex)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%ED%94%BC%ED%84%B0%ED%8C%AC%20%EC%BB%B4%ED%94%8C%EB%A0%89%EC%8A%A4(Peterpan%20Complex)-%EB%B4%84%EB%B4%84%EB%B4%84.jpg",
          },
          {
            title: "오래된 노래",
            singer: ["스탠딩 에그(Standing Egg)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%8A%A4%ED%83%A0%EB%94%A9%20%EC%97%90%EA%B7%B8(Standing%20Egg)-%EC%98%A4%EB%9E%98%EB%90%9C%20%EB%85%B8%EB%9E%98.jpg",
          },
          {
            title: "Oh Boy",
            singer: ["Red Velvet (레드벨벳)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Red%20Velvet%20(%EB%A0%88%EB%93%9C%EB%B2%A8%EB%B2%B3)-The%20Red%20-%20The%201st%20Album_2020_11_23.jpg",
          },
          {
            title: "별 보러 가자",
            singer: ["적재"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%A0%81%EC%9E%AC-FINE_2021_01_20.jpg",
          },
          {
            title: "Heaven",
            singer: ["EXO"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/EXO-LOTTO%20%E2%80%93%20The%203rd%20Album%20Repackage.jpg",
          },
          {
            title: "Day 1 ◑",
            singer: ["HONNE(혼네)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/HONNE(%ED%98%BC%EB%84%A4)-Love%20Me%20-%20Love%20Me%20Not.jpg",
          },
          {
            title: "농담 반 진담 반",
            singer: ["슈가볼(Sugarbowl)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%8A%88%EA%B0%80%EB%B3%BC(Sugarbowl)-Nuance_2020_11_11.jpg",
          },
          {
            title: "27살의 고백",
            singer: ["윤딴딴"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%9C%A4%EB%94%B4%EB%94%B4-27%EC%82%B4%EC%9D%98%20%EA%B3%A0%EB%B0%B1.jpg",
          },
          {
            title: "Alone (feat. Big Sean, Stefflon Don)",
            singer: ["Halsey(할시)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Halsey(%ED%95%A0%EC%8B%9C)-Alone.jpg",
          },
          {
            title: "잘 해보려는 나 알 수 없는 너",
            singer: ["윤딴딴"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%9C%A4%EB%94%B4%EB%94%B4-%EC%9D%B4%EB%9F%B0%20%EA%BD%83%20%EA%B0%99%EC%9D%80%20%EC%97%94%EB%94%A9%20(%EC%9B%B9%EB%93%9C%EB%9D%BC%EB%A7%88)%20OST%20-%20Part.1.jpg",
          },
          {
            title: "알듯 말듯해",
            singer: ["비투비 (서은광", " 임현식", " 육성재)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%B9%84%ED%88%AC%EB%B9%84%20(%EC%84%9C%EC%9D%80%EA%B4%91,%20%EC%9E%84%ED%98%84%EC%8B%9D,%20%EC%9C%A1%EC%84%B1%EC%9E%AC)-%EC%8C%88,%20%EB%A7%88%EC%9D%B4%EC%9B%A8%EC%9D%B4%20(KBS2%20%EC%9B%94%ED%99%94%EB%93%9C%EB%9D%BC%EB%A7%88)%20OST.jpg",
          },
          {
            title: "4cm (Feat. Wilcox)",
            singer: ["보니"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%B3%B4%EB%8B%88-4cm.jpg",
          },
          {
            title: "세월의 흔적 다 버리고",
            singer: ["015B", "오왠(O.WHEN)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/015B,%20%EC%98%A4%EC%99%A0(O.WHEN)-015B%20Anthology%20Part.3.jpg",
          },
          {
            title: "She's in Love with the Weekend (Radio Edit)",
            singer: ["Jodie Abacus(조디 아바커스)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Jodie%20Abacus(%EC%A1%B0%EB%94%94%20%EC%95%84%EB%B0%94%EC%BB%A4%EC%8A%A4)-She's%20in%20Love%20with%20the%20Weekend%20(Radio%20Edit)_2020_11_24.jpg",
          },
          {
            title: "She's In Love With The Weekend",
            singer: ["Jodie Abacus(조디 아바커스)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Jodie%20Abacus(%EC%A1%B0%EB%94%94%20%EC%95%84%EB%B0%94%EC%BB%A4%EC%8A%A4)-She's%20In%20Love%20With%20The%20Weekend.jpg",
          },
          {
            title: "I Like Me",
            singer: ["민수"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%AF%BC%EC%88%98-minsu%20X%20innisfree.jpg",
          },
          {
            title: "Thank You",
            singer: ["페퍼톤스(Peppertones)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%ED%8E%98%ED%8D%BC%ED%86%A4%EC%8A%A4(Peppertones)-Thank%20You.jpg",
          },
          {
            title: "1979",
            singer: ["The Smashing Pumpkins(스매싱 펌킨스)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/The%20Smashing%20Pumpkins(%EC%8A%A4%EB%A7%A4%EC%8B%B1%20%ED%8E%8C%ED%82%A8%EC%8A%A4)-1979.jpg",
          },
          {
            title: "시간이 달라서",
            singer: ["스탠딩 에그(Standing Egg)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%8A%A4%ED%83%A0%EB%94%A9%20%EC%97%90%EA%B7%B8(Standing%20Egg)-%EC%8B%9C%EA%B0%84%EC%9D%B4%20%EB%8B%AC%EB%9D%BC%EC%84%9C.jpg",
          },
          {
            title: "연애를 시작한다는 건 말이야",
            singer: ["블루파프리카(Bluepaprika)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%B8%94%EB%A3%A8%ED%8C%8C%ED%94%84%EB%A6%AC%EC%B9%B4(Bluepaprika)-%EA%B0%99%EC%9D%80%20%EC%8B%9C%EA%B0%84,%20%EB%8B%A4%EB%A5%B8%20%EB%B0%A4.jpg",
          },
          {
            title: "머리 쓰다듬지 마",
            singer: ["슈가볼(Sugarbowl)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%8A%88%EA%B0%80%EB%B3%BC(Sugarbowl)-Nuance_2020_11_11.jpg",
          },
          {
            title: "듣는편지",
            singer: ["40(포티)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/40(%ED%8F%AC%ED%8B%B0)-%EB%93%A3%EB%8A%94%ED%8E%B8%EC%A7%80_2020_11_20.jpg",
          },
          {
            title: "Day 1",
            singer: ["Red Velvet (레드벨벳)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Red%20Velvet%20(%EB%A0%88%EB%93%9C%EB%B2%A8%EB%B2%B3)-The%20Red%20-%20The%201st%20Album_2020_11_23.jpg",
          },
          {
            title: "I'm In Love",
            singer: ["라디(Ra.D)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%9D%BC%EB%94%94(Ra.D)-Realcollabo.jpg",
          },
          {
            title: "선을 그어 주던가",
            singer: ["1415"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/1415-DEAR%20-%20X_2021_01_20.jpg",
          },
        ],
      },
      {
        ch_name: "햇살 아래, 바람과 함께하는 청량한 POP",
        ch_img:
          "https://api2.indj.club/file/fetchImage/thumbnail/6598_album.png",
        ch_song: [
          {
            title: "Couch Potato",
            singer: ["Jakubi(자쿠비)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Jakubi(%EC%9E%90%EC%BF%A0%EB%B9%84)-Couch%20Potato_2020_11_24.jpg",
          },
          {
            title: "Colors",
            singer: ["Halsey(할시)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Halsey(%ED%95%A0%EC%8B%9C)-BADLANDS%20(Deluxe).jpg",
          },
          {
            title: "Paris",
            singer: ["The 1975"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/The%201975-I%20Like%20It%20When%20You%20Sleep,%20For%20You%20Are%20So%20Beautiful%20Yet%20So%20Unaware%20Of%20It_2020_11_25.jpg",
          },
          {
            title: "Technicolour Beat",
            singer: ["Oh Wonder(오 원더)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Oh%20Wonder(%EC%98%A4%20%EC%9B%90%EB%8D%94)-Oh%20Wonder_2020_11_18.jpg",
          },
          {
            title: "Only With You",
            singer: ["CYN"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/CYN-Only%20With%20You.jpg",
          },
          {
            title: "Temporary",
            singer: ["Ella Vos(엘라 보스)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Ella%20Vos(%EC%97%98%EB%9D%BC%20%EB%B3%B4%EC%8A%A4)-The%20Wild%20Honey%20Pie%20Buzzsession.jpg",
          },
          {
            title: "Make Me Smile (feat. J. Han)",
            singer: ["Sam Ock(샘 옥)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Sam%20Ock(%EC%83%98%20%EC%98%A5)-Grey.jpg",
          },
          {
            title: "Don't Kill My Vibe",
            singer: ["Sigrid(시그리드)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Sigrid(%EC%8B%9C%EA%B7%B8%EB%A6%AC%EB%93%9C)-Don't%20Kill%20My%20Vibe%20-%20EP.jpg",
          },
          {
            title: "James Has Changed",
            singer: ["Phoebe Ryan(피비 라이언)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Phoebe%20Ryan(%ED%94%BC%EB%B9%84%20%EB%9D%BC%EC%9D%B4%EC%96%B8)-James.jpg",
          },
          {
            title: "For You (Feat. Caton Del Rosario)",
            singer: ["Albert Posis"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Albert%20Posis-Higher.jpg",
          },
          {
            title: "I Wouldn't Know Any Better Than You",
            singer: ["Gentle Bones(젠틀 본스)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Gentle%20Bones(%EC%A0%A0%ED%8B%80%20%EB%B3%B8%EC%8A%A4)-I%20Wouldn't%20Know%20Any%20Better%20Than%20You_2020_11_11.jpg",
          },
          {
            title: "Selfish",
            singer: ["Svea"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Svea-Selfish.jpg",
          },
          {
            title: "Call Me (feat. MiMi)",
            singer: ["Neiked"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Neiked-Call%20Me.jpg",
          },
          {
            title: "James Has Changed",
            singer: ["Phoebe Ryan(피비 라이언)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Phoebe%20Ryan(%ED%94%BC%EB%B9%84%20%EB%9D%BC%EC%9D%B4%EC%96%B8)-James%20-%20EP_2020_11_25.jpg",
          },
          {
            title: "Water on the Bridge",
            singer: ["Chelsea Cutler(첼시 커틀러)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Chelsea%20Cutler(%EC%B2%BC%EC%8B%9C%20%EC%BB%A4%ED%8B%80%EB%9F%AC)-Sleeping%20With%20Roses.jpg",
          },
          {
            title: "Ultralife (Abbey Road Piano Sessions)",
            singer: ["Oh Wonder(오 원더)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Oh%20Wonder(%EC%98%A4%20%EC%9B%90%EB%8D%94)-Ultralife%20(Abbey%20Road%20Piano%20Sessions).jpg",
          },
          {
            title: "Cassette",
            singer: ["ayokay(에이요케이)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/ayokay(%EC%97%90%EC%9D%B4%EC%9A%94%EC%BC%80%EC%9D%B4)-Cassette.jpg",
          },
          {
            title: "Canals",
            singer: ["Joakim Karud"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Joakim%20Karud-Essentials%20-%20Summer%202016.jpg",
          },
          {
            title: "Aspirin",
            singer: ["Phoebe Ryan(피비 라이언)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Phoebe%20Ryan(%ED%94%BC%EB%B9%84%20%EB%9D%BC%EC%9D%B4%EC%96%B8)-James%20-%20EP_2020_11_25.jpg",
          },
          {
            title: "Without You",
            singer: ["Oh Wonder(오 원더)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Oh%20Wonder(%EC%98%A4%20%EC%9B%90%EB%8D%94)-Oh%20Wonder_2020_11_18.jpg",
          },
          {
            title: "pajamas",
            singer: ["gnash(내쉬)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/gnash(%EB%82%B4%EC%89%AC)-we_2020_11_24.jpg",
          },
          {
            title: "All We Do",
            singer: ["Oh Wonder(오 원더)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Oh%20Wonder(%EC%98%A4%20%EC%9B%90%EB%8D%94)-Oh%20Wonder_2020_11_18.jpg",
          },
          {
            title: "I’ll Still Have Me",
            singer: ["CYN"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/CYN-I%E2%80%99ll%20Still%20Have%20Me_2020_11_12.jpg",
          },
          {
            title: "How To Love (feat. Sofia Reyes)",
            singer: ["Cash Cash(캐쉬 캐쉬)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Cash%20Cash(%EC%BA%90%EC%89%AC%20%EC%BA%90%EC%89%AC)-Blood,%20Sweat%20&%203%20Years_2020_11_24.jpg",
          },
          {
            title: "Fhkd (feat. Kill J)",
            singer: ["HONNE(혼네)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/HONNE(%ED%98%BC%EB%84%A4)-Warm%20On%20A%20Cold%20Night%20(Remixed).jpg",
          },
        ],
      },
    ],
  },

  {
    mod: "당신은",
    title: "위풍당당 분위기메이커 토네이도",
    desc: [
      "센스가 좋고 유머러스해 사람들에게 인기가 많은 편입니다. 자신 때문에 누군가 웃으면 뿌듯함을 느끼지 않나요? 사교적인 성격과 동시에 사람을 깊게 사귀지 않아 진짜 친구가 많이 없다고 느껴 고독할 수 있어요. 종종 혼자만의 시간을 갖고 싶어 하네요",
      "자신이 말한 것은 꼭 지키려 하고 조직적인 능력이 뛰어나요. 남에게 힘든 얘기를 잘 하지 않고 의존하지 않으려 하기 때문에 주변사람들이 서운함을 느낄 수도 있겠네요. 한번 싫은 것은 끝까지 싫어하며 쉽게 바뀌지 않아요. 심한 감정 기복으로 변덕쟁이라는 말을 종종 듣곤 합니다. 다른사람의 의견에 귀기울일 필요가 있고. 타인과 자신의 감정에 충실할 필요가 있어요.",
      "또한 않고 논리적으로 해결책을 찾아냅니다. 평소에 남 의식을 별로 하지 않아 혼밥 혼영을 즐기는 마이웨이타입!",
    ],
    img: "wind",
    thum: "https://d3coxo32tidngo.cloudfront.net/image/mbti/wind_miri.png",
    miri: "https://d3coxo32tidngo.cloudfront.net/image/mbti/wind_miri.png",
    channel: [
      {
        ch_name: "머리 풀어헤치고 즐겨 with 락",
        ch_img:
          "https://api2.indj.club/file/fetchImage/thumbnail/저절로 머리가 흔들어지는 락과 함께 스트레스 쏴악 풀자.png",
        ch_song: [
          {
            title: "LOST IN THE ECHO",
            singer: ["Linkin Park(린킨 파크)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Linkin%20Park(%EB%A6%B0%ED%82%A8%20%ED%8C%8C%ED%81%AC)-LIVING%20THINGS.jpg",
          },
          {
            title: "Time-Bomb",
            singer: ["All Time Low(올 타임 로우)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/All%20Time%20Low(%EC%98%AC%20%ED%83%80%EC%9E%84%20%EB%A1%9C%EC%9A%B0)-Dirty%20Work.jpg",
          },
          {
            title: "Believing The Hype (Bonus Track)",
            singer: ["Galactic Cowboys(갤럭틱 카우보이스)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Galactic%20Cowboys(%EA%B0%A4%EB%9F%AD%ED%8B%B1%20%EC%B9%B4%EC%9A%B0%EB%B3%B4%EC%9D%B4%EC%8A%A4)-Long%20Way%20Back%20To%20The%20Moon.jpg",
          },
          {
            title: "Smells Like Teen Spirit",
            singer: ["Nirvana(너바나)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Nirvana(%EB%84%88%EB%B0%94%EB%82%98)-Nevermind_2020_11_17.jpg",
          },
          {
            title: "Nice2KnoU",
            singer: ["All Time Low(올 타임 로우)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/All%20Time%20Low(%EC%98%AC%20%ED%83%80%EC%9E%84%20%EB%A1%9C%EC%9A%B0)-Last%20Young%20Renegade.jpg",
          },
          {
            title: "Black Tears (Too Good to Steal from Edition)",
            singer: ["Heaven Shall Burn(헤븐 쉘 번)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Heaven%20Shall%20Burn(%ED%97%A4%EB%B8%90%20%EC%89%98%20%EB%B2%88)-Wanderer.jpg",
          },
          {
            title: "Plug In Baby",
            singer: ["Muse(뮤즈)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Muse(%EB%AE%A4%EC%A6%88)-Origin%20Of%20Symmetry.jpg",
          },
          {
            title: "Take My Hand",
            singer: ["Simple Plan(심플 플랜)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Simple%20Plan(%EC%8B%AC%ED%94%8C%20%ED%94%8C%EB%9E%9C)-Simple%20Plan.jpg",
          },
          {
            title: "Bring Me To Life",
            singer: ["Evanescence(에반에센스)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Evanescence(%EC%97%90%EB%B0%98%EC%97%90%EC%84%BC%EC%8A%A4)-Fallen_2020_11_17.jpg",
          },
          {
            title: "Crazy Train",
            singer: ["Ozzy Osbourne(오지 오스본)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Ozzy%20Osbourne(%EC%98%A4%EC%A7%80%20%EC%98%A4%EC%8A%A4%EB%B3%B8)-Blizzard%20of%20Ozz%20(Expanded%20Edition).jpg",
          },
          {
            title: "Break",
            singer: ["Eyes Set To Kill(아이즈 셋 투 킬)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Eyes%20Set%20To%20Kill(%EC%95%84%EC%9D%B4%EC%A6%88%20%EC%85%8B%20%ED%88%AC%20%ED%82%AC)-Eyes%20Set%20To%20Kill.jpg",
          },
          {
            title: "E-Pro",
            singer: ["BECK(벡)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/BECK(%EB%B2%A1)-Guero.jpg",
          },
          {
            title: "You Give Love A Bad Name",
            singer: ["Bon Jovi(본 조비)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Bon%20Jovi(%EB%B3%B8%20%EC%A1%B0%EB%B9%84)-Slippery%20When%20Wet%20%5BSpecial%20Edition%5D.jpg",
          },
          {
            title: "Highway To Hell",
            singer: ["AC/DC(에이씨디씨)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/AC-DC(%EC%97%90%EC%9D%B4%EC%94%A8%EB%94%94%EC%94%A8)-Highway%20to%20Hell_2020_11_25.jpg",
          },
          {
            title: "Emerald Sword",
            singer: ["Rhapsody Of Fire(랩소디 오브 파이어)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Rhapsody%20Of%20Fire(%EB%9E%A9%EC%86%8C%EB%94%94%20%EC%98%A4%EB%B8%8C%20%ED%8C%8C%EC%9D%B4%EC%96%B4)-Symphony%20Of%20Enchanted%20Lands.jpg",
          },
          {
            title: "The Kids Aren't Alright",
            singer: ["The Offspring(오프스프링)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/The%20Offspring(%EC%98%A4%ED%94%84%EC%8A%A4%ED%94%84%EB%A7%81)-Americana_2020_11_17.jpg",
          },
          {
            title: "Smoke On The Water (Remastered 2012)",
            singer: ["Deep Purple(딥 퍼플)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Deep%20Purple(%EB%94%A5%20%ED%8D%BC%ED%94%8C)-Machine%20Head%20(Remastered).jpg",
          },
          {
            title: "Are You Gonna Be My Girl",
            singer: ["Jet(제트)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Jet(%EC%A0%9C%ED%8A%B8)-Are%20You%20Gonna%20Be%20My%20Girl%20%5BDeluxe%20EP%5D.jpg",
          },
          {
            title: "Stoke The Fire",
            singer: ["Seether(시더)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Seether(%EC%8B%9C%EB%8D%94)-Poison%20The%20Parish.jpg",
          },
          {
            title: "Nowhere Left To Sink",
            singer: ["Like Moths To Flames(라이크 모쓰 투 플레임스)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Like%20Moths%20To%20Flames(%EB%9D%BC%EC%9D%B4%ED%81%AC%20%EB%AA%A8%EC%93%B0%20%ED%88%AC%20%ED%94%8C%EB%A0%88%EC%9E%84%EC%8A%A4)-Dark%20Divine.jpg",
          },
          {
            title: "Bad Boys (2018 Remaster)",
            singer: ["Whitesnake(화이트 스네이크)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Whitesnake(%ED%99%94%EC%9D%B4%ED%8A%B8%20%EC%8A%A4%EB%84%A4%EC%9D%B4%ED%81%AC)-1987%20(2018%20Remaster).jpg",
          },
          {
            title: "Back In Black",
            singer: ["AC/DC(에이씨디씨)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/AC-DC(%EC%97%90%EC%9D%B4%EC%94%A8%EB%94%94%EC%94%A8)-%EC%95%84%EC%9D%B4%EC%96%B8%EB%A7%A8%202%20(Iron%20Man%202)%20OST.jpg",
          },
          {
            title: "La Devotee",
            singer: ["Panic! At The Disco(패닉 앳 더 디스코)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Panic!%20At%20The%20Disco(%ED%8C%A8%EB%8B%89%20%EC%95%B3%20%EB%8D%94%20%EB%94%94%EC%8A%A4%EC%BD%94)-Death%20Of%20A%20Bachelor_2020_11_23.jpg",
          },
          {
            title: "One Night Only",
            singer: ["The Struts(더 스트럿츠)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/The%20Struts(%EB%8D%94%20%EC%8A%A4%ED%8A%B8%EB%9F%BF%EC%B8%A0)-One%20Night%20Only.jpg",
          },
          {
            title: "The Hell Song",
            singer: ["Sum 41"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Sum%2041-Does%20This%20Look%20Infected_2020_11_25.jpg",
          },
        ],
      },
      {
        ch_name: "노래방에서 HOT해! 인기 아이돌 댄스 뮤직",
        ch_img: "https://api2.indj.club/file/fetchImage/thumbnail/6815.png",
        ch_song: [
          {
            title: "뿜뿜",
            singer: ["모모랜드(MOMOLAND)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%AA%A8%EB%AA%A8%EB%9E%9C%EB%93%9C(MOMOLAND)-GREAT!.jpg",
          },
          {
            title: "나야 나 (PICK ME)",
            singer: ["PRODUCE 101(프로듀스 101)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/PRODUCE%20101(%ED%94%84%EB%A1%9C%EB%93%80%EC%8A%A4%20101)-PRODUCE%20101%20-%20%EB%82%98%EC%95%BC%20%EB%82%98%20(PICK%20ME).jpg",
          },
          {
            title: "달라달라",
            singer: ["ITZY (있지)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/ITZY%20(%EC%9E%88%EC%A7%80)-IT'z%20Different_2021_01_20.jpg",
          },
          {
            title: "8282",
            singer: ["다비치"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%8B%A4%EB%B9%84%EC%B9%98-Davichi%20In%20Wonderland.jpg",
          },
          {
            title: "HIP",
            singer: ["마마무(Mamamoo)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%A7%88%EB%A7%88%EB%AC%B4(Mamamoo)-reality%20in%20BLACK_2020_11_25.jpg",
          },
          {
            title: "빨간 맛 (Red Flavor)",
            singer: ["Red Velvet (레드벨벳)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Red%20Velvet%20(%EB%A0%88%EB%93%9C%EB%B2%A8%EB%B2%B3)-The%20Red%20Summer%20-%20Summer%20Mini%20Album_2020_11_18.jpg",
          },
          {
            title: "Nobody",
            singer: ["원더걸스"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%9B%90%EB%8D%94%EA%B1%B8%EC%8A%A4-The%20Wonder%20Years%20-%20Trilogy.jpg",
          },
          {
            title: "어쩌나",
            singer: ["세븐틴(Seventeen)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%84%B8%EB%B8%90%ED%8B%B4(Seventeen)-SEVENTEEN%205TH%20MINI%20ALBUM%20'YOU%20MAKE%20MY%20DAY'_2021_01_20.jpg",
          },
          {
            title: "별이 빛나는 밤",
            singer: ["마마무(Mamamoo)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%A7%88%EB%A7%88%EB%AC%B4(Mamamoo)-Yellow%20Flower_2020_11_12.jpg",
          },
          {
            title: "Ring Ding Dong",
            singer: ["SHINee (샤이니)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/SHINee%20(%EC%83%A4%EC%9D%B4%EB%8B%88)-2009,%20Year%20Of%20Us%20-%20The%20Third%20Mini%20Album.jpg",
          },
          {
            title: "DNA",
            singer: ["방탄소년단"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%B0%A9%ED%83%84%EC%86%8C%EB%85%84%EB%8B%A8-LOVE%20YOURSELF%20%E6%89%BF%20'Her'_2020_11_24.jpg",
          },
          {
            title: "빠빠빠",
            singer: ["크레용팝(Crayon Pop)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%ED%81%AC%EB%A0%88%EC%9A%A9%ED%8C%9D(Crayon%20Pop)-%EB%B9%A0%EB%B9%A0%EB%B9%A0.jpg",
          },
          {
            title: "Dance The Night Away",
            singer: ["TWICE (트와이스)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/TWICE%20(%ED%8A%B8%EC%99%80%EC%9D%B4%EC%8A%A4)-Summer%20Nights_2020_11_25.jpg",
          },
          {
            title: "밤 (Time for the moon night)",
            singer: ["여자친구(GFRIEND)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%97%AC%EC%9E%90%EC%B9%9C%EA%B5%AC(GFRIEND)-%EC%97%AC%EC%9E%90%EC%B9%9C%EA%B5%AC%20The%206th%20Mini%20Album%20'Time%20for%20the%20moon%20night'.jpg",
          },
          {
            title: "비밀정원",
            singer: ["오마이걸(OH MY GIRL)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%98%A4%EB%A7%88%EC%9D%B4%EA%B1%B8(OH%20MY%20GIRL)-%EB%B9%84%EB%B0%80%EC%A0%95%EC%9B%90_2021_01_19.jpg",
          },
          {
            title: "뱅뱅뱅 (BANG BANG BANG)",
            singer: ["BIGBANG"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/BIGBANG-A.jpg",
          },
          {
            title: "가시나",
            singer: ["선미"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%84%A0%EB%AF%B8-SUNMI%20SPECIAL%20EDITION%20%5B%EA%B0%80%EC%8B%9C%EB%82%98%5D.jpg",
          },
          {
            title: "으르렁 (Growl) (EXO-K ver.)",
            singer: ["EXO"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/EXO-The%201st%20Album%20'Xoxo%20(Kiss%20&%20Hug)'%20%5BRepackage%5D_2020_11_24.jpg",
          },
          {
            title: "SOLO",
            singer: ["제니 (JENNIE)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%A0%9C%EB%8B%88%20(JENNIE)-SOLO.jpg",
          },
          {
            title: "Ah-Choo",
            singer: ["러블리즈(Lovelyz)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%9F%AC%EB%B8%94%EB%A6%AC%EC%A6%88(Lovelyz)-Lovelyz8.jpg",
          },
          {
            title: "위아래",
            singer: ["EXID"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/EXID-%EC%9C%84%EC%95%84%EB%9E%98.jpg",
          },
        ],
      },
      {
        ch_name: "모두를 사로잡을 신나는 노래",
        ch_img:
          "https://api2.indj.club/file/fetchImage/thumbnail/모두를 사로잡을 신나는 노래.gif",
        ch_song: [
          {
            title: "As Long As I'm With You (feat. LA James)",
            singer: ["Giiants"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Giiants-As%20Long%20As%20I'm%20With%20You.jpg",
          },
          {
            title: "Love Again (Feat. Josh Moreland) (Radio Edit)",
            singer: ["X-Tof"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/X-Tof-Love%20Again.jpg",
          },
          {
            title: "Too Little Too Late (Blinded Hearts Remix)",
            singer: ["November Lights(노벰버 라이츠)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/November%20Lights(%EB%85%B8%EB%B2%B0%EB%B2%84%20%EB%9D%BC%EC%9D%B4%EC%B8%A0)-Too%20Little%20Too%20Late%20(Blinded%20Hearts%20Remix).jpg",
          },
          {
            title:
              "Don't Start Now (Zach Witness Remix) [Malibu Mermaids Version]",
            singer: ["Dua Lipa(두아 리파)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Dua%20Lipa(%EB%91%90%EC%95%84%20%EB%A6%AC%ED%8C%8C)-Don't%20Start%20Now%20(Zach%20Witness%20Remix)%20%5BMalibu%20Mermaids%20Version%5D.jpg",
          },
          {
            title: "Push My Luck (Famba Remix)",
            singer: ["The Chainsmokers(체인스모커스)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/The%20Chainsmokers(%EC%B2%B4%EC%9D%B8%EC%8A%A4%EB%AA%A8%EC%BB%A4%EC%8A%A4)-Push%20My%20Luck%20-%20The%20Remixes.jpg",
          },
          {
            title: "All We Got Now",
            singer: ["ILYAA"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/ILYAA-All%20We%20Got%20Now.jpg",
          },
          {
            title: "Used To Love (feat. Dean Lewis)",
            singer: ["Martin Garrix(마틴 개릭스)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Martin%20Garrix(%EB%A7%88%ED%8B%B4%20%EA%B0%9C%EB%A6%AD%EC%8A%A4)-Used%20To%20Love_2020_11_24.jpg",
          },
          {
            title: "Just A Dream (Flaremode & Adler Remix)",
            singer: ["Nipri", "LaCrème"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Nipri,%20LaCr%C3%A8me-Just%20A%20Dream.jpg",
          },
          {
            title: "Million Ways (Cole Karter Remix)",
            singer: ["HRVY"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/HRVY-Million%20Ways%20(Remixes)_2020_11_24.jpg",
          },
          {
            title: "What Am I (Martin Jensen Remix)",
            singer: ["Why Don't We(와이 돈 위)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Why%20Don't%20We(%EC%99%80%EC%9D%B4%20%EB%8F%88%20%EC%9C%84)-What%20Am%20I%20(Martin%20Jensen%20Remix).jpg",
          },
          {
            title: "Turning Me On (Mushroomizer Remix)",
            singer: ["Emilia Ali"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Emilia%20Ali-Dreamland%20(The%20Remixes).jpg",
          },
          {
            title: "All Of My Love (feat. Luma)",
            singer: ["Adam Pearce"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Adam%20Pearce-All%20Of%20My%20Love.jpg",
          },
          {
            title: "Won't Look Back (feat. Soundr) (feat. Soundr)",
            singer: ["StayLoose", "Last Heroes"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/StayLoose,%20Last%20Heroes-Won't%20Look%20Back%20(feat.%20Soundr).jpg",
          },
          {
            title: "Wanted (TT Spry Remix)",
            singer: ["OneRepublic(원리퍼블릭)", "TT Spry"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/OneRepublic(%EC%9B%90%EB%A6%AC%ED%8D%BC%EB%B8%94%EB%A6%AD),%20TT%20Spry-Wanted%20(TT%20Spry%20Remix).jpg",
          },
          {
            title: "Phoenix (We Rise) (Radio Edit)",
            singer: ["Tom Swoon(톰 스운)", "Belle Humble", "Dank(댕크)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Tom%20Swoon(%ED%86%B0%20%EC%8A%A4%EC%9A%B4),%20Belle%20Humble,%20Dank(%EB%8C%95%ED%81%AC)-Phoenix.jpg",
          },
          {
            title: "Keep It Simple (feat. Wilder Woods)",
            singer: ["Matoma", "Petey"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Matoma,%20Petey-Keep%20It%20Simple%20(feat.%20Wilder%20Woods).jpg",
          },
          {
            title: "La Di Da (Hibell Remix)",
            singer: ["Lennon Stella(레논 스텔라)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Lennon%20Stella(%EB%A0%88%EB%85%BC%20%EC%8A%A4%ED%85%94%EB%9D%BC)-La%20Di%20Da%20(Hibell%20Remix).jpg",
          },
          {
            title: "Say (Kastra Remix)",
            singer: ["Asher Postman", "Disero", "Annelisa Franklin"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Asher%20Postman,%20Disero,%20Annelisa%20Franklin-Say%20(Kastra%20Remix)_2020_11_24.jpg",
          },
          {
            title: "Nyc",
            singer: ["Tiggi Hawke"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Tiggi%20Hawke-Nyc.jpg",
          },
          {
            title: "Cute But Psycho (Blinded Hearts Remix)",
            singer: ["East Love"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/East%20Love-Cute%20But%20Psycho%20(Blinded%20Hearts%20Remix).jpg",
          },
        ],
      },
    ],
  },

  {
    mod: "당신은",
    title: "함박눈처럼 포근한 사람",
    desc: [
      "조용하지만 객관적이고 비판적으로 사고할 수 있는 호기심이 많은 타입이에요!",
      "감수성이 풍부해 감정 기복이 종종 있고 혼자만의 사색을 즐깁니다. 다른 사람의 감정도 잘 캐치해 배려심이 깊다는 말을 종종 듣습니다. 둥글둥글한 성격에 카멜레온 같이 어디든 잘 어울릴 수 있지만 남몰래 속으로 혼자 상처를 받기도 합니다.",
      "본인 속이 썩어있어도 사람들이 힘들어할 때 위로해주고 싶어하고 생각은 많은데 입 밖으로 잘 내뱉지는 않아요",
      "집을 좋아해 나가는 게 귀찮고 약속이 있으면 상대방이 취소해줬으면 하는 마음이에요. 하지만 놀 때는 세상 누구보다 잘 놀고 활동적인 당신! 가까운 사람들과 좁고 가까운 관계를 유지하는 것이 편안하다고 생각합니다. 내 사람이라고 느끼는 이들에겐 한없이 잘해주는 포근한 사람. 어느 순간 감성이 터져버리면 추억팔이를 많이 하고 과거의 사람에게 연락하고 싶은 충동을 자주 느껴요.",
    ],
    img: "snow",
    thum: "https://d3coxo32tidngo.cloudfront.net/image/mbti/snow.png",
    miri: "https://d3coxo32tidngo.cloudfront.net/image/mbti/snow_miri.png",
    channel: [
      {
        ch_name: "첫눈오는날 카페에서 커피마시며 듣는 음악",
        ch_img:
          "https://api2.indj.club/file/fetchImage/thumbnail/7653_album.png",
        ch_song: [
          {
            title: "Pug Nose",
            singer: ["Wayne Shorter(웨인 쇼터)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Wayne%20Shorter(%EC%9B%A8%EC%9D%B8%20%EC%87%BC%ED%84%B0)-Little%20Jazz%20Birds.jpg",
          },
          {
            title: "Deep Night (Rudy Van Gelder Edition-1998 Remaster)",
            singer: ["Sonny Clark(소니 클락)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Sonny%20Clark(%EC%86%8C%EB%8B%88%20%ED%81%B4%EB%9D%BD)-Cool%20Struttin'%20(Remastered%20-%20Rudy%20Van%20Gelder%20Edition).jpg",
          },
          {
            title: "Jazz Me Blues (Album Version)",
            singer: ["Art Pepper(아트 페퍼)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Art%20Pepper(%EC%95%84%ED%8A%B8%20%ED%8E%98%ED%8D%BC)-Art%20Pepper%20Meets%20The%20Rhythm%20Section%20(OJC%20Remaster).jpg",
          },
          {
            title: "You're My Everything",
            singer: ["Freddie Hubbard(프레디 허바드)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Freddie%20Hubbard(%ED%94%84%EB%A0%88%EB%94%94%20%ED%97%88%EB%B0%94%EB%93%9C)-Hub-Tones.jpg",
          },
          {
            title: "So Nice",
            singer: ["Elmo Hope(엘모 호프)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Elmo%20Hope(%EC%97%98%EB%AA%A8%20%ED%98%B8%ED%94%84)-Trio%20And%20Quintet.jpg",
          },
          {
            title: "Melancholee",
            singer: ["Lee Morgan(리 모건)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Lee%20Morgan(%EB%A6%AC%20%EB%AA%A8%EA%B1%B4)-Search%20For%20The%20New%20Land.jpg",
          },
          {
            title: "Dear Old Stockholm",
            singer: ["Miles Davis(마일즈 데이비스)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Miles%20Davis(%EB%A7%88%EC%9D%BC%EC%A6%88%20%EB%8D%B0%EC%9D%B4%EB%B9%84%EC%8A%A4)-'Round%20About%20Midnight.jpg",
          },
          {
            title: "Backstage Sally (Remastered 2003-Rudy Van Gelder Edition)",
            singer: [
              "Art Blakey & The Jazz Messengers(아트 블레이키 앤 더 재즈 메신저스)",
            ],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Art%20Blakey%20&%20The%20Jazz%20Messengers(%EC%95%84%ED%8A%B8%20%EB%B8%94%EB%A0%88%EC%9D%B4%ED%82%A4%20%EC%95%A4%20%EB%8D%94%20%EC%9E%AC%EC%A6%88%20%EB%A9%94%EC%8B%A0%EC%A0%80%EC%8A%A4)-The%20Finest.jpg",
          },
          {
            title: "My Funny Valentine (feat. Jim Hall)",
            singer: ["Bill Evans(빌 에반스)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Bill%20Evans(%EB%B9%8C%20%EC%97%90%EB%B0%98%EC%8A%A4)-My%20Funny%20Valentine.jpg",
          },
          {
            title: "I Love You (Remastered)",
            singer: ["Art Farmer(아트 파머)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Art%20Farmer(%EC%95%84%ED%8A%B8%20%ED%8C%8C%EB%A8%B8)-Modern%20Art.jpg",
          },
          {
            title: "Shoot The Moon",
            singer: ["Norah Jones(노라 존스)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Norah%20Jones(%EB%85%B8%EB%9D%BC%20%EC%A1%B4%EC%8A%A4)-Come%20Away%20With%20Me_2020_11_25.jpg",
          },
          {
            title: "Windows",
            singer: ["Chick Corea(칙 코리아)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Chick%20Corea(%EC%B9%99%20%EC%BD%94%EB%A6%AC%EC%95%84)-Now%20He%20Sings,%20Now%20He%20Sobs.jpg",
          },
          {
            title:
              "Chief Crazy Horse (Rudy Van Gelder Edition-2000 Digital Remaster-24 Bit Mastering)",
            singer: ["Wayne Shorter(웨인 쇼터)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Wayne%20Shorter(%EC%9B%A8%EC%9D%B8%20%EC%87%BC%ED%84%B0)-Adam's%20Apple%20(Rudy%20Van%20Gelder%20Edition).jpg",
          },
          {
            title: "My One And Only Love",
            singer: ["Chick Corea(칙 코리아)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Chick%20Corea(%EC%B9%99%20%EC%BD%94%EB%A6%AC%EC%95%84)-The%20Best%20Of%20Chick%20Corea.jpg",
          },
          {
            title: "Goodbye Pork Pie Hat",
            singer: ["Mingus Big Band"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Mingus%20Big%20Band-Blues%20&%20Politics.jpg",
          },
          {
            title: "St. Thomas",
            singer: ["Sonny Rollins(소니 롤린스)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Sonny%20Rollins(%EC%86%8C%EB%8B%88%20%EB%A1%A4%EB%A6%B0%EC%8A%A4)-Saxophone%20Colossus.jpg",
          },
          {
            title: "Peace (Instrumental)",
            singer: ["Tommy Flanagan(토미 플래너건)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Tommy%20Flanagan(%ED%86%A0%EB%AF%B8%20%ED%94%8C%EB%9E%98%EB%84%88%EA%B1%B4)-Something%20Borrowed,%20Something%20Blue.jpg",
          },
          {
            title: "Cool Struttin'",
            singer: ["Sonny Clark(소니 클락)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Sonny%20Clark(%EC%86%8C%EB%8B%88%20%ED%81%B4%EB%9D%BD)-Cool%20Struttin'%20(Remastered%20-%20Rudy%20Van%20Gelder%20Edition).jpg",
          },
          {
            title: "You'd Be So Nice to Come Home To",
            singer: ["Art Pepper(아트 페퍼)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Art%20Pepper(%EC%95%84%ED%8A%B8%20%ED%8E%98%ED%8D%BC)-Art%20Pepper%20Meets%20The%20Rhythm%20Section%20(OJC%20Remaster).jpg",
          },
          {
            title: "Silk",
            singer: ["Rick Braun(릭 브라운)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Rick%20Braun(%EB%A6%AD%20%EB%B8%8C%EB%9D%BC%EC%9A%B4)-Can%20You%20Feel%20It.jpg",
          },
          {
            title: "Bye Bye Blackbird",
            singer: ["Miles Davis(마일즈 데이비스)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Miles%20Davis(%EB%A7%88%EC%9D%BC%EC%A6%88%20%EB%8D%B0%EC%9D%B4%EB%B9%84%EC%8A%A4)-'Round%20About%20Midnight.jpg",
          },
          {
            title: "Blue In Green",
            singer: ["Miles Davis(마일즈 데이비스)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Miles%20Davis(%EB%A7%88%EC%9D%BC%EC%A6%88%20%EB%8D%B0%EC%9D%B4%EB%B9%84%EC%8A%A4)-Kind%20Of%20Blue_2020_11_25.jpg",
          },
          {
            title: "Cheek To Cheek",
            singer: ["JazzMaTazz"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/JazzMaTazz-At%20Last.jpg",
          },
          {
            title: "Along Came Betty",
            singer: [
              "Art Blakey(아트 블래키)",
              "The Jazz Messengers(재즈 메신저스)",
            ],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Art%20Blakey(%EC%95%84%ED%8A%B8%20%EB%B8%94%EB%9E%98%ED%82%A4),%20The%20Jazz%20Messengers(%EC%9E%AC%EC%A6%88%20%EB%A9%94%EC%8B%A0%EC%A0%80%EC%8A%A4)-Moanin'%20(The%20Rudy%20Van%20Gelder%20Edition).jpg",
          },
        ],
      },
      {
        ch_name: "추운 겨울 듣기 좋은 포근한 노래",
        ch_img:
          "https://api2.indj.club/file/fetchImage/thumbnail/추운 겨울 듣기 좋은 포근한 노래.png",
        ch_song: [
          {
            title: "있어줄래",
            singer: ["길구봉구"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EA%B8%B8%EA%B5%AC%EB%B4%89%EA%B5%AC-%EC%9E%88%EC%96%B4%EC%A4%84%EB%9E%98.jpg",
          },
          {
            title: "안녕 푸른 나의 별",
            singer: ["홍재목"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%ED%99%8D%EC%9E%AC%EB%AA%A9-%EC%95%88%EB%85%95%20%ED%91%B8%EB%A5%B8%20%EB%82%98%EC%9D%98%20%EB%B3%84.jpg",
          },
          {
            title: "그냥 니가 좋아",
            singer: ["홍대광"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%ED%99%8D%EB%8C%80%EA%B4%91-%EB%A9%80%EC%96%B4%EC%A7%84%EB%8B%A4.jpg",
          },
          {
            title: "이 별의 밤",
            singer: ["요다영(YODAYOUNG)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%9A%94%EB%8B%A4%EC%98%81(YODAYOUNG)-%EC%9D%B4%20%EB%B3%84%EC%9D%98%20%EB%B0%A4.jpg",
          },
          {
            title: "Lovin’ U (러빙유)",
            singer: ["Cherry B(체리비)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Cherry%20B(%EC%B2%B4%EB%A6%AC%EB%B9%84)-Lovin%E2%80%99%20U%20(%EB%9F%AC%EB%B9%99%EC%9C%A0).jpg",
          },
          {
            title: "무지개 (feat. 윤닭 Of 오브로젝트)",
            singer: ["스탠딩 에그(Standing Egg)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%8A%A4%ED%83%A0%EB%94%A9%20%EC%97%90%EA%B7%B8(Standing%20Egg)-The%20Artist%20Diary%20Project%20Part.%2012.jpg",
          },
          {
            title: "나비",
            singer: ["비투비"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%B9%84%ED%88%AC%EB%B9%84-HOUR%20MOMENT.jpg",
          },
          {
            title: "난 참 그대가",
            singer: ["406호 프로젝트"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/406%ED%98%B8%20%ED%94%84%EB%A1%9C%EC%A0%9D%ED%8A%B8-%EB%82%9C%20%EC%B0%B8%20%EA%B7%B8%EB%8C%80%EA%B0%80.jpg",
          },
          {
            title: "오늘도 빛나는 너에게 (To You My Light) (Feat.이라온)",
            singer: ["마크툽(Maktub)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%A7%88%ED%81%AC%ED%88%BD(MAKTUB)-Red%20Moon%20-%20To%20You%20My%20Light_2021_01_20.jpg",
          },
          {
            title: "꿈",
            singer: ["박효신"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%B0%95%ED%9A%A8%EC%8B%A0-I%20am%20A%20Dreamer_2020_11_23.jpg",
          },
          {
            title: "너이고 싶어",
            singer: ["윤현상"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%9C%A4%ED%98%84%EC%83%81-%ED%8C%8C%EB%9E%91-Wave.jpg",
          },
          {
            title: "고마워요",
            singer: ["노을"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%85%B8%EC%9D%84-%EB%B3%84.jpg",
          },
          {
            title: "그대라는 말",
            singer: ["정엽"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%A0%95%EC%97%BD-Thinkin'%20Back%20On%20Me.jpg",
          },
          {
            title: "선물",
            singer: ["멜로망스(MeloMance)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%A9%9C%EB%A1%9C%EB%A7%9D%EC%8A%A4(MeloMance)-Moonlight_2020_11_20.jpg",
          },
          {
            title: "시간의 바깥",
            singer: ["아이유(IU)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%95%84%EC%9D%B4%EC%9C%A0(IU)-Love%20poem_2021_06_25.jpg",
          },
          {
            title: "손편지",
            singer: ["그_냥"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EA%B7%B8_%EB%83%A5-%EB%91%90_%EC%9A%B8,%20%EC%84%B8%20%EB%B2%88%EC%A7%B8_2020_11_11.jpg",
          },
          {
            title: "첫키스 하는 날",
            singer: ["2BIC(투빅)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/2BIC(%ED%88%AC%EB%B9%85)-3rd%20Mini%20Repackage%20Album%20-%20Genuine.jpg",
          },
          {
            title: "그 밤",
            singer: ["에릭남(Eric Nam)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%97%90%EB%A6%AD%EB%82%A8(Eric%20Nam)-%EB%82%A8%EC%9E%90%EC%B9%9C%EA%B5%AC%20(tvN%20%EC%88%98%EB%AA%A9%EB%93%9C%EB%9D%BC%EB%A7%88)%20OST%20-%20Part.4.jpg",
          },
          {
            title: "웃는다",
            singer: ["커피소년"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%BB%A4%ED%94%BC%EC%86%8C%EB%85%84-%EB%91%90%EB%B2%88%EC%A7%B8%20%EC%9D%8C%EC%95%85%20%EB%A1%9C%EC%8A%A4%ED%8C%85.jpg",
          },
          {
            title: "우주선",
            singer: ["정승환"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%A0%95%EC%8A%B9%ED%99%98-%EC%95%88%EB%85%95,%20%EB%82%98%EC%9D%98%20%EC%9A%B0%EC%A3%BC_2020_11_21.jpg",
          },
          {
            title: "같이 걷는 길",
            singer: ["산들"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%82%B0%EB%93%A4-%EA%B7%B8%EB%A0%87%EA%B2%8C%20%EC%9E%88%EC%96%B4%20%EC%A4%98.jpg",
          },
          {
            title: "편지",
            singer: ["6월애"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/6%EC%9B%94%EC%95%A0-%ED%8E%B8%EC%A7%80.jpg",
          },
          {
            title: "너를 만나",
            singer: ["폴킴(Paul Kim)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%ED%8F%B4%ED%82%B4(Paul%20Kim)-%EB%84%88%EB%A5%BC%20%EB%A7%8C%EB%82%98_2021_01_19.jpg",
          },
        ],
      },
      {
        ch_name: "겨울에 들으면 찰떡인 노래",
        ch_img:
          "https://api2.indj.club/file/fetchImage/thumbnail/겨울에 들으면 찰떡인 노래.gif",
        ch_song: [
          {
            title: "어디쯤에 (feat. 수란, pH-1)",
            singer: ["그루비룸 (GroovyRoom)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EA%B7%B8%EB%A3%A8%EB%B9%84%EB%A3%B8%20(GroovyRoom)-EVERYWHERE_2020_11_24.jpg",
          },
          {
            title: "FEEL (feat. chancellor)",
            singer: ["윤하(Younha/ユンナ)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%9C%A4%ED%95%98(Younha-%E3%83%A6%E3%83%B3%E3%83%8A)-RescuE_2020_11_18.jpg",
          },
          {
            title: "눈 (Feat. 이문세)",
            singer: ["Zion.T"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Zion.T-%EB%88%88_2021_01_19.jpg",
          },
          {
            title: "Long Time No",
            singer: ["피셔맨(Fisherman)", "구원찬"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%ED%94%BC%EC%85%94%EB%A7%A8(Fisherman),%20%EA%B5%AC%EC%9B%90%EC%B0%AC-Format.jpg",
          },
          {
            title: "Love Interlude",
            singer: ["Myle.D"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Myle.D-Love%20Interlude.jpg",
          },
          {
            title: "예뻐, 멋져 (커플송)",
            singer: ["감성소년", "Revie(리비에)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EA%B0%90%EC%84%B1%EC%86%8C%EB%85%84,%20Revie(%EB%A6%AC%EB%B9%84%EC%97%90)-%EC%95%88%EB%85%95,%20%EC%97%AC%EB%A6%84.jpg",
          },
          {
            title: "Then we",
            singer: ["jeebanoff (지바노프)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/jeebanoff%20(%EC%A7%80%EB%B0%94%EB%85%B8%ED%94%84)-KARMA_2020_11_20.jpg",
          },
          {
            title: "Wanna Love You",
            singer: ["폴킴(Paul Kim)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%ED%8F%B4%ED%82%B4(Paul%20Kim)-Wanna%20Love%20You_2020_11_11.jpg",
          },
          {
            title: "Balloons (Remastering ver.)",
            singer: ["CHEEZE(치즈)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/CHEEZE(%EC%B9%98%EC%A6%88)-CHEEZE%201.5%EC%A7%91%20PLAIN_2021_01_20.jpg",
          },
          {
            title: "너를 공부해",
            singer: ["소란(SORAN)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%86%8C%EB%9E%80(SORAN)-%EB%84%88%EB%A5%BC%20%EA%B3%B5%EB%B6%80%ED%95%B4.jpg",
          },
          {
            title: "Laziness",
            singer: ["The Colored Paper"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/The%20Colored%20Paper-Laziness.jpg",
          },
          {
            title: "Howtonight",
            singer: ["조제(Josee)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%A1%B0%EC%A0%9C(Josee)-HOWTONIGHT.jpg",
          },
          {
            title: "이별시간 (PM 9-15)",
            singer: ["모아(Moa)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%AA%A8%EC%95%84(Moa)-%EC%9D%B4%EB%B3%84%EC%8B%9C%EA%B0%84%20(PM%209-15).jpg",
          },
          {
            title: "슈퍼마리오",
            singer: ["oceanfromtheblue"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/oceanfromtheblue-Luv-fi(2018).jpg",
          },
          {
            title: "Think About' Chu (Prod. By 박근태)",
            singer: ["샘김(Sam Kim)", "로꼬"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%83%98%EA%B9%80(Sam%20Kim),%20%EB%A1%9C%EA%BC%AC-Boys%20and%20Girls%20Music%20Vol.1.jpg",
          },
          {
            title: "Flower Bomb",
            singer: ["JERO"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/JERO-Flower%20Bomb.jpg",
          },
          {
            title: "하기나 해 (feat. Loco)",
            singer: ["GRAY(그레이)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/GRAY(%EA%B7%B8%EB%A0%88%EC%9D%B4)-grayground.%2001_2020_11_18.jpg",
          },
          {
            title: "Raingurl",
            singer: ["yaeji"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/yaeji-Ep2_2020_11_24.jpg",
          },
          {
            title: "Autumn Breeze (Feat. Rachel Lim)",
            singer: ["JIDA(지다)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/JIDA(%EC%A7%80%EB%8B%A4)-FADE%20AWAY_2020_11_18.jpg",
          },
        ],
      },
    ],
  },

  {
    mod: "당신은",
    title: "주변과 잘 융화되는 안개같은 사람",
    desc: [
      "창의력이 뛰어나지만 가지고 있는 재능에 비해 스스로에 대한 평가는 낮게 생각해요. 좀 더 자신에 대해 긍정의 힘을 불어넣는 것이 필요해요. 남에게 피해를 주는 걸 제일 싫어하면서 관심은 받고 싶은 조용한 관종이에요.",
      "친절하고 다정다감 하지만 쉽게 마음을 열어주진 않아요. 한번 마음을 얻기는 어렵지만 한번 마음을 얻으면 무한 신뢰를 줄 수 있으며 선을 넘지만 않는다면 관계를 계속 이어가요. 하지만 선을 넘는 순간 손절하는 타입이에요. ",
      "결정을 해야할 땐 주변 의견이나 분위기에 맞추는 편이라 선택을 잘못해요. 그래서 혼자 결정할 땐 최대한 미뤘다가 최후에 합니다.",
      "게으른 완벽주의자예요. 일을 완벽하게 해야 직성이 풀리지만 일을 시작하기까지가 오래 걸립니다. 날씨에 따라 듣고 싶은 음악이 달라요. 생각이 많을 땐 레몬사탕 보다 내 마음을 헤아려주는 음악을 들으며 산책하는 걸 좋아해요.",
    ],
    img: "fog",
    thum: "https://d3coxo32tidngo.cloudfront.net/image/mbti/fog_miri.png",
    miri: "https://d3coxo32tidngo.cloudfront.net/image/mbti/fog_miri.png",
    channel: [
      {
        ch_name: "음색이 달달/잔잔 RNB 그루브",
        ch_img: "https://api2.indj.club/file/fetchImage/thumbnail/6604_782.png",
        ch_song: [
          {
            title: "Hurt You First",
            singer: ["Niia(니아)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Niia(%EB%8B%88%EC%95%84)-I.jpg",
          },
          {
            title: "Wonderland (feat. Elijah Blake)",
            singer: ["Keyshia Cole(키샤 콜)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Keyshia%20Cole(%ED%82%A4%EC%83%A4%20%EC%BD%9C)-Woman%20To%20Woman.jpg",
          },
          {
            title: "Think About You",
            singer: ["LÉON"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/L%C3%89ON-For%20You.jpg",
          },
          {
            title: "Anymore",
            singer: ["Ella Mai(엘라 마이)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Ella%20Mai(%EC%97%98%EB%9D%BC%20%EB%A7%88%EC%9D%B4)-Anymore.jpg",
          },
          {
            title: "Know What I Want",
            singer: ["Kali Uchis(칼리 우치스)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Kali%20Uchis(%EC%B9%BC%EB%A6%AC%20%EC%9A%B0%EC%B9%98%EC%8A%A4)-Know%20What%20I%20Want.jpg",
          },
          {
            title: "Anymore",
            singer: ["Ella Mai(엘라 마이)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Ella%20Mai(%EC%97%98%EB%9D%BC%20%EB%A7%88%EC%9D%B4)-READY.jpg",
          },
          {
            title: "Red Light Special (Radio Edit)",
            singer: ["TLC(티엘씨)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/TLC(%ED%8B%B0%EC%97%98%EC%94%A8)-Now%20&%20Forever%20-%20The%20Hits.jpg",
          },
          {
            title: "Tokyo",
            singer: ["Ruth Koleva(루스 콜레바)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Ruth%20Koleva(%EB%A3%A8%EC%8A%A4%20%EC%BD%9C%EB%A0%88%EB%B0%94)-Tokyo.jpg",
          },
          {
            title: "Confidently Lost",
            singer: ["Sabrina Claudio(사브리나 클라우디오)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Sabrina%20Claudio(%EC%82%AC%EB%B8%8C%EB%A6%AC%EB%82%98%20%ED%81%B4%EB%9D%BC%EC%9A%B0%EB%94%94%EC%98%A4)-Confidently%20Lost_2020_11_20.jpg",
          },
          {
            title: "Skin",
            singer: ["Mergime"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Mergime-Skin.jpg",
          },
          {
            title: "Loner",
            singer: ["Kali Uchis(칼리 우치스)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Kali%20Uchis(%EC%B9%BC%EB%A6%AC%20%EC%9A%B0%EC%B9%98%EC%8A%A4)-Por%20Vida.jpg",
          },
          {
            title: "Be Mine",
            singer: ["Lucy And The Birds(루시 앤 더 버즈)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Lucy%20And%20The%20Birds(%EB%A3%A8%EC%8B%9C%20%EC%95%A4%20%EB%8D%94%20%EB%B2%84%EC%A6%88)-Stories%20In%20Between.jpg",
          },
          {
            title: "Lottery",
            singer: ["Kali Uchis(칼리 우치스)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Kali%20Uchis(%EC%B9%BC%EB%A6%AC%20%EC%9A%B0%EC%B9%98%EC%8A%A4)-Lottery.jpg",
          },
          {
            title: "Liar",
            singer: ["LÉON"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/L%C3%89ON-Liar.jpg",
          },
          {
            title: "Lush Life (Acoustic Version)",
            singer: ["Zara Larsson(자라 라슨)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Zara%20Larsson(%EC%9E%90%EB%9D%BC%20%EB%9D%BC%EC%8A%A8)-Lush%20Life%20(Acoustic%20Version).jpg",
          },
          {
            title: "WATERS",
            singer: ["DUVV", "HAE"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/DUVV,%20HAE-WATERS.jpg",
          },
          {
            title: "Make Me Over",
            singer: ["Candice Boyd"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Candice%20Boyd-Make%20Me%20Over.jpg",
          },
          {
            title: "Distraction",
            singer: ["Kehlani(켈라니)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Kehlani(%EC%BC%88%EB%9D%BC%EB%8B%88)-Distraction.jpg",
          },
        ],
      },
      {
        ch_name: "소울 가득한 도시의 뮤직",
        ch_img:
          "https://api2.indj.club/file/fetchImage/thumbnail/193992_album.png",
        ch_song: [
          {
            title: "Feels Good",
            singer: ["Rahsaan Patterson(라산 패터슨)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Rahsaan%20Patterson(%EB%9D%BC%EC%82%B0%20%ED%8C%A8%ED%84%B0%EC%8A%A8)-Wines%20&%20Spirits.jpg",
          },
          {
            title: "Me And Those Dreamin' Eyes Of Mine",
            singer: ["D'Angelo(디안젤로)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/D'Angelo(%EB%94%94%EC%95%88%EC%A0%A4%EB%A1%9C)-Ultimate%20D'Angelo.jpg",
          },
          {
            title: "Love Of My Own",
            singer: ["Eric Benét(에릭 베넷)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Eric%20Ben%C3%A9t(%EC%97%90%EB%A6%AD%20%EB%B2%A0%EB%84%B7)-A%20Day%20In%20The%20Life.jpg",
          },
          {
            title: "Best Of Me",
            singer: ["Anthony Hamilton(앤소니 해밀튼)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Anthony%20Hamilton(%EC%95%A4%EC%86%8C%EB%8B%88%20%ED%95%B4%EB%B0%80%ED%8A%BC)-Back%20To%20Love.jpg",
          },
          {
            title: "Masterpiece (Mona Lisa)",
            singer: ["Jazmine Sullivan(재즈민 설리번)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Jazmine%20Sullivan(%EC%9E%AC%EC%A6%88%EB%AF%BC%20%EC%84%A4%EB%A6%AC%EB%B2%88)-Reality%20Show.jpg",
          },
          {
            title: "Best Of Me",
            singer: ["Anthony Hamilton(앤소니 해밀튼)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Anthony%20Hamilton(%EC%95%A4%EC%86%8C%EB%8B%88%20%ED%95%B4%EB%B0%80%ED%8A%BC)-Back%20To%20Love%20%5BDeluxe%20ver.%5D.jpg",
          },
          {
            title: "Always In My Head",
            singer: ["India.Arie(인디아 아리)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/India.Arie(%EC%9D%B8%EB%94%94%EC%95%84%20%EC%95%84%EB%A6%AC)-Acoustic%20Soul.jpg",
          },
          {
            title: "If You Love Me",
            singer: ["Brownstone(브라운스톤)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Brownstone(%EB%B8%8C%EB%9D%BC%EC%9A%B4%EC%8A%A4%ED%86%A4)-From%20The%20Bottom%20Up.jpg",
          },
          {
            title: "Better",
            singer: ["Chrisette Michele(크리셋 미셸)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Chrisette%20Michele(%ED%81%AC%EB%A6%AC%EC%85%8B%20%EB%AF%B8%EC%85%B8)-Better%20(Deluxe%20Version).jpg",
          },
          {
            title: "Selfish",
            singer: ["Toni Braxton(토니 브랙스톤)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Toni%20Braxton(%ED%86%A0%EB%8B%88%20%EB%B8%8C%EB%9E%99%EC%8A%A4%ED%86%A4)-More%20Than%20A%20Woman.jpg",
          },
          {
            title: "Careless Whisper",
            singer: [
              "Kenny G(케니 지)",
              "Brian McKnight(브라이언 맥나이트)",
              "Earl Klugh(얼 클루)",
            ],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Kenny%20G(%EC%BC%80%EB%8B%88%20%EC%A7%80),%20Brian%20McKnight(%EB%B8%8C%EB%9D%BC%EC%9D%B4%EC%96%B8%20%EB%A7%A5%EB%82%98%EC%9D%B4%ED%8A%B8),%20Earl%20Klugh(%EC%96%BC%20%ED%81%B4%EB%A3%A8)-At%20Last...%20The%20Duets%20Album.jpg",
          },
          {
            title: "Alright",
            singer: ["Ledisi(레디시)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Ledisi(%EB%A0%88%EB%94%94%EC%8B%9C)-Lost%20And%20Found.jpg",
          },
          {
            title: "I'm Coming Back (feat. Rachelle Ferrell)",
            singer: ["Lalah Hathaway(레일라 해서웨이)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Lalah%20Hathaway(%EB%A0%88%EC%9D%BC%EB%9D%BC%20%ED%95%B4%EC%84%9C%EC%9B%A8%EC%9D%B4)-Where%20It%20All%20Begins.jpg",
          },
          {
            title: "Moved By You",
            singer: ["India.Arie(인디아 아리)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/India.Arie(%EC%9D%B8%EB%94%94%EC%95%84%20%EC%95%84%EB%A6%AC)-SongVersation%20%5BStandard%20Edition%5D.jpg",
          },
          {
            title: "Diamond In The Rough",
            singer: ["Anthony Hamilton(앤소니 해밀튼)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Anthony%20Hamilton(%EC%95%A4%EC%86%8C%EB%8B%88%20%ED%95%B4%EB%B0%80%ED%8A%BC)-The%20Point%20Of%20It%20All.jpg",
          },
          {
            title: "When A Man Lies",
            singer: ["R. Kelly(알 켈리)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/R.%20Kelly(%EC%95%8C%20%EC%BC%88%EB%A6%AC)-Write%20Me%20Back%20%5BDeluxe%20ver.%5D.jpg",
          },
          {
            title: "Elsewhere",
            singer: ["R. Kelly(알 켈리)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/R.%20Kelly(%EC%95%8C%20%EC%BC%88%EB%A6%AC)-Untitled.jpg",
          },
          {
            title: "Hope She Cheats On You (With A Basketball Player)",
            singer: ["Marsha Ambrosius(마르샤 앰브로시어스)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Marsha%20Ambrosius(%EB%A7%88%EB%A5%B4%EC%83%A4%20%EC%95%B0%EB%B8%8C%EB%A1%9C%EC%8B%9C%EC%96%B4%EC%8A%A4)-Late%20Nights%20&%20Early%20Mornings.jpg",
          },
          {
            title: "Open Your Eyes",
            singer: ["Dwele(드웰르)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Dwele(%EB%93%9C%EC%9B%B0%EB%A5%B4)-Sketches%20Of%20A%20Man.jpg",
          },
          {
            title: "Apologize",
            singer: ["George Tandy", " Jr.(조지 탠디 주니어)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/George%20Tandy,%20Jr.(%EC%A1%B0%EC%A7%80%20%ED%83%A0%EB%94%94%20%EC%A3%BC%EB%8B%88%EC%96%B4)-The%20Foundation.jpg",
          },
        ],
      },
      {
        ch_name: "음색이 좋은 알앤비",
        ch_img:
          "https://api2.indj.club/file/fetchImage/thumbnail/192904_album.png",
        ch_song: [
          {
            title: "Come Here",
            singer: ["Raquel Rodriguez"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Raquel%20Rodriguez-Come%20Here.jpg",
          },
          {
            title: "Pigment",
            singer: ["H.E.R."],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/H.E.R.-Pigment.jpg",
          },
          {
            title: "Dreamin'",
            singer: ["ADI"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/ADI-Dreamin'.jpg",
          },
          {
            title: "Cry",
            singer: ["K.Michelle(케이 미쉘)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/K.Michelle(%EC%BC%80%EC%9D%B4%20%EB%AF%B8%EC%89%98)-Anybody%20Wanna%20Buy%20A%20Heart.jpg",
          },
          {
            title: "Ridiculous",
            singer: ["Raheem Devaughn(라힘 드본)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Raheem%20Devaughn(%EB%9D%BC%ED%9E%98%20%EB%93%9C%EB%B3%B8)-A%20Place%20Called%20Love%20Land.jpg",
          },
          {
            title: "Just Wanna Love You",
            singer: ["Eli Way", "Rachel Marie"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Eli%20Way,%20Rachel%20Marie-Just%20Wanna%20Love%20You.jpg",
          },
          {
            title: "Loved By You (feat. Jazmine Sullivan)",
            singer: ["Mali Music(말리 뮤직)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Mali%20Music(%EB%A7%90%EB%A6%AC%20%EB%AE%A4%EC%A7%81)-The%20Transition%20Of%20Mali.jpg",
          },
          {
            title: "Wasn't Love Cafe",
            singer: ["Tangina Stone(탱지나 스톤)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Tangina%20Stone(%ED%83%B1%EC%A7%80%EB%82%98%20%EC%8A%A4%ED%86%A4)-Wasn't%20Love%20Cafe.jpg",
          },
          {
            title: "Cocoa Butter",
            singer: ["India.Arie(인디아 아리)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/India.Arie(%EC%9D%B8%EB%94%94%EC%95%84%20%EC%95%84%EB%A6%AC)-Cocoa%20Butter.jpg",
          },
          {
            title: "Barcelona",
            singer: ["Jake Sarno"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Jake%20Sarno-Chapter%201.jpg",
          },
          {
            title: "Ordinary",
            singer: ["Tuomo(뚜오모)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Tuomo(%EB%9A%9C%EC%98%A4%EB%AA%A8)-Reaches%20Out%20For%20You.jpg",
          },
          {
            title: "It Don't Matter (feat. Jsd)",
            singer: ["Prima"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Prima-It%20Don't%20Matter.jpg",
          },
          {
            title: "Stand In Your Line",
            singer: ["Dornik(도니크)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Dornik(%EB%8F%84%EB%8B%88%ED%81%AC)-Dornik.jpg",
          },
          {
            title: "If Tonight Is My Last",
            singer: ["Laura Izibor(로라 이지보어)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Laura%20Izibor(%EB%A1%9C%EB%9D%BC%20%EC%9D%B4%EC%A7%80%EB%B3%B4%EC%96%B4)-Let%20The%20Truth%20Be%20Told.jpg",
          },
          {
            title: "Basil",
            singer: ["Ruth Koleva(루스 콜레바)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Ruth%20Koleva(%EB%A3%A8%EC%8A%A4%20%EC%BD%9C%EB%A0%88%EB%B0%94)-Confidence.%20Truth.jpg",
          },
          {
            title: "Breakfast In Bed",
            singer: ["Mayer Hawthorne(메이어 호손)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Mayer%20Hawthorne(%EB%A9%94%EC%9D%B4%EC%96%B4%20%ED%98%B8%EC%86%90)-Man%20About%20Town.jpg",
          },
          {
            title: "When U're High",
            singer: ["Kim Cesarion(킴 세사리온)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Kim%20Cesarion(%ED%82%B4%20%EC%84%B8%EC%82%AC%EB%A6%AC%EC%98%A8)-Undressed.jpg",
          },
          {
            title: "Can't Hide (feat. Darien)",
            singer: ["Soulpersona(소울페르소나)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Soulpersona(%EC%86%8C%EC%9A%B8%ED%8E%98%EB%A5%B4%EC%86%8C%EB%82%98)-Soulacoaster.jpg",
          },
          {
            title: "Mama",
            singer: ["Raury(로리)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Raury(%EB%A1%9C%EB%A6%AC)-All%20We%20Need.jpg",
          },
          {
            title: "Diamond In The Rough",
            singer: ["Anthony Hamilton(앤소니 해밀튼)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Anthony%20Hamilton(%EC%95%A4%EC%86%8C%EB%8B%88%20%ED%95%B4%EB%B0%80%ED%8A%BC)-The%20Point%20Of%20It%20All.jpg",
          },
          {
            title: "No Other Way (feat. Snakehips)",
            singer: ["Sinead Harnett(시네이드 하넷)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Sinead%20Harnett(%EC%8B%9C%EB%84%A4%EC%9D%B4%EB%93%9C%20%ED%95%98%EB%84%B7)-N.O.W.jpg",
          },
          {
            title: "Sober",
            singer: ["Elli Ingram(엘리 잉그램)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Elli%20Ingram(%EC%97%98%EB%A6%AC%20%EC%9E%89%EA%B7%B8%EB%9E%A8)-Sober%20EP.jpg",
          },
        ],
      },
    ],
  },

  {
    mod: "당신은",
    title: "몽글몽글 감성 구름",
    desc: [
      "혼자 있는 시간을 좋아해 밖에 나가는 걸 귀찮아해요. 막상 나가면 누구보다 신나게 놀지만 사람 많은 곳에 가면 기가 빨려요. 개인주의적인 성향으로 친해지는 데 다소 오래 걸려요. 속에 숨겨진 관종끼가 있지만, 표출을 잘 하지 않아 무리에서 자발적인 아싸가 되기도 합니다.",
      "말로 표현하지 않지만 속으로 은밀하게 두루 관찰하는 스타일입니다. 원칙과 원리를 중요하게 생각해 자기주관이 뚜렷한 사람이에요. 논리와 분석으로 문제를 해결하기 좋아한답니다. 다른 사람 말에 잘 휘둘리지 않고 자신의 원칙에 맞지 않은 사람을 싫어해요. 그래서 단호박이라는 말을 많이 들어요. ",
      "독립적인 성격을 보이지만 은근 어리바리한 허당끼도 있답니다. 반복적인 업무를 싫어하고 창의성이나 자신감이 부족한 사람을 견디지 못해요 . 남한테 관심이 없는 만큼 내 일에 관여하는 것도 싫어해 혼자 일하는 게 제일 좋아요. 일 이외에도 누군가가 간섭하는 걸 제일 싫어해요.",
    ],
    img: "cloud",
    thum: "https://d3coxo32tidngo.cloudfront.net/image/mbti/cloud_miri.png",
    miri: "https://d3coxo32tidngo.cloudfront.net/image/mbti/cloud_miri.png",
    channel: [
      {
        ch_name: "싸늘한 밤공기를 가르는 칠흑 같은 몽환 보이스",
        ch_img:
          "https://api2.indj.club/file/fetchImage/thumbnail/싸늘한 밤공기를 가르는 칠흑 같은 몽환 보이스.png",
        ch_song: [
          {
            title: "Sleep Baby Sleep",
            singer: ["Broods(브루즈)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Broods(%EB%B8%8C%EB%A3%A8%EC%A6%88)-Broods.jpg",
          },
          {
            title: "Teenage Fantasy",
            singer: ["Jorja Smith(조자 스미스)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Jorja%20Smith(%EC%A1%B0%EC%9E%90%20%EC%8A%A4%EB%AF%B8%EC%8A%A4)-Lost%20&%20Found_2020_11_20.jpg",
          },
          {
            title: "to the grave (feat. Mike Stud)",
            singer: ["Bea Miller(비 밀러)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Bea%20Miller(%EB%B9%84%20%EB%B0%80%EB%9F%AC)-chapter%20three-%20yellow_2020_11_12.jpg",
          },
          {
            title: "End Of May",
            singer: ["Keren Ann(케렌 앤)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Keren%20Ann(%EC%BC%80%EB%A0%8C%20%EC%95%A4)-Not%20Going%20Anywhere%20(Bonus%20Track).jpg",
          },
          {
            title: "Shades Of Cool",
            singer: ["Lana Del Rey(라나 델 레이)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Lana%20Del%20Rey(%EB%9D%BC%EB%82%98%20%EB%8D%B8%20%EB%A0%88%EC%9D%B4)-Shades%20Of%20Cool.jpg",
          },
          {
            title: "Mess Around",
            singer: ["Indiana(인디아나)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Indiana(%EC%9D%B8%EB%94%94%EC%95%84%EB%82%98)-No%20Romeo%20%5BDeluxe%5D.jpg",
          },
          {
            title: "Blue Lights",
            singer: ["Jorja Smith(조자 스미스)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Jorja%20Smith(%EC%A1%B0%EC%9E%90%20%EC%8A%A4%EB%AF%B8%EC%8A%A4)-Lost%20&%20Found_2020_11_20.jpg",
          },
          {
            title: "[19금]Feels",
            singer: ["Kiiara"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Kiiara-low%20kii%20savage.jpg",
          },
          {
            title: "Soft Universe",
            singer: ["Aurora(오로라)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Aurora(%EC%98%A4%EB%A1%9C%EB%9D%BC)-Infections%20Of%20A%20Different%20Kind%20%E2%80%93%20Step%201.jpg",
          },
          {
            title: "Wishlist",
            singer: ["Kiiara"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Kiiara-Wishlist.jpg",
          },
          {
            title: "Team",
            singer: ["Lorde(로드)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Lorde(%EB%A1%9C%EB%93%9C)-Pure%20Heroine%20(Extended).jpg",
          },
          {
            title: "Sideline (feat. Jazmine Sullivan)",
            singer: ["Niia(니아)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Niia(%EB%8B%88%EC%95%84)-I.jpg",
          },
          {
            title: "Gunshot",
            singer: ["Lykke Li(리키 리)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Lykke%20Li(%EB%A6%AC%ED%82%A4%20%EB%A6%AC)-I%20Never%20Learn.jpg",
          },
          {
            title: "Habits (Stay High)",
            singer: ["Tove Lo(토브 로)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Tove%20Lo(%ED%86%A0%EB%B8%8C%20%EB%A1%9C)-Queen%20Of%20The%20Clouds.jpg",
          },
          {
            title: "bury a friend",
            singer: ["Billie Eilish(빌리 아일리시)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Billie%20Eilish(%EB%B9%8C%EB%A6%AC%20%EC%95%84%EC%9D%BC%EB%A6%AC%EC%8B%9C)-bury%20a%20friend_2020_11_20.jpg",
          },
          {
            title: "Vanessa",
            singer: ["Grimes(그라임스)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Grimes(%EA%B7%B8%EB%9D%BC%EC%9E%84%EC%8A%A4)-Darkbloom.jpg",
          },
          {
            title: "Tonight",
            singer: ["Lykke Li(리키 리)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Lykke%20Li(%EB%A6%AC%ED%82%A4%20%EB%A6%AC)-Youth%20Novels.jpg",
          },
          {
            title: "Teardrop",
            singer: ["Massive Attack(매시브 어택)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Massive%20Attack(%EB%A7%A4%EC%8B%9C%EB%B8%8C%20%EC%96%B4%ED%83%9D)-Mezzanine_2020_11_17.jpg",
          },
          {
            title: "Six Feet Under",
            singer: ["Billie Eilish(빌리 아일리시)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Billie%20Eilish(%EB%B9%8C%EB%A6%AC%20%EC%95%84%EC%9D%BC%EB%A6%AC%EC%8B%9C)-Six%20Feet%20Under_2020_11_20.jpg",
          },
          {
            title: "Killing You",
            singer: ["Broods(브루즈)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Broods(%EB%B8%8C%EB%A3%A8%EC%A6%88)-Evergreen.jpg",
          },
          {
            title: "Desert (Avril Puzzle Mix)",
            singer: ["Emilie Simon(에밀리 시몽)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Emilie%20Simon(%EC%97%90%EB%B0%80%EB%A6%AC%20%EC%8B%9C%EB%AA%BD)-Desert.jpg",
          },
          {
            title: "[19금]Fuck With Myself",
            singer: ["Banks(뱅크스)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Banks(%EB%B1%85%ED%81%AC%EC%8A%A4)-Fuck%20With%20Myself.jpg",
          },
          {
            title: "Nobody",
            singer: ["Niia(니아)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Niia(%EB%8B%88%EC%95%84)-I.jpg",
          },
          {
            title: "The Seed",
            singer: ["Aurora(오로라)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Aurora(%EC%98%A4%EB%A1%9C%EB%9D%BC)-The%20Seed.jpg",
          },
        ],
      },
      {
        ch_name: "비 오는 흐린 날, 인디 포크에 젖고 싶은 날 ",
        ch_img:
          "https://api2.indj.club/file/fetchImage/thumbnail/7039_album.png",
        ch_song: [
          {
            title: "Paul",
            singer: ["혁오 (HYUKOH)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%ED%98%81%EC%98%A4%20(HYUKOH)-23_2021_01_19.jpg",
          },
          {
            title: "Hero",
            singer: ["짙은(Zitten)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%A7%99%EC%9D%80(Zitten)-diaspora%20-%20%ED%9D%A9%EC%96%B4%EC%A7%84%20%EC%82%AC%EB%9E%8C%EB%93%A4_2020_11_21.jpg",
          },
          {
            title: "너란 기억이 주르륵",
            singer: ["메리스윗카페"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%A9%94%EB%A6%AC%EC%8A%A4%EC%9C%97%EC%B9%B4%ED%8E%98-%EB%84%88%EB%9E%80%20%EA%B8%B0%EC%96%B5%EC%9D%B4%20%EC%A3%BC%EB%A5%B4%EB%A5%B5.jpg",
          },
          {
            title: "비오는 날",
            singer: ["루싸이트 토끼"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%A3%A8%EC%8B%B8%EC%9D%B4%ED%8A%B8%20%ED%86%A0%EB%81%BC-Twinkle%20Twinkle.jpg",
          },
          {
            title: "150728",
            singer: ["잇상"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%9E%87%EC%83%81-150728.jpg",
          },
          {
            title: "유통기한 (Story Ver.)",
            singer: ["좋아서하는밴드"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%A2%8B%EC%95%84%EC%84%9C%ED%95%98%EB%8A%94%EB%B0%B4%EB%93%9C-0%EC%A7%91%20-%20%EC%9C%A0%ED%86%B5%EA%B8%B0%ED%95%9C.jpg",
          },
          {
            title: "울지마",
            singer: ["브로콜리너마저"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%B8%8C%EB%A1%9C%EC%BD%9C%EB%A6%AC%EB%84%88%EB%A7%88%EC%A0%80-%EC%A1%B8%EC%97%85_2020_11_18.jpg",
          },
          {
            title: "타투 (Tattoo)",
            singer: ["적재"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%A0%81%EC%9E%AC-%ED%83%80%ED%88%AC%20(Tattoo).jpg",
          },
          {
            title: "소나기",
            singer: ["소년"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%86%8C%EB%85%84-%EC%86%8C%EB%85%84%201%EC%A7%91%20part%201.jpg",
          },
          {
            title: "이 빗속에 (Acoustic ver.)",
            singer: ["블루파프리카(Bluepaprika)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%B8%94%EB%A3%A8%ED%8C%8C%ED%94%84%EB%A6%AC%EC%B9%B4(Bluepaprika)-Midnight%20Song.jpg",
          },
          {
            title: "섬 (Queen of Diamonds)",
            singer: ["검정치마"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EA%B2%80%EC%A0%95%EC%B9%98%EB%A7%88-THIRSTY_2020_11_18.jpg",
          },
          {
            title: "Rain",
            singer: ["죠지"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%A3%A0%EC%A7%80-%5BVol.40%5D%20%EC%9C%A0%ED%9D%AC%EC%97%B4%EC%9D%98%20%EC%8A%A4%EC%BC%80%EC%B9%98%EB%B6%81%2010%EC%A3%BC%EB%85%84%20%ED%94%84%EB%A1%9C%EC%A0%9D%ED%8A%B8%20-%20%EC%8A%A4%EB%AC%B4%20%EB%B2%88%EC%A7%B8%20%EB%AA%A9%EC%86%8C%EB%A6%AC%20'%EC%9C%A0%EC%8A%A4%EC%BC%80%20X%20%EC%A3%A0%EC%A7%80'_2020_11_20.jpg",
          },
          {
            title: "THE DOOR",
            singer: ["적재"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%A0%81%EC%9E%AC-%ED%95%9C%EB%A7%88%EB%94%94_2020_11_25.jpg",
          },
          {
            title: "Ohio",
            singer: ["혁오 (HYUKOH)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%ED%98%81%EC%98%A4%20(HYUKOH)-20_2021_01_20.jpg",
          },
          {
            title: "와르르 ♥",
            singer: ["Colde(콜드)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Colde(%EC%BD%9C%EB%93%9C)-Love%20part%201_2021_01_20.jpg",
          },
          {
            title: "비",
            singer: ["폴킴(Paul Kim)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%ED%8F%B4%ED%82%B4(Paul%20Kim)-%EB%B9%84_2020_11_20.jpg",
          },
          {
            title: "곳에 따라 비",
            singer: ["가을방학"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EA%B0%80%EC%9D%84%EB%B0%A9%ED%95%99-%EA%B0%80%EC%9D%84%EB%B0%A9%ED%95%99_2020_11_18.jpg",
          },
          {
            title: "새벽에 내리는 비",
            singer: ["로코베리(Rocoberry)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%A1%9C%EC%BD%94%EB%B2%A0%EB%A6%AC(Rocoberry)-Merry%20Summer_2020_11_18.jpg",
          },
          {
            title: "TOMBOY",
            singer: ["혁오 (HYUKOH)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%ED%98%81%EC%98%A4%20(HYUKOH)-23_2021_01_19.jpg",
          },
          {
            title: "비오는 날엔",
            singer: ["소심한 오빠들(Sosimboys)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%86%8C%EC%8B%AC%ED%95%9C%20%EC%98%A4%EB%B9%A0%EB%93%A4(Sosimboys)-%EB%B9%84%EC%98%A4%EB%8A%94%20%EB%82%A0%EC%97%94.jpg",
          },
          {
            title: "잠시 빗소리를 좀 들어볼까",
            singer: ["헤이즐"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%ED%97%A4%EC%9D%B4%EC%A6%90-%EC%9E%A0%EC%8B%9C%20%EB%B9%97%EC%86%8C%EB%A6%AC%EB%A5%BC%20%EC%A2%80%20%EB%93%A4%EC%96%B4%EB%B3%BC%EA%B9%8C.jpg",
          },
          {
            title: "하려고해고백",
            singer: ["죠지"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%A3%A0%EC%A7%80-cassette_2020_11_24.jpg",
          },
          {
            title: "Rainy",
            singer: ["어쿠루브(Acourve)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%96%B4%EC%BF%A0%EB%A3%A8%EB%B8%8C(Acourve)-First%20Step_2020_11_20.jpg",
          },
          {
            title: "여름밤 소나기",
            singer: ["정흠밴드(Jungheum Band)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%A0%95%ED%9D%A0%EB%B0%B4%EB%93%9C(Jungheum%20Band)-How%20are%20you.jpg",
          },
          {
            title: "EVERYTHING",
            singer: ["검정치마"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EA%B2%80%EC%A0%95%EC%B9%98%EB%A7%88-EVERYTHING.jpg",
          },
          {
            title: "와리가리",
            singer: ["혁오 (HYUKOH)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%ED%98%81%EC%98%A4%20(HYUKOH)-22_2021_01_19.jpg",
          },
          {
            title: "나랑 아니면",
            singer: ["검정치마"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EA%B2%80%EC%A0%95%EC%B9%98%EB%A7%88-TEAM%20BABY_2021_01_20.jpg",
          },
        ],
      },
      {
        ch_name: "무언가 느껴지는 목요일 밤",
        ch_img:
          "https://api2.indj.club/file/fetchImage/thumbnail/151744_album.png",
        ch_song: [
          {
            title: "Moonlight",
            singer: ["92914"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/92914-Moonlight.jpg",
          },
          {
            title: "Okinawa (오키나와)",
            singer: ["92914"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/92914-Okinawa_2020_11_21.jpg",
          },
          {
            title: "틈",
            singer: ["이유하"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%9D%B4%EC%9C%A0%ED%95%98-Womb.jpg",
          },
          {
            title: "134340",
            singer: ["다린"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%8B%A4%EB%A6%B0-134340_2020_11_18.jpg",
          },
          {
            title: "떠나지마",
            singer: ["양다일"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%96%91%EB%8B%A4%EC%9D%BC-Us.jpg",
          },
          {
            title: "시소",
            singer: ["애쉬락(AshRock)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%95%A0%EC%89%AC%EB%9D%BD(AshRock)-%EC%8B%9C%EC%86%8C.jpg",
          },
          {
            title: "석양 (Feat. 스텔라장)",
            singer: ["플레인(Pleyn)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%ED%94%8C%EB%A0%88%EC%9D%B8(Pleyn)-%EC%84%9D%EC%96%91.jpg",
          },
          {
            title: "Monglong",
            singer: ["기덕(9duck)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EA%B8%B0%EB%8D%95(9duck)-500.jpg",
          },
          {
            title: "담담하게",
            singer: ["Lucia(심규선)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Lucia(%EC%8B%AC%EA%B7%9C%EC%84%A0)-%EA%BD%83%EA%B7%B8%EB%8A%98.jpg",
          },
          {
            title: "Eternal Tunnel",
            singer: ["신설희"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%8B%A0%EC%84%A4%ED%9D%AC-Surge%207.4_2020_11_20.jpg",
          },
          {
            title: "XYZ",
            singer: ["니화 (NiiHWA)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%8B%88%ED%99%94%20(NiiHWA)-XYZ.jpg",
          },
          {
            title: "빙그르르 (feat. Greenblue)",
            singer: ["알레프(ALEPH)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%95%8C%EB%A0%88%ED%94%84(ALEPH)-1_2020_11_20.jpg",
          },
          {
            title: "섬",
            singer: ["민수"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%AF%BC%EC%88%98-%EC%84%AC.jpg",
          },
          {
            title: "모두 주세요",
            singer: ["신해경"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%8B%A0%ED%95%B4%EA%B2%BD-%EB%82%98%EC%9D%98%20%EA%B0%80%EC%97%AD%EB%B0%98%EC%9D%91.jpg",
          },
          {
            title: "생일 노래",
            singer: ["민수"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%AF%BC%EC%88%98-%EC%83%9D%EC%9D%BC%20%EB%85%B8%EB%9E%98.jpg",
          },
          {
            title: "Cruising (Studio Live Ver.)",
            singer: ["애쉬락(AshRock)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%95%A0%EC%89%AC%EB%9D%BD(AshRock)-Cruising.jpg",
          },
          {
            title: "남과 남은",
            singer: ["홍크(HONK)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%ED%99%8D%ED%81%AC(HONK)-Gaerong.jpg",
          },
          {
            title: "Cinder Cone",
            singer: ["신설희"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%8B%A0%EC%84%A4%ED%9D%AC-Cinder%20Cone.jpg",
          },
          {
            title: "Reality",
            singer: ["기덕(9duck)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EA%B8%B0%EB%8D%95(9duck)-Reality.jpg",
          },
          {
            title: "Pearls",
            singer: ["퓨어킴(Puer Kim)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%ED%93%A8%EC%96%B4%ED%82%B4(Puer%20Kim)-GEM.jpg",
          },
          {
            title: "Night Drive",
            singer: ["이채언루트(Echae En Route)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%9D%B4%EC%B1%84%EC%96%B8%EB%A3%A8%ED%8A%B8(Echae%20En%20Route)-Night%20Drive.jpg",
          },
          {
            title: "Difference",
            singer: ["다섯(Dasutt)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%8B%A4%EC%84%AF(Dasutt)-%E6%BC%A0(%EB%A7%89)_2020_11_20.jpg",
          },
          {
            title: "Weatherman",
            singer: ["솔튼페이퍼(SALTNPAPER)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%86%94%ED%8A%BC%ED%8E%98%EC%9D%B4%ED%8D%BC(SALTNPAPER)-More%20Than%20Just%20Circles.jpg",
          },
          {
            title: "her",
            singer: ["오존(O3ohn)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%98%A4%EC%A1%B4(O3ohn)-%5BO%5D_2020_11_20.jpg",
          },
        ],
      },
    ],
  },

  {
    mod: "당신은",
    title: "이슬비처럼 촉촉한 감성의 소유자 ",
    desc: [
      "목표지향적이며 독립적이고 맡은일에 최선을 다며 남의 눈치를 잘 보지 않는 스타일! 스스로가 정체되어 있는 것을 싫어해서 끊임없이 노력하는 타입이네요",
      "자신만의 세계에 푹 빠져있어서 남에게 관심이 그리 많지 않아요. 그래서 새로운 사람을 만나는 걸 반가워하지 않고, 사람의 얼굴과 이름을 잘 기억하지 못해요. 특정 분야에 깊은 지식을 가진 사람이 많으며 창의적입니다.",
      "자신의 감정 표현에 서툴러 주변 사람들에게 시크하고 차가워 보일 수 있겠네요. 하지만 본인은 그걸로 딱히 스트레스를 받지도 않습니다. 친한 사람과 있을 때는 말이 많아져요. 개인주의적인 성향이 강하고 하기 싫은 건 죽어도 안 해요. 그래도 해야 하는 일은 또 완벽히 해내려 하는 완벽주의자의 모습을 보이기도 합니다.",
      "매사에 진지한 편이라 유머에는 소질이 없어요. 만약 본인이 재밌는 사람이라고 생각한다면 그건 다른 사람의 말도 들어봐야 합니다.",
    ],
    img: "rain",
    thum: "https://d3coxo32tidngo.cloudfront.net/image/mbti/rain.png",
    miri: "https://d3coxo32tidngo.cloudfront.net/image/mbti/rain_miri.png",
    channel: [
      {
        ch_name: "비 내리는 골목의 희미한 불빛, 시티팝",
        ch_img:
          "https://api2.indj.club/file/fetchImage/thumbnail/비 내리는 골목의 희미한 불빛, 시티팝.png",
        ch_song: [
          {
            title: "EVERYTHING",
            singer: ["검정치마"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EA%B2%80%EC%A0%95%EC%B9%98%EB%A7%88-EVERYTHING.jpg",
          },
          {
            title: "청춘 (Day)",
            singer: ["우효(Oohyo)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%9A%B0%ED%9A%A8(Oohyo)-%EC%B2%AD%EC%B6%98_2020_11_18.jpg",
          },
          {
            title: "선 (Line)",
            singer: ["김아름"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EA%B9%80%EC%95%84%EB%A6%84-Lonely.jpg",
          },
          {
            title: "& New",
            singer: ["아이디(Eyedi)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%95%84%EC%9D%B4%EB%94%94(Eyedi)-&%20New.jpg",
          },
          {
            title: "Same",
            singer: ["Kisnue(키스누)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Kisnue(%ED%82%A4%EC%8A%A4%EB%88%84)-Same.jpg",
          },
          {
            title: "몽경요곡 (feat. 고냉)",
            singer: ["해파리 소년(Jelly Boy)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%ED%95%B4%ED%8C%8C%EB%A6%AC%20%EC%86%8C%EB%85%84(Jelly%20Boy)-%EB%AA%BD%EA%B2%BD%EC%9A%94%EA%B3%A1%20(%E5%A4%A2%E5%A2%83%E6%8B%97%E5%93%AD).jpg",
          },
          {
            title: "Drive (feat. 죠지)",
            singer: ["김현철"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EA%B9%80%ED%98%84%EC%B2%A0-Fe's%2010th%20-%20Preview_2020_11_18.jpg",
          },
          {
            title: "생각 생각 생각 (Feat. 수민)",
            singer: ["뮤지"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%AE%A4%EC%A7%80-Color%20of%20night.jpg",
          },
          {
            title: "℃ (도시) (prod.Noden)",
            singer: ["Lay.bn"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Lay.bn-Diving.jpg",
          },
          {
            title: "새벽 (midnight)",
            singer: ["김아름"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EA%B9%80%EC%95%84%EB%A6%84-%EC%83%88%EB%B2%BD%20(midnight).jpg",
          },
          {
            title: "Dive",
            singer: ["이루리"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%9D%B4%EB%A3%A8%EB%A6%AC-Dive.jpg",
          },
          {
            title: "Grace",
            singer: ["ADOY"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/ADOY-CATNIP.jpg",
          },
          {
            title: "서울의 밤 (Night in Seoul)",
            singer: ["uju(우주)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/uju(%EC%9A%B0%EC%A3%BC)-%EC%84%A0%EB%8D%B0%EC%9D%B4%EC%84%9C%EC%9A%B8%20Ep.2.jpg",
          },
          {
            title: "난 아니에요",
            singer: ["검정치마"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EA%B2%80%EC%A0%95%EC%B9%98%EB%A7%88-TEAM%20BABY_2021_01_20.jpg",
          },
          {
            title: "Savior",
            singer: ["DOHE(도헤)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/DOHE(%EB%8F%84%ED%97%A4)-Luminous%20Destiny.jpg",
          },
          {
            title: "봄날의 사케 (feat. Sugar Flow)",
            singer: ["HUS(허밍어반스테레오)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/HUS(%ED%97%88%EB%B0%8D%EC%96%B4%EB%B0%98%EC%8A%A4%ED%85%8C%EB%A0%88%EC%98%A4)-Easy%20Come,%20Easy%20Go.jpg",
          },
          {
            title: "Cocoshine",
            singer: ["divin'"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/divin'-The%20Puzzle%20Of%20Time.jpg",
          },
          {
            title: "오래된 친구",
            singer: ["빛과 소금"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%B9%9B%EA%B3%BC%20%EC%86%8C%EA%B8%88-%EC%98%A4%EB%9E%98%EB%90%9C%20%EC%B9%9C%EA%B5%AC.jpg",
          },
          {
            title: "좋아하고 있어요",
            singer: ["유키카"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%9C%A0%ED%82%A4%EC%B9%B4-%EC%A2%8B%EC%95%84%ED%95%98%EA%B3%A0%20%EC%9E%88%EC%96%B4%EC%9A%94.jpg",
          },
          {
            title: "오늘같은 밤",
            singer: ["nokdu"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/nokdu-nokdu%20ep%20vol.1.jpg",
          },
        ],
      },
      {
        ch_name: "봄비가 뚝뚝 내리던 어느 흐린 날",
        ch_img:
          "https://api2.indj.club/file/fetchImage/thumbnail/봄비가 뚝뚝 내리던 어느 흐린 날.png",
        ch_song: [
          {
            title: "답을 찾지 못한 날",
            singer: ["윤하(Younha/ユンナ)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%9C%A4%ED%95%98(Younha-%E3%83%A6%E3%83%B3%E3%83%8A)-RescuE_2020_11_18.jpg",
          },
          {
            title: "느낌",
            singer: ["폴킴(Paul Kim)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%ED%8F%B4%ED%82%B4(Paul%20Kim)-%EC%A0%95%EA%B7%9C%201%EC%A7%91%20Part.2%20'%ED%84%B0%EB%84%90'.jpg",
          },
          {
            title: "스물다섯, 스물하나",
            singer: ["자우림(Jaurim)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%9E%90%EC%9A%B0%EB%A6%BC(Jaurim)-Goodbye,%20grief._2021_01_19.jpg",
          },
          {
            title: "내가 사라졌으면 좋겠어",
            singer: ["옥상달빛"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%98%A5%EC%83%81%EB%8B%AC%EB%B9%9B-%ED%9D%AC%ED%95%9C%ED%95%9C%20%EC%8B%9C%EB%8C%80_2020_11_18.jpg",
          },
          {
            title: "깊이 잠들어요",
            singer: ["한올"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%ED%95%9C%EC%98%AC-%EC%9E%91%EC%9D%80%20%EC%9C%84%EB%A1%9C_2020_11_20.jpg",
          },
          {
            title: "누워서 쓰는 일기 (feat. 존중)",
            singer: ["캣비(Cat B)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%BA%A3%EB%B9%84(Cat%20B)-12%20to%2016.jpg",
          },
          {
            title: "기억이 안나",
            singer: ["갱생 프로젝트(Rebirth Project)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EA%B0%B1%EC%83%9D%20%ED%94%84%EB%A1%9C%EC%A0%9D%ED%8A%B8(Rebirth%20Project)-%EA%B8%B0%EC%96%B5%EC%9D%B4%20%EC%95%88%EB%82%98.jpg",
          },
          {
            title: "우산",
            singer: ["윤하(Younha/ユンナ)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%9C%A4%ED%95%98(Younha-%E3%83%A6%E3%83%B3%E3%83%8A)-%EC%9A%B0%EC%82%B0.jpg",
          },
          {
            title: "소란했던 시절에",
            singer: ["빌리어코스티"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%B9%8C%EB%A6%AC%EC%96%B4%EC%BD%94%EC%8A%A4%ED%8B%B0-%EC%86%8C%EB%9E%80%ED%96%88%EB%8D%98%20%EC%8B%9C%EC%A0%88%EC%97%90.jpg",
          },
          {
            title: "나를 좋아하지 않는 그대에게",
            singer: ["박원"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%B0%95%EC%9B%90-Like%20A%20Wonder_2020_11_20.jpg",
          },
          {
            title: "한걸음 한걸음",
            singer: ["김태현"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EA%B9%80%ED%83%9C%ED%98%84-%ED%95%9C%EA%B1%B8%EC%9D%8C%20%ED%95%9C%EA%B1%B8%EC%9D%8C.jpg",
          },
          {
            title: "그냥 (Just)",
            singer: ["Zion.T", "Crush"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Zion.T,%20Crush-Young_2021_01_20.jpg",
          },
          {
            title: "그의 바다",
            singer: ["백예린"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%B0%B1%EC%98%88%EB%A6%B0-Bye%20bye%20my%20blue_2021_01_20.jpg",
          },
          {
            title: "수면제 (Lullaby)",
            singer: ["온유 (ONEW)", "로코베리(Rocoberry)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%98%A8%EC%9C%A0%20(ONEW),%20%EB%A1%9C%EC%BD%94%EB%B2%A0%EB%A6%AC(Rocoberry)-%EC%88%98%EB%A9%B4%EC%A0%9C%20(Lullaby)%20-%20SM%20STATION.jpg",
          },
          {
            title: "안아줘",
            singer: ["정준일"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%A0%95%EC%A4%80%EC%9D%BC-Lo9ve3r4s_2020_11_21.jpg",
          },
          {
            title: "가끔 미치도록 네가 안고 싶어질 때가 있어",
            singer: ["가을방학"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EA%B0%80%EC%9D%84%EB%B0%A9%ED%95%99-%EA%B0%80%EC%9D%84%EB%B0%A9%ED%95%99_2020_11_18.jpg",
          },
          {
            title: "없었어 (feat. 장한)",
            singer: ["캣비(Cat B)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%BA%A3%EB%B9%84(Cat%20B)-Cat%20Begin.jpg",
          },
          {
            title: "instagram",
            singer: ["DEAN(딘)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/DEAN(%EB%94%98)-instagram_2021_01_20.jpg",
          },
          {
            title: "이별후애 (feat. 은희)",
            singer: ["랩젠(Rapzen)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%9E%A9%EC%A0%A0(Rapzen)-%EC%9D%B4%EB%B3%84%ED%9B%84%EC%95%A0.jpg",
          },
          {
            title: "서툰 마음 (Prod. 정키)",
            singer: ["수지(SUZY)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%88%98%EC%A7%80(SUZY)-Faces%20of%20Love_2020_11_12.jpg",
          },
          {
            title: "끝",
            singer: ["권진아"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EA%B6%8C%EC%A7%84%EC%95%84-%EC%9B%83%EA%B8%B4%20%EB%B0%A4.jpg",
          },
          {
            title: "Bye bye my blue",
            singer: ["백예린"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%B0%B1%EC%98%88%EB%A6%B0-Bye%20bye%20my%20blue_2021_01_20.jpg",
          },
          {
            title: "흔적 (feat. Chancey The Glow)",
            singer: ["로즈몬드(Rosemond)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%A1%9C%EC%A6%88%EB%AA%AC%EB%93%9C(Rosemond)-Weather%20Report.jpg",
          },
          {
            title: "사랑이 잘 (With 오혁)",
            singer: ["아이유(IU)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%95%84%EC%9D%B4%EC%9C%A0(IU)-%EC%82%AC%EB%9E%91%EC%9D%B4%20%EC%9E%98_2021_01_20.jpg",
          },
          {
            title: "언제쯤이면 (duet with 아이유)",
            singer: ["윤현상"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%9C%A4%ED%98%84%EC%83%81-%ED%94%BC%EC%95%84%EB%85%B8%ED%8F%AC%EB%A5%B4%ED%85%8C%20(Pianoforte).jpg",
          },
          {
            title: "울어도 괜찮아",
            singer: ["인아(In.a)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%9D%B8%EC%95%84(In.a)-%EC%9A%B8%EC%96%B4%EB%8F%84%20%EA%B4%9C%EC%B0%AE%EC%95%84_2020_11_18.jpg",
          },
          {
            title: "슬픔은 나의 몫",
            singer: ["소울라이츠(Soulights)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%86%8C%EC%9A%B8%EB%9D%BC%EC%9D%B4%EC%B8%A0(Soulights)-Solace.jpg",
          },
          {
            title: "Like You",
            singer: ["Hoody (후디)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Hoody%20(%ED%9B%84%EB%94%94)-Like%20You.jpg",
          },
          {
            title: "시간을 믿었어",
            singer: ["윤하(Younha/ユンナ)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%9C%A4%ED%95%98(Younha-%E3%83%A6%E3%83%B3%E3%83%8A)-Subsonic.jpg",
          },
          {
            title: "난 네가 (Rainy ver.) (feat. 남수림)",
            singer: ["제이에이(JA)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%A0%9C%EC%9D%B4%EC%97%90%EC%9D%B4(JA)-%EB%82%9C%20%EB%84%A4%EA%B0%80%20(Rainy%20ver.).jpg",
          },
          {
            title: "멀어지다",
            singer: ["넬(NELL)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%84%AC(NELL)-%EB%A9%80%EC%96%B4%EC%A7%80%EB%8B%A4_2020_11_16.jpg",
          },
        ],
      },
      {
        ch_name: "사랑은 둘이 하고 이별은 혼자 하네 ",
        ch_img:
          "https://api2.indj.club/file/fetchImage/thumbnail/7527_album.png",
        ch_song: [
          {
            title: "시간에 거는 기대",
            singer: ["나원주"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%82%98%EC%9B%90%EC%A3%BC-%EB%82%98%EC%9B%90%EC%A3%BC%202%EC%A7%91%20Movie%20Works%20+%20%EC%95%BC%EC%88%98%EC%99%80%20%EB%AF%B8%EB%85%80%20O.S.T..jpg",
          },
          {
            title: "마지막 사랑",
            singer: ["박기영"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%B0%95%EA%B8%B0%EC%98%81-Promise_2021_01_19.jpg",
          },
          {
            title: "왜죠",
            singer: ["Lucia(심규선)", "윤덕원(브로콜리너마저)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Lucia(%EC%8B%AC%EA%B7%9C%EC%84%A0),%20%EC%9C%A4%EB%8D%95%EC%9B%90(%EB%B8%8C%EB%A1%9C%EC%BD%9C%EB%A6%AC%EB%84%88%EB%A7%88%EC%A0%80)-New%20Days%20&%20New%20Beginning%20(Ten%20Years%20After-%20Pastel%20Music%2010th%20Anniversary).jpg",
          },
          {
            title: "거짓말 같은 시간 (feat. 김연우)",
            singer: ["토이(Toy)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%ED%86%A0%EC%9D%B4(Toy)-A%20Night%20In%20Seoul_2020_11_16.jpg",
          },
          {
            title: "안되나요",
            singer: ["휘성(Realslow)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%ED%9C%98%EC%84%B1(Realslow)-YG%2010th%20-%20YG%2010%EC%A3%BC%EB%85%84%20%EA%B8%B0%EB%85%90%20%EC%9D%8C%EB%B0%98.jpg",
          },
          {
            title: "그럴 수도 있었을 것이다",
            singer: ["김거지"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EA%B9%80%EA%B1%B0%EC%A7%80-%EB%8B%AC%EB%8F%99%EB%84%A4.jpg",
          },
          {
            title: "날마다 타인",
            singer: ["한희정"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%ED%95%9C%ED%9D%AC%EC%A0%95-%EB%82%A0%EB%A7%88%EB%8B%A4%20%ED%83%80%EC%9D%B8.jpg",
          },
          {
            title: "사랑의 말 (HOW MUCH I LOVE YOU)",
            singer: ["브라운 아이드 소울(Brown Eyed Soul)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%B8%8C%EB%9D%BC%EC%9A%B4%20%EC%95%84%EC%9D%B4%EB%93%9C%20%EC%86%8C%EC%9A%B8(Brown%20Eyed%20Soul)-BROWN%20EYED%20SOUL%20%EC%A0%95%EA%B7%9C%204%EC%A7%91%20'SOUL%20COOKE'_2020_11_20.jpg",
          },
          {
            title: "제발, 하지만",
            singer: ["윤덕원(브로콜리너마저)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%9C%A4%EB%8D%95%EC%9B%90(%EB%B8%8C%EB%A1%9C%EC%BD%9C%EB%A6%AC%EB%84%88%EB%A7%88%EC%A0%80)-%ED%9D%90%EB%A6%B0%20%EA%B8%B8.jpg",
          },
          {
            title: "너는 나의 봄이다",
            singer: ["성시경"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%84%B1%EC%8B%9C%EA%B2%BD-%EC%8B%9C%ED%81%AC%EB%A6%BF%20%EA%B0%80%EB%93%A0%20(SBS%20%EC%A3%BC%EB%A7%90%EB%93%9C%EB%9D%BC%EB%A7%88)%20-%20Part.4.jpg",
          },
          {
            title: "고작",
            singer: ["오지은"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%98%A4%EC%A7%80%EC%9D%80-%5B3%5D_2020_11_21.jpg",
          },
          {
            title: "잔향",
            singer: ["김동률"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EA%B9%80%EB%8F%99%EB%A5%A0-%ED%86%A0%EB%A1%9C(%E5%90%90%E9%9C%B2)_2020_11_12.jpg",
          },
          {
            title: "중독된 사랑",
            singer: ["조장혁"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%A1%B0%EC%9E%A5%ED%98%81-Love%20-%20%EC%A4%91%EB%8F%85%EB%90%9C%20%EC%82%AC%EB%9E%91_2020_11_16.jpg",
          },
          {
            title: "Replay",
            singer: ["김동률"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EA%B9%80%EB%8F%99%EB%A5%A0-kimdongrYULE_2020_11_12.jpg",
          },
          {
            title: "아프고 아픈 이름...",
            singer: ["Ann One"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Ann%20One-Infinite%20Wave%20Of%20Love.jpg",
          },
          {
            title: "사랑했나요",
            singer: ["나원주"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%82%98%EC%9B%90%EC%A3%BC-%EB%82%98%EC%9B%90%EC%A3%BC%201.jpg",
          },
          {
            title: "난치병(難治病)",
            singer: ["하림"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%ED%95%98%EB%A6%BC-%EB%8B%A4%EC%A4%91%EC%9D%B8%EA%B2%A9%EC%9E%90(%E5%A4%9A%E9%87%8D%E4%BA%BA%E6%A0%BC%E8%80%85)_2020_11_18.jpg",
          },
          {
            title: "잘 지내자, 우리",
            singer: ["짙은(Zitten)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%A7%99%EC%9D%80(Zitten)-%EC%82%AC%EB%9E%91%EC%9D%98%20%EB%8B%A8%EC%83%81%20Chapter%205%20-%20The%20Letter%20From%20Nowhere_2020_11_21.jpg",
          },
          {
            title: "어떤 날도, 어떤 말도",
            singer: ["Lucia(심규선)"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/Lucia(%EC%8B%AC%EA%B7%9C%EC%84%A0)-%EC%9E%90%EA%B8%B0%EB%A7%8C%EC%9D%98%20%EB%B0%A9_2020_11_16.jpg",
          },
          {
            title: "어떻게 사랑이 그래요",
            singer: ["이승환"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EC%9D%B4%EC%8A%B9%ED%99%98-Hwantastic_2020_11_16.jpg",
          },
          {
            title: "기억의 습작",
            singer: ["김동률"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EA%B9%80%EB%8F%99%EB%A5%A0-Thanks-%20The%20Best%20Songs%201994-2004.jpg",
          },
          {
            title: "오늘처럼 다시 눈이 내리면",
            singer: ["나원주"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%82%98%EC%9B%90%EC%A3%BC-%EB%82%98%EC%9B%90%EC%A3%BC%202%EC%A7%91%20Movie%20Works%20+%20%EC%95%BC%EC%88%98%EC%99%80%20%EB%AF%B8%EB%85%80%20O.S.T..jpg",
          },
          {
            title: "미아(迷兒)",
            singer: ["박정현"],
            thumbnail:
              "https://api2.indj.club/file/fetchImage/cover/%EB%B0%95%EC%A0%95%ED%98%84-On%20&%20On.jpg",
          },
        ],
      },
    ],
  },
];
