import React from "react";
import "../style/err.scss";

const Loading = () => {
  return (
    <div className="err">
      <div className="err-img server" alt="">
        <h1 className="err-text title">서버 점검중입니다.</h1>
        <h2 className="err-text sub-title">
          서비스 이용에 불편을 끼쳐드려서죄송합니다.
          <br /> 더 나은 서비스를 위해 점검 중입니다.
        </h2>
      </div>
    </div>
  );
};
export default Loading;
