import React, { useState } from 'react';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { _footer } from 'recoil/state';
import '../../../index.css';
import './Advert.scss';
import Step from './Step/Step';

const Advert = () => {
  // const [_, setFooter] = useRecoilState(_footer);
  // useEffect(() => {
  //   setFooter(false);
  // }, []);

  const [scrollY, setScrollY] = useState(0);

  const handleScroll = () => {
    const scrollPosition = window.pageYOffset;
    setScrollY(scrollPosition);
  };
  const goToapp = () => {
    window.location.href =
      'https://apps.apple.com/kr/app/indj-%EC%9E%90%EC%9C%A0%EB%A1%AD%EA%B2%8C-%EB%93%A3%EB%8A%94-%EC%83%81%ED%99%A9-%EA%B0%90%EC%84%B1-%EC%9D%B8%EA%B3%B5%EC%A7%80%EB%8A%A5-%EC%9D%8C%EC%95%85/id1513542512';
  };
  const goTogoogle = () => {
    window.location.href = 'https://play.google.com/store/apps/details?id=com.indj.music';
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  console.log(scrollY);
  return (
    <div id="advert">
      <div className="advert_container">
        <div className="in">
          <div className="mainheader">
            <span>스테이션</span>
            <br />
            <span>무료 듣기 이벤트 !</span>
          </div>

          <div className="subheader">
            <span>WHAT? 30초 광고만 보면</span>
            <br />
            <div>
              <span className="subheader_bold">30분 음악 이용권</span>을 받을 수 있다고?
            </div>
          </div>
          <div className="middleimg">
            <img
              className="ticket"
              alt="패스티켓"
              src="https://indj.s3.ap-northeast-2.amazonaws.com/image/event/advert_ticket.png"
            />
          </div>
        </div>
        <div className="in">
          <Step></Step>
        </div>

        <div className={scrollY < 1200 && scrollY < 1705 ? 'out' : 'in'}>
          <div className="notice">
            <div className="notice_header">
              <span className="notice_way">유의사항</span>
            </div>

            <div className="notice_list">
              <ul>
                <li>광고를 뛰어넘기 하지 않기</li>
                <li>1일 최대 5회 광고 시청에 대한 보상 가능</li>
                <li>어뷰징 확인 될 경우 지급 대상 제외</li>
              </ul>
            </div>
          </div>

          <div className="download">
            <div className="download_text">
              <span>만약 아직 inDJ 회원이 아니시라면?</span>
              <br />
              <span>스마트폰에서 다운로드 해주세요.</span>
            </div>
            <div className="download_link">
              <div className="download_store" onClick={goToapp}>
                <img src="https://indj.s3.ap-northeast-2.amazonaws.com/image/event/appstore.png" alt="앱스토어"></img>
              </div>
              <div className="download_store" onClick={goTogoogle}>
                <img
                  src="https://indj.s3.ap-northeast-2.amazonaws.com/image/event/playstore.png"
                  alt="플레이스토어"
                ></img>
              </div>
            </div>
          </div>

          <div className="lastline">
            <img
              src="https://indj.s3.ap-northeast-2.amazonaws.com/image/event/advert_lastline.png"
              alt="마지막선"
            ></img>
          </div>

          <div className="lasttext">
            <div>
              음악은 당신의 <span className="lasttext_bold">상황</span>과 <span className="lasttext_bold">감정</span>을
              잘 느끼고 소통합니다.
            </div>

            <div>
              당신보다 더 당신을 잘 아는 <span className="lasttext_bold">음악 메타버스 플랫폼</span>
            </div>

            <div>이제 만나러 갑니다!</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Advert;
