import React, { useState, useEffect } from 'react';
import { getBannerApi } from 'apis/apis';
import './promotion.scss';
import { useRecoilState } from 'recoil';
import { _footer } from 'recoil/state';
import end from './sample_img/end.png';
import ing from './sample_img/ing.png';

const PromotionNode = (data) => {
  return (
    <div className="Promotion">
      <img src={data.banner} alt="배너" />
      <div className="description_lab">
        <div className="description">
          {data.name}
          <div className="date">
            기간 : {data.start_at} ~ {data.expire_at}
          </div>
        </div>
        <div className="event">{data.flag ? <img src={ing} alt="진행중" /> : <img src={end} alt="종료" />}</div>
      </div>
    </div>
  );
};

const Promotion = () => {
  const [promotionList, setPromotionList] = useState([]);
  const [_, setFooter] = useRecoilState(_footer);

  const getApi = async () => {
    const {
      data: { promotion },
    } = await getBannerApi();

    setPromotionList(promotion);
  };
  useEffect(() => {
    setFooter(false);
    getApi();
  }, []);

  return (
    <div className="notting" onClick={(e) => console.log()}>
      {promotionList.map((el, idx) => (
        <PromotionNode data={el} key={idx} />
      ))}
    </div>
  );
};
export default Promotion;
