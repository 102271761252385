import React from 'react';
import TermsUp from './Terms_up';
import { NavLink } from 'react-router-dom';
import '../css/terms.css';
import { Upload } from 'antd';

const toTop = () => {
  window.scroll({
    behavior: 'smooth',
    left: 0,
    top: 0,
  });
};

const Terms_loc = ({ match }) => {
  return (
    <div className="app dk" id="app">
      <div id="content" className="app-content mopt white bg box-shadow-z2" role="main">
        <div id="subContentsWrap" className="noDepth">
          <TermsUp></TermsUp>
          <h2 className="contentTitle nobar">청소년 보호정책</h2>

          <div className="innerWrapin subContents">
            <div className="ruleBlock">
              <div className="ruleBlockin">
                <div className="ruleCont">
                  <h4 className="tit">제1조 (청소년 보호를 위한 목표 및 기본 원칙)</h4>
                  <ol className="number-list">
                    <li>
                      청소년이 정신적·신체적으로 유해한 환경으로부터 보호받고 건전하게 성장할 수 있도록 노력하며,
                      안전하게 인터넷을 이용할 수 있는 서비스를 제공하기 위해 청소년보호정책을 명시하고 있습니다.
                    </li>
                  </ol>
                </div>

                <div className="ruleCont">
                  <h4 className="tit">제2조 (청소년유해정보에 대한 청소년 보호 장치 및 관리 조치)</h4>
                  <ol className="number-list">
                    <li>
                      청소년이 아무런 제한장치 없이 유해정보에 노출되지 않도록 청소년 유해매체물에 대해서 연령인증장치를
                      마련, 적용하고 있기에 성인 인증 장치가 마련된 서비스에 대해서는 이용 범위를 제한하게 하는 등 관
                      리를 진행 또는 준비하고 있으며 불법적이거나 청소년에 유해한 키워드에 대한 내용 및 콘텐츠를
                      지속적으로 관리하고 있습니다.
                    </li>
                  </ol>
                </div>
                <div className="ruleCont">
                  <h4 className="tit">제3조 (청소년유해정보로 인한 피해상담 및 고충 처리)</h4>
                  <ol className="number-list">
                    <li>
                      유해 정보로 인한 피해상담 및 고충 처리를 위한 전문인력을 배치하여 피해가 확산되지 않도록 하고
                      있습니다. 하단의 청소년보호책임자의 소속 및 성명, 연락처를 참고하시어 피해상담 및 고충 처리를
                      요청하 실 수도 있습니다. 자체적으로 처리가 곤란할 경우 피해 관련기관을 안내해 드리고 있습니다.
                    </li>
                  </ol>
                </div>
                <div className="ruleCont">
                  <h4 className="tit">제4조 (유해정보로부터 청소년을 보호하기 위한 교육)</h4>
                  <ol className="number-list">
                    <li>
                      청소년보호담당자 및 각 서비스 담당자들을 대상으로 청소년 보호를 위한 각종 관련 법령 및 제재 기준,
                      유해정보 발견 시 대처방법, 위반사항 처리에 대한 보고 절차 등을 교육하고 있습니다.
                    </li>
                  </ol>
                </div>
                <div className="ruleCont">
                  <h4 className="tit">제5조 (청소년보호책임자 및 담당자의 지정)</h4>
                  <ol className="number-list">
                    <li>
                      아래와 같이 청소년보호책임자 및 청소년보호담당자를 지정하여 청소년유해정보의 차단 및 관리, 청소년
                      유해 정보로부터의 청소년보호정책을 수립하는 등 건전한 인터넷 환경을 조성하기 위한 제반 활동을 전
                      개합니다.
                    </li>
                  </ol>
                </div>
                <div className="ruleCont">
                  <h4 className="tit">제6조 (서비스 이용 대상 연령 요건)</h4>
                  <ol className="number-list">
                    <li>
                      서비스를 사용하려면 만 14세 이상이어야 합니다. 하지만 부모나 법정대리인이 사용 설정하면 모든
                      연령대의 아동 또는 청소년이 서비스를 사용할 수 있습니다.
                      <br />
                      부모 또는 법정대리인의 허락
                      <br />
                      귀하가 거주 국가에서 법률상 미성년자에 해당하는 경우, 귀하는 서비스를 사용할 수 있도록 부모 또는
                      법정대리인의 허락을 받았음을 진술합니다. 부모 또는 법정대리인과 함께 본 계약을 숙지하시기
                      바랍니다. 거주 국가에서 법률상 미성년자로 간주되는 사용자의 부모 또는 법정대리인이 사용자의 서비스
                      사용을 허락한 경우, 부모 또는 법정대리인은 본 계약 조건의 적용을 받게 되며, 미성년 사용자의 서비스
                      이용 활 동에 대한 책임을 집니다.
                    </li>
                  </ol>
                </div>

                <div className="ruleBlock">
                  <h3>부칙</h3>
                  <div className="ruleBlockin">
                    <div className="ruleCont">
                      <h4 className="tit">제1조 (시행일)</h4>
                      <ol className="num-circle-list">
                        <li>본 약관은 2022년 12월 20일부터 시행됩니다.</li>
                      </ol>
                    </div>

                    <div className="ruleCont">
                      <h4 className="tit">제2조 (청소년 보호 책임자 및 담당자)</h4>
                      <ol className="num-circle-list">
                        <li>성명 : 윤형만</li>
                        <li>소속 : 인디제이서비스본부</li>
                        <li>직위 : 본부장</li>
                        <li>대표전화 : 070-7779-0733</li>
                        <li>문의하기 : mst@indj.net</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div onClick={() => toTop()}>
        <img className="uparr" src="/../images/up.png" alt="upImg"></img>
      </div>
    </div>
  );
};
export default Terms_loc;
